import React, { useState } from 'react';
import GlobalModal from '.';
import { Card, Grid, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { setUnblockUser } from '../../redux/users/actions';
import {unblockUser} from "../../service/users";

const UnblockConfirm = ({open, handleClose, activeUser, enqueueSnackbar, dispatchUnblockUser}) => {
    const [loading, setLoading] = useState(false);

    const handleDelete = () => {
        setLoading(true);
        unblockUser(activeUser._id, handleSuccess, handleError);
    }

    const handleSuccess = (data) => {
        setLoading(false);
        enqueueSnackbar(`User Unblocked.`, {variant: 'info'});
        dispatchUnblockUser(activeUser);
        handleClose();
    }
    
    const handleError = (err)=>{
        setLoading(false)
        handleClose();
        enqueueSnackbar(`Error occurred while unblocking the user.`, {variant: 'error'})
        console.error(err);
    }

    return ( 
        <React.Fragment>
            <GlobalModal open={open} handleClose={handleClose}>
                <Card className="deleteConfirmCard">
                    <Grid container spacing={3}>
                        <Grid item xs={12} className="modalHeader">
                            <h2>Unblock User</h2>
                        </Grid>

                        <Grid item xs={12}>
                            <b>Are you sure you want to unblock this user?</b>
                        </Grid>

                        <Grid item xs={12} className="modalFooter" >
                            <button disabled={loading} onClick={handleClose} className="filterButton red">Cancel</button>
                            <button disabled={loading} onClick={handleDelete} className="filterButton">
                                {loading 
                                ? <CircularProgress
                                    color="secondary"
                                    size={20}
                                >
                                </CircularProgress> 
                                : 'Unblock'}
                            </button>
                        </Grid>
                    </Grid>
                </Card>
            </GlobalModal>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        activeUser: state.users.activeUser
    }
}

const mapActionsToProps = {
    dispatchUnblockUser: setUnblockUser
}



export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(UnblockConfirm));