import React, { Component } from "react";
import {
  Route,
  BrowserRouter as Router,
  Redirect,
  Switch,
} from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { AuthContext } from "./auth/AuthContext";
// import $script from "scriptjs";
import NotFound from "./components/notFound/NotFound";
import Layout from "./components/layout/layout";
import Login from "./components/authPages/Login/Login";
import Logout from "./components/Logout";
import AuthRoute from "./auth/AuthRoute";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SnackbarProvider } from "notistack";

//redux
import { connect } from "react-redux";
import { toggleLoading, toggleAuthentication } from "./redux/app/actions";

import {theme} from './constants/theme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Recovery from "./components/authPages/Recovery";
import SignUp from "./components/authPages/SignUp";
import ResetPassword from "./components/authPages/ResetPassword";

import { getPlainFrom } from "./helpers/storage";

import './App.scss';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    const token = getPlainFrom('token');

    if (token) {
      this.context.setUser(token)
        .then(()=>{
          this.setState({
            loading: false
          });
        })
        .catch(()=>{
          this.setState({
            loading: false
          });
        })
    } else {
      this.setState({
        loading: false
      });
    }

    
  }

  render() {
    const { app } = this.props;
    if (this.state.loading) {
      return (
        <div
          style={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <CircularProgress color="secondary" size={80} />
        </div>
      );
    }
    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider
              maxSnack={4}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <div>
                <Switch>
                  {/* <Route exact path="/admin" component={Login} /> */}
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/forgot-password" component={Recovery} />
                  <Route exact path="/reset" component={ResetPassword} />
                  <Route exact path="/reset/:token" component={ResetPassword} />
                  <Route exact path="/sign-up" component={SignUp} />
                  <Route exact path="/logout" component={Logout} />
                  <AuthRoute path="/" component={Layout} />
                  <Route exact path="/not-found" component={NotFound} />
                  <Redirect exact from="*" to="/not-found" />
                </Switch>
                {app.loading && (
                  <div
                    style={{
                      position: "fixed",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                      width: "100vw",
                      zIndex: "1202",
                      top: 0,
                      backgroundColor: "rgba(0,0,0, 0.6)",
                    }}
                  >
                    <CircularProgress color="secondary" size={80} />
                  </div>
                )}
              </div>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Router>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    app: state.app,
  };
};

const mapActionsToProps = {
  toggleLoading: toggleLoading,
  toggleAuthentication: toggleAuthentication,
};

export default connect(mapStateToProps, mapActionsToProps)(App);
