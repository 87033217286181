import {
  SET_ALL_NEWS,
  SET_FILTERED_NEWS,
  SET_CANT_FILTERS,
  RESET_FILTERS,
  SET_FILTERS,
  SET_FILTER_DATE,
  SET_CAT_CONTENT,
  SET_FILTER_OPTIONS,
  SET_NEWS_LOADING,
  SET_SEARCH_TERMS,
  SET_TOTAL_COUNT,
  SET_NEWS_PAGE,
  SET_NEWS_LIMIT, 
  DELETE_NEWS, ADD_NEWS,
  UPDATE_NEWS,
  SET_EXPANDED_NEWS_ID,
  RESET_ALL
} from "./types";

import { subYears, format } from "date-fns";

const initialState = {
  loading: true,
  allNews: [],
  expandedNewsId: null,
  total: 10,
  limit: 10,
  page: 1,
  orderBy: "publishedAt:desc",
  filtered: [],
  cantOfFilters: 0,
  filterOptions: {
    loading: true
  },
  allFilters: {
    client: '5f34f94fa4d764a4e356ef12', //TODO(): fix this
    // status: "published",
    // therapeuticAreas: ["Neuroscience"],
    // diseases: ["AD Symp (LP)"]
    // "compoundPriority": "High",
    "endDate": format(new Date(), "yyyy-MM-dd"),
    "startDate": format(subYears(new Date(), 1), "yyyy-MM-dd"),
    // "terms": [
    //     "sleep",
    //     "narcolepsy"
    // ]
  }
};
export default function appReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ALL_NEWS: {
      return {
        ...state,
        allNews: payload,
      };
    }
    case SET_NEWS_LOADING: {
      return {
        ...state,
        loading: payload
      }
    }
    case SET_CAT_CONTENT: {
      return {
        ...state,
        catContent: payload,
      };
    }

    case SET_NEWS_PAGE: {
      return {
        ...state,
        page: payload,
      };
    }
    case SET_NEWS_LIMIT: {
      return {
        ...state,
        limit: payload,
      };
    }

    case SET_TOTAL_COUNT: {
      return {
        ...state,
        total: payload
      }
    }

    case RESET_FILTERS: {
      const allFilters = {
        client: '5f34f94fa4d764a4e356ef12',
        diseases: payload || []
      }
      return {
        ...state,
        cantOfFilters: countFilters(allFilters),
        allFilters
      };
    }

    case SET_SEARCH_TERMS: {
      let aux = state.allFilters;
      aux.terms = payload;
      return {
        ...state,
        cantOfFilters: countFilters(aux),
        allFilters: aux,
      };
    }

    case SET_FILTER_DATE: {
      let aux = state.allFilters;
      aux.date = payload;
      return {
        ...state,
        cantOfFilters: countFilters(aux),
        allFilters: aux,
      };
    }
    case SET_FILTERS: {
      const filters = {...state.allFilters, ...payload}
      return {
        ...state,
        cantOfFilters: countFilters(filters),
        allFilters: filters,
      };
    }
    case SET_FILTER_OPTIONS: {
      return {
        ...state,
        filterOptions: payload,
      };
    }
    case SET_FILTERED_NEWS: {
      // console.log(payload, "payload filtered news");
      return {
        ...state,
        loading: false,
        filtered: payload,
      };
    }
    case SET_CANT_FILTERS: {
      return {
        ...state,
        cantOfFilters: payload,
      };
    }

    case ADD_NEWS: {
      const filtered = [...state.filtered]
      filtered.splice(0,0, payload);
      return {
        ...state,
        expandedNewsId: payload._id,
        filtered
      }
    }
    
    case UPDATE_NEWS: {
      const filtered = [...state.filtered]
      const index = state.filtered.findIndex(n=> n._id === payload._id)
      filtered.splice(index, 1, payload);
      return {
        ...state,
        expandedNewsId: payload._id,
        filtered
      }
    }
    
    case DELETE_NEWS: {
      const filtered = state.filtered.filter(n=> n._id !== payload._id)
      return {
        ...state,
        filtered
      }
    }

    case SET_EXPANDED_NEWS_ID: {
      return {
        ...state,
        expandedNewsId: payload
      }
    }

    case RESET_ALL: {
      return {
        ...state,
        loading: true,
        allNews: [],
        expandedNewsId: null,
        filtered: [],
        cantOfFilters: 0,
        filterOptions: {
          loading: true
        },
        allFilters: {
          client: '5f34f94fa4d764a4e356ef12', //TODO(): fix this
          "endDate": format(new Date(), "yyyy-MM-dd"),
          "startDate": format(subYears(new Date(), 1), "yyyy-MM-dd"),
        }
      };
    }

    default:
      return state;
  }
}


function countFilters(filters) {
  let count = 0;
  Object.keys(filters).forEach(key=>{
    // ignoring dates
    if(['endDate', 'startDate'].includes(key)) return;

    if(filters[key] && filters[key].length !== 0){
      count++;
    }
  })
  // console.log('Filters Count: ', count)
  return count - 1; // for the preselected client
}

