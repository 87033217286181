import React, {useEffect} from "react";
import TablePagination from '@material-ui/core/TablePagination';
import "./newsTable.scss";
import ShowNews from "./../../ShowNews";
import { connect } from "react-redux";
import {setNewsPage, setNewsLimit, setFilteredNews, setLoading, setSearchTerms} from "../../../redux/news/actions";
import { withSnackbar } from "notistack";
import { searchNews } from "../../../service/news";
import AdminBase from "../administration/AdminBase";
import {Link, useParams} from "react-router-dom";
import { ChevronLeft } from "@material-ui/icons";
import NewsFilter from "./NewsFilter";
import { resetRelatedFields, setRelatedClient } from "../../../redux/relatedFields/actions";
import ExpandableFilters from "../../common/ExpandableFilters";

const NewsTable = (props) => {

  // geting the disease id when we are inside the disease report
  const {dId} = useParams();

  useEffect(()=>{
    // if(!props.client) props.history.push("/")

    // setting the active client to relative fields
    if(!dId){ // if not inside a disease
      props.dispatchResetRelatedFields();
    }
    props.dispatchSetRelatedClient([props.client])
  }, [])

  const handleChangePage = (event, newPage) => {
    props.dispatchSetNewsPage(newPage+1)
    searching()
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
    props.dispatchSetNewsPage(1)
    props.dispatchSetNewsLimit(parseInt(event.target.value, 10))
    searching()
  };

  const searching = () => {
    props.dispatchSetNewsLoading(true);
    searchNews(props.dispatchSetFilteredNews, callbackError);
  };

  const callbackError = (err, variant) => {
    props.enqueueSnackbar(err, variant);
  };

  const handleSearch = (text) => {
    const searchText = text.trim();
    if (!searchText) {
      props.dispatchSetSearchTerms([])
    }

    props.dispatchSetSearchTerms([searchText])

    searching();
  };

  const headerCenterComponent = () => (
      <ExpandableFilters />
  );

  const rootTitle = () => (
      <Link to="/">
        <ChevronLeft />
        <h1>News</h1>
      </Link>
  );

  const headerRightComponent = () => (
      <div>
        <NewsFilter></NewsFilter>
      </div>
  );

  // sending the disease id incase we are already inside the disease report.
  // const toDraft = () => {
  //   return dId ? `${DRAFTS_NEWS}/${dId}` : DRAFTS_NEWS;
  // };

  const handleResetSearch = () => {
    props.dispatchSetSearchTerms([]);
    searching()
  };

  const {data, page, limit, total} = props;
  const showPagination = !!data.length;
  return (
    <section className="news-module-wrapper">
      <AdminBase
          rootTitle={rootTitle()}
          headerCenterComponent={headerCenterComponent()}
          headerRightComponent={headerRightComponent()}
          handleSearch={handleSearch}
          handleResetSearch={handleResetSearch}
          showFilters={false}
          showSearchBox={true}>

        <div id="toImage">
          <ShowNews
            // other props
            className="NewsTable"
            data={data}
          />


          {showPagination && <TablePagination
            component="div"
            count={total}
            page={page-1}
            onChangePage={handleChangePage}
            rowsPerPage={limit}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 20, 50]}
          />}

        </div>
      </AdminBase>
    </section>
  );
}

const mapStateToProps = (state) => {
  const { loading, page, limit, total } = state.news;
  return {
    loading,
    page,
    limit,
    total,
    client: state.app.client
  };
};

const mapDispatchToProps = {
  dispatchSetNewsPage: setNewsPage,
  dispatchSetNewsLimit: setNewsLimit,
  dispatchSetFilteredNews: setFilteredNews,
  dispatchSetNewsLoading: setLoading,
  dispatchSetSearchTerms: setSearchTerms,
  dispatchSetRelatedClient: setRelatedClient,
  dispatchResetRelatedFields: resetRelatedFields
};



export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(NewsTable));
