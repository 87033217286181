export function validateDate(string) {
  // yyyy+ format
  const regEx = /(\d{4})\+/g;
  const match = regEx.exec(string);
  if (match) {
    return true;
  }
  const d = new Date(string);
  return d instanceof Date && !isNaN(d);
}

export function stringToDate(string) {
  const regEx = /(\d{4})\+/g; // yyyy+ format
  const match = regEx.exec(string);
  if (match) {
    string = `12/31/${match[1]}`;
  }

  const d = new Date(string);
  if (d instanceof Date && !isNaN(d)) return d;

  return null;
}

export function stringToYear(string) {
  let d = stringToDate(string);
  if (!d) return null;

  return d.getFullYear();
}

export function minDate(d1, d2) {
  //if any of the dates are null return the valid one.
  if (!(d1 && d2)) return d1 || d2;
  //comparing
  if (d1 < d2) return d1;
  return d2;
}

export function isCustomFormat(string) {
  const regEx = /(\d{4})\+/g;
  const match = regEx.exec(string);
  if (match) {
    return true;
  }
  return false;
}


// new obj version duplicate check
export function isThisDuplicate(base, newItem) {
  for (let i = 0; i < base.length; i++) {
    if (
      base[i]["Product name"] === newItem["Product name"] &&
      base[i]["Company Name"] === newItem["Company Name"] &&
      base[i]["Condition treated"] === newItem["Condition treated"] &&
      base[i]["Segment"] === newItem["Segment"]
    ) {
      return { index: i };
    }
  }
  return false;
}

export const collectFootnotes = (rows, selector) => {
  let footnotes = [];

  rows.forEach((row) => {
    if (row[selector]) {
      const noteString = row[selector];
      try {
        // string could contain "/" delimated multiple notes
        const notes = noteString.split("|");
        notes.forEach((note) => {
          const trimed = note.trim();
          if (trimed) {
            footnotes.push({ productName: row["Product name"], text: trimed });
          }
        });
      } catch {
        footnotes.push({ productName: row["Product name"], text: noteString });
      }
    }
  });

  //Distinct Footnotes
  return footnotes.filter((note, index) => footnotes.indexOf(note) === index);
};
