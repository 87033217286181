import Axios from './';
import store from '../redux/store';
import { setAllDiseases, setAllTa } from '../redux/therapeutic/actions';

const path = '/therapeuticAreas/'

export const getTheraputicAreas = (callBackSuccess, callBackError) => {
    Axios
    .get(path)
    .then(response=>{
        callBackSuccess(response.data)
    })
    .catch(err => {
        callBackError(err)
    })
}


export const dispatchGetTAnDiseases = async (callBackError, callBackSuccess) => {
    const {therapeutics} = store.getState()
    if(!therapeutics.loading) return;

    try {
        const therapeutics = await Axios.get(path);
        const diseases = await Axios.get('/diseases');

        console.log("therapeutics=>", therapeutics)
        console.log("diseases=>", diseases)

        store.dispatch(setAllTa(therapeutics.data));
        store.dispatch(setAllDiseases(diseases.data));

        if(callBackSuccess)
            callBackSuccess();

    } catch(error) {
        if(error.response && error.response.status === 401){
            console.log('Unauthorised!');
        }

        console.log(error)
        if(callBackError)
            callBackError(error)
    }    
}


export const deleteTA = (id) => {
    return Axios.delete(path+id)
}


export const addTA = ({ name, client }, callBackSuccess, callBackError) => {
    //const {app} = store.getState()
    const ta = {
        name,
        //client: app.client
        client
    }
    Axios
    .post(path, ta)
    .then(response=>{
        const {data} = response;
        callBackSuccess(data)
    })
    .catch(err => {
        callBackError(err)
    })
};

export const updateTA = (ta, callBackSuccess, callBackError) => {
    const data = {
        name: ta.name,
        client: ta.client
    }

    Axios
    .put(path+ta._id, data)
    .then(response=>{
        callBackSuccess(response.data)
    })
    .catch(err => {
        callBackError(err)
    })
}