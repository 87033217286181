import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { TM } from "../report2/TM";
import BoxSizeControl from '../report1/BoxSizeControl';
import { searchCompounds } from "../../service/compounds";
import SelectWithSelectAll from "../report7/SelectWithSelectAll";
import { getCompoundName } from "../../helpers/utils";
import _ from 'lodash';
import "./index.scss";
import ReportBase from "../groupReports/ReportBase";
import { withRouter } from "react-router";

class Report8 extends Component {
  state = { filters: null, showTm: true, showDMT: true, boxSize: { height: 180, width: 185 } };
  data = null;
  filters = {
    developmentPhase: { name: 'Phase', options: [] },
    mechanismOfAction: { name: 'Mechanism Of Action', options: [] },
    originator: { name: 'Company', type: 'object', options: [] },
    patientSegment: { name: 'Patient Segment', options: [] },
    therapeuticModality: { name: 'Therapeutic Modality', options: [] },
  }

  componentDidMount() {
    const { dId, taId, clientId } = this.props.match.params;
    searchCompounds({
      client: clientId,
      therapeuticArea: taId,
      disease: dId,
      status: "active"
    })
      .then(({ data }) => {
        this.data = data.compounds;
        this.processData(data.compounds);
      })
      .catch((error) => {
        console.log(error)
        // TODO handle errors
      })
  }

  processData(data) {
    const filters = {}
    Object.keys(this.filters).forEach(key => {
      let distinctItems = [];
      if (this.filters[key].type === 'object') {
        distinctItems = this.distinctObjects(key, data)
      } else
        distinctItems = this.distinctItems(key, data);

      this.filters[key].options = distinctItems;
      filters[key] = distinctItems;
    })
    // console.log(filters);
    this.setState({ filters })
  }

  distinctItems(key, data) {
    return data.filter(row => row[key])
      .map(row => row[key].toLowerCase())
      .sort()
      .filter((value, index, arr) => arr.indexOf(value) === index);
  }

  distinctObjects(key, data) {
    const objects = data.filter(row => row[key]).map(row => row[key])
    const distinct = _.uniqBy(objects, '_id')
    const ordered = _.orderBy(distinct, ['name'], ['asc'])
    return ordered;
  }

  formatData = (viewBy, data) => {
    const formated = {};
    this.state.filters[viewBy].forEach(key => {
      formated[key] = data.filter(row => row[viewBy].toLowerCase() === key)
    });
    return formated;
  }

  handleFilterChange(filterKey, value) {
    const filters = { ...this.state.filters }
    filters[filterKey] = value;

    this.setState({ filters });
  }

  doFilter() {
    const { filters } = this.state;
    if (!filters) return null;

    let data = this.data;
    Object.keys(filters).forEach(filterKey => {
      const value = filters[filterKey];
      const { options } = this.filters[filterKey];

      if (!options.length) return; // no options
      if (options.length === value.length) return; //all selected

      // filtering by the selected items in the filter
      data = data.filter(row => {
        if (this.filters[filterKey].type === 'object')
          return value.includes(row[filterKey])

        return value.includes(row[filterKey].toLowerCase());
      })
    })
    return this.formatData('developmentPhase', data)
  }

  toggleTm = (e) => {
    this.setState({ showTm: e.target.checked })
  }

  toggleIsDMT = (e) => {
    this.setState({ showDMT: e.target.checked })
  }


  render(props) {
    const { filters, boxSize, showDMT, showTm } = this.state;
    const id = "report-8";

    const formated = this.doFilter();

    return (
      <div id={id}>
        <ReportBase
          {...this.props}
          exportImg={id}
          filters={(
            <div className="filters">
              {filters && <div>
                {Object.keys(this.filters).map((filterKey) => {
                  const { onChange, name, options } = this.filters[filterKey];
                  const value = filters[filterKey];

                  return (<FormControl key={filterKey} style={{ margin: "0 10px" }}>
                    <SelectWithSelectAll
                      value={value}
                      options={options} 
                      variant="filled"
                      onChange={
                        onChange
                          ? (value) => onChange(filterKey, value)
                          : (value) => this.handleFilterChange(filterKey, value)
                      }
                      renderValue={(selected) => name}
                    />
                  </FormControl>)
                })}
              </div>}

              <FormControl style={{ margin: '5px 0 0 10px', flexDirection: 'row' }}>
                <FormControlLabel
                  control={<Checkbox checked={showTm} onChange={this.toggleTm} color="secondary" />}
                  label="Therapeutic Modalities"
                />
                <FormControlLabel
                  control={<Checkbox checked={showDMT} onChange={this.toggleIsDMT} color="secondary" />}
                  label="DMT"
                />
              </FormControl>
            </div>
          )}
          toolbarRightComponent={(
            <BoxSizeControl
              className="boxSizeControl"
              onChange={this.boxSizeChange}
              value={boxSize}
            />
          )}
        >
          <div id="toImage">
            {formated && (
              <table>
                <thead>
                  <tr>
                    {Object.keys(formated).map((key, index) => (
                      <th key={`head-${index}`}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {Object.keys(formated).map((key, index) => {

                      return (
                        <td key={key + index}>
                          <div className="holder">
                            {formated[key].map((row, index) => {
                              return (
                                <div
                                  key={key + index}
                                  style={{
                                    minHeight: boxSize.height + "px",
                                    width: boxSize.width + "px",
                                  }}
                                  className="box"
                                >
                                  <div className="box-texts">
                                    <strong>
                                      {getCompoundName(row["name"])}
                                    </strong>
                                    <p className="for-image">{row["mechanismOfAction"]}</p>
                                    <p className="for-image">
                                      {row["originator"] ? row["originator"].name : ''}
                                    </p>
                                    {row["patientSegment"] && (
                                      <span
                                        style={{
                                          backgroundColor: "red",
                                          color: "white",
                                        }}
                                        className="badge badge-danger"
                                      >
                                        {row["patientSegment"]}
                                      </span>
                                    )}
                                  </div>

                                  <div className="tm-container">
                                    {(showTm && row["therapeuticModality"]) && (
                                      <TM tm={row["therapeuticModality"]}></TM>
                                    )}
                                    {(showDMT && row['isDMT']) && <TM tm={'potential dmt'}></TM>}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          {/* {formated && (
          <InvalidRecords
            headers={this.invalidHeaders}
            totalRecord={this.data.length - 1}
            leftBehind={this.leftBehind.map((row) =>
              this.invalidHeaders.map((v) => row[v])
            )}
          />
        )} */}
          {!formated && (
            <SkeletonTheme color="#E2DEDE">
              <p>
                <Skeleton height={100} />
                <br />
                <br />
                <Skeleton count={8} height={60} />
              </p>
            </SkeletonTheme>
          )}
        </ReportBase>
      </div>
    );
  }

  boxSizeChange = (e) => {
    const { boxSize } = this.state;
    boxSize[e.target.name] = e.target.value;
    this.setState({ boxSize });
  };
}

export default withRouter(Report8);
