import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import {
    InputLabel,
    FormControl,
    Select,
    MenuItem,
  } from '@material-ui/core';
  import CancelIcon from '@material-ui/icons/Cancel';

import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { union } from 'lodash';
import { ADD_PAIR, REMOVE_PAIR, updatePair } from '../../../redux/licenseePair/actions';
import { ComboBoxSimple } from "../../common";
import {setCompanyQuery} from "../../../redux/companies/actions";
import {getAllCompany} from "../../../service/companies";

const useStyles = makeStyles({
    root: {
      border: '0.1px solid #c4c4c4',
      padding: '12px',
      borderRadius: '10px'
    },
    FormControl: {
      margin: '0 35px'
    },
    labelsPlaceHolders: {
      marginLeft: '20px'
    },
    cancelIconWrapper: {
      position: 'relative', 
      left: '94%',
      top: '19px',
      zIndex: '100' 
    },
    addIconWrapper: {
      justifyContent: 'flex-end',
      display: 'flex'
    },
    lineSeparator: {
      textAlign: 'center',
      color: '#CCC4C4',
      fontWeight: '700'
    }
  });

const InputsRL = () => {
    // const { register, handleSubmit, setValue } = useForm();
    const {
          formControl,
          cancelIconWrapper,
          addIconWrapper,
          root
        } = useStyles();
    

    const allPairs = useSelector(({licensee}) => licensee.allPairs);
    const allCompanies = useSelector(({ companies }) => companies.allCompanies);
    const loadingCompanies = useSelector(({ companies }) => companies.loading);
    const dispatch = useDispatch();

    const addPair = () => {
      dispatch({type: ADD_PAIR})
    };

    const removePair = index => {
      dispatch({type: REMOVE_PAIR, payload: index})
    };

    const onChange = (index, key, value) => {
        dispatch(updatePair({index, key, value}));
    }


    return (
      <React.Fragment>
        {allPairs.length > 0 && allPairs.map((license, index) => {
          const fieldName = `inputsRL[${index}]`;
          return <>

          {index > 0 && <div className={cancelIconWrapper}>
            <IconButton
                onClick={()=>removePair(index)}
                color="secondary"
                aria-label="add"
                component="span">
              <CancelIcon color="secondary" />
            </IconButton>
          </div>}
          <div className={root}>

            <Grid item xs={12} sm={12}>
              <FormControl variant="outlined" fullWidth className={formControl}>
                <InputLabel id="abc-12">
                  {`REGION${(index+1)}`}
                </InputLabel>
                <Select
                labelId="abc-12"
                label={`REGION${(index+1)}`}
                  id={`${fieldName}.region${index}`}
                  value={license.region}
                  name={`${fieldName}.region`}
                  fullWidth
                  onChange={(e)=>onChange(index, 'region', e.target.value)}>
                  {"US, EU, Japan, China, Global".split(', ').sort().map((op) => <MenuItem key={op} value={op}>
                    {op}
                  </MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              &nbsp;
              <ComboBoxSimple
                required={true}
                options={union(allCompanies, license.companies)}
                id={`${fieldName}.licencee${index}`}
                value={license.companies}
                fullWidth
                handleMyChange={(e, value) => onChange(index, 'companies', value)}
                multiple={true}
                placeHolder={"LICENCEE"}
                handleSearch={(value) => {
                  dispatch(setCompanyQuery(value));
                  return getAllCompany();
                }}
                loading={loadingCompanies}
              />
            </Grid>
          </div></>;
          }
        )}
        <div className={addIconWrapper}>
          <IconButton onClick={addPair} color="secondary" aria-label="add" component="span">
            <AddCircleOutlineIcon />
          </IconButton>
        </div>
      </React.Fragment>
    )
  }


  export default InputsRL;