/* eslint-disable no-useless-escape */
import { useLocation } from 'react-router';
import * as Yup from 'yup';


export function createUserData({ user_name, email, role, permissions }) {
  let myuserName = user_name.split('"');
  let actions = ["password", "edit", "delete"];
  return { user_name: myuserName[1], email, role, permissions, actions };
}

export function getColorStyleForPriority({ priorNum }) {
  switch (priorNum) {
    case 1:
      return '#b23128';
    case 2:
      return '#c65961';
    case 3:
      return '#f6a458';
    default:
      return '#8ac7ca';
  }
}

export function getTextForPriority({ priorNum }) {
  switch (priorNum) {
    case 1:
      return 'Alert';
    case 2:
      return 'High';
    case 3:
      return 'Medium';
    default:
      return 'Low';
  }
}

export function getTextForCompoundPriority({ priorNum }) {
  switch (priorNum) {
    case 3:
      return 'High';
    case 2:
      return 'Medium';
    default:
      return 'Low';
  }
}

export function getCompoundName(nameObj) {
  let toRender = nameObj.brand || nameObj.generic || nameObj.scientific || nameObj.other;
  if (typeof toRender === 'object') {
    toRender = toRender.join(',')
  }
  return toRender;
}

export const passwordValidator = Yup.string()
  .required('Required')
  .matches(/(?=.*[\d])/g, "At least One Number")
  .matches(/(?=.*[a-z])/g, "At least One Lower Case letter")
  .matches(/(?=.*[A-Z])/g, "At least One Upper Case letter")
  .matches(/(?=.*[#$@!%&*?\.\^\(\)\-\_\,\{\}])/g, 'Must Contain one of #$@!%&*?.^()-_,{}')
  .min(10, 'Minimum 10 character long!')

export const fullValidatorForSchema = (schema) => (values) => schema.validate(values, {
  abortEarly: false,
  strict: false,
}).then(() => ({})).catch(({ inner }) => inner.reduce((memo, { path, message }) => ({
  ...memo,
  [path]: (memo[path] || []).concat(message),
}), {}))


export function useQuery() {
  return new URLSearchParams(useLocation().search);
}