import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useQuery } from '../../helpers/utils';

const RedirectToTm = () => {
    const query = useQuery();
    const history = useHistory();
    const { pathname, search } = useLocation();

    const tm = query.get('tm');

    const redirect = () => {
        if(!tm) history.push(`${pathname}${search}&tm=true`)
        else history.push(`${pathname}?type=landscape-type-1`)
    }

    return (
        <FormControlLabel
            control={
                <Checkbox
                    id={`withTm`}
                    type="checkbox"
                    checked={Boolean(tm)}
                    name={`withTm`}
                    onChange={redirect}
                />
            }
            label={"Therapeutic Modality"}
        />
    );
}

export default RedirectToTm;