import React, {Fragment, useEffect, useState} from 'react';
import DateRangePicker from '../DateInputPicker/dateRangePicker';
import { format } from 'date-fns';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {Button, CircularProgress} from '@material-ui/core';
import {connect} from 'react-redux';
import {withSnackbar} from 'notistack';
import {bindActionCreators} from 'redux';
import { uniq } from 'lodash';

import {ComboBox} from './ComboBox';
import {resetFilters, setCantFilters, setFilteredNews, setFilters, setLoading} from '../../redux/news/actions';
import {searchNews} from '../../service/news';
import {useParams} from "react-router-dom";
import {getTextForCompoundPriority, getTextForPriority} from "../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '7ch',
    paddingTop: '27px'
  },
  buttonMore: {
    marginLeft: '20px'
  },
  actionButtonsContainer: {
    textAlign: 'right'
  },
  actionButtons: {
    marginLeft: '10px'
  },
}));

const ExpandableFilters = ({ filterOptions, allFilters, ...props }) => {
  const classes = useStyles();
  // const [ready, setReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [filters, setFilters] = useState({});

  const {dId} = useParams();
  const disableIndication = Boolean(dId);

  useEffect(() => {
    // console.log(allFilters)
    setFilters({ ...allFilters });
  }, []);

  const getMOAs = () => {
    const allMoa = filterOptions.compounds.map(({mechanismOfAction}) => mechanismOfAction);
    return allMoa.filter((value, index, array)=> array.indexOf(value) === index);
  }
  

  const handleDateChange = (date) => {
    // setReady(true);
    const { startDate, endDate } = date.selection;
    const aux = {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd')
    };
    props.dispatchSetFilters(aux); // this line was causing the news date range issue
    // added this line to fix it 
    setFilters({...filters, ...aux})
    // need to handle the reset case and state management of date range 
    setDateRange(date.selection);

    // searching with the changed date rightway
    props.dispatchSetLoading(true);
    searchNews(props.dispatchSetFilteredNews, callbackError);
  };

  const handleSelectChange = (id, value) => {
    let aux = filters;
    aux[id] = value;
    // console.log(aux)
    setFilters({ ...aux });
    if(!open) handleFilter({preventDefault: () => {}});
  };

  const handleFilter = (e) => {
    e.preventDefault();
    props.dispatchSetFilters(filters);
    props.dispatchSetLoading(true);
    searchNews(props.dispatchSetFilteredNews, callbackError);
    setOpen(false);
  };

  const handleReset = (e) => {
    e.preventDefault();
    if(disableIndication){
      const {diseases} = allFilters;
      setFilters({diseases});
      props.dispatchResetFilters(diseases);
    }else {
      setFilters({});
      props.dispatchResetFilters();
    }
    props.dispatchSetLoading(true);
    searchNews(props.dispatchSetFilteredNews, callbackError);
    setOpen(false);
  };

  const callbackError = (err, variant) => {
    props.enqueueSnackbar(err, variant);
  };

  if (filterOptions.loading) {
    return (
        <CircularProgress color='secondary'></CircularProgress>
    )
  }

  return (
      <Fragment>
        <div className={classes.textField}>Sort By </div>
        <DateRangePicker
          handleSelect={handleDateChange}
          dateRange={dateRange}
          className={'date-picker-news'}
          noLabel={true}
        />
        <Grid container spacing={1}>
          <Grid item md={3} >
            <ComboBox
                id='therapeuticArea'
                options={filterOptions.therapeuticAreas}
                value={filters.therapeuticArea || []}
                fullWidth
                handleMyChange={handleSelectChange}
                style={{ width: '100%', transition: 'margin .5s', paddingTop: '10px'  }}
                label='Therapeutic Area'
            />
          </Grid>
          <Grid item md={3} >
            <ComboBox
                id='disease'
                options={filterOptions.diseases}
                value={filters.disease || []}
                fullWidth
                handleMyChange={handleSelectChange}
                style={{ width: '100%', transition: 'margin .5s', paddingTop: '10px'  }}
                label='Disease'
            />
          </Grid>
          <Grid item md={3} >
            <ComboBox
                id='compounds'
                options={filterOptions.compounds.map(compound => ({_id: compound._id, name: compound.name.generic}))}
                value={filters.compounds || []}
                fullWidth
                handleMyChange={handleSelectChange}
                style={{ width: '100%', transition: 'margin .5s', paddingTop: '10px'  }}
                label='Compounds'
            />
          </Grid>
          <Grid item md={3} >
            <ComboBox
                id='companies'
                options={filterOptions.companies}
                value={filters.companies || []}
                fullWidth
                handleMyChange={handleSelectChange}
                style={{ width: '100%', transition: 'margin .5s', paddingTop: '10px'  }}
                label='Company'
            />
          </Grid>
          {open?
            <>
              <Grid item md={3} >
                <ComboBox
                    id='mechanismOfAction'
                    options={getMOAs()}
                    value={filters.mechanismOfAction || []}
                    fullWidth
                    handleMyChange={handleSelectChange}
                    style={{ width: '100%', transition: 'margin .5s' }}
                    label='Mechanism Of Action'
                />
              </Grid>

              <Grid item md={3} >
                <ComboBox
                  id='priority'
                  options={filterOptions.priority.map(prio => ({_id: prio, name: getTextForPriority({priorNum: prio})}))}
                  value={filters.priority || []}
                  fullWidth
                  handleMyChange={handleSelectChange}
                  style={{ width: '100%', transition: 'margin .5s' }}
                  label='News Priority'
                />
              </Grid>

              <Grid item md={3} >
                <ComboBox
                  id='compoundPriority'
                  options={uniq(filterOptions.compounds.map(compound => compound.priority))
                      .map(priorNum => ({_id: priorNum, name: getTextForCompoundPriority({ priorNum })}))
                  }
                  value={filters.compoundPriority || []}
                  fullWidth
                  handleMyChange={handleSelectChange}
                  style={{ width: '100%' }}
                  label='Compound Priority'
                />
              </Grid>
              <Grid item md={3} >
                <ComboBox
                  id='categories'
                  options={filterOptions.categories}
                  value={filters.categories || []}
                  fullWidth
                  handleMyChange={handleSelectChange}
                  style={{ width: '100%' }}
                  label='Categories'
                />
              </Grid>
              <Grid item xs={12}  className={classNames('filterActions', classes.actionButtonsContainer)}>
                <button className={classNames('modalButton')} onClick={() => setOpen(false)}>
                  Cancel
                </button>
                <button onClick={handleReset} className={classNames('modalButton', classes.actionButtons)}>
                  Reset
                </button>
                <button className={classNames('modalButton fill', classes.actionButtons)} onClick={handleFilter}>
                  Apply
                </button>
              </Grid>
            </>
            :
            null
          }
        </Grid>
        {!open?
          <Button className={classNames(classes.textField, classes.buttonMore)} onClick={() => setOpen(true)}>
            <AddCircleIcon fontSize='default' color='secondary' />
            <span>&nbsp;More</span>
          </Button>
          :
          null
        }
      </Fragment>
  );
}

const mapStateToProps = (state) => {
  const {
    filterOptions,
    allFilters,
  } = state.news;

  return {
    filterOptions,
    allFilters
  };
};

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
      {
        dispatchSetFilteredNews: setFilteredNews,
        dispatchSetCantFilters: setCantFilters,
        dispatchSetFilters: setFilters,
        dispatchResetFilters: resetFilters,
        dispatchSetLoading: setLoading,
      },
      dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(ExpandableFilters));