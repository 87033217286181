export const MANAGE_COMPANY_LOGOS = "/manage-company-logos";
export const USERS = "users";
export const NEWS = '/news';

export const ADD_NEWS = '/news/add';
export const DRAFTS_NEWS = '/news/drafts';
export const EDIT_NEWS = '/news/edit';
export const ADMINISTRATION = '/administration';
export const COMPANY = 'company';
export const COMPOUND = 'compound';
export const CLIENT = 'client';
export const KEYWORD = 'keyword';

export const CLIENTS = '/clients';
export const THERAPEUTIC_AREAS = 'therapeuticAreas';
export const DISEASES = 'diseases';

export const EVENTS = 'events';
export const TRIAL = 'trial';

export function tasLink(clientId) {
    return `${CLIENTS}/${clientId}/${THERAPEUTIC_AREAS}`;
}

export function diseasesLink(clientId, taId) {
    return `${CLIENTS}/${clientId}/${THERAPEUTIC_AREAS}/${taId}/${DISEASES}`;
}
export function addDiseaseLink(clientId, taId) {
    return `${CLIENTS}/${clientId}/${THERAPEUTIC_AREAS}/${taId}/${DISEASES}/add`;
}

export function editDiseaseLink(clientId, taId, dId) {
    return `${CLIENTS}/${clientId}/${THERAPEUTIC_AREAS}/${taId}/${DISEASES}/${dId}/edit`;
}

export function reportsLink(clientId, taId, dId, type="") {
    type = type ? '?type='+type : '';
    return `${CLIENTS}/${clientId}/${THERAPEUTIC_AREAS}/${taId}/${DISEASES}/${dId}/reports${type}`;
}

