import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Link, useParams } from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from "@material-ui/core/Button";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Popover from '@material-ui/core/Popover';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CssBaseline from "@material-ui/core/CssBaseline";
import FilledInput from '@material-ui/core/FilledInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import ImageIcon from '@material-ui/icons/Image';
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { isEmpty } from 'lodash';

import { createNews, getFilters, getNewsById, updateNewsById } from "../../../service/news";
import { ComboBoxSimple, RelatedFields } from '../../common';

import { Editor } from '@tinymce/tinymce-react';

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import './AddNews.scss';
import { withSnackbar } from 'notistack';
import { toggleLoading } from '../../../redux/app/actions';
import { uploadFile } from '../../../service/files';
import { CircularProgress } from '@material-ui/core';
import { setExpandedNewsId } from '../../../redux/news/actions';
import {
  resetRelatedFields,
  setRelatedClient,
  setRelatedCompany,
  setRelatedCompound,
  setRelatedDisease,
  setRelatedTA
} from "../../../redux/relatedFields/actions";
import {getTextForPriority} from "../../../helpers/utils";

const categories = [
  "Commercial",
  "Development Needs",
  "Regulatory",
  "Trial Initiation",
  "Trial Results",
  "Others"
];

const SUMMARY_LIMIT = 1000;
const DELETE_KEY = 8;
const SUPR_KEY = 46;

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    height: "auto",
    alignItems: "center",
  },

  pageHeader: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 34,
    justifyContent: 'space-between',
    fontFamily: 'PoppinsSemiBold'
  },

  arrowBackIcon: {
    width: '25px',
    height: '25px',
    color: '#8A8A8A'
  },

  formControl: {
    margin: "10px",
  },

  subTitle: {
    marginTop: "10px",
    fontFamily: 'Poppins'
  },

  screenTitle: {
    cursor: 'pointer'
  },

  checkSource: {
    color: '#56C6CC'
  },

  previewButton: {
    textTransform: 'none',
    background: '#3A405A',
    fontFamily: 'Poppins',
  },

  labelsPlaceHolders: {
    marginLeft: '20px'
  },

  applyButton: {
    /*background: '#AE2F2F',*/
    marginLeft: '10px',
    fontFamily: 'Poppins',
    textTransform: 'none',
  },

  buttonsWrapper: {
    display: 'flex',
    marginTop: '20px',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },

  uploadButton: {
    textTransform: 'none',
    marginTop: '10px',
    padding: "10px",
    borderRadius: '4px',
    background: '#CCC4C4',
    cursor: 'pointer'
  },

  fileIconStyle: {
    fontSize: '13px',
    marginBottom: '2px'
  },

  linkIcon: {
    fontSize: "14px",
    transform: 'rotate(-30deg)'
  },

  statusWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

const AddNews = ({ enqueueSnackbar, ...props }) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();

  const { newsId } = useParams();

  const [dataToEdit, setDataToEdit] = useState({});

  const [title, setTitle] = useState('');
  const [date, setDate] = useState(new Date());

  const [content, setContent] = useState('');
  const [commentary, setCommentary] = useState('');

  const [sources, setSources] = useState([]);
  const [sourceLink, setSourceLink] = useState('');
  const [sourceName, setSourceName] = useState('');

  const [category, setCategory] = useState("");

  const [priority, setPriority] = useState({_id: 0, name: ''});
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [status, setStatus] = useState(true);
  // const [popoverId, setPopoverId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const { filterOptions } = useSelector(({ news }) => news);
  const { client } = useSelector(({ app }) => app);
  const { therapeuticAreas, diseases,  compounds,  companies, optionValues } = useSelector(({ relatedFields }) => relatedFields);

  useEffect(() => {
    if(!filterOptions.loading && !isEmpty(dataToEdit)) initNews(dataToEdit);
  },[filterOptions, dataToEdit, optionValues.compounds.loading]);

  useEffect(() => {
    dispatch(resetRelatedFields());
    if (newsId) { // edit mode
      dispatch(toggleLoading())
      Promise.all([getFilters(), getNewsById(newsId)])
        .then(([responseFilters, { data }]) => {
          setDataToEdit(data);
        })
        .catch(({ response }) => {
          if (!response) {
            enqueueSnackbar('Unexpected error occured while getting the news!', { variant: 'error' })
          } else {
            const { message } = response.data;
            enqueueSnackbar(message, {
              variant: "error"
            });
          }
        })
        .finally(() => {
          dispatch(toggleLoading())
        })
    }
    else { // create mode
      // pre select the client
      dispatch(setRelatedClient([client]));
      getFilters()
        .then(() => { })
        .catch((err) => {
          console.log('error occured while getting the filters')
        })
    }

    return () => {
      dispatch(resetRelatedFields());
    }
  }, []);

  // const decodeEntity = (content) => {
  //   return content.replace(/[\u00A0-\u9999<>\&]/gim, function (i) {
  //     return '&#' + i.charCodeAt(0) + ';';
  //   })
  // }

  const handleEditorChange = (content, editor) => {
    setContent(content)
  }

  const handleAddSource = () => {
    if (!sourceName.trim()) return;
    const newSource = { name: sourceName, link: sourceLink };
    setSources([...sources, newSource]);
    setSourceName('');
    setSourceLink('');
  };

  const deleteFile = (index) => {
    const tempFiles = [...files];
    tempFiles.splice(index, 1);
    setFiles(tempFiles);
  }

  const handleFileChange = ({ target }) => {
    console.log(target.files);
    const { files } = target;
    if (!files.length) return;

    const formData = new FormData();
    const tempState = [];
    for (let f of files) {
      tempState.push({ name: f.name, link: '' })
      formData.append('file', f)
    }
    setFiles(tempState);
    setUploading(true)

    uploadFile(formData)
      .then(({ data }) => {
        // console.log(data);
        tempState.forEach((f, i) => f.link = data[i]);
        setFiles(tempState);
        setUploading(false)
      })
      .catch(({ response }) => {
        if (!response) {
          enqueueSnackbar('Unexpected error occured!', { variant: 'error' })
        } else {
          const { message } = response.data;
          enqueueSnackbar(message, { variant: 'error' })
        }
      })
  };

  const handleStatusChange = ({ target }) => {
    setStatus(target.checked);
  };

  const renderStatus = () => (
    status ?
      <VisibilityOutlinedIcon style={{ marginBottom: '8px' }} color="action" /> :
      <VisibilityOffOutlinedIcon style={{ marginBottom: '8px' }} color="action" />
  )

  const initNews = ({
    client, title, publishedAt, sources, therapeuticArea,
    content, compounds, companies, categories, disease,
    priority, status, analystCommentary, attachments
  }) => {
    dispatch(setRelatedClient([client]));
    setTitle(title);
    setDate(new Date(publishedAt * 1000))
    setContent(content);
    setCommentary(analystCommentary || '');
    dispatch(setRelatedCompany(companies));
    setCategory(categories[0]);
    dispatch(setRelatedTA(therapeuticArea));
    dispatch(setRelatedDisease(disease));
    setSources(sources);
    const prio = {_id: priority, name: getTextForPriority({ priorNum: priority})};
    setPriority(priority? prio:null);
    setStatus(status === 'published' ? true : false);
    if (attachments && attachments.length) {
      const tempFiles = attachments.map(f => {
        return { name: f.name, link: f.link };
      })
      setFiles(tempFiles);
    }
    if(!optionValues.compounds.loading) {
      dispatch(setRelatedCompound(compounds.map(compound => {
        const o = optionValues.compounds.filter(c => c._id === compound._id);
        return o.length? o[0]:null;
      }).filter(compound => compound !== null)));
    }
  }

  const reset = () => {
    dispatch(resetRelatedFields());
    setTitle('');
    setDate(new Date())
    setContent('');
    setCommentary('')
    setCategory('')
    setSources([])
    setPriority("");
    setStatus(false);
    setFiles([]);
    setUploading(false);
  }

  const validate = () => {
    let valid = true;
    if(!companies.length){
      enqueueSnackbar(`Companies is required!`, { variant: 'warning' })
      valid = false;
    }
    if(!compounds.length){
      enqueueSnackbar(`Compounds is required!`, { variant: 'warning' })
      valid = false;
    }
    if (!content.trim()) {
      enqueueSnackbar(`Content is required!`, { variant: 'warning' })
      valid = false;
    }
    if (uploading) {
      enqueueSnackbar(`File upload in progress!`, { variant: 'warning' })
      valid = false;
    }
    return valid;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(decodeEntity(content))
    if (!validate()) return;
    dispatch(toggleLoading())

    const data = {
      client: client? client._id:"",
      companies: companies.length? companies.map(company => company._id):[],
      compounds: compounds.length? compounds.map(compound => compound._id):[],
      therapeuticArea: therapeuticAreas.length? therapeuticAreas[0]._id:null,
      disease: diseases? diseases[0]._id:null,
      categories: [category],
      analystCommentary: commentary,
      publishedAt: date.toISOString().split('T')[0],
      title,
      content,
      priority: priority? priority._id:'',
      status: status ? 'published' : 'draft',
      sources
    };
    if (files.length) data.attachments = files;
    console.log("Request Data => ", data)

    if (newsId) update(data);
    else create(data);

  };

  const create = (data) => {
    createNews(data, (response) => {
      dispatch(toggleLoading())
      dispatch(setExpandedNewsId(response.news._id))
      enqueueSnackbar("News Added!", {
        variant: "success",
      });
      reset();
      props.history.goBack();
    }, err => {
      dispatch(toggleLoading())
      const { response } = err;
      if (response) {
        const { message } = response.data;
        enqueueSnackbar(message, {
          variant: "error"
        });
      } else {
        enqueueSnackbar("Eror occured while adding news.", {
          variant: "error"
        });

      }
    }
    );
  }

  const update = (data) => {
    updateNewsById(newsId, data)
      .then(() => {
        dispatch(toggleLoading())
        dispatch(setExpandedNewsId(newsId))
        enqueueSnackbar("News Updated!", {
          variant: "success",
        });
        reset();
        props.history.goBack();
      })
      .catch(err => {
        dispatch(toggleLoading())
        const { response } = err;
        if (response) {
          const { message } = response.data;
          enqueueSnackbar(message, {
            variant: "error"
          });
        } else {
          enqueueSnackbar("Eror occured while updating news.", {
            variant: "error"
          });

        }
      })
  }

  const renderSources = () => {
    return sources.map((source, index) => (
      <Chip
        key={source.name}
        label={source.name}
        onDelete={() => handleDelete(index)}
      />
    ));
  }

  const handleDelete = (index) => {
    const auxSources = [...sources];
    auxSources.splice(index, 1);
    setSources(auxSources);
  };

  const handlePopoverOpen = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  }

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <React.Fragment>
      <div className={classes.pageHeader}>
        <Link
          to="/news"
        >
          <ArrowBackIosIcon className={classes.arrowBackIcon} />
          <span className={classes.screenTitle}>News</span>
        </Link>
      </div>
      <Divider />
      <h4 className={classes.subTitle}>{newsId ? 'Update' : 'Add'} a News</h4>
      <main>
        <CssBaseline />
        <Grid
          className={classes.root}
          container
          alignContent="center"
          justify="center"
          spacing={1}
        >
          <Grid item xs={8}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="title">Title</InputLabel>
                    <Input
                      required
                      name="title"
                      value={title}
                      onChange={({ target }) => setTitle(target.value)}
                      id="title"
                    />
                  </FormControl>
                </Grid>
                <Grid container item justify="flex-end" md={6} xs={12}>
                  <FormControl className={classes.formControl}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="none"
                      id="date-picker-inline"
                      label={"Date"}
                      value={date}
                      onChange={(newDate) => setDate(newDate)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl style={{ minHeight: '400px' }} fullWidth className={classes.formControl}>
                <strong>Content</strong>
                <Editor
                  value={content}
                  required
                  apiKey="8i5j7zmlxbq6qi5g9bttji7n9xfltvmv7pf9dcw50vi549kk"
                  init={{
                    height: 400,
                    menubar: false,
                    content_style: "p {margin: 0px}",
                    // browser_spellcheck: true,
                    // entity_encoding : 'raw',
                    // encoding: 'xml',
                    plugins: [
                      'lists link image',
                    ],
                    invalid_elements: 'div',
                    toolbar:
                      // eslint-disable-next-line no-multi-str
                      'undo redo | formatselect | bold italic underline forecolor | \
                       bullist numlist | image link'
                  }}
                  onEditorChange={handleEditorChange}
                />

              </FormControl>
              <FormControl style={{ minHeight: '200px' }} fullWidth className={classes.formControl}>
                <strong>Summary</strong>
                <Editor
                  id="commentary"
                  required
                  value={commentary}
                  apiKey="8i5j7zmlxbq6qi5g9bttji7n9xfltvmv7pf9dcw50vi549kk"
                  init={{
                    height: 200,
                    menubar: false,
                    content_style: "p {margin: 2px 0px}",
                    plugins: [
                      'lists link image',
                    ],
                    toolbar:
                      // eslint-disable-next-line no-multi-str
                      'undo redo | formatselect | bold italic underline forecolor | \
                       bullist numlist | image link'
                  }}
                  onEditorChange={(content) => {
                    const text = content.replace( /(<([^>]+)>)/ig, '');
                    return text.length < 1000? setCommentary(content):setCommentary(text.substr(0,SUMMARY_LIMIT));
                  }}
                  onKeyDown={(e, editor) => { //fix: limit on SUMMARY_LIMIT
                    const text = editor.getContent().replace( /(<([^>]+)>)/ig, '');
                    console.log("Length",text.length)
                    return text.length < SUMMARY_LIMIT || e.keyCode === DELETE_KEY || e.keyCode === SUPR_KEY;
                  }}
                />
              </FormControl>

              {/* Two columns */}
              <Grid container spacing={6}>
                <RelatedFields config={{
                  client: {disabled: true, breakpoints: {xs: 12, md: 6}, formControl: {className: classes.formControl, fullWidth: true}},
                  therapeuticArea: {multiple: false, breakpoints: {xs: 12, md: 6}, formControl: {className: classes.formControl, fullWidth: true}},
                  disease: {multiple: false, breakpoints: {xs: 12, md: 6}, formControl: {className: classes.formControl, fullWidth: true}},
                  compound: {multiple: true, breakpoints: {xs: 12, md: 6}, options: [], formControl: {className: classes.formControl, fullWidth: true}},
                  company: {multiple: true, autoFill: true, breakpoints: {xs: 12, md: 6}, options: filterOptions.loading? []:filterOptions.companies, formControl: {className: classes.formControl, fullWidth: true}},
                }}/>

                <Grid item md={6} xs={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <ComboBoxSimple
                        required
                        options={categories || []}
                        id="categories"
                        value={category}
                        fullWidth
                        handleMyChange={(id,value) => setCategory(value)}
                        style={{ width: "100%" }}
                        placeHolder={'Categories'}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={6} xs={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <ComboBoxSimple
                        required
                        options={filterOptions.loading? []:filterOptions.priority.map(prio => ({_id: prio, name: getTextForPriority({priorNum: prio})}))}
                        id="priority"
                        value={priority}
                        fullWidth
                        handleMyChange={(id,value) => setPriority(value)}
                        style={{ width: "100%" }}
                        placeHolder={'Priority'}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={6} xs={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel className={classes.labelsPlaceHolders} htmlFor="file">
                      Choose file to upload
                    </InputLabel>
                    <input
                        style={{ display: 'none' }}
                        id="file"
                        name="file"
                        type="file"
                        disabled={uploading}
                        multiple
                        onChange={handleFileChange}
                    />
                    <FilledInput
                        color="primary"
                        id="filled-adornment-password"
                        type={'text'}
                        disabled
                        endAdornment={
                          <InputAdornment position="end">
                            {uploading ?
                                <CircularProgress color="inherit" size={20}></CircularProgress> :
                                <label className={classes.uploadButton} htmlFor="file">
                                  Browse file
                                </label>}
                          </InputAdornment>
                        }
                    />
                    {Boolean(files.length) &&
                    files.map((file, index) => (
                        <FormHelperText key={index}>
                          <ImageIcon className={classes.fileIconStyle} />
                          &nbsp;
                          {file.name}
                          <HighlightOffIcon onClick={() => deleteFile(index)} className={classes.fileIconStyle} />
                        </FormHelperText>
                    ))}
                  </FormControl>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Grid container spacing={6}>
                    <Grid item md={8} xs={12}>
                      <FormControl fullWidth className={classes.formControl}>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                              vertical: 0,
                              horizontal: 30,
                            }}

                        >
                          <div className="optionsPopover">
                            <div className="sourceInputContainer">
                              <input
                                  placeholder="Name"
                                  onChange={({ target }) => setSourceName(target.value)}
                                  value={sourceName}
                              />
                            </div>
                            <Divider />
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <div className="sourceInputContainer">
                                <input
                                    placeholder="Link"
                                    onChange={({ target }) => setSourceLink(target.value)}
                                    value={sourceLink}
                                />
                              </div>
                              <IconButton
                                  onClick={handleAddSource}
                                  color="primary"
                                  size="small"
                                  aria-label="upload picture"
                                  component="span"
                              >
                                <CheckCircleIcon className={classes.checkSource} />
                              </IconButton>
                            </div>
                          </div>
                        </Popover>

                        <div className="sources-box">
                          {renderSources()}
                          <AddCircleOutlineOutlinedIcon
                              onClick={handlePopoverOpen}
                              color="secondary"
                          />
                        </div>

                        <FormHelperText>
                          <InsertLinkIcon className={classes.linkIcon} />
                          &nbsp;
                          Sources
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                          control={<Switch
                              onChange={handleStatusChange}
                              checked={status}
                              name="status"
                          />}
                          labelPlacement="start"
                          label="Status"
                      />
                      {renderStatus()}
                    </Grid>
                  </Grid>
                </Grid>

                {/* Buttons */}
                <Grid item xs={12} className={classes.buttonsWrapper}>
                  <Button
                      variant="outlined"
                      color="secondary"
                      size="large"
                      style={{ marginLeft: "10px", textTransform: 'none' }}
                      onClick={()=> props.history.goBack()}
                  >
                    Cancel
                  </Button>
                  <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      className={classes.applyButton}
                      type="submit"
                  >
                    Apply
                  </Button>
                </Grid>

              </Grid>

            </form>
          </Grid>
        </Grid>
      </main>
    </React.Fragment>
  );
}



export default withSnackbar(AddNews);