import React from 'react';
import GlobalModal from '.';
import { Card, Grid } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/ClearRounded';

const UserUndo = ({open, handleClose}) => {
    return ( 
        <GlobalModal open={open} handleClose={handleClose}>
            <Card className="userUndoCard">
                <Grid container spacing={3}>
                    <Grid item xs={12} className="modalHeader">
                        <h2>User Deleted</h2>
                        <ClearIcon onClick={handleClose}></ClearIcon>
                    </Grid>

                    <Grid item xs={12}>
                        <b>The user has been deleted</b>
                    </Grid>

                    <Grid item xs={12} className="modalFooter" >
                        <button onClick={handleClose} className="filterButton red">Undo</button>
                    </Grid>
                </Grid>
            </Card>
        </GlobalModal>
    );
}
 
export default UserUndo;