import Axios from './'
import store from '../redux/store';
import { setAllUser, setMoreUser, setNewUser, setUserLoading } from '../redux/users/actions';
import { randomBytes } from 'crypto';
import { encryptPassword } from '../helpers/encryption';

const path = '/users'

export const getUsers = async (nextPage=false) => {
  const {users} = store.getState();
  
  //changing the loading state
  store.dispatch(setUserLoading(true));

  const query = {...users.query, forwardToken: nextPage ? users.nextPage : ''};
  const queryString = query? Object.keys(query)
    .filter(key => query[key] !== "")
    .map(key => `${key}=${query[key]}`)
    .join('&'):"";
  return Axios.get(`${path}${queryString !== ""? `?${queryString}`:""}`)
    .then(({ data }) => {
      if (nextPage) {
        store.dispatch(setMoreUser(data))
      } else
        store.dispatch(setAllUser(data));
      return Promise.resolve(data.users);
    })
};

export const getUserPermissions = async (id) => {
  return Axios.get(`${path}/${id}/permissions`)
    .then(({ data }) => {
      return Promise.resolve(data);
    }).catch(err => {
      console.log(err.response);
      throw err;
    })
};


export const createUser = async (data, callbackSucces, callbackError) => {
  data.permissions = extractIds(data.permissions);
  Axios.post(path, data).then(res => {
    // console.log('New User', res.data.user);
    store.dispatch(setNewUser(res.data.user))
    callbackSucces(res.data);
  }).catch(err => {
    console.log(err.response)
    callbackError(err)
  })
}

export const deleteUser = async (id, callbackSucces, callbackError) => {
  Axios.delete('/users/' + id).then(res => {
    callbackSucces(res.data)
  })
    .catch(err => {
      callbackError(err)
    })
}

export const rejectUser = async (id, data, callbackSuccess, callbackError) => {
  Axios.put('/userRequests/reject/' + id, data).then(res => {
    callbackSuccess(res.data);
  })
    .catch(err => {
      callbackError(err)
    })
}

export const unblockUser = async (id, callbackSuccess, callbackError) => {
  Axios.post(`/users/${id}/unblock`)
    .then(res => {
      callbackSuccess(res.data);
    })
    .catch(err => {
      callbackError(err)
    })
}

export const approveUser = async (user, callbackSuccess, callbackError) => {
  editUser('/userRequests/approve/' + user._id, user, callbackSuccess, callbackError);
}

export const updateUser = async (user, callbackSuccess, callbackError) => {
  editUser('/users/' + user._id, user, callbackSuccess, callbackError);
}

const editUser = (url, user, callbackSuccess, callbackError) => {
  const data = {
    name: user.name,
    email: user.email,
    role: user.role,
  }
  if(user.permissions) data.permissions = extractIds(user.permissions);
  if(user.clients) data.clients = user.clients;
  Axios.put(url, data)
    .then(res => {
      callbackSuccess(res.data)
    })
    .catch(err => {
      console.log(err.response)
      callbackError(err)
    })
}

// since latest endpoints only supports the id's inside permissions.
function extractIds(permissions) {
  // any item with empty disease array removed
  // const valids = permissions.filter(p=> Boolean(p.diseases.length));
  // console.log(valids);
  const withIds = permissions
    .map(p => {
      return {
        therapeuticArea: p.therapeuticArea.id,
        diseases: p.diseases.map(d => d.id)
      }
    });
  return withIds;
}


export const changePassword = async (currPassword, newPassword, uid) => {
  const iv = randomBytes(16);
  currPassword = (await encryptPassword(currPassword, iv)).encryptedText;
  newPassword = (await encryptPassword(newPassword, iv)).encryptedText;

  const data = {currPassword, newPassword, iv: iv.toString('hex')};
  return Axios.post(`${path}/change-password/${uid}`, data);
}
