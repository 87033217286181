export const SET_ALL_COMPOUNDS = "SET_ALL_COMPOUNDS";
export const SET_MORE_COMPOUNDS = "SET_MORE_COMPOUNDS";
export const ADD_COMPOUND = "ADD_COMPOUND";
export const DELETE_COMPOUND = "DELETE_COMPOUND";
export const UPDATE_COMPOUND = "UPDATE_COMPOUND";
export const SET_ACTIVE_COMPOUND = "SET_ACTIVE_COMPOUND";
export const SET_COMPOUND_LOADING = "SET_COMPOUND_LOADING";
export const SET_COMPOUND_QUERY = "SET_COMPOUND_QUERY";


export function setAllCompounds(data) {
  return {
    type: SET_ALL_COMPOUNDS,
    payload: data
  };
}

export function setMoreCompounds(data) {
  return {
    type: SET_MORE_COMPOUNDS,
    payload: data
  };
}

export function addCompound(compound) {
  return {
    type: ADD_COMPOUND,
    payload: compound
  };
}

export function deleteCompound(compound) {
  return {
    type: DELETE_COMPOUND,
    payload: compound
  };
}

export function updateCompound(compound) {
  return {
    type: UPDATE_COMPOUND,
    payload: compound
  };
}

export function setActiveCompound(compound) {
  return {
    type: SET_ACTIVE_COMPOUND,
    payload: compound
  }
}

export function setCompoundLoading(loading) {
  return {
    type: SET_COMPOUND_LOADING,
    payload: loading
  };
}

export function setCompoundQuery(query) {
  return {
    type: SET_COMPOUND_QUERY,
    payload: query
  };
}