import Axios from "./index";
import store from '../redux/store';
import { setCompoundLoading, setMoreCompounds, setAllCompounds } from "../redux/compounds/actions";
const path = '/compounds';

export function getAllCompounds(nextPage=false) {
  const {nextPage:ft, query} = store.getState().compounds;

  //changing the loading state
  store.dispatch(setCompoundLoading(true));

  const url = `${path}${query ? '?name='+query : ''}${nextPage ? '?forwardToken='+ft : ''}`;
  return Axios.get(url)
      .then(({data})=>{
          if(nextPage) {
              store.dispatch(setMoreCompounds(data))
          }else
              store.dispatch(setAllCompounds(data));
          return Promise.resolve(data.compounds);
      })
}


export function deleteCompoundById(id, callbackSuccess, callbackError) {
  Axios.delete(`${path}/${id}`).then(res=>{
    callbackSuccess(res.data);
  }).catch(err=>{
    console.log(err.response);
    callbackError(err);
  });
}

export function createNewCompound(data) {
  return Axios.post(`${path}`, data)
}

export function editCompound(id, data) {
  return Axios.put(`${path}/${id}`, data)
}

export function getCompoundById(id) {
  return Axios.get(`${path}/${id}`);
}

export function searchCompounds(filters) {
  return Axios.post(`${path}/search`, {filters})
}