import React from "react";
import { Button, Typography } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { connect } from "react-redux";
import {AddCircle, Cancel, CheckCircle, Delete, Edit, LockOpen} from "@material-ui/icons";
import { uniqBy } from 'lodash';
import { withSnackbar } from "notistack";

import "./users.scss";

import {getUserPermissions, getUsers} from "../../../../service/users";
import { setAllUser, setActiveUser, addUserQuery } from "../../../../redux/users/actions";
import "./users.scss";
import AdminBase from "../AdminBase";
import GenericTable from "../../../Table/genericTable";

import { AuthContext } from "../../../../auth/AuthContext";
import UserDeleteConfirm from "../../../Modal/UserDeleteConfirm";
import UserPermissionsInformation from "../../../Modal/UserPermissionsInformation";

import UserRequestFilters from "./UserRequestFilters";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {toggleLoading} from "../../../../redux/app/actions";
import UserRejectConfirm from "../../../Modal/UserRejectConfirm";
import UserUnblockConfirm from "../../../Modal/UserUnblockConfirm";

//TODO() improve this: - function component - filtered way
class UsersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      data: [],
      userModalOpen: false,
      editing: false,
      categories: [],
      showPermissionsModalOpen: false,
      deleteConfirm: false,
      rejectConfirm: false
    };

    this.columns = [
      {
        key: 'name',
        name: 'User',
        align: 'left',
        showOnMdUp: true,
        renderRow: ({ name }) => {
          let toRender = `${name.first} ${name.last}`;
          return (<span>{toRender}</span>)
        }
      },
      { name: "Email", key: "email" },
      { name: "Role", key: "role" },
      {
        key: 'clients',
        name: 'Permissions',
        renderRow: (row) => {
          let toRender = uniqBy(row.clients.map(client => client.name)).join(', ');
          return (<span>
            <span className={'truncate'}>{toRender}</span>
            <span className={'view-more-container'}>
              <Button onClick={() => this.openPermissionsModal(row)}>
                <AddCircle fontSize="default" color="secondary" />
                <span style={{ textTransform: 'initial', marginLeft: '2px' }}>
                  VIEW ALL
                </span>
              </Button>
            </span>
          </span>)
        }
      },
      {
        key:"status",
        name: "Status",
        renderRow: ({status}) => {
          if(status === "pending"){
            return <span className="status-active capitalize">{status}</span>;
          } else if(status === "blocked"){
            return <span className="status-blocked capitalize">{status}</span>;
          } else {
            return <span className="capitalize">{status}</span>;
          }
        }
      }
    ];
  }

  openPermissionsModal (row) {
    this.props.dispatchSetActiveUser(row);
    this.setState({
      showPermissionsModalOpen: true
    });
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.props.dispatchAddUserQuery(null)
    this.loadUsers();
  }

  componentWillUnmount() {
    this.props.dispatchAddUserQuery(null);
  }

  loadUsers() {
    getUsers()
      .then((data) => { })
      .catch((error) => this.userErrors(error))
  }

  getNext100 = async () => {
    return getUsers(true);
  }

  searchUsers = (search) => {
    this.props.dispatchAddUserQuery(search)
    this.loadUsers();
  };

  rowActions = [
    {
      component: (row) => {
        const { path } = this.props.match;
        const onClick = (user) => {
          this.props.dispatchToggleLoading();
          getUserPermissions(user._id).then((data) => {
            user.permissions = data;
            this.props.dispatchSetActiveUser(user);
            this.props.dispatchToggleLoading();
            this.props.history.push(`${path}/edit/${user._id}`);
            return;
          }).catch((err) => {
            this.props.enqueueSnackbar(
              "Sorry! We couldn't load the permissions :(",
              {
                variant: "error",
              });
          });

        }
        // if user is admin and logged in user is not admin
        if (row.role === 'admin' && !this.context.onlyAdmin()) return null;
        return row.status !== "pending"? (
          <span onClick={() => onClick(row)}>
            <Edit fontSize="small" style={{ marginRight: 15 }} />
            <Typography variant="inherit">Edit</Typography>
          </span>
        ):
        (
          <span onClick={() => onClick(row)}>
            <CheckCircle fontSize="small" style={{ marginRight: 15 }} />
            <Typography variant="inherit">Accept</Typography>
          </span>
        )
      }
    },
    {
      component: (row) => {
        const onClick = (user) => {
          this.props.dispatchSetActiveUser(user);
          this.setState({ unblockConfirm: true })
        }
        if (!this.context.onlyAdmin()) return null;
        if(row.status !== "blocked") return null;
        return (
          <span onClick={() => onClick(row)}>
              <LockOpen fontSize="small" style={{ marginRight: 15 }} />
              <Typography variant="inherit">Unblock</Typography>
            </span>
        )
      }
    },
    {
      component: (row) => {
        const onClick = (user) => {
          this.props.dispatchSetActiveUser(user);
          this.setState({ deleteConfirm: true })
        }
        if (!this.context.onlyAdmin()) return null;
        // user request
        if(row.status === "pending") return null;
        return (
          <span onClick={() => onClick(row)}>
            <Delete fontSize="small" style={{ marginRight: 15 }} />
            <Typography variant="inherit">Delete</Typography>
          </span>
        )
      }
    },
    {
      component: (row) => {
        if(row.status === "pending") {
          return (
            <span onClick={() => {
              this.props.dispatchSetActiveUser(row);
              this.setState({ rejectConfirm: true });
            }}>
              <Cancel fontSize="small" style={{ marginRight: 15 }} />
              <Typography variant="inherit">Reject</Typography>
            </span>
          )
        } else{
          return null;
        }
      }
    }
  ];

  addNewUser = () => {
    // const { url } = useRouteMatch();
    this.props.dispatchSetActiveUser({
      name: {
        first: '',
        last: ''
      },
      email: '',
      role: '',
      permissions: []
    });
    this.props.history.push('/administration/users/add')
  }

  closeUserModal = () => {
    this.setState({ userModalOpen: false })
  }

  userSuccess = (users) => {
    this.setState({ users: users });
    this.props.setAllUser(users)
  };

  userErrors = (err) => {
    const { response } = err;
    if (!response) {
      this.props.enqueueSnackbar('Unexpected error occured!', { variant: 'error' })
    } else if(response.status === 404) {
      this.props.setAllUser({users: [], total: 0, nextPage: ""})
    } else {
      const { message } = response.data;
      this.props.enqueueSnackbar(message, { variant: 'error' })
    }
    console.log(err)
  }

  headerRightComponent = (
    <>
      <Button onClick={this.addNewUser}>
        <PersonAddIcon color="secondary" />
        <span style={{ textTransform: 'initial', marginLeft: '2px' }}>
          Add New User
        </span>
      </Button>
    </>
  );

  titleComponent = (
    <h2>User management</h2>
  );

  render() {
    const { loading, allUser, total } = this.props;
    const { deleteConfirm, showPermissionsModalOpen, rejectConfirm, unblockConfirm } = this.state;
    return (
      <AdminBase headerRightComponent={this.headerRightComponent}
        titleComponent={this.titleComponent}
        handleSearch={(text) => this.searchUsers({name: text})}
        handleResetSearch={()=>this.searchUsers({name: ''})}
        showFilters={true}
        showSearchBox={true}>

        <UserRequestFilters onSelect={(status) => this.searchUsers({status})} />
        <GenericTable
          columns={this.columns}
          data={allUser}
          rowActions={this.rowActions}
          loading={loading}
          pagination={{
            loadMore: this.getNext100,
            total
          }}
        />

        <UserPermissionsInformation
          open={showPermissionsModalOpen}
          handleClose={() => this.setState({ showPermissionsModalOpen: false })}
        />

        <UserDeleteConfirm
          open={deleteConfirm}
          handleClose={() => this.setState({ deleteConfirm: false })}
        />

        <UserRejectConfirm
          open={rejectConfirm}
          handleClose={() => this.setState({ rejectConfirm: false })}
        />

        <UserUnblockConfirm
          open={unblockConfirm}
          handleClose={() => this.setState({ unblockConfirm: false })}
        />

      </AdminBase>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { allUser, loading, total } = state.users;
  return {
    allUser,
    loading,
    total
  };
};

const mapActionsToProps = {
  setAllUser: setAllUser,
  dispatchSetActiveUser: setActiveUser,
  dispatchAddUserQuery: addUserQuery,
  dispatchToggleLoading: toggleLoading
}



export default compose(
  connect(mapStateToProps, mapActionsToProps),
  withRouter,
  withSnackbar
)(UsersList);
