import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
const style = {
  display: "flex",
  width: "100vw",
  height: "100vh",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column"
};
const NotFound = () => {
  return (
    <div style={style}>
      <h3>Are you lost?</h3>
      <Link to="/dashboard">
        <Button variant="raised" size="large" color="primary">
          Take Me Home..
        </Button>
      </Link>
    </div>
  );
};

export default NotFound;
