import React, { useState } from 'react';
import GlobalModal from '.';
import {Card, Grid, CircularProgress, TextField, Switch, FormControlLabel} from '@material-ui/core';

import CancelIcon from "../../assets/img/cancelIcon.svg";
import { connect } from 'react-redux';
import { rejectUser } from '../../service/users';
import { withSnackbar } from 'notistack';
import {setRejectUser} from '../../redux/users/actions';

const RejectConfirm = ({open, handleClose, activeUser, enqueueSnackbar, dispatchRejectUser}) => {

    const [loading, setLoading] = useState(false);
    const [rejectReason, setRejectReason] = useState("");
    const [sendRejectionEmail, setSendRejectionEmail] = useState(false);

    const handleReject = () => {
        setLoading(true)
        rejectUser(activeUser._id, {rejectReason, sendRejectionEmail}, handleSuccess, handleError)
    }

    const handleSuccess = (data) => {
        setLoading(false);
        dispatchRejectUser(activeUser)
        enqueueSnackbar(`User rejected successfully!`, {variant: 'success'});
        handleClose();
    }
    
    const handleError = (err)=>{
        setLoading(false);
        handleClose();
        enqueueSnackbar(`Error occurred while rejecting the user.`, {variant: 'error'});
    }

    const renderStatus = () => {
        const customStyle = {
            color: '#56C6CC',
            margin: '8px',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '21px'
        };
        return sendRejectionEmail ?
          <span style={customStyle}>YES</span> :
          <span style={customStyle}>NO</span>;
    }

    return ( 
        <React.Fragment>
            <GlobalModal open={open} handleClose={handleClose}>
                <Card className="deleteConfirmCard">
                    <Grid container spacing={3}>
                        <Grid item xs={12} className="modalHeader">
                            <img
                              src={CancelIcon}
                              className="form-icon-image"
                              alt="administration icon"
                            />
                            <h2>Reject User</h2>
                        </Grid>

                        <Grid item xs={12} className="modalHeader">
                            Are you sure you want to reject this User?
                            <span className={"fullName"}>{activeUser.name? `${activeUser.name.first} ${activeUser.name.last}`:"No name provided"}</span>
                        </Grid>

                        <Grid item xs={12} >
                            <TextField
                              multiline
                              rows={4}
                              variant={"filled"}
                              style={{width: "100%"}}
                              value={rejectReason}
                              onChange={({target}) => setRejectReason(target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} className={"text-center"}>
                            <FormControlLabel
                              control={<Switch
                                color={"primary"}
                                onChange={({target}) => setSendRejectionEmail(target.checked)}
                                checked={sendRejectionEmail}
                                name={"sendRejectionEmail"}
                              />}
                              labelPlacement="start"
                              label={"Send notification"}
                            />
                            {renderStatus()}
                        </Grid>

                        <Grid item xs={12} className="modalFooter" >
                            <button disabled={loading} onClick={handleClose} className="filterButton">Cancel</button>
                            <button disabled={loading} onClick={handleReject} className="filterButton red">
                                {loading 
                                ? <CircularProgress
                                    color="secondary"
                                    size={20}
                                >
                                </CircularProgress> 
                                : 'Reject'}
                            </button>
                        </Grid>
                    </Grid>
                </Card>
            </GlobalModal>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        activeUser: state.users.activeUser
    }
}

const mapActionsToProps = {
    dispatchRejectUser: setRejectUser
}



export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(RejectConfirm));