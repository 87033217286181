export const EVENT_TYPES = [
    'Conference',
    'Regulatory/ Other Development',
    'Trial Initiation/ Results',
    'Commercial'
];

export function eventTypeColor(type) {
    if (type.toLowerCase().includes("development")) return "#FFEDAE";
    if (type.toLowerCase().includes("commercial")) return "#006FBB";
    if (type.toLowerCase().includes("conference")) return "#D5C697";
    return "#f4cccc";
}

export function eventTypeFontColor(type) {
    // if (type.toLowerCase().includes("development")) return "#FFEDAE";
    if (type.toLowerCase().includes("commercial")) return "#FFFFFF";
    return "inherit";
}