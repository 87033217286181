import React from "react";

import Gene_Therapy from "../../assets/img/TM/Gene_Therapy.png";
import Cell_Therapy from "../../assets/img/TM/Cell_Therapy.png";
import mAbs from "../../assets/img/TM/mAbs.png";
import RNAiASO from "../../assets/img/TM/RNAi-ASO.png";
import Small_Molecules from "../../assets/img/TM/Small_Molecules.png";
import Peptide from "../../assets/img/TM/Peptide.png";
import Vaccine from "../../assets/img/TM/Vaccine.png";
import Unknown from "../../assets/img/TM/Unknown.png";
import Dormant from "../../assets/img/TM/Dormant.png";
import Potential_DMT from "../../assets/img/TM/Potential_DMT.png";

export const tmMap = {
  "gene therapy": Gene_Therapy,
  "cell therapies": Cell_Therapy,
  mabs: mAbs,
  "rnai/aso": RNAiASO,
  "small molecules": Small_Molecules,
  peptide: Peptide,
  vaccine: Vaccine,
  unknown: Unknown,
  dormant: Dormant,
  "potential dmt": Potential_DMT,
};

export const TM = (props) => {
  const { tm } = props;
  if (!tm) {
    return null;
  }

  const all = tm.split(",");
  const distinct = [];
  all.forEach((str) => {
    str = str.trim().toLowerCase();
    if (str && !distinct.includes(str)) distinct.push(str);
  });

  if (!distinct.length) return null;

  return (
    <React.Fragment>
      {distinct.map((str, i) => (
        <img key={i} src={tmMap[str] ? tmMap[str] : tmMap["unknown"]} alt="therapeutic modality"/>
      ))}
    </React.Fragment>
  );
};
