import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  sectionTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    display: 'flex',
    alignItems: 'center',
    color: '#8A8A8A'
  },
});

const ComboBoxSimple = ({
  options,
  style,
  id,
  name,
  label,
  disabled,
  multiple,
  value,
  handleMyChange,
  variant,
  placeHolder,
  required,
  loading,
  handleSearch,
  error,
  helperText,
  onBlur
}) => {

  const {
    sectionTitle
  } = useStyles();

  const objectType = options.length && typeof options[0] === 'object';

  const onChange = (e, value, reason) => {
    if(reason === 'clear' && handleSearch){
      handleSearch();
    }
    handleMyChange(e, value, reason);
  };

  return (
      <>
        {label && <label className={sectionTitle}>{label}</label>}
        <Autocomplete
            id={id}
            name={name}
            fullWidth
            required={Boolean(required)}
            disabled={disabled}
            multiple={Boolean(multiple)}
            options={options}
            onChange={onChange}
            autoHighlight
            getOptionLabel={(option) => objectType ? (option.name ? option.name : '') : (option.length ? option: '')}
            value={value}
            style={{ ...style }}
            loading={loading}
            onBlur={onBlur}
            renderInput={parameters => (
                <TextField
                    {...parameters}
                    label={placeHolder}
                    variant={variant || "outlined"}
                    onChange={ ev => {
                      // dont fire API if the user delete or not entered anything
                      if (ev.target.value !== "" || ev.target.value !== null) {
                        if (handleSearch) {
                          handleSearch(ev.target.value);
                        }
                      }
                    }}
                    inputProps={{
                      ...parameters.inputProps,
                    }}
                    error={error}
                    helperText={helperText}
                />
            )}
        />
      </>
  );
};

export { ComboBoxSimple };