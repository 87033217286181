import {
  SET_ALL_TRIALS,
  SET_MORE_TRIALS,
  ADD_TRIAL,
  UPDATE_TRIAL,
  DELETE_TRIAL,
  SET_ACTIVE_TRIAL,
  SET_TRIAL_LOADING,
  SET_TRIAL_QUERY,
  SET_TRIALS_FILTER_OPTIONS
} from "./actions";

const initialState = {
  filterOptions: {
    loading: true
  },
  allTrials: [],
  nextPage: null,
  loading: true,
  active: null,
  query: null,
  total: 0
};

export default function eventsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ALL_TRIALS: {
      const duplicateState = { ...state };
      duplicateState.allTrials = payload.events;
      duplicateState.nextPage = payload.nextPage;
      duplicateState.total = payload.total;
      duplicateState.loading = false;
      return duplicateState;
    }
    
    case SET_MORE_TRIALS: {
      const duplicateState = { ...state };
      duplicateState.allTrials = [...state.allTrials, ...payload.events];
      duplicateState.nextPage = payload.nextPage;
      duplicateState.total = payload.total;
      duplicateState.loading = false;
      return duplicateState;
    }

    case DELETE_TRIAL: {
      const allTrials = [...state.allTrials];
      const index = allTrials.findIndex(c=> c._id === payload._id);
      allTrials.splice(index, 1);
      return {...state, allTrials};
    }

    case ADD_TRIAL: {
      const allTrials = [...state.allTrials];
      allTrials.splice(0, 0, payload);
      return {...state, allTrials};
    }

    case UPDATE_TRIAL: {
      const allTrials = [...state.allTrials];
      const index = allTrials.findIndex(c=> c._id === payload._id);
      allTrials.splice(index, 1, payload);
      return {...state, allTrials};
    }

    case SET_ACTIVE_TRIAL: {
      const duplicateState = { ...state };
      duplicateState.active = payload;
      return duplicateState
    }
    
    case SET_TRIAL_LOADING: {
      const duplicateState = { ...state };
      duplicateState.loading = payload;
      return duplicateState;
    }

    case SET_TRIAL_QUERY: {
      const duplicateState = { ...state };
      duplicateState.query = payload;
      return duplicateState
    }

    case SET_TRIALS_FILTER_OPTIONS: {
      const duplicateState = { ...state };
      duplicateState.query = payload;
      return duplicateState
    }

    default:
      return state;
  }
}
