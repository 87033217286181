export const SET_ALL_CLIENTS = "SET_ALL_CLIENTS";
export const ADD_CLIENT = "ADD_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const SET_ACTIVE_CLIENT = "SET_ACTIVE_CLIENT";

export function setAllClients(companies) {
  return {
    type: SET_ALL_CLIENTS,
    payload: companies
  };
}

export function addClient(client) {
  return {
    type: ADD_CLIENT,
    payload: client
  };
}

export function deleteClient(client) {
  return {
    type: DELETE_CLIENT,
    payload: client
  };
}

export function updateClient(client) {
  return {
    type: UPDATE_CLIENT,
    payload: client
  };
}

export function setActiveClient(client) {
  return {
    type: SET_ACTIVE_CLIENT,
    payload: client
  };
}