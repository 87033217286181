import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { reportsLink } from '../../constants/routes';
import { useQuery } from '../../helpers/utils';
import HorizontalScrollItems from '../common/HorizontalScrollItems';

const ReportNavigation = ({reports, ...props}) => {
    const {clientId, dId, taId} = useParams();
    const query = useQuery();

    const isActive = (type) => query.get('type') === type;

    const elements = reports.map((report, index)=>(
            <NavLink 
                isActive={() => isActive(report.type)} key={index} 
                activeClassName="menu active" 
                to={reportsLink(clientId, taId, dId, report.type)}
            >
                <button className="atButton medium">{report.name}</button>
            </NavLink>
        ))

    return (
      <div className="scrollable-nav"><HorizontalScrollItems elements={elements} /></div>
    );
}
 
export default ReportNavigation;