import Axios from './';
import { encryptPassword } from '../helpers/encryption';

const encryptedObj = async (password) => {
  const {iv, encryptedText} = await encryptPassword(password);
  return {iv, password: encryptedText};
}

export const loginWithCreds = async (email, password) => {
  const encrypted = await encryptedObj(password);
  return Axios.post('/auth/login', {
    email, ...encrypted 
  })
};

export const signUp = async (data) => {
  return Axios.post('/auth/signup', data)
};

export const recoverPassword = (email) => {
  return Axios.post('/auth/reset-request', { email })
}

export const updatePassword = async (data) => {
  const encrypted = await encryptedObj(data.password);
  return Axios.post('/auth/update-password', {...data, ...encrypted})
}


export const getPermissions = async () => {
  return Axios.get('/users/user-permissions')
}

