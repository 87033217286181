import React from 'react';
import GeneralLayout from '../common/GeneralLayout'
import FontSize from '../common/FontSize'
import GenericBreadCrumbs from '../common/GenericBreadCrumbs';
import ExportButton from '../export-button/ExportButton';
import ReportNavigation from './ReportNavigation';
import { Typography } from '@material-ui/core';
import { useParams } from 'react-router';
import { reportsLink} from '../../constants/routes';
import withPBC from '../common/withPBC';

const ReportBase = ({ config, type, children, fontSizing=true, noExport=false, ...props }) => {

    const { clientId, dId, taId } = useParams();

    // extracting the report from report type    
    const reportIndex = config.reports.findIndex(report => report.type === type);
    const report = reportIndex === -1 ? null : config.reports[reportIndex];

    const crumbs = withPBC();
    if(report) crumbs.push({name: report.name, to: reportsLink(clientId, taId, dId, type)})

    return (
        <GeneralLayout
            headerLeftComponent={<GenericBreadCrumbs items={crumbs} />}
            headerRightComponent={noExport ? null : <ExportButton exportImg={props.exportImg} />}
            toolbarLeftComponent={(
                <>
                    <ReportNavigation reports={config.reports} />
                    { !props.noTitle &&
                        <Typography variant="h6" style={{marginLeft: 10}}>
                            {config.titlePrefix && `${config.titlePrefix} `} 
                            {report && report.name}
                        </Typography>
                    }
                    {props.filters || null}
                </>
            )}
            toolbarRightComponent={(
                <>
                    {fontSizing && <FontSize />}
                    {props.toolbarRightComponent || null}
                </>
            )}
        >
            {children}
        </GeneralLayout>
    );
}

export default ReportBase;