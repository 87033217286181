import Typography from "@material-ui/core/Typography";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import React from "react";

const SkeletonTextAndHeading = (props) => {
  const {size = 2, count = 2} = props;

  const renderItems = () => {
    const list = [];
    for (let i = 0; i<size; i++) {
      let item = (
        <div key={i} className="skeleton-p">
          <Typography component="div" key={"h3"} variant={"h3"}>
            <Skeleton />
          </Typography>
          <Typography component="div" key={"p"} variant={"body1"}>
            <Skeleton count={count}/>
          </Typography>
        </div>
      );
      list.push(<div key={i}>{item}</div>);
    }
    return list;
  }

  return (
    <SkeletonTheme color="#E2DEDE">
      {renderItems()}
    </SkeletonTheme>
  );
};

export default SkeletonTextAndHeading;