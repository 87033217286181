import {
  SET_ALL_USER,
  SET_ACTIVE_USER,
  UPDATE_USERS,
  DELET_USER,
  ADD_USER,
  SET_MORE_USERS,
  SET_USER_LOADING,
  SET_USER_QUERY,
  ADD_USER_QUERY,
  REJECT_USER, UNBLOCK_USER
} from "./types";
// import { CardActionArea } from "@material-ui/core";
const initialState = {
  allUser: [],
  nextPage: null,
  total: 0,
  loading: true,
  query: null,
  activeUser: {
    name: {
      first: '',
      last: ''
    },
    email: '',
    role: '',
    permissions: [

    ]
  }
};

export default function userReducer(state = initialState, { type, payload }) {
  switch (type) {
      case SET_ALL_USER: {
        const duplicateState = { ...state };
        duplicateState.allUser = payload.users;
        duplicateState.nextPage = payload.nextPage;
        duplicateState.total = payload.total;
        duplicateState.loading = false;
        return duplicateState;
      }  

      case SET_MORE_USERS: {
        const duplicateState = { ...state };
        duplicateState.allUser = [...state.allUser, ...payload.users];
        duplicateState.nextPage = payload.nextPage;
        duplicateState.total = payload.total;
        duplicateState.loading = false;
        return duplicateState;
      }

      case SET_USER_LOADING: {
        const duplicateState = { ...state };
        duplicateState.loading = payload;
        return duplicateState
      }
      
      case SET_USER_QUERY: {
        const duplicateState = { ...state };
        duplicateState.query = payload;
        return duplicateState
      }
       
      case SET_ACTIVE_USER: {
        return {
          ...state,
          activeUser: payload,
        };
      }

      case UPDATE_USERS: {
        const allUser = [...state.allUser];
        const index = allUser.findIndex((user)=>user._id === payload._id);
        allUser[index] = payload;
        return {
          ...state,
          allUser
        };
      }

      case DELET_USER: {
        const allUser = [...state.allUser];
        const index = allUser.findIndex((user)=>user._id === payload._id);
        allUser.splice(index,1);
        return {
          ...state,
          allUser
        };
      }

      case REJECT_USER: {
        const allUser = [...state.allUser];
        const index = allUser.findIndex((user)=>user._id === payload._id);
        allUser[index] = {...allUser[index], status: "terminated"};
        return {
          ...state,
          allUser
        };
      }

      case UNBLOCK_USER: {
        const allUser = [...state.allUser];
        const index = allUser.findIndex((user)=>user._id === payload._id);
        allUser[index] = {...allUser[index], status: "active"};
        return {
          ...state,
          allUser
        };
      }

      case ADD_USER: {
        const allUser = [...state.allUser];
        allUser.push(payload);
        return {
          ...state,
          allUser
        };
      }

      case ADD_USER_QUERY: {
        const duplicateState = { ...state };
        duplicateState.query = {...duplicateState.query, ...payload};
        return duplicateState;
      }


      default:
      return state;
  }
}
