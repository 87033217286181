import React from "react";
import classNames from "classnames";
import { DateRangePicker, DateRange } from "react-date-range";
import { defaultStaticRanges } from "./staticRanges";
import Button from "@material-ui/core/Button";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { format } from "date-fns";
import MobileModal from "../Modal/MobileModal";
import { Grid } from "@material-ui/core";
import moment from "moment";

class DatePicker extends React.Component {
  constructor(props) {
    super();
    this.state = {
      open: false,
      startDate: props.dateRange.startDate,
      endDate: props.dateRange.endDate,
      showdate: "",
    };
  }
  onClose = (e) => {
    this.setState({ open: !this.state.open });
  };
  handleBlur = (e) => {
    this.setState({ open: !this.state.open });
  };
  handleSelect = async (ranges) => {
    this.setState({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      showDate: "pepe",
    });
    let show =
      format(ranges.selection.startDate, "dd/MM/yyyy") +
      " - " +
      format(ranges.selection.endDate, "dd/MM/yyyy");

    this.setState({ showdate: show });

    this.props.handleSelect(ranges);
  };

  handleSelectMobile = async (ranges) => {
    this.setState({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      showDate: "pepe",
    });
  };
  
  handleMobileApply = () => {
    const {startDate, endDate} = this.state;
    this.props.handleSelect({selection: {startDate, endDate}});
    this.setState({open: false})
  }

  clickListener = (e) => {
    let correctClick = e.path.filter((elm) => {
      if (elm === document.getElementById("dateRangeContainer")) {
        return elm;
      }
      return false;
    });
    if (correctClick.length === 0) this.setState({ open: false });
  };

  isMobile = () => {
    return window.innerWidth <= 600;
  }

  componentDidMount() {
    if(!this.isMobile())
      document.addEventListener("click", this.clickListener, true);
  }
  componentWillUnmount() {
    if(!this.isMobile())
      document.removeEventListener("click", this.clickListener, true);
  }
  render() {
    const { startDate, endDate, open } = this.state;
    const isMobile = this.isMobile();
    const selectionRange = {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    };
    return (
      <div
        className={classNames("datePicker",this.props.className)}
        id="dateRangeContainer"
        style={{ position: "relative", bottom: "0" }}
      >
        {open && !isMobile && (
          <div className="containerDateRange">

             <DateRangePicker
              onChange={(range) => this.handleSelect(range)}
              ranges={[selectionRange]}
              twoStepChange
              inputRanges={[]}
              maxDate={moment(new Date()).add(1, 'y').toDate()}
              onBlur={() => {
                this.setState({ open: false });
              }}
              staticRanges={this.props.staticRanges ? this.props.staticRanges : defaultStaticRanges}
              rangeColors={["#b22028"]}
            ></DateRangePicker>
          </div>
        )}

        {open && isMobile && (
          <MobileModal
            open={open}
            label="Dates"
            handleClose={() => this.setState({ open: !this.state.open })}
          >
            <DateRange
              onChange={(range) => this.handleSelectMobile(range)}
              ranges={[selectionRange]}
              twoStepChange
              inputRanges={[]}
              onBlur={() => {}}
              maxDate={moment(new Date()).add(1, 'y').toDate()}
              rangeColors={["#b22028"]}
            ></DateRange>

            <Grid item xs={12} style={{marginTop:"15px", display: 'flex', justifyContent: "center"}}>
              <Button onClick={this.handleMobileApply} variant="contained" style={{padding: "10px 5rem"}} color="secondary" size="large">Apply</Button>
            </Grid>
          </MobileModal>
        )}

        
        
        <Button
          style={{textTransform: 'unset'}}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <DateRangeIcon fontSize="large"  color="secondary"></DateRangeIcon>
          {this.props.noLabel ?
            null
            :
            <span className="dateRangeButtonLabel">&nbsp; Dates</span>
          }
        </Button>
      </div>
    );
  }
}
export default DatePicker;
