import {
  TOGGLE_LOADING,
  TOGGLE_AUTHENTICATION,
  SET_CURRENT_CLIENT,
  SET_APP_PERMISSIONS,
  DELETE_TA,
  UPDATE_TA,
  ADD_TA,
  DELETE_DISEASE,
  UPDATE_DISEASE,
  ADD_DISEASE,
  CLEAR_CURRENT_CLIENT
} from "./actions";
import { getObjectFrom, setObjectOn, removeKey } from '../../helpers/storage';
import { CLIENT_SELECT_KEY } from '../../constants/app';

const initialState = {
  app: { authenticated: false, loading: false },
  client: getObjectFrom(CLIENT_SELECT_KEY) || null,
  permitted: []
};

export default function appReducer(state = initialState, { type, payload }) {

  const getIndexes = (clientId = null, taId = null, dId = null) => {
    const { permitted } = state;
    return idToIndexes(permitted, clientId, taId, dId)
  }

  switch (type) {
    case TOGGLE_LOADING: {
      const duplicateState = { ...state };
      duplicateState.loading = !duplicateState.loading;
      return duplicateState;
    }

    case TOGGLE_AUTHENTICATION: {
      const duplicateState = { ...state };
      duplicateState.authenticated = true;
      return duplicateState;
    }

    case SET_CURRENT_CLIENT: {
      const duplicateState = { ...state };
      duplicateState.client = payload;
      setObjectOn(CLIENT_SELECT_KEY, payload);
      return duplicateState;
    }

    case CLEAR_CURRENT_CLIENT: {
      const duplicateState = { ...state };
      duplicateState.client = null;
      removeKey(CLIENT_SELECT_KEY);
      return duplicateState;
    }

    case SET_APP_PERMISSIONS: {
      const duplicateState = { ...state };
      duplicateState.permitted = payload;
      return duplicateState;
    }

    case DELETE_TA: {
      const permitted = [...state.permitted];

      const { clientIndex, taIndex } = getIndexes(payload.clientId, payload.taId);
      permitted[clientIndex].permissions.splice(taIndex, 1);

      return {
        ...state,
        permitted
      }
    }

    case UPDATE_TA: {
      const permitted = [...state.permitted];

      const { clientIndex, taIndex } = getIndexes(payload.clientId, payload.ta._id);
      permitted[clientIndex].permissions[taIndex].therapeuticArea = payload.ta;

      return {
        ...state,
        permitted
      }

    }

    case ADD_TA: {
      const permitted = [...state.permitted];
      const { clientIndex } = getIndexes(payload.clientId);
      const { permissions } = permitted[clientIndex];
      permissions.push({ therapeuticArea: payload.ta, diseases: [] });

      return {
        ...state,
        permitted
      }
    }

    case DELETE_DISEASE: {
      const permitted = [...state.permitted];
      const {clientId, taId, dId} = payload;

      const { clientIndex, taIndex, dIndex } = getIndexes(clientId, taId, dId);
      permitted[clientIndex].permissions[taIndex].diseases.splice(dIndex, 1);

      return {
        ...state,
        permitted
      }
    }

    case UPDATE_DISEASE: {
      const permitted = [...state.permitted];
      const {clientId, taId, disease} = payload;
      const { clientIndex, taIndex, dIndex} = getIndexes(clientId, taId, disease._id);
      permitted[clientIndex].permissions[taIndex].diseases[dIndex] = disease;

      return {
        ...state,
        permitted
      }
    }

    case ADD_DISEASE: {
      const permitted = [...state.permitted];
      const {clientId, taId, disease} = payload;

      const { clientIndex, taIndex} = getIndexes(clientId, taId);
      permitted[clientIndex].permissions[taIndex].diseases.push(disease)

      return {
        ...state,
        permitted
      }
    }

    default:
      return state;
  }


}

export const idToIndexes = (permitted, clientId = null, taId = null, dId = null) => {
  const indexes = {}

  if (clientId)
    indexes.clientIndex = permitted.findIndex(client => client._id === clientId);

  if (taId)
    indexes.taIndex = permitted[indexes.clientIndex].permissions.findIndex(p => p.therapeuticArea._id === taId)

  if (dId)
    indexes.dIndex = permitted[indexes.clientIndex].permissions[indexes.taIndex].diseases.findIndex(d => d._id === dId);

  return indexes;
}
