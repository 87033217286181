import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { uniqBy, some } from 'lodash';
import { Card, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import DiseaseList from './list';
import { setActiveUser, setUpdateUser } from '../../../../../redux/users/actions';
import { withSnackbar } from 'notistack';
import { toggleLoading } from '../../../../../redux/app/actions';

const useStyles = makeStyles({
    grantButton: {
        textTransform: 'none'
    }
});



const UserPermissions = ({activeUser, dispatchSetActiveUser, dispatchToggleLoading, ...props}) => {

    const [permissions, setPermissions] = useState(activeUser.permissions.flatMap(client => client.permissions));

    let selectEverything;

    useEffect(() => {
      handleApply();
    },[permissions]);


    const handleApply = () => {
        const duplicate = {...activeUser, permissions};
        // FIX 1: delete duplicated
        duplicate.permissions = duplicate.permissions.map(permission => {
          permission.diseases = uniqBy(permission.diseases, 'id')
          return permission;
        });
        // FIX 2: remove empty
        duplicate.permissions = duplicate.permissions.filter(permission => {
          return permission.diseases.length > 0;
        });
        // FIX 3: fix for the permission structure
        duplicate.permissions = duplicate.permissions.map(permission => {
          const client = props.permitted
              .filter(client => some(client.permissions, (clientPermission) => clientPermission.therapeuticArea._id === permission.therapeuticArea.id));
          const c = client.length > 0? {_id: client[0]._id, name: client[0].name}:{_id: 0, name: "-"};
          return { ...c, permissions: permission };
        });

        dispatchSetActiveUser(duplicate);

        //TODO() move it to AddUser or remove it
        /*if(activeUser._id){ // update the user
            dispatchToggleLoading();
            // FIX: changing the body structure to adapt it to the service
            updateUser({...duplicate, permissions: duplicate.permissions.flatMap(permission => permission.permissions)}, ()=>{
                dispatchToggleLoading()
                props.enqueueSnackbar(`Permissions Updated`, {
                    variant: "success",
                });
                props.dispatchUpdateUser(duplicate);
                props.history.goBack();
            }, (err) => {
                dispatchToggleLoading()
                props.enqueueSnackbar(`Error occured while updating.`, {
                    variant: "error"
                });
            })
        } else {
            props.history.goBack();
        }*/
    }

    const handleReset = (e) => {
      e.preventDefault();
      setPermissions([]);
    }

    const grantAllPermission = () => {
        if(selectEverything)
            selectEverything();
    }

    const classes = useStyles();
    
    return (
        <Grid container spacing={3} justify="center">
            <Grid item xs={12} md={10}>
            <Card className="userPermissionsCard">
                {/* <Grid container spacing={3}> */}
                    <Grid item xs={12} className="modalHeader">
                        <h2>Select User Permissions</h2>
                        <div>
                        <Button
                            className={classes.grantButton}
                            onClick={grantAllPermission}
                            startIcon={<AccountCircleIcon color="secondary" />}
                        >
                            <span className="text">Grant All</span>
                        </Button>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <DiseaseList forwardRef={f => { selectEverything = f }} permissions={permissions} onChange={setPermissions}></DiseaseList>
                    </Grid>

                    <Grid item xs={12} className="modalFooter" >
                      <button onClick={handleReset} className="filterButton">Reset</button>
                      {/*<button onClick={handleApply} className="filterButton red">Apply</button>*/}
                    </Grid>
                {/* </Grid> */}
            </Card>
            </Grid>


            <Grid item xs={12} md={8}>
            <Card className="selectedPermissions">
                {/* <Grid container spacing={3}> */}
                    <Grid item xs={12}>
                        <h3>User Permissions</h3>
                    </Grid>



                    <Grid item xs={12}>
                        
                        <p>When you grant permissions to a user, you will see them here.</p>
                        {!!permissions.length && (
                            <ul>
                                {permissions.map((p, i)=>(
                                    <li key={p.therapeuticArea.id}>
                                        <strong>{p.therapeuticArea.name}</strong>
                                        {p.diseases && <ul>
                                            {p.diseases.map(d=>(
                                                <li key={d.id}>{d.name}</li>
                                            ))}
                                        </ul>}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </Grid>

                {/* </Grid> */}
            </Card>
            </Grid>

        </Grid>
    );
}

const mapStateToProps = (state) => {
    //const { activeUser } = state.users;
    const { permitted } = state.app;
    return {
      //activeUser,
      permitted
    }
}

const mapActionsToProps = {
    dispatchSetActiveUser: setActiveUser,
    dispatchUpdateUser: setUpdateUser,
    dispatchToggleLoading: toggleLoading
}


export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(UserPermissions));


