import {
  SET_ALL_USER,
  SET_ACTIVE_USER,
  UPDATE_USERS,
  DELET_USER,
  ADD_USER,
  SET_MORE_USERS,
  SET_USER_LOADING,
  SET_USER_QUERY,
  ADD_USER_QUERY,
  REJECT_USER,
  UNBLOCK_USER
} from "./../types";

export const setAllUser = (users) => {
  return {
    type: SET_ALL_USER,
    payload: users,
  };
};

export function setMoreUser(data) {
  return {
    type: SET_MORE_USERS,
    payload: data
  };
}

export const setActiveUser = (user) => {
  return {
    type: SET_ACTIVE_USER,
    payload: user
  };
};

export const setUpdateUser = (user) => {
  return {
    type: UPDATE_USERS,
    payload: user
  };
};

export const setDeleteUser = (user) => {
  return {
    type: DELET_USER,
    payload: user
  };
};

export const setRejectUser = (user) => {
  return {
    type: REJECT_USER,
    payload: user
  };
}

export const setUnblockUser = (user) => {
  return {
    type: UNBLOCK_USER,
    payload: user
  };
};

export const setNewUser = (user) => {
  return {
    type: ADD_USER,
    payload: user
  };
};

export function setUserLoading(loading) {
  return {
    type: SET_USER_LOADING,
    payload: loading
  };
}

export function setUserQuery(query) {
  return {
    type: SET_USER_QUERY,
    payload: query
  };
}

export function addUserQuery(query) {
  return {
    type: ADD_USER_QUERY,
    payload: query
  };
}