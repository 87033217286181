import React from 'react';
import ReportBase from './ReportBase';
import RequiredColumns from './RequiredColumns';

const InvalidReport = ({ message, notAvailable, ...props }) => {
    return (
        <div>
            <ReportBase {...props} fontSizing={false} noTitle noExport>
                {message && <h2 style={{ textAlign: 'center', marginTop: '40px' }}>{message}</h2>}
                {notAvailable && <RequiredColumns notAvailable={notAvailable} />}
            </ReportBase>
        </div>
    );
}

export default InvalidReport;