import { SET_ALL_COMPOUNDS, ADD_COMPOUND, UPDATE_COMPOUND, DELETE_COMPOUND, SET_ACTIVE_COMPOUND, SET_MORE_COMPOUNDS, SET_COMPOUND_LOADING, SET_COMPOUND_QUERY } from "./actions";

const initialState = {
  allCompounds: [],
  loading: true,
  nextPage: null,
  active: null,
  query: null,
  total: 0
};

export default function compoundsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ALL_COMPOUNDS: {
      const duplicateState = { ...state };
      duplicateState.allCompounds = payload.compounds;
      duplicateState.nextPage = payload.nextPage;
      duplicateState.total = payload.total;
      duplicateState.loading = false;
      return duplicateState;
    }
    
    case SET_MORE_COMPOUNDS: {
      const duplicateState = { ...state };
      duplicateState.allCompounds = [...state.allCompounds, ...payload.compounds];
      duplicateState.nextPage = payload.nextPage;
      duplicateState.total = payload.total;
      duplicateState.loading = false;
      return duplicateState;
    }

    

    case DELETE_COMPOUND: {
      const allCompounds = [...state.allCompounds];
      const index = allCompounds.findIndex(c=> c._id === payload._id);
      allCompounds.splice(index, 1);
      return {...state, allCompounds};
    }

    case ADD_COMPOUND: {
      const allCompounds = [...state.allCompounds];
      allCompounds.splice(0, 0, payload);
      return {...state, allCompounds};
    }

    case UPDATE_COMPOUND: {
      const allCompounds = [...state.allCompounds];
      const index = allCompounds.findIndex(c=> c._id === payload._id);
      allCompounds.splice(index, 1, payload);
      return {...state, allCompounds};
    }

    case SET_ACTIVE_COMPOUND: {
      const duplicateState = { ...state };
      duplicateState.active = payload;
      return duplicateState
    }

    case SET_COMPOUND_LOADING: {
      const duplicateState = { ...state };
      duplicateState.loading = payload;
      return duplicateState
    }
    
    case SET_COMPOUND_QUERY: {
      const duplicateState = { ...state };
      duplicateState.query = payload;
      return duplicateState
    }

    default:
      return state;
  }
}
