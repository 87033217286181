import moment from 'moment';
import React from 'react';
import { eventTypeColor, eventTypeFontColor } from '../../constants/events';

const Events = ({ event, months }) => {
    const toBeMonths = months.map((month, mi) => <td key={mi}></td>);

    // no startDateTime
    if (!event.startDateTime) return toBeMonths;

    const start = moment(event.startDateTime).format("MMM YYYY")
    const end = moment(event.endDateTime).format("MMM YYYY")

    months.forEach((month, index) => {
        const isBetween = moment(month, 'MMM YYYY').isBetween(moment(start, 'MMM YYYY'), moment(end, 'MMM YYYY'), undefined, '[]');
        // console.log({start, end, month, isBetween})
        if(!isBetween) return;

        if(month === start){
            toBeMonths[index] = (
                <td
                    className={event ? "event for-image" : "for-image"}
                    key={index}
                    style={{
                        backgroundColor: eventTypeColor(
                            event.type
                        ),
                        color: eventTypeFontColor(event.type),
                    }}
                    // dangerouslySetInnerHTML={{__html: event.description}}
                >
                    {event.title}
                </td>
            );
        }
        else if(month === end) {
            toBeMonths[index] = (<td
                key={index}
                className="event-continue-line end"
            />)
        }
        else {
            toBeMonths[index] = (<td
                key={index}
                className="event-continue-line"
            />)
        }
    });

    return toBeMonths.map(month => month)
}

export default Events;