import React, { useState } from 'react';
import GlobalModal from '.';
import { Card, Grid, CircularProgress } from '@material-ui/core';
import UserUndo from './UserUndoModal';
import { connect } from 'react-redux';
import { deleteUser } from '../../service/users';
import { withSnackbar } from 'notistack';
import { setDeleteUser } from '../../redux/users/actions';

const DeleteConfirm = ({open, handleClose, activeUser, enqueueSnackbar, dispatchDeleteUser}) => {

    const [userUndo, setUserUndo] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleDelete = () => {
        setLoading(true)
        deleteUser(activeUser._id, handleSuccess, handleError)       
    }

    const handleSuccess = (data) => {
        setLoading(false)
        // enqueueSnackbar(`User Deleted.`, {variant: 'info'})
        dispatchDeleteUser(activeUser)
        handleClose();
        setUserUndo(true)
        // setUserUndo(true);
    }
    
    const handleError = (err)=>{
        setLoading(false)
        handleClose();
        enqueueSnackbar(`Error occured while deleting the user.`, {variant: 'error'})
        console.error(err);
    }

    return ( 
        <React.Fragment>
            <GlobalModal open={open} handleClose={handleClose}>
                <Card className="deleteConfirmCard">
                    <Grid container spacing={3}>
                        <Grid item xs={12} className="modalHeader">
                            <h2>Delete User</h2>
                        </Grid>

                        <Grid item xs={12}>
                            <b>Are you sure you want to delete this user?</b>
                        </Grid>

                        <Grid item xs={12} className="modalFooter" >
                            <button disabled={loading} onClick={handleClose} className="filterButton red">Cancel</button>
                            <button disabled={loading} onClick={handleDelete} className="filterButton">
                                {loading 
                                ? <CircularProgress
                                    color="secondary"
                                    size={20}
                                >
                                </CircularProgress> 
                                : 'Delete'}
                            </button>
                        </Grid>
                    </Grid>
                </Card>
            </GlobalModal>
            <UserUndo open={userUndo} handleClose={()=> setUserUndo(false)} />
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        activeUser: state.users.activeUser
    }
}

const mapActionsToProps = {
    dispatchDeleteUser: setDeleteUser
}



export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(DeleteConfirm));