import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { withSnackbar } from "notistack";
import News from "../layout/news/news";

import "./report5.scss";
import { connect } from "react-redux";
import { setFilters, setNewsPage } from "../../redux/news/actions";
import ReportBase from "../groupReports/ReportBase";

class Report5 extends Component {
  state = { loading: true };

  componentDidMount() {
    const { config, allFilters, dispatchSetFilters, dispatchSetNewsPage  } = this.props;
    const { name, _id } = config.therapeuticArea;

    // setting current disease and ta as news filters
    const aux = {...allFilters};
    aux['disease'] = [{ _id: config._id, name: config.name }];
    aux['therapeuticArea'] = [{ _id, name }];
    // console.log(aux)

    dispatchSetFilters({ ...aux });
    dispatchSetNewsPage(1)

    this.setState({ loading: false })
  }

  componentWillUnmount() {
    // resetting news filters
    const { allFilters, dispatchSetFilters, dispatchSetNewsPage  } = this.props;
    const aux = {...allFilters};
    aux['disease'] = [];
    aux['therapeuticArea'] = [];
    dispatchSetFilters({ ...aux });
    dispatchSetNewsPage(1)
  }


  render() {
    const { loading } = this.state;
    const id = "report-5";

    return (
      <div id={id}>
        <ReportBase
          {...this.props}
          exportImg={id}
          fontSizing={false}
          noTitle
          noExport
        >
          {!loading && <div>
            <News></News>
          </div>}
          {loading && (
            <SkeletonTheme color="#E2DEDE">
              <p>
                <Skeleton height={70} />
                <br />
                <br />
                <Skeleton count={5} height={50} />
              </p>
            </SkeletonTheme>
          )}
        </ReportBase>

      </div>
    );
  }
}

const stateToProps = (state) => {
  const { allFilters } = state.news;
  const { client } = state.app;
  return {
    allFilters,
    client
  }
}

const mapActionsToProps = {
  dispatchSetFilters: setFilters,
  dispatchSetNewsPage: setNewsPage
}

export default connect(stateToProps, mapActionsToProps)(withSnackbar(Report5));
