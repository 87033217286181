import React from 'react';

const RequiredColumns = ({notAvailable}) => {
    return (
        <>
          <h1>Missing required columns</h1>
          <ul>
            {notAvailable.map((col, i) => <li key={i}>{col}</li>)}
          </ul>
        </>
      )
}
 
export default RequiredColumns;