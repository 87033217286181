import { createStore, compose } from "redux";
import rootReducer from ".";

// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
// import hardSet from "redux-persist/lib/stateReconciler/hardSet";
// import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const persistConfig = {
//   key: "atacana_persist:",
//   storage,
//   // stateReconciler: hardSet,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export default () => {
//   // const store = createStore(persistedReducer, composeEnhancers());
//   const store = createStore(rootReducer, composeEnhancers());

//   // let persistor = persistStore(store);
//   return { store };
// };

const store = createStore(rootReducer, composeEnhancers());
export default store;


