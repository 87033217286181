import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';
import { Fragment } from 'react';

const ImportButton = ({uploading, onChange, id, label, fileType}) => {

    const handleChange = ({ target }) => {
        const { files } = target;
        if (!files.length) return;
    
        const formData = new FormData();
        formData.append('files', files[0])

        if(onChange) onChange(formData);
    }

    return (
        <Fragment>
            <Button
                variant="contained"
                color="secondary"
                disabled={uploading}
                type="submit"
                size="medium"
                htmlFor={id}
                component="label"
            >
                {uploading ?
                    <CircularProgress
                        color="inherit"
                        size={20}
                    ></CircularProgress> : label
                }

            </Button>
            <input
                style={{ display: 'none' }}
                onChange={handleChange}
                name="file"
                accept={fileType ? fileType : ".xlsx"}
                id={id}
                type="file" />
        </Fragment>
    );
}

export default ImportButton;