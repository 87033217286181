import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "./footnotes.scss";

class Footnotes extends Component {
  state = { footnotesVisible: false, vertical: true };

  handleFootnotesvisibility = () => {
    const footnotesVisible = this.state.footnotesVisible;
    this.setState({ footnotesVisible: !footnotesVisible });
  };

  handleFootnotesviewType = () => {
    const vertical = this.state.vertical;
    this.setState({ vertical: !vertical });
  };

  checkForLink = (str) => {
    return str.split("@").length === 3;
  };

  render() {
    const { footnotesVisible, vertical } = this.state;
    const { notes, general } = this.props;
    return (
      <div className="footnotes-container">
        <FormControlLabel
          id="footnotes-check"
          control={
            <Checkbox
              id="footnotes-check"
              checked={footnotesVisible}
              type="checkbox"
              name="footnotesVisible"
              onChange={this.handleFootnotesvisibility}
            />
          }
          label="Footnotes"
        />
        {footnotesVisible && (
          <React.Fragment>
            <FormControlLabel
              id="footnotes-viewtype-toggle"
              control={
                <Checkbox
                  id="footnotes-viewtype-toggle"
                  checked={vertical}
                  type="checkbox"
                  name="footnotesViewTypeToggle"
                  onChange={this.handleFootnotesviewType}
                />
              }
              label="Vertical"
            />
            <div className="footnotes">
              <ul className={!vertical ? "horizontal" : ""}>
                {notes.map((note, index) => (
                  <li key={index}>
                    <p>
                      <span>{`${index + 1}. `}</span>
                      <strong>{note.productName}: </strong>
                      {this.checkForLink(note.text) ? (
                        <span>
                          {note.text.split("@")[0]}
                          <a
                            target="blank"
                            href={note.text.split("@")[2].trim()}
                          >
                            {note.text.split("@")[1]}
                          </a>
                        </span>
                      ) : (
                        <span>{note.text}</span>
                      )}
                    </p>
                  </li>
                ))}
                {general &&
                  general.map((note, index) => (
                    <li key={"gen" + index}>
                      {this.checkForLink(note) ? (
                        <p>
                          {note.split("@")[0]}
                          <a target="blank" href={note.split("@")[2].trim()}>
                            {note.split("@")[1]}
                          </a>
                        </p>
                      ) : (
                        <p>{note}</p>
                      )}
                    </li>
                  ))}
              </ul>
              {this.props.children}
            </div>
          </React.Fragment>
        )}
        {/* {footnotesVisible && !vertical (
          <div className="footnotes">
            <ul>
              {notes.map((note, index) => (
                <li key={index}>
                  <p>
                    <span>{`${index + 1}. `}</span>
                    <strong>{note.productName}: </strong>
                    {this.checkForLink(note.text) ? (
                      <span>
                        {note.text.split("@")[0]}
                        <a target="blank" href={note.text.split("@")[2].trim()}>
                          {note.text.split("@")[1]}
                        </a>
                      </span>
                    ) : (
                      <span>{note.text}</span>
                    )}
                  </p>
                </li>
              ))}
              {general &&
                general.map((note, index) => (
                  <li key={"gen" + index}>
                    {this.checkForLink(note) ? (
                      <p>
                        {note.split("@")[0]}
                        <a target="blank" href={note.split("@")[2].trim()}>
                          {note.split("@")[1]}
                        </a>
                      </p>
                    ) : (
                      <p>{note}</p>
                    )}
                  </li>
                ))}
            </ul>
            {this.props.children}
          </div>
        )} */}
      </div>
    );
  }
}

Footnotes.propTypes = {
  notes: PropTypes.array,
  general: PropTypes.array,
};

export default Footnotes;
