import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Chip, withStyles } from "@material-ui/core";
// import { firestore, storage } from "../../../base";
// import { fetchData, verifyColumns } from "../../utils/dataService";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { dispatchAddDisease, dispatchUpdateDisease, toggleLoading } from "./../../../redux/app/actions";
import "./AddDisease.scss";
import AddCategory from "../category/AddCategory";
import { addDisease, updateDisease, getDisease } from "../../../service/diseases";
import _ from 'lodash';
import { ComboBoxSimple } from "../../common";
// import { REPORTS } from "../../../constants/reports";
import { getAllReports } from "../../../service/reports";
import ChipInput from "material-ui-chip-input";

const styles = (theme) => ({
  root: {
    height: "auto",
    alignItems: "center",
  },

  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: "center",
  },

  title: {
    textAlign: "center",
  },

  form: {
    padding: "20px",
  },

  submit: {
    marginTop: "20px",
    textTransform: "initial",
  },
  formControlTa: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: "10px",
    flexDirection: 'column'
  },
  labelTa: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  nameTa: {
    margin: "5px 0px 0px 15px",
  },
  formControl: {
    margin: "10px",
  },
  rightIcon: {
    marginLeft: "10px",
  },
  divider: {
    marginLeft: '10px',
    width: '100%',
    background: 'darkgray'
  }
});

const chipRenderer = ({ chip, className, handleClick, handleDelete }, key) => (
  <Chip
    className={className}
    style={{margin: '8px 8px 0 0'}}
    key={key}
    label={chip}
    onClick={handleClick}
    onDelete={handleDelete}
  />
);

class AddDisease extends Component {
  state = {
    id: null,
    redirect: false,
    file: null,
    uploadParcentage: 0,
    categoryDialog: false,
    data: {
      name: "",
      therapeuticArea: "",
      sheetId: "",
      sheetName: "",
      range: "",
      titlePrefix: "",
      reports: [],
      tm: false,
      verified: false,
      mesh: []
    },
    reports: []
  };

  getTaName(taId) {
    const { clientId } = this.props.match.params;
    const { permitted } = this.props;
    const clientIndex = permitted.findIndex(client => client._id === clientId);
    const taIndex = permitted[clientIndex].permissions.findIndex(p => p.therapeuticArea._id === taId)
    const { name } = permitted[clientIndex].permissions[taIndex].therapeuticArea;
    return name;
  }

  componentDidMount() {
    const { dId, taId } = this.props.match.params;

    if (taId && dId) { // edit mode
      getDisease({ taId, dId },
        (dData) => {
          const data = _.pick(dData, Object.keys(this.state.data));
          data.therapeuticArea = taId;
          data.mesh = data.mesh || [];
          this.setState({ data, id: dId })
        },
        ({ response }) => {
          this.handleError(response);

          if (response.status === 404) {
            this.props.history.goBack();
          }
        }
      );
    } else { // add mode
      const auxData = { ...this.state.data };
      auxData.therapeuticArea = taId;
      this.setState({ data: auxData });
    }

    getAllReports()
      .then(({ data }) => {
        this.setState({ reports: data })
      })
      .catch(({ response }) => this.handleError(response))
  }

  handleError = (response) => {
    if (!response) {
      this.props.enqueueSnackbar('Unexpected error occured!', { variant: 'error' });
      return;
    }

    const { message } = response.data;
    this.props.enqueueSnackbar(message, {
      variant: "error"
    });
  }

  handleDialogClose = () => {
    this.setState({ categoryDialog: false });
  };

  changeVstatus = (target) => {
    const data = { ...this.state.data };
    if (typeof target === "boolean") data.verified = target;
    else data.verified = !data.verified;
    this.setState({ data });
  };

  changeTMstatus = (target) => {
    const data = { ...this.state.data };
    if (typeof target === "boolean") data.tm = target;
    else data.tm = !data.tm;
    this.setState({ data });
  };

  handleChange = (name) => (event) => {
    const data = { ...this.state.data };
    data[name] = event.target.value;
    this.setState({ data });
  };

  reset = () => {
    const { taId } = this.props.match.params;
    this.setState({
      id: null,
      data: {
        name: "",
        therapeuticArea: taId,
        sheetId: "",
        sheetName: "",
        range: "",
        titlePrefix: "",
        tm: false,
        reports: [],
        mesh: [],
        verified: false,
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const data = { ...this.state.data };

    // changing the report _id to report
    data.reports = data.reports.map((report) => {
      const { _id, name } = report;
      return { report: _id, name }
    })

    const { id } = this.state;
    const { clientId, taId } = this.props.match.params;
    this.props.toggleLoading();


    if (!id) { // add Disease
      addDisease(data, (nd) => {
        this.props.dispatchAddDisease(clientId, taId, nd)
        this.props.enqueueSnackbar("Disease Added!", {
          variant: "success",
        });
        this.reset();
        this.props.toggleLoading();
        this.props.history.goBack();
      }, (err) => {
        const { response } = err;
        if (response) {
          const { message } = response.data
          this.props.enqueueSnackbar(message, {
            variant: "error"
          });
        } else {
          this.props.enqueueSnackbar("Eror occured while adding disease.", {
            variant: "error"
          });
        }
        this.props.toggleLoading();
      })
    } else { // update
      updateDisease(id, data, (nd) => {
        this.props.dispatchUpdateDisease(clientId, taId, { _id: id, ...data })
        this.props.enqueueSnackbar("Disease Updated!", {
          variant: "success",
        });
        this.props.toggleLoading();
        this.props.history.goBack();
      }, (err) => {
        const { response } = err;
        if (response) {
          const { message } = response.data
          this.props.enqueueSnackbar(message, {
            variant: "error"
          });
        } else {
          this.props.enqueueSnackbar("Eror occured while updating disease.", {
            variant: "error"
          });
        }
        this.props.toggleLoading();
      })
    }
  };

  handleReportsChange = (e, reports) => {
    const data = { ...this.state.data };
    data.reports = reports
    this.setState({ data });
  }

  addChip = (chip) => {
    const data = { ...this.state.data };
    data.mesh.push(chip)
    this.setState({ data });
  }

  handleDeleteChip = (chip, index) => {
    const data = { ...this.state.data };
    data.mesh.splice(index, 1);
    this.setState({ data });
  }

  // onDrop = (files) => {
  //   if (files[0].size > 1024 * 1024 * 5) {
  //     this.props.enqueueSnackbar(`Maximum allowed file size is 5MB!`, {
  //       variant: "error",
  //     });
  //     return;
  //   }

  //   const file = Object.assign(files[0], {
  //     preview: URL.createObjectURL(files[0]),
  //   });

  //   this.setState({ file });
  //   // this.uploadImageAddDisease();
  // };





  render() {
    const { classes } = this.props;
    const { data, redirect, categoryDialog, reports } = this.state;

    if (redirect) return <Redirect to="/" />;

    return (
      <Grid
        className={classes.root}
        container
        alignContent="center"
        justify="center"
      >
        <Grid item xs={12} sm={12} lg={8} xl={8}>
          <Paper className={classes.paper}>
            <Typography className={classes.title} variant="h5" component="h3">
              Fill out the information below.
            </Typography>
            <form onSubmit={this.handleSubmit} className={classes.form}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="name">Disease Name</InputLabel>
                <Input
                  required
                  value={data.name}
                  onChange={this.handleChange("name")}
                  id="name"
                />
                <FormHelperText>
                  What do you want to call this report.
                </FormHelperText>
              </FormControl>

              <div id="categoryInput" className={classes.formControlTa}>
                <label className={classes.labelTa}>Therapeutic Area</label>
                <span className={classes.nameTa}>
                  {data.therapeuticArea && this.getTaName(data.therapeuticArea)}
                </span>
                <input id="category" type="hidden" value={data.therapeuticArea} />
              </div>
              <hr className={classes.divider} />
              <AddCategory
                open={categoryDialog}
                handleClose={this.handleDialogClose}
              ></AddCategory>

              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="sheetId">Sheet ID</InputLabel>
                <Input
                  required
                  value={data.sheetId}
                  onChange={this.handleChange("sheetId")}
                  id="sheetId"
                />
                <FormHelperText>
                  Could be found in spreadsheet url. (Sheet must be shared as
                  "anyone with the link can view")
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="sheetName">Sheet Name</InputLabel>
                <Input
                  required
                  value={data.sheetName}
                  onChange={this.handleChange("sheetName")}
                  id="sheetName"
                />
                <FormHelperText>
                  Sheet Name inside the spreadsheet.
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="range">Headers Range</InputLabel>
                <Input
                  required
                  value={data.range}
                  onChange={this.handleChange("range")}
                  id="range"
                  placeholder="A5"
                />
                <FormHelperText>
                  Starting cell id of column names. ex: A5
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="range">Title Prefix</InputLabel>
                <Input
                  value={data.titlePrefix}
                  onChange={this.handleChange("titlePrefix")}
                  id="titlePrefix"
                  placeholder="Prefix"
                />
                <FormHelperText>
                  Add the Prefix you want to see before every report title.
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <ComboBoxSimple
                  options={reports}
                  value={data.reports || []}
                  placeHolder="Reports"
                  variant="standard"
                  multiple={true}
                  handleMyChange={this.handleReportsChange}
                />
                <FormHelperText>
                  Select the reports for this disease
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <ChipInput
                  value={data.mesh}
                  label="Mesh"
                  chipRenderer={chipRenderer}
                  placeholder='Type and press enter to add mesh'
                  helperText="Add few mesh for this disease"
                  onAdd={this.addChip}
                  onDelete={this.handleDeleteChip} />
              </FormControl>

              {/* <Dropzone onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone-container">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input
                        {...getInputProps({
                          accept: "image/*",
                          multiple: false,
                        })}
                      />
                      <p>
                        Drag &#38; drop image here, or click to select image for
                        this disease.
                      </p>
                    </div>
                    {file && (
                      <aside className="image-preview">
                        <div>
                          <img src={file.preview} alt={file.name} />
                        </div>
                        <Fab
                          className="delete-button"
                          variant="round"
                          size="medium"
                          color="secondary"
                          onClick={this.resetFile}
                        >
                          <DeleteIcon />
                        </Fab>
                      </aside>
                    )}
                  </section>
                )}
              </Dropzone> */}

              <FormControl fullWidth className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={data.verified}
                      onChange={this.changeVstatus}
                      color="primary"
                    />
                  }
                  label={data.verified ? "Verified" : "Not Verified"}
                />
                <FormHelperText>
                  Click the Verify button bellow to verify this disease.
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={data.tm}
                      onChange={this.changeTMstatus}
                      color="primary"
                    />
                  }
                  label="Competitive Landscape by Therapeutic Modality"
                />
                <FormHelperText>
                  Click the Verify button bellow to enable this report.
                </FormHelperText>
              </FormControl>

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                size="large"
                className={classes.submit}
              >
                {this.state.id ? "Update" : "Submit"}
              </Button>

              <Button
                variant="outlined"
                color="secondary"
                size="large"
                style={{ marginLeft: "10px" }}
                className={classes.submit}
                onClick={() => this.props.history.goBack()}
              >
                Go Back
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

AddDisease.propTypes = {
  classes: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
  const { permitted } = state.app;
  return {
    permitted
  }
}

const actionsToProps = {
  toggleLoading: toggleLoading,
  dispatchAddDisease: dispatchAddDisease,
  dispatchUpdateDisease: dispatchUpdateDisease
};

export default connect(
  stateToProps,
  actionsToProps
)(withStyles(styles)(withSnackbar(AddDisease)));
