import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";
import CategoriesBackground from "../../../assets/img/card.png";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Popover from "@material-ui/core/Popover";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import AddCategory from "../category/AddCategory";
import { deleteTa } from "../../../redux/app/actions";
import { useDispatch, useSelector } from "react-redux";
import { deleteTA } from "../../../service/therapeuticAreas";
import { Divider } from "@material-ui/core";
import { AuthContext } from "../../../auth/AuthContext";
import ConfirmModal from "../../Modal/ConfirmModal";
import CategoriesIcon from "../../../assets/img/SideMenu/therapAreas.svg";
import "./styles.scss";
import { diseasesLink } from "../../../constants/routes";
import GenericBreadCrumbs from "../../common/GenericBreadCrumbs";
import withPBC from "../../common/withPBC";
import { useContext } from "react";
import { idToIndexes } from "../../../redux/app";
import GeneralLayout from "../../common/GeneralLayout";

const styles = (theme) => ({
  appBar: {
    position: "relative",
  },
  addCategoryButton: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.015em',
    color: '#313131',
    textTransform: 'none',
  },
  pageHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: 34,
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'PoppinsSemiBold'
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(6))]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardGrid: {
    padding: `${theme.spacing(5)}px 0`,
  },
  grid: {
    margin: '0px!important'
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(6)
  },
  copyRightText: {
    marginTop: '45px'
  },
  rootHeader: {
    cursor: 'pointer',
    display: 'flex',
    paddingBottom: '10px'
  }
});

const TheraputicAreas = ({ classes, ...props }) => {
  const [popoverId, setPopoverId] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const [forEdit, setForEdit] = useState({})
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [permissions, setPermissions] = useState([]);

  const { clientId } = useParams();
  const { permitted } = useSelector(state => state.app);
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const context = useContext(AuthContext)

  useEffect(() => {
    if (!clientId) {
      enqueueSnackbar("Please, select a client first!", { variant: 'error' });
      return props.history.push('/');
    }

    const { clientIndex } = idToIndexes(permitted, clientId)

    if (clientIndex < 0) {
      enqueueSnackbar("Invalid Client ID", { variant: 'error' });
      return props.history.push('/');
    }
    const { permissions } = permitted[clientIndex];
    setPermissions(permissions)
  }, [])


  const handleConfirmClose = () => {
    setConfirmDelete(false);
    setForEdit({})
  }

  const openAddCategory = (category) => {
    setForEdit(category);
    setOpen(true)
  };

  const openConfirmDelete = (ta) => {
    setConfirmDelete(true);
    setForEdit(ta)
  }

  const handleDelete = async () => {
    try {
      await deleteTA(forEdit._id);
      dispatch(deleteTa(clientId, forEdit._id))
      enqueueSnackbar("Therapeutic Area Deleted!", {
        variant: "success",
      });
    } catch ({ response }) {
      if (response) {
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Error ocured while deleting Therapeutic Area.", {
          variant: "error",
        });
      }
    }
  };

  const handlePopoverOpen = (event, popoverId) => {
    setPopoverId(popoverId);
    setAnchorEl(event.target)
  }

  const handlePopoverClose = () => {
    setPopoverId(null);
    setAnchorEl(null)
  }

  return (
    <GeneralLayout
      headerLeftComponent={<GenericBreadCrumbs items={withPBC()} />}
      headerRightComponent={context.isAdmin() &&
        <Button
          className={classes.addCategoryButton}
          startIcon={<AddCircleIcon color="secondary" />}
          onClick={openAddCategory}
        >
          Add New Area
      </Button>}
    >
      <main>
        {open && (
          <AddCategory
            open={open}
            handleClose={() => {
              setOpen(false);
              setForEdit({})
            }}
            category={forEdit}
          ></AddCategory>
        )}
        <div className={classNames(classes.layout)}>
          {/* <div className={classes.cardGrid}> */}
          <Grid container spacing={5} className={classNames(classes.grid)}>
            {(permissions && !permissions.length) &&
              <h4 style={{ textAlign: 'center' }}>You don't have access to any Therapeutic Areas</h4>
            }

            {Boolean(permissions && permissions.length) && (
              <React.Fragment>
                {permissions.map(({ therapeuticArea }) => (
                  <div
                    key={therapeuticArea._id}
                    className="single-category">

                    <Link to={diseasesLink(clientId, therapeuticArea._id)}>
                      <span className="card-title">{therapeuticArea.name}</span>
                    </Link>
                    {context.isAdmin() &&
                      <Fragment>
                        <MoreVertIcon
                          className="edit-delete"
                          onClick={e => handlePopoverOpen(e, therapeuticArea._id)} />
                        <Popover
                          open={therapeuticArea._id
                            && popoverId === therapeuticArea._id}
                          anchorEl={anchorEl}
                          onClose={handlePopoverClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <div className="optionsPopover">
                            <div
                              className="optionsContainer"
                              onClick={() => openAddCategory(therapeuticArea)}>

                              <EditIcon />
                              <span className="optionTag">Edit</span>
                            </div>
                            <Divider />
                            {context.onlyAdmin() &&
                              <div className="optionsContainer" onClick={() => openConfirmDelete(therapeuticArea)}>
                                <DeleteIcon /><span className="optionTag">Delete</span>
                              </div>
                            }
                          </div>
                        </Popover>
                      </Fragment>}
                    <img src={CategoriesBackground}
                      className="background-single-category"
                      alt="background"
                      onClick={() => props.history.push(diseasesLink(clientId, therapeuticArea._id))} />
                  </div>
                ))}
              </React.Fragment>
            )}
          </Grid>
        </div>
      </main>
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom />
        <div style={{ display: 'block' }}>
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
            className={classes.copyRightText}
          >
            &copy; Atacana Group Inc. {moment().year()} - All Rights Reserved.
          </Typography>
        </div>
      </footer>

      <ConfirmModal
        open={confirmDelete}
        handleClose={handleConfirmClose}
        handleConfirm={handleDelete}
        action="Delete"
        title="Delete Therapeutic Area"
        message="This will delete all the Diseases inside this therapeutic area. Are you sure?"
        icon={CategoriesIcon}
      />
    </GeneralLayout>
  );
}

export default withStyles(styles)(TheraputicAreas);

