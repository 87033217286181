import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
// import { PersistGate } from "redux-persist/integration/react";

import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import AuthProvider from "./auth/AuthContext";
import store from "./redux/store";
// import persist from "./redux/store";
// const { store, persistor } = persist();

ReactDOM.render(
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
      <AuthProvider>
        <App />
      </AuthProvider>
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
