export default function mapSegment(segment) {
  segment = extractSegment(segment);
  if (["Mild AD", "Mild to Moderate AD", "Moderate AD"].includes(segment))
    return "Mild to Moderate AD";

  if (["MCI/Prodromal"].includes(segment)) return "Early AD (Mild & MCI)";

  return segment;
}

export function extractSegment(segment) {
  //expression for maching the initial numbers in a segement. eg 12.Narcolepsy (EDS + Cataplexy)
  const expression = /^([\d]+)\./g;
  return segment.replace(expression, "");
}
