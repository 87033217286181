import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { ChevronLeft } from "@material-ui/icons";
import FormBase from './FormBase';
import AdminBase from "./AdminBase";
import { uploadFile } from '../../../service/files';
import AddCompoundImgIcon from '../../../assets/img/addCompoundIcon.svg';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createNewCompound, editCompound, getCompoundById } from '../../../service/compounds';
import { withSnackbar } from 'notistack';
import { toggleLoading } from '../../../redux/app/actions';
import { addCompound, updateCompound } from '../../../redux/compounds/actions';
import {
  resetRelatedFields,
  setRelatedClient,
  setRelatedDisease,
  setRelatedTA
} from '../../../redux/relatedFields/actions';

import { getAllCompany } from '../../../service/companies';
import { set, some } from 'lodash';
import { setAllPair, resetPairs } from '../../../redux/licenseePair/actions';
import allTM from '../../../constants/theraputicModality';
import { getTextForCompoundPriority } from '../../../helpers/utils';
import { setCompanyQuery } from "../../../redux/companies/actions";

const CompanyAdd = ({ enqueueSnackbar, ...props }) => {

  const [brandName, setBrandName] = useState('');
  const [compoundWebsite, setCompoundWebsite] = useState('');
  const [scientificName, setScientificName] = useState('');
  const [otherNames, setOtherNames] = useState([]);
  const [genericName, setGenericName] = useState('');
  const [routeOfAdmin, setRouteOfAdmin] = useState('');
  const [mechanismOfAct, setMechanismOfAct] = useState('');
  const [uploading, setUploading] = useState('');
  const [files, setFiles] = useState([]);
  const [multiItems, setMultiItems] = useState({
    approvedInGeographies: []
  });

  const [status, setStatus] = useState(true);
  const [dmt, setDmt] = useState(false);

  const [developmentPhase, setDevelopmentPhase] = useState({ _id: 0, name: '' });
  const [priority, setPriority] = useState({ _id: 0, name: '' });
  const [therapeuticModality, setTherapeuticModality] = useState({ _id: 0, name: '' });
  // const [approvedInGeographies, setApprovedInGeographies] = useState("");
  const [originator, setOriginator] = useState({ _id: 0, name: '' });

  const allPairs = useSelector(({ licensee }) => licensee.allPairs);
  const dispatch = useDispatch();
  const allCompanies = useSelector(({ companies }) => companies.allCompanies);
  const companiesLoading = useSelector(({ companies }) => companies.loading);
  const companyQuery = useSelector(({ companies }) => companies.query);

  const [isValid, setValidity] = useState(false);

  const { compoundId } = useParams();

  useEffect(() => {

    props.dispatchResetRelatedFields();

    getAllCompany()
      .then((data) => {
        console.log('fetched all companies...')
      })
      .catch(({ response }) => {
        if (response) {
          enqueueSnackbar(response.data.message, { variant: 'error' })
        } else
          enqueueSnackbar("Unexpected error occured while getting companies!", { variant: 'error' })
      })

    // fething the full compound object and populating fields
    if (compoundId) {
      dispatch(toggleLoading())
      getCompoundById(compoundId)
        .then(({ data }) => {
          initExisting(data)
        })
        .catch((error) => {
          if (error.response) {
            enqueueSnackbar(error.response.data.message, { variant: 'error' })
          } else
            enqueueSnackbar("Unexpected error occured while getting the compound", { variant: 'error' })
        })
        .finally(dispatch(toggleLoading()))
    } else {
      dispatch(resetPairs())
    }

    return () => {
      if (companyQuery !== '' && companyQuery !== null) {
        dispatch(setCompanyQuery());
        return getAllCompany();
      }
    }

  }, []);

  const initExisting = (c) => {
    props.dispatchSetRelatedClient(c.client ? [c.client] : []);
    props.dispatchSetRelatedTA(c.therapeuticArea ? [c.therapeuticArea] : []);
    props.dispatchSetRelatedDisease(c.disease ? [c.disease] : []);
    setBrandName(c.name.brand || '');
    setGenericName(c.name.generic);
    setOtherNames(c.name.other ? c.name.other : []);
    setScientificName(c.name.scientific || '');

    setCompoundWebsite(c.compoundWebsite || '');
    setRouteOfAdmin(c.routeOfAdministration || '');
    setMechanismOfAct(c.mechanismOfAction || '');

    setTherapeuticModality({ _id: c.therapeuticModality, name: c.therapeuticModality } || '');
    setPriority({ _id: c.priority, name: getTextForCompoundPriority({ priorNum: c.priority }) } || '');
    setDevelopmentPhase({ _id: c.developmentPhase, name: c.developmentPhase } || '');

    setOriginator(c.originator);

    if (c.licensee && c.licensee.length) dispatch(setAllPair(c.licensee.map(lic => {
      return {
        ...lic,
        companies: allCompanies.filter(company => some(lic.companies, companyId => company._id === companyId))
      }
    })));

    setStatus(c.status === 'active' ? true : false);
    setDmt(c.isDMT);

    const { approvedInGeographies, logo } = c;
    if (approvedInGeographies) setMultiItems({ approvedInGeographies })
    if (logo) setFiles([{ name: 'logo', link: logo }]);
  }

  const goBack = () => {
    props.history.goBack();
  };

  const admTitleComponent = (
    <Link to="#" onClick={goBack}>
      <h2><ChevronLeft /> {compoundId ? 'Update' : 'Add'} Compound</h2>
    </Link>
  );

  const addOtherName = (newOtherName, getter, setter) => {
    const values = [...getter];
    values.push(newOtherName);
    setter(values);
  }

  const quitOtherName = (index, getter, setter) => {
    const values = [...getter];
    const filteredItems = values.filter(item => item !== index);
    setter(filteredItems);
  }

  const formComponents = [
    {
      id: 'nameBrand',
      name: 'nameBrand',
      required: true,
      title: "Name",
      key: 'name.brand',
      type: 'input',
      value: brandName,
      onChange: brandname => setBrandName(brandname),
      placeHolder: "Brand Name",
      sm: 12
    },
    {
      id: 'nameGeneric',
      name: 'nameGeneric',
      required: true,
      key: 'name.generic',
      type: 'input',
      value: genericName,
      onChange: gn => setGenericName(gn),
      placeHolder: "Generic Name",
      sm: 12,
    },
    {
      id: 'nameScientific',
      name: 'nameScientific',
      required: true,
      key: 'name.scientific',
      value: scientificName,
      type: 'input',
      onChange: cn => setScientificName(cn),
      placeHolder: "Scientific Name",
      sm: 12
    },
    {
      type: 'chipInput',
      id: 'otherName',
      name: 'otherName',
      required: true,
      value: otherNames,
      handleAddChip: (newOtherName) => addOtherName(newOtherName, otherNames, setOtherNames),
      handleDeleteChip: (nameToQuit) => quitOtherName(nameToQuit, otherNames, setOtherNames),
      placeHolder: "Other Names",
      key: 'name.other',
      sm: 12
    },
    {
      id: 'mechanismOfAction',
      name: 'mechanismOfAction',
      type: 'input',
      key: 'mechanismOfAction',
      title: 'space',
      required: developmentPhase._id === 'Phase 2',
      value: mechanismOfAct,
      onChange: moa => setMechanismOfAct(moa),
      placeHolder: "Mechanism of Action",
      sm: 12
    },
    {
      id: 'routeOfAdministration',
      name: 'routeOfAdministration',
      key: 'routeOfAdministration',
      value: routeOfAdmin,
      type: 'input',
      required: developmentPhase._id === 'Phase 2',
      onChange: roa => setRouteOfAdmin(roa),
      placeHolder: "Route of Administration",
      sm: 12
    },
    {
      type: 'select',
      id: "therapeuticModality",
      name: "therapeuticModality",
      required: true,
      value: therapeuticModality,
      onChange: (e, value) => setTherapeuticModality(value),
      placeHolder: "Therapeutic Modality",
      sm: 12,
      options: allTM
    },
    {
      type: 'relatedClientTaDisease',
      toUpperCase: true,
      sm: 12,
      relatedFields: {
        client: true,
        therapeuticArea: true,
        disease: true
      }
    },
    {
      id: "approvedInGeographies",
      type: 'comboSelect',
      value: multiItems.approvedInGeographies || [],
      onChange: (id, value) => handleComboChange(id, value),
      placeHolder: "Approved In Geographies",
      options: "US, EU, Japan, China, Global".split(', ').sort(),
      sm: 12
    },
    {
      type: 'fileUploader',
      id: "file",
      name: "file",
      uploadStatus: uploading,
      placeHolder: "Upload Compound Logo",
      onChange: e => handleFileChange(e),
      files: files,
      handleClick: index => deleteFile(index),
      sm: 12
    },
    {
      type: 'input',
      key: 'compoundWebsite',
      value: compoundWebsite,
      icon: "language",
      onChange: cws => setCompoundWebsite(cws),
      placeHolder: "Compound Website",
      sm: 12
    },
    {
      type: 'select',
      id: "priority",
      required: true,
      value: priority,
      onChange: (e, value) => setPriority(value),
      placeHolder: "Priority",
      options: [
        { _id: 1, name: 'High' },
        { _id: 2, name: 'Medium' },
        { _id: 3, name: 'Low' },
      ],
      sm: 12
    },
    {
      type: 'select',
      id: "developmentPhase",
      required: true,
      value: developmentPhase,
      onChange: (e, value) => setDevelopmentPhase(value),
      placeHolder: "Development Phase",
      sm: 12,
      options: `Discovery,Pre-clinical,Phase 1,
                Phase 1/2,Phase 2,Phase 2/3,Phase 3,
                Phase 4,Filed/Registered,Approved,
                Marketed,Terminated,Withdrawn,Suspended,
                Others`.split(',').map(item => { return { _id: item.trim(), name: item.trim() } })
    },
    {
      type: 'switch',
      value: dmt,
      name: "isDMT",
      label: 'DMT?',
      style: 'backgroundGray',
      switchColor: "primary",
      onChange: e => handleDmtStatusChange(e),
      render: () => renderDMTStatus(),
      sm: 6
    },
    {
      type: 'switch',
      value: status,
      label: 'Status',
      style: 'backgroundGray',
      switchColor: 'secondary',
      name: "status",
      onChange: e => handleStatusChange(e),
      render: () => renderStatus(),
      sm: 6
    },
    {
      id: "originator",
      type: 'select',
      required: true,
      value: originator,
      title: 'Company',
      onChange: (e, value) => { return setOriginator(value) },
      placeHolder: "Originator",
      options: allCompanies,
      loading: companiesLoading,
      sm: 12,
      handleSearch: (value) => {
        dispatch(setCompanyQuery(value));
        return getAllCompany();
      }
    },
    {
      type: 'dynamicsCombo',
      params: {}
    },
  ];

  const renderDMTStatus = () => {
    const customStyle = {
      color: '#56C6CC',
      margin: '8px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '21px'
    };
    return dmt ?
      <span style={customStyle}>YES</span> :
      <span style={customStyle}>NO</span>;
  }


  const renderStatus = () => {
    const customStyle = { margin: '7px' }
    return status ?
      <VisibilityOutlinedIcon style={customStyle} color="action" /> :
      <VisibilityOffOutlinedIcon style={customStyle} color="action" />;
  }

  const handleStatusChange = ({ target }) => {
    setStatus(target.checked);
  };

  const handleDmtStatusChange = ({ target }) => {
    setDmt(target.checked);
  };

  const handleComboChange = (id, value) => {
    const aux = { ...multiItems };
    aux[id] = value;
    setMultiItems(aux);
  }

  const handleFileChange = ({ target }) => {
    const { files } = target;
    if (!files.length) return;

    const formData = new FormData();
    const tempState = [];
    for (let f of files) {
      tempState.push({ name: f.name, link: '' })
      formData.append('file', f)
    }
    setFiles(tempState);
    setUploading(true)

    uploadFile(formData)
      .then(({ data }) => {
        tempState.forEach((f, i) => f.link = data[i]);
        setFiles(tempState);
        setUploading(false)
      })
      .catch(({ response }) => {
        if (!response) {
          enqueueSnackbar('Unexpected error occured!', { variant: 'error' })
        } else {
          const { message } = response.data;
          enqueueSnackbar(message, { variant: 'error' })
        }
      })
  };

  const deleteFile = (index) => {
    const tempFiles = [...files];
    tempFiles.splice(index, 1);
    setFiles(tempFiles);
  }

  const buttons = [
    {
      onClick: () => handleCancelClick(),
      variant: 'outlined',
      text: 'cancel'
    },
    {
      variant: 'contained',
      disabled: !isValid,
      text: compoundId ? 'Update' : 'Add',
      type: 'submit'
    },
  ]

  const handleCancelClick = () => {
    goBack();
  }

  const validate = () => {
    let valid = true;
    const names = [brandName, genericName, scientificName, otherNames.length];
    const nameExist = names.some(name => Boolean(name));
    if (!nameExist) {
      enqueueSnackbar("Compound must have at least one name!", { variant: 'warning' });
      valid = false;
    }

    if (!originator || !originator._id) {
      enqueueSnackbar("Compound must have a Originatior Company!", { variant: 'warning' });
      valid = false;
    }

    return valid;
  }

  const handleAddCompound = () => {
    //if (!validate()) return;
    dispatch(toggleLoading())
    const compound = buildCompound();
    // return;
    createNewCompound(compound)
      .then(({ data }) => {
        dispatch(addCompound(data))
        enqueueSnackbar("Compound Added", {
          variant: "success",
        });
        goBack();
      })
      .catch(handleErrors)
      .finally(() => {
        dispatch(toggleLoading())
      })
  }

  const handleUpdateCompound = () => {
    if (!validate()) return;
    dispatch(toggleLoading())
    const compound = buildCompound();
    // return;
    editCompound(compoundId, compound)
      .then(({ data }) => {
        dispatch(updateCompound(data))
        enqueueSnackbar("Compound Updated", {
          variant: "success",
        });
        goBack();
      })
      .catch(handleErrors)
      .finally(() => {
        dispatch(toggleLoading())
      })
  }

  const buildCompound = () => {
    const compound = { name: {}, client: props.client ? props.client._id : '' };
    const inputs = formComponents.filter(comp => comp.type === 'input');
    inputs.forEach((input, index) => {
      set(compound, input.key, input.value)
    });

    if (otherNames.length) compound.name.other = otherNames;

    compound.developmentPhase = developmentPhase._id;
    compound.priority = priority._id;
    compound.therapeuticModality = therapeuticModality._id;
    compound.therapeuticArea = props.therapeuticArea ? props.therapeuticArea._id : "";
    compound.disease = props.disease ? props.disease._id : "";

    compound.originator = originator._id;
    compound.licensee = allPairs.map(p => {
      const pair = { ...p };
      pair.companies = p.companies.map(c => c._id);
      return pair;
    });



    if (multiItems.approvedInGeographies.length)
      compound.approvedInGeographies = multiItems.approvedInGeographies

    //switch
    compound.status = status ? 'active' : 'inactive';
    compound.isDMT = dmt;

    if (files.length) compound.logo = files[0].link;

    return compound;
  };

  const handleErrors = ({ response }) => {
    if (response) {
      enqueueSnackbar(response.data.message, {
        variant: "error",
      });
    } else {
      enqueueSnackbar(`Unexpected error occurred`, {
        variant: "error",
      });
    }
  }

  return (
    <AdminBase titleComponent={admTitleComponent}
      showFilters={false}
      showSearchBox={false}>
      <FormBase
        onSubmit={compoundId ? handleUpdateCompound : handleAddCompound}
        title={`Please Complete the Fields to ${compoundId ? 'Update the' : 'Add a New'} Compound`}
        formComponents={formComponents}
        // formComponentsCol2={formComponentsCol2}
        onChangeValidity={setValidity}
        buttons={buttons}
        iconImage={AddCompoundImgIcon} />
    </AdminBase>
  )
}

const mapStateToProps = (state) => {
  const { clients, therapeuticAreas, diseases } = state.relatedFields;
  return {
    client: clients[0],
    therapeuticArea: therapeuticAreas[0],
    disease: diseases[0],
  }
};

const mapActionsToProps = {
  dispatchResetRelatedFields: resetRelatedFields,
  dispatchSetRelatedDisease: setRelatedDisease,
  dispatchSetRelatedClient: setRelatedClient,
  dispatchSetRelatedTA: setRelatedTA
};

export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(CompanyAdd));