import axios from '.';
import { setAllCompanies, setCompanyLoading, setMoreCompanies } from '../redux/companies/actions';
import store from '../redux/store';
const path = '/companies';

export function getAllCompany(nextPage=false) {
    const {companies} = store.getState();

    //changing the loading state
    store.dispatch(setCompanyLoading(true));

    return axios.get(`${path}${companies.query ? '?name='+companies.query : ''}${nextPage ? '?forwardToken='+companies.nextPage : ''}`)
        .then(({data})=>{
            if(nextPage) {
                store.dispatch(setMoreCompanies(data))
            }else
                store.dispatch(setAllCompanies(data));
            return Promise.resolve(data.companies);
        })
}



export function deleteCompanyById(id) {
    return axios.delete(`${path}/${id}`)
}

export function createNewCompany(company, callbackSuccess, callbackError) {
    axios.post(`${path}`, company).then(res=>{
        callbackSuccess(res.data);
    }).catch(err=>{
        console.log(err.response);
        callbackError(err);
    });
}

export function editCompany(id, data) {
    return axios.put(`${path}/${id}`, data)
}