import { TOGGLE_MENU } from "./actions";
const initialState = {
  openMenu: false
};
export default function appReducer(state = initialState, { type, payload }) {
  switch (type) {
    case TOGGLE_MENU: {
      const duplicateState = { ...state };
      duplicateState.openMenu = payload;
      return duplicateState;
    }

    default:
      return state;
  }
}
