import { Divider, Grid } from '@material-ui/core';
import React, { Fragment } from 'react';

import './styles.scss';

const GeneralLayout = ({ headerLeftComponent, headerRightComponent, toolbarLeftComponent, toolbarRightComponent, children, ...props }) => {
    return (
        <Fragment>
            <Grid className="section-header" container>
                <Grid item xs={6} md={6} className="section-left">
                    {headerLeftComponent}
                </Grid>
                <Grid item xs={6} md={6} className="section-right">
                    {headerRightComponent}
                </Grid>
            </Grid>
            <Divider light />

            {(toolbarLeftComponent || toolbarRightComponent) &&
                <>
                    <Grid className="layout-toolbar" container>
                        <Grid item xs={12} md={7} className="toolbar-left">
                            {toolbarLeftComponent}
                        </Grid>

                        <Grid item xs={12} md={5} className="toolbar-right">
                            {toolbarRightComponent}
                        </Grid>
                    </Grid>
                    <Divider />
                </>
            }

            <Grid container className="layout-content">
                <Grid item xs={12}>
                    {children}
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default GeneralLayout;