import axios from './'
import store from '../redux/store';
import { setTotalCount, setFiltersOptions } from '../redux/news/actions';


const getStateFilters = () => {
  const {allFilters} = store.getState().news;

  const filters = { ...allFilters };
  Object.keys(filters).forEach(key => {
    // checking for empty property in filters
    if (filters[key] && filters[key].length === 0) {
      delete filters[key];
      return;
    }

    // extraction of the ids
    if (typeof filters[key][0] === 'object') {
      filters[key] = filters[key].map(item => item._id)
    }
  })

  filters.client = store.getState().app.client._id;
  
  return filters;
}

export const searchNews = (callbackSuccess, callbackError) => {
  const { limit, page, orderBy} = store.getState().news;
  const filters = getStateFilters();

  filters.status = 'published';
  // console.log('Filters', filters)

  const data = {
    limit, page, orderBy, filters
  }

  axios
    .post(`/news/search`, data)
    .then((response) => {
      const {filterValues, news, totalCount} = response.data;

      store.dispatch(setTotalCount(totalCount))
      
      if(news.length) { // Preventing the app to crash in some scenarios. Need to debug that. AT-471
        store.dispatch(setFiltersOptions(filterValues));
      }

      callbackSuccess(news);
    })
    .catch((error) => {
      const { response } = error;
      if (!response) {
        callbackError(
          "Unexpected error occurred!",
          {
            variant: "error",
          }
        );
      } else {
        const { message } = response.data;
        callbackError(message, {
          variant: "error",
        });
      }

    });
};


export const searchDraftNews = (data) => {
  const {app} = store.getState();
  data.filters.client = app.client._id;
  return axios.post(`/news/search`, data);
}


export const getFilters = () => {
  const { filterOptions } = store.getState().news;
  const { client } = store.getState().app;

  if (!filterOptions.loading) return Promise.resolve(filterOptions);
  if (!client) return Promise.reject(new Error("Please, select a client first!"));

  const clientId = client._id;
  return axios
    .get(`/filters/news/${clientId}`)
    .then(({ data }) => {
      console.log("Filter Options => ", data)
      store.dispatch(setFiltersOptions(data));
      return Promise.resolve(data);
    })
};

export const createNews = (data, callbackSuccess, callbackError) => {
  axios
    .post('/news', data)
    .then(({ data }) => {
      console.log("Create News Response =>", data);
      callbackSuccess(data)
    })
    .catch((err) => {
      console.log(err.response);
      callbackError(err);
    })
};


export const deleteNewsById = (id) => {
  return axios.delete(`/news/${id}`);
}

export const getNewsById = (id) => {
  return axios.get(`/news/${id}`)
}

export const updateNewsById = (id, data) => {
  return axios.put(`/news/${id}`, data)
}

const config = { responseType: 'blob' };

export const exportNews = () => {
  const filters = getStateFilters();
  // console.log(filters);
  filters.status = 'published';

  return axios.post('/news/export', {filters}, config)

}
