import axios from '.';
import { setAllTrials, setTrialLoading, setMoreTrials } from '../redux/trials/actions';
import store from '../redux/store';

const path = '/trials';

export function getAllTrial(nextPage = false) {
    const { trials } = store.getState();

    //changing the loading state
    store.dispatch(setTrialLoading(true));

    return axios.get(`${path}${trials.query ? '?name=' + trials.query : ''}${nextPage ? '?forwardToken=' + trials.nextPage : ''}`)
        .then(({ data }) => {
            if (nextPage) {
                store.dispatch(setMoreTrials(data))
            } else
                store.dispatch(setAllTrials(data));
            return Promise.resolve(data.trials);
        })
}

export function deleteTrialById(id) {
    return axios.delete(`${path}/${id}`)
}

export function createNewTrial(event, callbackSuccess, callbackError) {
    axios.post(`${path}`, event).then(res => {
        callbackSuccess(res.data);
    }).catch(err => {
        console.log(err.response);
        callbackError(err);
    });
}

export function editTrial(id, data) {
    return axios.put(`${path}/${id}`, data)
}

export function searchTrials(filters) {
    return axios.post(`${path}/search`, { filters })
}