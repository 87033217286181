import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ReactResizeDetector from 'react-resize-detector';
import mapSegment from "../utils/mapSegment";
import {
  stringToDate,
  minDate,
  validateDate,
  isThisDuplicate,
  stringToYear,
  isCustomFormat,
  collectFootnotes,
} from "../utils/utilities";
import { separateSegments, verifyColumns } from "../utils/dataService";
import InvalidRecords from "../InvalidRecords";
import Footnotes from "../footnotes/Footnotes";
import $ from "jquery";
import interact from "interactjs";
import InvalidReport from "../groupReports/InvalidReport";
import ReportBase from "../groupReports/ReportBase";
import "./styles.scss";

class CompetitorLaunchTimelineDetails extends Component {
  footnotesColumnName = "report-2-footnotes";
  state = { body: null, legends: null, segments: null, years: {}, notAvailable: [] };

  colors = [
    "#e6194b",
    "#3cb44b",
    "#ffe119",
    "#4363d8",
    "#f58231",
    "#911eb4",
    "#46f0f0",
    "#f032e6",
    "#bcf60c",
    "#fabebe",
    "#008080",
    "#e6beff",
    "#9a6324",
    "#fffac8",
    "#a9a9a9",
  ];

  headersAsKeys = [
    "H/M/L; New; NA",
    "NCT link",
    "Segment",
    "Est. Launch",
    "Product name",
    "Company Name",
    "Launch Assumptions",
    "MOA (Revised)",
    "Duplicated",
  ];
  data = null;
  leftBehind = [];
  footnotes = [];
  generalfootnotes = [];

  validation() {
    const notAvailable = verifyColumns(this.props.data);
    if (notAvailable.length) {
      this.setState({ notAvailable })
      return false;
    }
    return true;
  }

  componentDidMount() {
    this.data = this.props.data;
    if (!this.validation()) return;

    this.data = separateSegments(this.data);

    const fileHeaders = this.data[0];
    let auxBody = [];
    let legends = [];
    let auxSegments = [];

    this.data.forEach((row, index) => {
      if (index === 0) return;
      const processedObject = row.reduce((object, cell, index) => {
        object[fileHeaders[index]] = cell;
        return object;
      }, {});

      //collecting general footnotes
      if (processedObject["report-2-general-footnotes"]) {
        this.generalfootnotes.push(
          processedObject["report-2-general-footnotes"]
        );
      }

      if (
        ["HH", "Medium", "TAK ASSET"].includes(
          processedObject["H/M/L; New; NA"]
        ) &&
        validateDate(processedObject["Est. Launch"])
      ) {
        const isDuplicate = isThisDuplicate(auxBody, processedObject);
        if (isDuplicate) {
          const oldOnesDate = stringToDate(
            auxBody[isDuplicate.index]["Est. Launch"]
          );
          const newOnesDate = stringToDate(processedObject["Est. Launch"]);

          // replacing product with min date
          if (minDate(oldOnesDate, newOnesDate) === newOnesDate) {
            auxBody[isDuplicate.index].Duplicated = "Yes";
            this.leftBehind.push(auxBody[isDuplicate.index]);
            auxBody.splice(isDuplicate.index, 1);
            auxBody.push(processedObject);
            return;
          }

          processedObject.Duplicated = "Yes";
          this.leftBehind.push(processedObject);
          return;
        }

        auxBody.push(processedObject);
        legends.push(processedObject["MOA (Revised)"]);
        auxSegments.push(processedObject["Segment"]);
      } else {
        // processedObject.Duplicated = "Yes";
        this.leftBehind.push(processedObject);
      }
    });

    //distinct footnotes
    this.footnotes = collectFootnotes(auxBody, this.footnotesColumnName);

    // selecting the years
    const allYears = auxBody.map((row) => stringToYear(row["Est. Launch"]));
    const minYear = Math.min.apply(null, allYears);
    const years = {};
    for (
      let i = minYear <= 2019 ? minYear : 2019;
      i <= Math.max.apply(null, allYears);
      i++
    ) {
      years[i] = true;
    }

    //distinct legends
    legends = legends.filter((value, index, a) => a.indexOf(value) === index);

    // sorting with initial numbers
    auxSegments = auxSegments.sort();

    //segments mapping rules
    auxSegments = auxSegments.map((value) => mapSegment(value));

    //distinct segments
    auxSegments = auxSegments.filter(
      (value, index, arr) => arr.indexOf(value) === index
    );

    this.setState({
      body: auxBody,
      legends: legends,
      segments: auxSegments,
      years,
    });
    // });

    console.log('report-3', {
      body: auxBody,
      legends: legends,
      segments: auxSegments,
      years,
    })

    this.reAlign();

  }

  reAlign() {
    setTimeout(() => {
      // const rows = $(".gtRow");
      // const row1 = $(rows[0]).children("div");
      // let leftBlankCount = 0;

      // $('.segments').each(function(){$(this).on('resize', this.handleResize)})

      const items = $(".gtCell");
      items.each(function (index) {
        // const blank = !$(this).hasClass("gtCell");
        // const left = $(this).hasClass("toLeft");
        // if(index === 0 && left){
        //   $(this).removeClass('toLeft');
        // }
        // if (blank) leftBlankCount++;
        // else if (leftBlankCount > 0 && !left) {
        //   $(this).addClass("toLeft");
        //   leftBlankCount = 0;
        // }

        // if (blank) return;

        const nextBlank = $(this).nextUntil(".gtCell").length;
        const prevBlank = $(this).prevUntil(".gtCell").length;

        if (nextBlank > prevBlank) {
          $(this).removeClass("toLeft");
        } else if (nextBlank < prevBlank) {
          $(this).addClass("toLeft");
        }

        // if (nextBlank > 1 || prevBlank > 1) {
        //   $(this)
        //     .children(".info")
        //     .css({ width: "max-content" });
        // }
      });

      // $('.info .marker-container').dra
      // $(".info .marker-container").draggable({
      //   drag: function(e, ui) {
      //     var orig = ui.originalPosition;
      //     var curr = ui.position;
      //   }
      // });

      interact(".info .marker-container").draggable({
        lockAxis: "y",
        onmove(event) {
          const container = $(event.target).parent(".info")[0];
          let y = $(container).data("y");
          y = y ? Number(y) + event.dy : event.dy;
          $(container)
            .data("y", y)
            .css("transform", "translateY(" + y + "px)");
        },
      });
    }, 1000);
  }

  handleResize = (w, h, s, t, y) => {
    const padding = 20;
    let maxWidth = 0
    $('.sideBar').each(function () {
      const sidebarHeight = $(this).innerHeight();
      const pOuterHeight = $(this).find('p').outerWidth();

      if (pOuterHeight > sidebarHeight)
        $(this).find('p').css({ width: sidebarHeight + 'px' })
      else
        $(this).find('p').css({ width: sidebarHeight - 10 + 'px' }) // -10 is for the flactuating bug
      // const test2 = $(this).find('p').outerWidth();

      const pHeight = $(this).find('p').outerHeight();

      maxWidth = maxWidth >= pHeight ? maxWidth : pHeight;
    })

    $('.sideBar').css('width', (maxWidth + padding) + 'px');
    // console.log('running')
  }

  filters = (years) => (
    <div className="filterContainer">
      <FormGroup row>
        {this.state.body &&
          Object.keys(years).map((key, index) => {
            return (
              <div key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={`column-${index}-check`}
                      checked={years[key]}
                      type="checkbox"
                      name={key}
                      onChange={this.handleColVisibilityChange}
                    />
                  }
                  label={key}
                />
              </div>
            );
          })}
      </FormGroup>
    </div>
  )

  render() {
    const { legends, body, segments, years, notAvailable } = this.state;
    const id = "report-2";

    if (notAvailable.length) return InvalidReport({ notAvailable, ...this.props });

    this.columnsWidth =
      100 / Object.keys(years).filter((key) => years[key]).length;

    return (
      <div id={id}>
        <ReportBase
          {...this.props}
          exportImg={id}
          filters={this.filters(years)}
        >
          <div id="toImage">
            {legends && (
              <Grid container spacing={0} justify="flex-start">
                {legends.map((value, index) => {
                  if (index > 13) return null;
                  return (
                    <Grid className="legend" key={index} item xs={2} md={2}>
                      <ListItem alignItems="flex-start">
                        <div
                          style={{ backgroundColor: this.colors[index] }}
                          className="marker"
                        />
                        <p
                          className="legendText"
                        >{value}</p>
                      </ListItem>
                    </Grid>
                  );
                })}
                {legends.length >= 15 && (
                  <Grid className="legend" item xs={6} md={3}>
                    <ListItem alignItems="flex-start">
                      <div
                        style={{ backgroundColor: this.colors[14] }}
                        className="marker"
                      />

                      <p className="legendText"  >{"Other"}</p>
                    </ListItem>
                  </Grid>
                )}
              </Grid>
            )}

            <div className="table-container">
              {body && (
                <div className="gridTable">
                  <div></div>
                  <div className="gtHeaders">
                    {Object.keys(years).map((key, i) => {
                      if (!years[key]) return null;

                      return <div key={i}>{key}</div>;
                    })}
                  </div>

                  {body && segments.map((segment) => this.printRows(segment))}
                </div>
              )}
            </div>
            {!body && (
              <SkeletonTheme color="#E2DEDE">
                <p>
                  <Skeleton height={100} />
                  <br />
                  <br />
                  <Skeleton count={8} height={60} />
                </p>
              </SkeletonTheme>
            )}

            {body && (
              <Footnotes general={this.generalfootnotes} notes={this.footnotes} />
            )}
          </div>
        </ReportBase>

        {body && (
          <InvalidRecords
            headers={this.headersAsKeys}
            totalRecord={this.data.length - 1}
            leftBehind={this.leftBehind.map((row) =>
              this.headersAsKeys.map((val) => row[val])
            )}
          />
        )}
      </div>
    );
  }

  rows = [];

  printRows(segment) {
    const { body, legends, years } = this.state;
    this.rows = body.filter((row) => mapSegment(row["Segment"]) === segment);

    /*
      maping the items so that we can have multiple item in single row.
      pushing the same items in a year in second row.
    */
    const tobeRows = [];
    while (this.rows.length > 0) {
      const tobeRow = Object.keys(years).map((key) => {
        let found = false;
        this.rows.forEach((row, index) => {
          if (found !== false) return;
          if (Number(key) === stringToYear(row["Est. Launch"])) {
            found = index;
          }
        });
        if (found !== false) {
          return this.rows.splice(found, 1)[0];
        } else {
          return null;
        }
      });
      tobeRows.push(tobeRow);
    }
    // rows.forEach((row, index)=>{

    // })

    return (
      <React.Fragment key={"segment-" + segment}>
        <ReactResizeDetector
          handleHeight onResize={this.handleResize}
        >
          <div className="sideBar">
            <p>
              {segment}
            </p>
          </div>
        </ReactResizeDetector>

        <div className="segments">
          {tobeRows.map((row, index) => {
            return (
              <div key={"row-" + index} className="gtRow">
                {row.map((item, idx) => {
                  // return <td>key={`cell-${index}-${idx}`}></td>;

                  if (!years[Object.keys(years)[idx]]) return null;

                  if (item === null) {
                    return (
                      <div
                        style={{ width: this.columnsWidth + "%" }}
                        key={`cell-${index}-${idx}`}
                      />
                    );
                  }

                  return (
                    <div
                      key={`cell-${index}-${idx}`}
                      style={{ width: this.columnsWidth + "%" }}
                      className={`gtCell quarter-${calAlignment(
                        item["Est. Launch"]
                      )}`}
                    >
                      {/* <div style={{}} className="item-container"> */}
                      <div className="info">
                        <div className="marker-container">
                          <div
                            style={{
                              backgroundColor: this.colors[
                                legends.indexOf(item["MOA (Revised)"]) > 13
                                  ? 14
                                  : legends.indexOf(item["MOA (Revised)"])
                              ],
                              borderLeftColor: this.colors[
                                legends.indexOf(item["MOA (Revised)"]) > 13
                                  ? 14
                                  : legends.indexOf(item["MOA (Revised)"])
                              ],
                            }}
                            onDoubleClick={this.changeAlignment}
                            className={
                              "marker" +
                              (item["H/M/L; New; NA"] === "TAK ASSET"
                                ? " bordered"
                                : "") +
                              (isCustomFormat(item["Est. Launch"])
                                ? " cutomFormat"
                                : "")
                            }
                          />
                        </div>
                        <p>
                          <strong className="product-company">
                            {`${item["Product name"]} (`}
                            <span className="company-name">
                              {`${item["Company Name"]})`}

                              {item[this.footnotesColumnName] && (
                                <span className="footnotesIndex">
                                  (
                                  {item[this.footnotesColumnName]
                                    .split("|")
                                    .map((note) => {
                                      let index = -1;
                                      this.footnotes.some((n, i) => {
                                        if (n.text === note.trim()) {
                                          index = i;
                                          return true;
                                        }
                                        return false;
                                      });
                                      if (index === -1) return null;
                                      return (
                                        <span key={index}>{index + 1}</span>
                                      );
                                    })}
                                  )
                                </span>
                              )}
                            </span>
                          </strong>

                          <span>
                            {item["Launch Assumptions"].split("-")[0]}
                          </span>

                          {item["Launch Assumptions"].split("-")[1] && (
                            <span style={{ color: "red" }}>
                              {" "}
                              - {item["Launch Assumptions"].split("-")[1]}
                            </span>
                          )}
                        </p>
                      </div>
                      {/* </div> */}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  changeAlignment = (e) => {
    const gtCell = $(e.currentTarget).closest(".gtCell");
    if (gtCell.hasClass("toLeft")) {
      gtCell.removeClass("toLeft");
    } else {
      gtCell.addClass("toLeft");
    }
  };

  handleColVisibilityChange = (e) => {
    const key = e.target.name;
    const years = { ...this.state.years };
    years[key] = !years[key];
    this.setState({ years });
  };
}

function calAlignment(dateString) {
  const dateObj = stringToDate(dateString);
  let month = dateObj.getMonth();
  if (month > 5) {
    return month + " toLeft";
  }
  return month;
}

export default CompetitorLaunchTimelineDetails;
