import React from "react";

import Synthetic_Lethality from "../../assets/img/BioMarkers/Synthetic_Lethality.svg";
import Cell_Gene_Therapy from "../../assets/img/BioMarkers/Cell_Gene_Therapy.svg";
import Immunotherapy from "../../assets/img/BioMarkers/Immunotherapy.svg";
import Other from "../../assets/img/BioMarkers/Other.svg";
import Epigentics from "../../assets/img/BioMarkers/Epigenetics.svg";

export const bmMap = {
  "cell & gene therapy": Cell_Gene_Therapy,
  immunotherapy: Immunotherapy,
  epigenetics: Epigentics,
  'synthetic lethality': Synthetic_Lethality,
  other: Other,
};

export const splitBioMarkers = (biomarkerString, toLower=true) => {
  const all = biomarkerString.split(";");
  const distinct = [];
  all.forEach((str) => {
    str = str.trim();
    str = toLower ? str.toLowerCase() : str;
    if (str && !distinct.includes(str)) distinct.push(str);
  });
  return distinct;
}

export const BioMarkers = (props) => {
  const { bm } = props;
  if (!bm) {
    return null;
  }

  const distinct = splitBioMarkers(bm)
  if (!distinct.length) return null;

  return (
    <React.Fragment>
      {distinct.map((str, i) => {
        if (bmMap[str] && str !== 'other') return <img className="biomarkers-logo" key={i} src={bmMap[str]} alt="Biomarker" />
        return <img className="biomarkers-logo" key={i} src={bmMap["other"]} alt="Biomarker" />
      })}
    </React.Fragment>
  );
};

export const BioBadge = ({bm, color}) => {
  if (!bm) {
    return null;
  }

  const distinct = splitBioMarkers(bm, false)
  if (!distinct.length) return null;

  return (
    <React.Fragment>
      {distinct.map((str, i) => {
        return <span
          key={i}
          style={{
            backgroundColor:  color || 'red',
            color: "white",
          }}
          className="badge badge-danger"
        >
          {str}
        </span>
      })}
    </React.Fragment>
  );
};
