import React from 'react';
import {
  InputLabel,
  FilledInput,
  FormHelperText,
  CircularProgress,
  InputAdornment
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  labelsPlaceHolders: { marginLeft: '20px' },
  uploadButton: {
    textTransform: 'none',
    marginTop: '10px',
    padding: "10px",
    borderRadius: '4px',
    background: '#CCC4C4',
    cursor: 'pointer'
  },
  fileIconStyle: {
    fontSize: '13px',
    marginBottom: '2px'
  },
  
});

const FileUploader = ({ placeHolder, name, uploadStatus, onChange, id, files, onClick }) => {

  const { labelsPlaceHolders, uploadButton, fileIconStyle } = useStyles();

  return (
    <>
      <InputLabel className={labelsPlaceHolders} htmlFor="file">
        {placeHolder}
      </InputLabel>
      <input
        style={{ display: 'none' }}
        id={id}
        name={name}
        type="file"
        accept="image/*"
        disabled={uploadStatus}
        multiple
        onChange={onChange}
      />
      <FilledInput
        color="primary"
        id="filled-adornment-password"
        type={'text'}
        disabled
        endAdornment={
          <InputAdornment position="end">
            {uploadStatus ?
              <CircularProgress color="inherit" size={20}></CircularProgress> :
              <label className={uploadButton} htmlFor="file">
                Browse
            </label>}
          </InputAdornment>
        }
      />
      {Boolean(files.length) &&
        files.map((file, index) => (
          <FormHelperText key={index}>
            <ImageIcon className={fileIconStyle} />
            &nbsp;
            {file.name}
            <HighlightOffIcon
              onClick={() => onClick(index)}
              className={fileIconStyle}
            />
          </FormHelperText>
        ))
      }
    </>
  );
}

export { FileUploader };