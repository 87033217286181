import React, {useContext, useEffect, useState} from 'react';
import {Card, Grid, CircularProgress, Button} from '@material-ui/core';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import EditIcon from "@material-ui/icons/Edit";

import GlobalModal from '.';
import './modal.scss';

import {getUserPermissions} from "../../service/users";
import SkeletonTextAndHeading from "../common/SkeletonTextAndHeading";
import {useHistory} from "react-router";
import {AuthContext} from "../../auth/AuthContext";
import {setActiveUser} from "../../redux/users/actions";

const UserPermissionsInformationModal = ({
    open, 
    handleClose,
    activeUser,
    ...props
}) => {
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [userPermissions, setUserPermissions] = useState([]);
    const [canChange, setCanChange] = useState(true)

    const {currentUser} = useContext(AuthContext);

    useEffect(() => {
      if(activeUser && activeUser._id){
        setLoading(true);
        getUserPermissions(activeUser._id).then((data) => {
          setUserPermissions(data);
          activeUser.permissions = data;
          props.dispatchSetActiveUser(activeUser);
        }).catch((err) => {
          props.enqueueSnackbar(
            "Sorry! We couldn't load the permissions :(",
            {
                variant: "error",
            });
        }).finally(() => {
            setLoading(false);
        });
      }
    },[activeUser]);

    useEffect(()=>{
      //editor can not change another editor's permission
      if((activeUser && activeUser.role === 'editor') && currentUser.role === 'editor'){
        setCanChange(false)
      }
    }, []);

    return ( 
        <React.Fragment>
            <GlobalModal
                open={open}
                handleClose={handleClose}
            >
                <Card className="permissionsInformationCard">
                    <Grid container spacing={3} className={"scrollable"}>
                      <Grid item xs={12} className="modalHeader withClose" disabled={!canChange}>
                          <h1>User Permissions</h1>
                          <EditIcon
                            style={{ cursor: 'pointer'}}
                            onClick={() => canChange? history.push(`/administration/users/edit/${activeUser._id}`):null}></EditIcon>
                      </Grid>

                      {loading && (
                        <Grid item sm={12} md={12} lg={12}>
                          <SkeletonTextAndHeading size={2} count={3} />
                        </Grid>
                      )}

                    <Grid item container xs={12} md={12} lg={12}>
                      {!loading && (
                        userPermissions.map((userPermission, index) => (
                            <Grid key={index} item xs={12}>
                              <span className={'permission-container'}>
                                  <h2>{userPermission.name}</h2>
                                  <p>{userPermission.permissions.map(permission => permission.therapeuticArea.name).join(', ')}</p>
                              </span>
                            </Grid>
                        ))
                      )}
                    </Grid>

                      <Grid item xs={12} className="modalFooter">
                          <Button
                            key={'close'}
                            variant={'outlined'}
                            type={'button'}
                            color='secondary'
                            onClick={() => handleClose()}
                            className={null}>
                              {loading
                                ? <CircularProgress
                                  color="inherit"
                                  size={20}
                                >
                                </CircularProgress>
                                : 'Cancel'}
                          </Button>
                      </Grid>
                    </Grid>
                </Card>
            </GlobalModal>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    const {activeUser} = state.users;
    return {
      activeUser,
      dispatchSetActiveUser: setActiveUser
    }
}

const mapActionToProps = {}
 
export default connect(mapStateToProps, mapActionToProps)(withSnackbar(UserPermissionsInformationModal));