import React from 'react';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

const BoxSizeControl = (props) => {
    const {value, onChange} = props;
    return ( <div className={props.className}>
    <TextField
      label="Box Width"
      onChange={onChange}
      value={value.width}
      type="number"
      margin="normal"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">PX</InputAdornment>
        ),
        name: "width",
      }}
    />

    <TextField
      label="Box Height"
      onChange={onChange}
      value={value.height}
      type="number"
      margin="normal"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">PX</InputAdornment>
        ),
        name: "height",
      }}
    />
  </div> );
}
 
export default BoxSizeControl;