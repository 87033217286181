import { InputAdornment, TextField } from '@material-ui/core';
import React, { useState } from 'react';

const FontSize = ({fontSize, ...props}) => {
    const [size, setSize] = useState(fontSize? fontSize : 18);

    const handleChange = (e) => {
        const fontSize = e.target.value;
        const el = document.querySelector(`#toImage`);
        el.style.fontSize = fontSize + "px";
        setSize(fontSize);
    }

    return (
        <TextField
            className="fontSizeControl"
            style={{width: '90px', margin: '0 10px'}}
            value={size}
            type="number"
            label="Font Size"
            onChange={handleChange}
            margin="none"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">PX</InputAdornment>
                )
            }}
        ></TextField>
    );
}

export default FontSize;