import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import vars from '../_config.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "row-reverse",
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepIcon: {
    color: `${vars.appRed}!important`,
  }
}));

export const LinearStep = ({ label, children }) => {
  return children;
}

const FormStepper = ({nextText, finishText, enableNext, onFinalStep, ...props}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const isTheLastStep = () => {
    return activeStep === React.Children.count(props.children) - 1;
  };

  const handleNext = () => {
    isTheLastStep()? onFinalStep && onFinalStep():setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {React.Children.map(props.children, (child) => {
          return <Step key={child.props.label}>
            <StepLabel StepIconProps={{
              classes: { active: classes.stepIcon, completed: classes.stepIcon }
            }}>
              <Typography><b>{child.props.label}</b></Typography>
            </StepLabel>
            <StepContent>
              {child}
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleNext}
                    className={classes.button}
                    disabled={!enableNext}
                  >
                    {isTheLastStep()? finishText? finishText:'Finish' : nextText? nextText:'Next'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        })}
      </Stepper>
    </div>
  );
}

export default FormStepper;