import { Breadcrumbs, makeStyles } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    icon: {
        margin: '0 5px 0 -15px',
        fontSize: "32px"
    },

    link: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: '24px',

        '&:hover': {
            color: theme.palette.text.primary,
            textDecoration: 'underline'
        }
    }
}));

const GenericBreadCrumbs = ({ items = [], ...props }) => {
    const classes = useStyles();

    if (!items.length) return null;

    return (
        <>
            <ChevronLeft className={classes.icon} onClick={()=> props.history.goBack()}/>
            <Breadcrumbs aria-label="breadcrumb">
                {items.map((item, index) => (
                    <Link className={classes.link} key={index} color="textPrimary" to={item.to}>
                        {item.name}
                    </Link>
                ))}
            </Breadcrumbs>
        </>
    );
}

export default withRouter(GenericBreadCrumbs);