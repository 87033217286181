import React, { useState, useEffect, Fragment } from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Grid, Divider, TablePagination } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import { Link, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from "../../Table/newsTable";
import { searchDraftNews } from '../../../service/news';
import SearchBox from '../../ReduceFilters/search-box';
import { withSnackbar } from 'notistack';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles({
  pageHeader: {
    flexDirection: 'row',
    fontSize: 34,
    // background: 'white',
    justifyContent: 'space-between',
    fontFamily: 'PoppinsSemiBold'
  },

  arrowBackIcon: {
    width: '25px',
    height: '25px',
    color: '#8A8A8A'
  },

  subTitle: {
    margin: "10px 20px",
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    color: '#323232'
  },

  newsTable: {
    padding: '0 20px',
    fontFamily: 'Poppins',
    margin: '20px 0',
    borderRadius: '10px'
  },

  checkViewLegend: {
    margin: '15px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    color: '#8A8A8A'
  },

  checkboxesWrapper: {
    margin: '10px 0'
  }
});

const DraftsNews = ({ enqueueSnackbar, ...props }) => {

  const classes = useStyles(props);
  const [allNews, setAllNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState("draft");

  const { dId } = useParams();

  useEffect(() => {
    searching();
  }, [limit, page, status])

  // news reducer
  const handleChange = (index, type) => {
    const auxAllNews = [...allNews];
    const activeNews = auxAllNews[index];

    switch (type) {
      case 'delete': {
        auxAllNews.splice(index, 1)
        setTotal(total - 1);
        break;
      }

      case 'publish': {
        activeNews.status = activeNews.status === 'published' ? 'draft' : 'published';
        break;
      }

      default:
        break;
    }
    console.log(auxAllNews)
    setAllNews(auxAllNews);
  }

  const doSearch = () => {
    const searchText = searchQuery.trim();
    if (!searchText) {
      return;
    }

    searching();
  };

  const callbackError = ({ response }) => {
    if (response) {
      enqueueSnackbar(response.data.message, { variant: 'error' });
    } else {
      enqueueSnackbar("Unexpected Error Occured!", { variant: 'error' });
    }
  };

  const searching = () => {
    setLoading(true);
    const filters = { status };

    if (searchQuery.trim()) filters.terms = [searchQuery];

    // if we have a disease id in params
    if (dId) filters.diseases = [dId];

    const allFilters = {
      limit, page: page + 1, orderBy: "createdAt:desc", filters
    }

    searchDraftNews(allFilters)
      .then(({ data }) => {
        // console.log(data);
        setTotal(data.totalCount);
        setAllNews(data.news)
      })
      .catch(callbackError)
      .finally(() => setLoading(false))
  };

  const handleResetSearch = () => {
    setSearchQuery("");
    // props.dispatchSetSearchTerms([]);
    searching();
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} className="">

        <Grid item md={12} xs={12}>
          <Link
            to="/news"
            className={classes.pageHeader}
          >
            <ArrowBackIosIcon className={classes.arrowBackIcon} />
            <span className={classes.screenTitle}>News</span>
          </Link>

          <Divider variant="middle" style={{ marginBottom: "10px", width: '100%' }} />

          <Grid container spacing={4}>

            <Grid item md={3} xs={8}>
              <h4 className={classes.subTitle}>Drafts list</h4>
            </Grid>

            <Grid item md={2} xs={6}>
              <span className={classes.checkViewLegend}>Type View</span>
            </Grid>

            <Grid item md={2} xs={4}>
              <div className={classes.checkboxesWrapper}>
                <FormControlLabel
                  label="Drafts"
                  control={
                    <Checkbox
                      checked={status === 'draft'}
                      color="secondary"
                      onChange={() => setStatus('draft')}
                    />
                  }
                />
                <FormControlLabel
                  label="Fetch"
                  control={
                    <Checkbox
                      checked={status === 'fetch'}
                      color="secondary"
                      onChange={() => setStatus('fetch')}
                    />
                  }
                />
              </div>
            </Grid>
            {/* <Grid item md={2} xs={4}>
              
            </Grid> */}
            <Grid item md={5} xs={12}>
              <SearchBox
                value={searchQuery}
                placeholder="Search here"
                doSearch={doSearch}
                onReset={handleResetSearch}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Grid>

          </Grid>

          <Divider variant="middle" style={{ marginBottom: "10px" }} />

          <Grid container className={classes.newsTable}>
            {
              loading ?
                (<Fragment>
                  <Skeleton variant="rect" height={50} width={'100%'} style={{ margin: '5px 0' }} />
                  <Skeleton variant="rect" height={50} width={'100%'} style={{ margin: '5px 0' }} />
                  <Skeleton variant="rect" height={50} width={'100%'} style={{ margin: '5px 0' }} />
                  <Skeleton variant="rect" height={50} width={'100%'} style={{ margin: '5px 0' }} />
                  <Skeleton variant="rect" height={50} width={'100%'} style={{ margin: '5px 0' }} />
                </Fragment>) :
                Boolean(total) ?
                  <Table
                    data={allNews}
                    onChange={handleChange}
                    mode={status}
                  /> :
                  <h3 style={{ margin: 'auto' }}>No Draft News found</h3>
            }


            {(Boolean(total) && !loading) && <Grid container justify="flex-end" className="usersPagination">
              <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 25]}
                count={total}
                className={classes.pagination}
                page={page}
                onChangePage={(e, p) => setPage(p)}
                rowsPerPage={limit}
                onChangeRowsPerPage={(e) => setLimit(e.target.value)}
              />
            </Grid>}
          </Grid>
        </Grid>

      </Grid>
    </React.Fragment>
  );
}


export default withSnackbar(DraftsNews);