export const SET_ALL_EVENTS = "SET_ALL_EVENTS";
export const SET_MORE_EVENTS = "SET_MORE_EVENTS";
export const ADD_EVENT = "ADD_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const SET_ACTIVE_EVENT = "ACTIVE_EVENT";
export const SET_EVENT_LOADING = 'SET_EVENT_LOADING';
export const SET_EVENT_QUERY = "SET_EVENT_QUERY";
export const SET_EVENTS_FILTER_OPTIONS = "SET_EVENTS_FILTER_OPTIONS";

export function setAllEvents(data) {
  return {
    type: SET_ALL_EVENTS,
    payload: data
  };
}

export function setMoreEvents(data) {
  return {
    type: SET_MORE_EVENTS,
    payload: data
  };
}

export function addEvent(event) {
  return {
    type: ADD_EVENT,
    payload: event
  };
}

export function deleteEvent(event) {
  return {
    type: DELETE_EVENT,
    payload: event
  };
}

export function updateEvent(event) {
  return {
    type: UPDATE_EVENT,
    payload: event
  };
}

export function setActiveEvent(event) {
  return {
    type: SET_ACTIVE_EVENT,
    payload: event
  };
}

export function setEventLoading(loading) {
  return {
    type: SET_EVENT_LOADING,
    payload: loading
  };
}

export function setEventQuery(query) {
  return {
    type: SET_EVENT_QUERY,
    payload: query
  };
}

export const setFiltersOptions = (filterOptions) => {
    return {
      type: SET_EVENTS_FILTER_OPTIONS,
      payload: filterOptions,
    };
}