import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { useContext } from 'react';
import { AuthContext } from '../../../../auth/AuthContext';

const useStyles = makeStyles({
    select: {
        // root: {
            backgroundColor: '#F9F6F6'
        // }
    },
})

const RoleSelect = ({value, onChange}) => {
    const classes = useStyles();

    const { onlyAdmin } = useContext(AuthContext);
    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel>Role</InputLabel>
            <Select
                className={classes.select}
                onChange={onChange}
                value={value}
                required
            >
                <MenuItem value="">Select</MenuItem>
                {onlyAdmin() && <MenuItem value={'admin'}>Admin</MenuItem>}
                <MenuItem value={'editor'}>Editor</MenuItem>
                <MenuItem value={'external'}>External</MenuItem>
            </Select>
        </FormControl>
    );
}

export default RoleSelect;