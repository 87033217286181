import React, { useState, useEffect } from 'react';
import { Card, Grid, TextField } from '@material-ui/core';
import { uniqBy, some } from 'lodash';

import {approveUser, createUser, updateUser} from '../../../../service/users';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { setActiveUser } from '../../../../redux/users/actions';
import { toggleLoading } from '../../../../redux/app/actions';
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import RoleSelect from './RoleSelect';
import AdminBase from "../AdminBase";
import {Link, useParams} from "react-router-dom";
import {ChevronLeft} from "@material-ui/icons";
import UserPermissions from "./userPermissions";

const useStyles = makeStyles({
    buttons: {
        textTransform: 'none'
    }
})

function AddUser({ history, enqueueSnackbar, activeUser, permitted, dispatchToggleLoading, dispatchSetActiveUser }) {

    const classes = useStyles();
    const { userId } = useParams();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');

    const noActiveUser = () => {
      return activeUser.name.first === "" &&
        activeUser.name.last === "" &&
        activeUser.email === "" &&
        activeUser.role === "" &&
        !activeUser.permissions.length;
    };

    useEffect(() => {
        if(userId && noActiveUser(activeUser)) history.goBack();
        setFirstName(activeUser.name.first);
        setLastName(activeUser.name.last);
        setEmail(activeUser.email);
        setRole(activeUser.role);
    }, []);

    const getClientsFromPermissions = () => {
        return permitted
            .filter(client => some(client.permissions,
                (permission) => activeUser.permissions
                    .map(permission => permission.permissions)
                    .filter(userPermission => userPermission.therapeuticArea.id === permission.therapeuticArea._id).length > 0));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatchToggleLoading()
        // it removes duplicated
        activeUser.permissions = activeUser.permissions.map(permission => {
            permission.diseases = uniqBy(permission.diseases, 'id')
            return permission;
        });

        const clients = getClientsFromPermissions();
        const user = {
            ...activeUser,
            email,
            role,
            name: {
                first: firstName,
                last: lastName
            },
            clients: clients.length > 0? clients.map(client => client._id):[]
        };

        if(userId){
          let messageSuccess = "User Updated!";
          let editFunction = updateUser;
          if(user.status === "pending"){
            messageSuccess = "User Accepted!";
            editFunction = approveUser;
          }
          persistEntity(editFunction, user, messageSuccess,"Error occurred while updating the user.");
        } else {
          persistEntity(createUser, user, "User Added!","Error occurred while creating the user.");
        }
  }

  const persistEntity = (serviceCall, user, messageSuccess, messageError) => {
      serviceCall({...user, permissions: user.permissions.flatMap(permission => permission.permissions)},
        () => {
          dispatchToggleLoading()
          enqueueSnackbar(messageSuccess, {
            variant: "success",
          });
          history.goBack();
        },
        ({ response }) => {
          dispatchToggleLoading()
          if (response) {
            enqueueSnackbar(response.data.message, {
              variant: "error",
            });
          } else {
            enqueueSnackbar(messageError, {
              variant: "error",
            });
          }
        })
  }

  const admTitleComponent = (
    <Link to= "#" onClick={() => history.goBack()}>
      <h2><ChevronLeft /> Users</h2>
    </Link>
  );

    return (
      <AdminBase
        titleComponent={admTitleComponent}
        showFilters={false}
        showSearchBox={false}
      >
        <Card className={"userCard"}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h1>{!userId? "New User":"User Update"}</h1>
                    </Grid>

                    <Grid item xs={12} md={2} className="userLabelContainer">
                      <span className="userLabel">User</span>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            onChange={e => {
                              setFirstName(e.currentTarget.value);
                            }}
                            value={firstName}
                            autoFocus
                            fullWidth
                            variant="outlined"
                            required
                            className="atacana"
                            type="text"
                            placeholder="First Name" />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            onChange={e => {
                              setLastName(e.currentTarget.value);
                            }}
                            value={lastName}
                            fullWidth
                            variant="outlined"
                            required
                            className="atacana"
                            type="text"
                            placeholder="Last Name" />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            onChange={e => {
                              setEmail(e.currentTarget.value);
                            }}
                            value={email}
                            required
                            fullWidth
                            variant="outlined"
                            className="atacana"
                            type="email"
                            placeholder="Email Address" />
                    </Grid>

                    <Grid item xs={12} md={2} className="userLabelContainer">
                      <span className="userLabel">Role</span>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <RoleSelect
                            value={role}
                            onChange={e => {
                                setRole(e.target.value);
                            }}
                        >
                        </RoleSelect>
                    </Grid>

                    <Grid item xs={12}>
                      <UserPermissions activeUser={{
                        ...activeUser,
                        name: {
                          first: firstName,
                          last: lastName
                        },
                        role,
                        email
                      }}/>
                    </Grid>

                    <Grid item xs={12} className="modalFooter">
                      <Button
                          variant="outlined"
                          color="secondary"
                          size="large"
                          style={{ marginLeft: "10px" }}
                          className={classes.buttons}
                          onClick={()=> history.goBack()}
                      >
                          Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        size="large"
                        className={classes.buttons}
                      >
                        Accept
                      </Button>
                    </Grid>
                </Grid>
            </form>
        </Card>
      </AdminBase>
    );
}

const mapStateToProps = (state) => {
    const { permitted } = state.app;
    const { activeUser } = state.users;
    return {
        permitted,
        activeUser
    }
}

const mapActionsToProps = {
    dispatchSetActiveUser: setActiveUser,
    dispatchToggleLoading: toggleLoading
}

export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(AddUser))