import Axios from "./index";

const path = '/clients';

export function getAllClients(callbackSuccess, callbackError) {
  return Axios.get(`${path}`).then(res=>{
    callbackSuccess(res.data);
  }).catch(err=>{
    console.log(err.response);
    callbackError(err);
  });
}

export const createClient = async (data, callbackSuccess, callbackError) => {
  Axios.post(`${path}`, data).then(res=>{
    callbackSuccess(res.data);
  }).catch(err=>{
    console.log(err.response);
    callbackError(err);
  })
};

export const editClient = async (id, data, callbackSuccess, callbackError) => {
  Axios.put(`${path}/${id}`, data).then(res=>{
    callbackSuccess(res.data);
  }).catch(err=>{
    console.log(err.response);
    callbackError(err);
  })
};

export function deleteClientById(id, callbackSuccess, callbackError) {
  Axios.delete(`${path}/${id}`).then(res=>{
    callbackSuccess(res.data);
  }).catch(err=>{
    console.log(err.response);
    callbackError(err);
  });
}