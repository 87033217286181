import { 
    SET_ALL_TA, 
    SET_ALL_DISEASES, 
    // DELETE_TA, 
    // UPDATE_TA, 
    // ADD_TA, 
    // DELETE_DISEASE, 
    // UPDATE_DISEASE, 
    // ADD_DISEASE,
    SET_CURRENT_PERMISSIONS
} from "./types";
// import { getObjectFrom } from "../../helpers/storage";
// import { CLIENT_PERMISSIONS_KEY } from "../../constants/app";




const initialState = {
    loading: true,
    allTa: [],
    allDiseases: [],
    permissions: []
}

export default function taReducer(state = initialState, {type, payload}) {

    switch (type) {
        case SET_ALL_TA: {
            return {
                ...state,
                loading: false,
                allTa: payload
            }
        }

        case SET_ALL_DISEASES: {
            return {
                ...state,
                allDiseases: payload
            }
        }

        case SET_CURRENT_PERMISSIONS: {
            // setObjectOn(CLIENT_PERMISSIONS_KEY, payload);
            return {
                ...state,
                permissions: payload
            }
        }
            
    
        default:
            return state;
    }
}