import {
  SET_ALL_KEYWORDS,
  ADD_KEYWORD,
  UPDATE_KEYWORD,
  UPDATE_STATUS_KEYWORD,
  DELETE_KEYWORD,
  SET_ACTIVE_KEYWORD,
  SET_MORE_KEYWORDS,
  SET_KEYWORD_LOADING,
  CLEAR_ALL_KEYWORDS,
  SET_KEYWORD_QUERY
} from "./actions";

const initialState = {
  allKeywords: [],
  nextPage: null,
  query: null,
  total: 0,
  loading: true
};

export default function companiesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ALL_KEYWORDS: {
      const duplicateState = { ...state };
      duplicateState.allKeywords = payload.keywords;
      duplicateState.nextPage = payload.nextPage;
      duplicateState.total = payload.total;
      duplicateState.loading = false;
      return duplicateState;
    }
    
    case SET_MORE_KEYWORDS: {
      const duplicateState = { ...state };
      duplicateState.allKeywords = [...state.allKeywords, ...payload.keywords];
      duplicateState.nextPage = payload.nextPage;
      duplicateState.total = payload.total;
      duplicateState.loading = false;
      return duplicateState;
    }

    case DELETE_KEYWORD: {
      const allKeywords = [...state.allKeywords];
      const index = allKeywords.findIndex(c=> c._id === payload._id);
      allKeywords.splice(index, 1);
      return {allKeywords};
    }

    case ADD_KEYWORD: {
      const allKeywords = [...state.allKeywords];
      allKeywords.splice(0, 0, payload);
      return {allKeywords};
    }

    case UPDATE_KEYWORD: {
      const allKeywords = [...state.allKeywords];
      const index = allKeywords.findIndex(c=> c._id === payload._id);
      allKeywords.splice(index, 1, payload);
      return {allKeywords};
    }

    case UPDATE_STATUS_KEYWORD: {
      const allKeywords = [...state.allKeywords];
      const index = allKeywords.findIndex(c=> c._id === payload._id);
      allKeywords[index].status = payload.status;
      return {allKeywords};
    }

    case SET_ACTIVE_KEYWORD: {
      const duplicateState = { ...state };
      duplicateState.active = payload;
      return duplicateState
    }
    
    case SET_KEYWORD_LOADING: {
      const duplicateState = { ...state };
      duplicateState.loading = payload;
      return duplicateState;
    }
    
    case SET_KEYWORD_QUERY: {
      const duplicateState = { ...state };
      duplicateState.query = payload;
      return duplicateState;
    }

    case CLEAR_ALL_KEYWORDS: {
      return initialState;
    }

    default:
      return state;
  }
}
