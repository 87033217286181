import axios from '.';
import { setAllEvents, setEventLoading, setMoreEvents } from '../redux/events/actions';
import store from '../redux/store';
import { setFiltersOptions } from "../redux/events/actions";

const path = '/events';

export function getAllEvent(nextPage = false) {
    const { events } = store.getState();

    //changing the loading state
    store.dispatch(setEventLoading(true));

    return axios.get(`${path}${events.query ? '?name=' + events.query : ''}${nextPage ? '?forwardToken=' + events.nextPage : ''}`)
        .then(({ data }) => {
            if (nextPage) {
                store.dispatch(setMoreEvents(data))
            } else
                store.dispatch(setAllEvents(data));
            return Promise.resolve(data.events);
        })
}

export const getFilters = (clientId) => {
    const { filterOptions } = store.getState().events;
    //const { client } = store.getState().app;

    if (!filterOptions.loading) return Promise.resolve(filterOptions);

    //const clientId = client._id;
    //TODO(): change news by events
    return axios
        .get(`/filters/news/${clientId}`)
        .then(({ data }) => {
            console.log("Events Filter Options => ", data)
            store.dispatch(setFiltersOptions(data));
            return Promise.resolve(data);
        })
};

export function deleteEventById(id) {
    return axios.delete(`${path}/${id}`)
}

export function createNewEvent(event, callbackSuccess, callbackError) {
    axios.post(`${path}`, event).then(res => {
        callbackSuccess(res.data);
    }).catch(err => {
        console.log(err.response);
        callbackError(err);
    });
}

export function editEvent(id, data) {
    return axios.put(`${path}/${id}`, data)
}

export function searchEvents(filters) {
    return axios.post(`${path}/search`, { filters })
}