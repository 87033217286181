import { Button, Divider, IconButton, List, ListItem, ListItemText, Popover } from '@material-ui/core';
import { AccountCircleOutlined } from '@material-ui/icons';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';

const UserMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { currentUser } = useContext(AuthContext)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <AccountCircleOutlined style={{color: '#dadada'}} />
            </IconButton>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {currentUser &&
                    <div className="userPopover">
                        <div className="info">
                            <h3>{`${currentUser.name.first} ${currentUser.name.last}`}</h3>
                            <Divider light={true} />
                            <h4>{currentUser.email}</h4>
                        </div>

                        <List component="nav" style={{padding: 0}} aria-label="user menu">
                            <Link to="/change-password">
                                <ListItem button className="listItem">
                                    <ListItemText primary="Change Password" />
                                </ListItem>
                            </Link>
                        </List>
                        
                        <List component="nav" style={{padding: 0}} aria-label="user menu">
                            <Link to="/about">
                                <ListItem button className="listItem">
                                    <ListItemText primary="About" />
                                </ListItem>
                            </Link>
                        </List>

                        <Link  to="/logout" style={{marginTop: '10px', display: 'inline-block'}}>
                            <Button color="secondary" variant="outlined">Log Out</Button>
                        </Link>
                    </div>
                }
            </Popover>
        </>
    );
}

export default UserMenu;