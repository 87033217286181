import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import { connect } from "react-redux";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import {ChevronLeft} from "@material-ui/icons";

import FormBase from './FormBase';
import AdminBase from "./AdminBase";
import AddKeywordIcon from '../../../assets/img/addKeywordIcon.svg';
import { createKeyword, editKeyword } from "../../../service/keywords";
import { addKeyword, updateKeyword } from "../../../redux/keywords/actions";
import {
  resetRelatedFields,
  setRelatedClient,
  setRelatedDisease,
  setRelatedTA,
} from '../../../redux/relatedFields/actions';
import { toggleLoading } from "../../../redux/app/actions";
import { withSnackbar } from "notistack";

const PRIORITY_KEY = 1;
const CompanyAdd = ({ enqueueSnackbar, dispatchToggleLoading, activeKeyword, ...props }) => {

  const [name, setName] = useState('');
  const [ priority, setPriority ] = useState({_id: 0, name: ''});
  const [ status, setStatus ] = useState(true);

  const { keywordId } = useParams();

  useEffect(() => {
    props.dispatchResetRelatedFields();
  },[]);

  useEffect(() => {
    if (keywordId) {
      activeKeyword ? initExisting(activeKeyword) : goBack();
    }
  }, [keywordId]);

  const initExisting = (keyword) => {
    setName(keyword.name || '');
    props.dispatchSetRelatedClient(keyword.client? [keyword.client] : []);
    props.dispatchSetRelatedTA(keyword.therapeuticArea? [keyword.therapeuticArea]:[]);
    props.dispatchSetRelatedDisease(keyword.disease? [keyword.disease] : []);
    setPriority(formComponents[PRIORITY_KEY].options.find((option) => option._id === keyword.priority) || '');
    setStatus(keyword.status === 'active' ? true : false);
  };

  const goBack = () => {
    props.history.goBack();
  };

  const admTitleComponent = (
    <Link to= "#" onClick={goBack}>
      <h2><ChevronLeft /> Add Keyword</h2>
    </Link>
  );

  const formComponents = [
    {
      placeHolder: 'Name',
      type: 'input',
      onChange: name => setName(name),
      value: name,
      sm: 12
    },
    {
      type: "select",
      placeHolder: "PRIORITY",
      value: priority,
      onChange: (e,value) => setPriority(value),
      options: [
        {
          name: 'Low',
          _id: 1,
        },
        {
          name: 'Medium',
          _id: 2,
        },
        {
          name: 'High',
          _id: 3,
        }
      ],
      sm: 12
    },
    {
      type: 'relatedClientTaDisease',
      toUpperCase: true,
      sm: 12,
      relatedFields: {
        client: true,
        therapeuticArea: true,
        disease: true
      }
    },
    {
      type: 'switch',
      label: 'Status',
      onChange: e => handleStatusChange(e),
      value: status,
      name: 'status',
      render: () => renderStatus(),
      sm: 12
    },
  ];
  
  const renderStatus = () => {
    const customStyle = { margin: '7px' }
    return status ?
      <VisibilityOutlinedIcon style={customStyle} color="action" /> :
      <VisibilityOffOutlinedIcon style={customStyle} color="action" />;
  }

  const handleStatusChange = ({ target }) => {
    setStatus(target.checked);
  };

  const buttons = [
    {
      onClick: () => handleCancelClick(),
      variant: 'outlined',
      text: 'cancel'
    },
    {
      variant: 'contained',
      type: 'submit',
      text: keywordId ? 'update' : 'add'
    },
  ];

  const handleCancelClick = () => {
    goBack();
  };

  const handleKeywordAdd = () => {
    dispatchToggleLoading();

    const keyword = buildKeyword();

    createKeyword(keyword, (data) => {
      dispatchToggleLoading();
      props.dispatchAddKeyword(data.keyword);
      enqueueSnackbar("Keyword Added!", {
        variant: "success",
      });
      goBack();
    }, ({ response }) => {
      dispatchToggleLoading();
      if (response) {
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(`Error occurred while creating the keyword.`, {
          variant: "error",
        });
      }
    })
  };

  const handleKeywordUpdate = () => {
    dispatchToggleLoading();
    const keyword = buildKeyword();
    editKeyword(keywordId, keyword, (data) => {
      props.dispatchUpdateKeyword(data);
      enqueueSnackbar("Keyword updated!", {
        variant: "success",
      });
      goBack();
    }, ({ response }) => {
      if (response) {
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(`Error occurred while editing the keyword.`, {
          variant: "error",
        });
      }
    })
    .finally(() => {
      dispatchToggleLoading();
    })
  };

  const buildKeyword = () => {
    const keyword = {
      name,
      status: status? 'active':'inactive',
      client: props.client? props.client._id:'',
      therapeuticArea: props.therapeuticArea? props.therapeuticArea._id:'',
      disease: props.disease? props.disease._id:'',
      priority: priority._id > 0? priority._id:''
    };
    return keyword;
  };

  return (
    <AdminBase
      titleComponent={admTitleComponent}
      showFilters={false}
      showSearchBox={false}
    >
      <FormBase
        title={`${keywordId ? 'Update the' : 'Add a New'}  Keyword`}
        onSubmit={keywordId ? handleKeywordUpdate : handleKeywordAdd}
        formComponents={formComponents}
        buttons={buttons}
        iconImage={AddKeywordIcon}
        history={props.history}
      />

    </AdminBase>
  )
}

const mapStateToProps = (state) => {
  const { active } = state.keywords;
  const { clients, therapeuticAreas, diseases } = state.relatedFields;
  return {
    activeKeyword: active,
    client: clients[0],
    therapeuticArea: therapeuticAreas[0],
    disease: diseases[0],
  };
};

const mapActionsToProps = {
  dispatchToggleLoading: toggleLoading,
  dispatchAddKeyword: addKeyword,
  dispatchUpdateKeyword: updateKeyword,
  dispatchResetRelatedFields: resetRelatedFields,
  dispatchSetRelatedTA: setRelatedTA,
  dispatchSetRelatedDisease: setRelatedDisease,
  dispatchSetRelatedClient: setRelatedClient
};

export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(CompanyAdd));