import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import { pick } from 'lodash';
import { withSnackbar } from "notistack";

import Typography from "@material-ui/core/Typography";
import { Button } from '@material-ui/core';
import { AddCircle, Edit, DeleteOutline, VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Switch from "@material-ui/core/Switch";

import {ADMINISTRATION, EVENTS, TRIAL} from '../../../constants/routes';
import AdminBase from "./AdminBase";
import GenericTable from "../../Table/genericTable";
import PublishNewsScreenIcon from "../../../assets/img/News/draftScreenIcon.svg";
import ConfirmModal from "../../Modal/ConfirmModal";

import {updateEvent, setEventQuery, setActiveEvent, deleteEvent} from "../../../redux/events/actions";

import './styles.scss';
import {getAllEvent, editEvent, deleteEventById} from "../../../service/events";

const Trails = ({ enqueueSnackbar, ...props }) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const { loading, allEvents, total } = useSelector((state) => state.events);
  const dispatch = useDispatch();

  const { path } = useRouteMatch();

  useEffect(()=>{
    //dispatch(setEventQuery(null))
    loadEvents();
  }, [])

  const loadEvents = () => {
    getAllEvent()
      .then((data)=>{})
      .catch(({response})=>{
        if(response){
          enqueueSnackbar(response.data.message, {variant: 'error'})
        }else
          enqueueSnackbar("Unexpected error occurred while getting events!", {variant: 'error'})
      })
  }

  const getNext100 = async () => {
    //return getAllCompounds(true);
  }

  const handleDelete = () => {
    deleteEventById(toBeDeleted._id).then((data) => {
      dispatch(deleteEvent(toBeDeleted));
      setToBeDeleted(null);
      enqueueSnackbar("Event Deleted", {variant: 'success'});
    }).catch((response) => {
      if (response)
        enqueueSnackbar(response.data.message, { variant: 'error' });
      else {
        enqueueSnackbar("Unexpected error occurred while deleting a event!", { variant: 'error' });
      }
    });
  };

  const handleSearch = (text) => {
    dispatch(setEventQuery(text));
    loadEvents();
  };

  const handleResetSearch = () => {
    //dispatch(setEventQuery(null))
    //loadEvents();
  }

  const headerRightComponent = (
    <Link to={`${ADMINISTRATION}/${TRIAL}/add`} >
      <Button>
        <AddCircle fontSize="default" color="secondary" />
        <span style={{ textTransform: 'initial', marginLeft: '2px' }}>
          Add Trial
        </span>
      </Button>
    </Link>

  );
  const titleComponent = (
    <h2>Trials List</h2>
  );

  const columns = [
    {
      key: 'title',
      name: 'Trial',
      align: 'left',
      showOnMdUp: true,
    },
    {
      key: 'therapeuticArea.name',
      name: 'Therapeutic Area'
    },
    {
      key: 'disease.name',
      name: 'Disease'
    },
    {
      key: 'compound.name',
      name: 'Compound'
    },
    {
      key: 'status',
      name: 'Status',
      showOnMdUp: true,
    },
  ];
  const rowActions = [
    {
      component: (
        <>
          <ListItemIcon>
            <Edit fontSize="small" />

          </ListItemIcon>
          <Typography variant="inherit">Edit</Typography>
        </>
      ),
      onClick: (row) => {
        dispatch(setActiveEvent(row));
        props.history.push(`${path}/edit/${row._id}`);
      }
    },
    {
      component: (
        <>
          <ListItemIcon>
            <DeleteOutline fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Delete</Typography>
        </>
      ),
      onClick: (row) => {
        setToBeDeleted(row);
        setShowDeleteConfirm(true);
      }
    },
    {
      component: (row) => (
        <>
          <Typography variant="inherit">Status</Typography>
          <Switch
            checked={row.status.toLowerCase() === 'active'}
            onChange={(event) => {
              row.status = event.target.checked ? 'active' : 'inactive';
              const toSend = pick(row, [
                'title','type','startDateTime','endDateTime','status','client','therapeuticArea','disease', 'compound','isImportant'
              ]);
              if(toSend.client) toSend.client = toSend.client._id;
              if(toSend.disease) toSend.disease = toSend.disease._id;
              if(toSend.therapeuticArea) toSend.therapeuticArea = toSend.therapeuticArea._id;
              if(toSend.compound) toSend.compound = toSend.compound._id;
              if(toSend.startDateTime) toSend.startDateTime = moment.unix(toSend.startDateTime).format("YYYY-MM-DD HH:mm:ss");
              if(toSend.endDateTime) toSend.endDateTime = moment.unix(toSend.endDateTime).format("YYYY-MM-DD HH:mm:ss");

              editEvent(row._id, toSend)
                .then(({ data }) => {
                  dispatch(updateEvent(data))
                  enqueueSnackbar(`Event ${data.status}!`, {
                    variant: "success",
                  });
                })
                .catch(({ response }) => {
                  if (response) {
                    enqueueSnackbar(response.data.message || 'error occurred', {
                      variant: "error",
                    });
                  } else {
                    enqueueSnackbar(`Error occurred`, {
                      variant: "error",
                    });
                  }
                })
            }}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          {row.status.toLowerCase() === 'active' ?
            <VisibilityOutlined fontSize="small" /> :
            <VisibilityOffOutlined fontSize="small" />
          }
        </>
      ),
    }
  ];
  return (
    <AdminBase headerRightComponent={headerRightComponent}
               titleComponent={titleComponent}
               handleSearch={handleSearch}
               handleResetSearch={handleResetSearch}
               showFilters={true}
               showSearchBox={true}>
      <GenericTable
        columns={columns}
        data={allEvents}
        rowActions={rowActions}
        loading={loading}
        pagination={{
          loadMore: getNext100,
          total
        }}
      />

      <ConfirmModal
        icon={PublishNewsScreenIcon}
        title="Delete Trail"
        message="Are you sure you want to delete this trial?"
        action="Delete"
        open={showDeleteConfirm}
        handleClose={() => setShowDeleteConfirm(false)}
        handleConfirm={handleDelete}
      />
    </AdminBase>
  );
};

export default withSnackbar(Trails);