import { config } from "./../base";
import axios from "axios";
import { getPlainFrom } from "../helpers/storage";

const Axios = axios.create();

const authToken = () => 'Bearer ' + getPlainFrom('token');

Axios.defaults.baseURL = config.apiRoot;

export const uploadFile = (formData) => {
    return Axios.post('/files/upload', formData, {
        headers: {
            Authorization: authToken(),
            "Content-Type": "multipart/form-data",
        }
    })
}

export function importFile(to, data) {
    return Axios.post(`/${to}/upload`, data, {
        headers: {
            Authorization: authToken(),
            "Content-Type": "multipart/form-data",
        }
    });
  }