import { setAllKeywords, setKeywordLoading, setMoreKeywords } from "../redux/keywords/actions";
import Axios from "./index";
import store from '../redux/store';
const path = '/keywords';

export function getAllKeywords(nextPage=false) {
  const {keywords} = store.getState();

  //changing the loading state
  store.dispatch(setKeywordLoading(true));

  return Axios.get(`${path}${keywords.query ? '?name='+keywords.query : ''}${nextPage ? '?forwardToken='+keywords.nextPage : ''}`)
      .then(({data})=>{
          if(nextPage) {
              store.dispatch(setMoreKeywords(data))
          }else
              store.dispatch(setAllKeywords(data));
          return Promise.resolve(data.keywords);
      })
}

export const createKeyword = async (data, callbackSuccess, callbackError) => {
  Axios.post(`${path}`, data).then(res=>{
    callbackSuccess(res.data);
  }).catch(err=>{
    console.log(err.response);
    callbackError(err);
  })
};

export const editKeyword = async (id, data, callbackSuccess, callbackError) => {
  Axios.put(`${path}/${id}`, data).then(res=>{
    callbackSuccess(res.data);
  }).catch(err=>{
    console.log(err.response);
    callbackError(err);
  })
};

export function deleteKeywordById(id, callbackSuccess, callbackError) {
  Axios.delete(`${path}/${id}`).then(res=>{
    callbackSuccess(res.data);
  }).catch(err=>{
    console.log(err.response);
    callbackError(err);
  });
}

