import React from 'react';
import { Input as InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles({
  filledInput: {
    background: '#F4F4F4',
    boxShadow: "0px 2px 2px #d9d9d9"
  }
});

const Input = ({ id, name, onChange, value, placeHolder, required , startAdornment, type, error, onBlur, helperText }) => {

  const { filledInput } = useStyles();

  return (
    <>
    <InputBase
      id={id}
      name={name}
      onChange={onChange}
      value={value && value}
      placeholder={`${placeHolder}${required? "*":""}`}
      required={required}
      className={filledInput}
      startAdornment={startAdornment}
      type={type}
      error={error}
      onBlur={onBlur} />
      {helperText && (<FormHelperText id="component-helper-text">{helperText}</FormHelperText>)}
    </>
  );
}

export { Input };