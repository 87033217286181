export const SET_ALL_USER = "SET_ALL_USER";
export const SET_ACTIVE_USER = "SET_ACTIVE_USER";
export const UPDATE_USERS = 'UPDATE_USERS';
export const DELET_USER = 'DELETE_USER'
export const ADD_USER = 'ADD_USER';
export const SET_USER_LOADING = "SET_USER_LOADING";
export const SET_MORE_USERS = "SET_MORE_USERS";
export const SET_USER_QUERY = "SET_USER_QUERY";
export const ADD_USER_QUERY = "ADD_USER_QUERY";
export const REJECT_USER = 'REJECT_USER';
export const UNBLOCK_USER = 'UNBLOCK_USER';