import React, { createContext, useState } from "react";
import JwtDecode from "jwt-decode";
import { setCurrentPermissions } from "../redux/therapeutic/actions";
import { clearAllKeywords } from "../redux/keywords/actions";
import store from "../redux/store";
import { getPermissions } from "../service/auth";
import { clearCurrentClient, setAppPermissions, setCurrentClient } from '../redux/app/actions';
import { getPlainFrom, setPlainOn, removeKey } from '../helpers/storage';
import {CLIENT_PERMISSIONS_KEY} from '../constants/app';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  const setUser = async (token) => {
    try {
      const user = JwtDecode(token);
      // console.log(user);

      //feching the permissions
      const {data} = await getPermissions();

      store.dispatch(setAppPermissions(data))
      
      // only one client
      if(data.length === 1){
        const {_id, name} = data[0];
        store.dispatch(setCurrentClient({_id, name}))
      }
      

      setCurrentUser(user)
      return Promise.resolve(user);
    } catch (err){
      console.error(err);
      setCurrentUser(null);
      
      // suspecting invalid token situation
      logout();
      return Promise.reject()
    }
  }

  const isAdmin = () => {
    const allowed = ['editor', 'admin'];
    return currentUser ? allowed.includes(currentUser.role) : false
  }

  const onlyAdmin = () => {
    const allowed = ['admin'];
    return currentUser ? allowed.includes(currentUser.role) : false
  }

  const setToken = (token) => {
    setPlainOn('token', token);
    // setUser(token);
  }

  const refreshPermission = () => {
    getPermissions()
      .then(({data})=>{
        // console.log(data)
        store.dispatch(setCurrentPermissions(data))
      })
      .catch(({response})=>{
        console.log(response)
      })
  }

  const isLoggedIn = () => {
    const token = getPlainFrom('token');
    try {
      const result = JwtDecode(token);
      if(result.exp < Math.round(Date.now() / 1000)) {
        return false;
      }
      return true;
    } catch {
      console.error('Invalid Token');
      return false;
    }
  }

  const logout = () => {
    removeKey('token');
    store.dispatch(clearCurrentClient());
    // clear the rest of the stores
    store.dispatch(clearAllKeywords());
    removeKey(CLIENT_PERMISSIONS_KEY);
    setCurrentUser(null);
  }



  return (
    <AuthContext.Provider value={{ currentUser, refreshPermission, setUser, onlyAdmin, setToken, isLoggedIn, isAdmin, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
