import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import $ from "jquery";
import { searchEvents } from "../../service/events";
import { withRouter } from "react-router";
import moment from "moment";
import Events from "./events";
import { getCompoundName } from "../../helpers/utils";
import { eventTypeColor, eventTypeFontColor, EVENT_TYPES } from "../../constants/events";
import DateRangePicker from "../DateInputPicker/dateRangePicker";
import { IconButton } from "@material-ui/core";
import { FilterList, SettingsBackupRestore } from "@material-ui/icons";
import { ReportStaticRanges } from "../DateInputPicker/staticRanges";
import ReportBase from "../groupReports/ReportBase";
import "./style.scss";

class Report9 extends Component {
  state = {
    byCompounds: null,
    dateRange: {
      startDate: new Date(),
      endDate: new Date(),
    }
  };

  processEvents = () => {
    const min = moment.min(this._events.map(({ startDateTime }) => moment(startDateTime)));
    const max = moment.max(this._events.map(({ endDateTime }) => moment(endDateTime)));

    const byCompounds = {};
    this._events.forEach((event) => {
      const { _id } = event.compound;
      if (byCompounds[_id])
        byCompounds[_id] = [...byCompounds[_id], event];
      else
        byCompounds[_id] = [event];
    });

    this.setState({
      dateRange: {
        startDate: min.toDate(),
        endDate: max.toDate(),
      },
      byCompounds
    })
  }

  getMonths = (startDate, endDate) => {
    const months = [];
    while (startDate.format("MMM YYYY") !== endDate.format("MMM YYYY")) {
      months.push(startDate.format("MMM YYYY"))
      startDate.add(1, 'month')
    }
    months.push(endDate.format("MMM YYYY"))
    return months;
  }

  componentDidMount() {
    const { dId, taId, clientId } = this.props.match.params;
    searchEvents({
      client: clientId,
      therapeuticArea: taId,
      disease: dId,
      status: "active"
    })
      .then(({ data }) => {
        this._events = data;
        this.processEvents();
      })
      .catch((error) => {
        console.log(error)
        // TODO handle errors
      })
  }


  segmentTdFix() {
    setTimeout(() => {
      const elements = $("td.segmentTd");
      elements.each(function () {
        const height = $(this).height();
        // console.log(height);
        $(this)
          .find('p')
          .css({ "max-width": height + "px" });
      });
    }, 3000);
  }

  handleDateChange = (date) => {
    this.setState({ dateRange: date.selection });
  };

  filters = (
    <div className="filterContainer">
      <FilterList /> Filters
      <DateRangePicker
        handleSelect={this.handleDateChange}
        dateRange={this.state.dateRange}
        staticRanges={ReportStaticRanges}
      ></DateRangePicker>
      <div>
        <IconButton onClick={this.processEvents}>
          <SettingsBackupRestore />
        </IconButton>
      </div>
    </div>
  )

  render() {
    const { byCompounds, dateRange } = this.state;
    // const { config, type } = this.props;
    const id = "report-9";


    const loading = !(byCompounds);
    const months = this.getMonths(moment(dateRange.startDate), moment(dateRange.endDate));

    return (
      <div id={id}>
        <ReportBase
          {...this.props}
          exportImg={id}
          filters={this.filters}
        >
          <div style={{ overflowX: 'scroll', minHeight: 410 }}>
            <div id="toImage">
              {!loading && (
                <table>
                  <thead>
                    <tr>
                      <th>Company</th>
                      <th
                        className="bg-red"
                        style={{ textAlign: "center", fontWeight: "bold" }}
                      >
                        Priority Assets
                    </th>
                      {months.map((key, index) => {
                        return (
                          <th key={index} className="month-headers">
                            {key}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Object.keys(byCompounds).map(key => {
                        const compoundEvents = byCompounds[key];
                        const length = compoundEvents.length;
                        if (!length) return null;

                        const { name, mechanismOfAction, developmentPhase, originator } = compoundEvents[0].compound;
                        return compoundEvents.map((event, ei) => (
                          <tr key={ei}>
                            {ei === 0 ?
                              (<>
                                <td rowSpan={length}>{originator ? originator.name : ""}</td>
                                <td rowSpan={length} className="thirdCol">
                                  <span>
                                    {getCompoundName(name)}
                                  </span>
                                  <span style={{ fontStyle: "italic" }}>
                                    {mechanismOfAction}
                                  </span>
                                  <span>{developmentPhase}</span>
                                </td>
                              </>) : null
                            }
                            {<Events event={event} months={months} />}
                          </tr>
                        ))



                      })
                    }
                  </tbody>
                </table>
              )}
              {loading &&
                <SkeletonTheme color="#E2DEDE">
                  <p>
                    <Skeleton height={70} />
                    <br />
                    <br />
                    <Skeleton count={5} height={50} />
                  </p>
                </SkeletonTheme>
              }

              {!loading && <div className="eventTypes">
                {EVENT_TYPES.map((type, i) => (
                  <p
                    key={i}
                    style={{
                      backgroundColor: eventTypeColor(type),
                      color: eventTypeFontColor(type),
                    }}
                  >
                    {type}
                  </p>
                ))}
              </div>}

            </div>

          </div>
        </ReportBase>
      </div>
    );
  }
}


export default withRouter(Report9);
