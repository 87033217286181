import React, { useState, } from 'react';
import GlobalModal from '.';
import { Card, Grid, CircularProgress } from '@material-ui/core';
import PropTypes from "prop-types";

const ConfirmModal = ({ open, handleClose, handleConfirm, icon, message, title, action }) => {
    const [loading, setLoading] = useState(false);

    const handleAction = async () => {
        setLoading(true);
        
        try {
            await handleConfirm();
        } catch {
            console.log(`Eror occured while ${title}`);
        } finally {
            setLoading(false);
            handleClose();
        }
    }

    return (
        <React.Fragment>
            <GlobalModal open={open} handleClose={handleClose} disableBackdropClick>
                <Card className="deleteConfirmCard">
                    <Grid container spacing={3}>
                        <Grid item xs={12} className="modalHeader">
                            {icon && <img src={icon} alt="icon" />}
                            <h2> {title} </h2>
                        </Grid>

                        <Grid item xs={12}>
                            <h3> {message} </h3>
                        </Grid>

                        <Grid item xs={12} className="modalFooter" >
                            <button disabled={loading} onClick={handleClose} className="modalButton">Cancel</button>
                            <button disabled={loading} onClick={handleAction} className="modalButton fill">
                                {loading
                                    ? <CircularProgress
                                        color="inherit"
                                        size={20}
                                    >
                                    </CircularProgress>
                                    : action}
                            </button>
                        </Grid>
                    </Grid>
                </Card>
            </GlobalModal>
        </React.Fragment>
    );
}

ConfirmModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    icon: PropTypes.string
}

export default ConfirmModal;