import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { toggleLoading, toggleAuthentication } from "../../../redux/app/actions";
import LogoVector from "../../../assets/img/logo.svg";
import { Button, Card, FormControl, OutlinedInput } from "@material-ui/core";
import { AuthContext } from "../../../auth/AuthContext";
import { loginWithCreds } from '../../../service/auth';
import { Password } from "../../common";
import AuthLayout from "../AuthLayout";
import { withSnackbar } from "notistack";


class UserLogin extends Component {
  static contextType = AuthContext;
  state = {
    redirect: false,
    email: "",
    password: "",
    error: false
  };

  handleLogin = async (e) => {
    e.preventDefault();
    this.setState({ error: false })
    const { email, password } = this.state;
    this.props.toggleLoading();

    try {
      const { data } = await loginWithCreds(email, password);
      this.context.setToken(data.token);

      this.props.enqueueSnackbar(data.message, {variant: 'success'});

      await this.context.setUser(data.token);
      this.props.toggleLoading();

      // if(user.resetPasswordOnLogin){
      //   this.props.history.push('/reset/'+data.token);
      // } else 
      this.setState({ redirect: true });
    } catch (error) {
      console.error(error)
      this.props.toggleLoading();

      const { data } = error.response;
      this.setState({ error: data.message })
    }
  };

  componentDidMount() {
    if (this.context.isLoggedIn()) {
      this.setState({ redirect: true });
    }
  }

  render() {
    const { redirect, email, password, error } = this.state;
    const { from } = this.props.location.state? this.props.location.state:{from: {pathname: "/"}};
    if (redirect) {
      return <Redirect to={from} />;
    } else
      return (
        <AuthLayout>
          <Card className="auth-card" id="login" style={{ color: 'white' }}>

            <div className="welcome-message">
              <img src={LogoVector} alt="logo-vector" />
              <h4>Welcome</h4>
            </div>

            <form autoComplete="off" className="auth-form" onSubmit={this.handleLogin}>
              <FormControl>
                <OutlinedInput
                  placeholder="Email"
                  error={Boolean(error)}
                  fullWidth
                  variant="outlined"
                  className="auth-inputs"
                  onChange={(e) =>
                    this.setState({ email: e.currentTarget.value })
                  }
                  value={email}
                  type="email"
                  autoFocus={true}
                  required
                />
              </FormControl>
              <FormControl>
                <Password
                  required
                  fullWidth
                  error={Boolean(error)}
                  placeholder="Password"
                  variant="outlined"
                  className="auth-inputs"
                  notched={false}
                  onChange={(e) =>
                    this.setState({ password: e.currentTarget.value })
                  }
                  value={password}
                />
              </FormControl>

              {error && <p className="auth-warning">{error}</p>}
              <Link className="reset-link" to="/forgot-password" >Forgot password?</Link>


              <Button type="submit" color="secondary" variant="outlined" className="accessBtn">
                Login
              </Button>
            </form>
          </Card>
          <p className="reg-invite">Don’t have an account? <Link to="/sign-up">Create one</Link></p>
        </AuthLayout>
      );
  }
}

const actionsToProps = {
  toggleLoading: toggleLoading,
  toggleAuthentication: toggleAuthentication,
};



export default connect(null, actionsToProps)(withSnackbar(UserLogin));


