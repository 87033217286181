import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import { getColorStyleForPriority, getTextForPriority} from "../../helpers/utils";
import { AtacanaChip } from "./AtacanaChip";

const StatusIndicator = ({ priorNum, className = '' }) => {
  const color = getColorStyleForPriority({ priorNum });
  const useStyles = makeStyles(theme => ({
    root: {
      background: color,
      borderRadius: '3px',
      fontFamily: 'Poppins',
      fontSize: '14px',
    },
  }));
  const classes = useStyles();
  return (
      <AtacanaChip
          text={getTextForPriority({ priorNum })}
          className={classes.root+" "+className}
          variant={'default'}
          isBig={true}/>
  )
};

export { StatusIndicator };