import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { getGroupReportData } from "../../service/diseases";
import { Redirect, useParams } from "react-router";
import { useSnackbar } from "notistack";
import { useQuery } from "../../helpers/utils";
import InvalidReport from "./InvalidReport";
import { reportsLink } from "../../constants/routes";

import Report1 from '../report1';
import Report2 from '../report2';
import Report3 from '../report3';
import Report4 from '../report4';
import Report5 from '../report5';
import Report6 from '../report6';
import Report7 from '../report7/';
import Report8 from '../report8/';
import Report9 from '../report9/';

const GroupReports = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [config, setConfig] = useState(null);

  const { dId, taId, clientId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();

  useEffect(() => {
    if (dId && taId) {
      getData();
    } else {
      enqueueSnackbar("wrong id!", {
        variant: "success",
      });
      props.history.push('/');
      return;
    }
    // scroll to top
    document.querySelector("#main-to").scrollTo(0, 0);
  }, [])

  const getData = () => {
    getGroupReportData({ dId, taId })
      .then((response) => {
        const config = response[1].data
        console.log({ config })
        let { data } = response[0];

        // making sure of no blank Field
        const headerLength = data[0].length;
        // const TMcolPresent = data[0].includes("Therapeutic Modality");
        data = data.map((row) => {
          if (row.length >= headerLength) return row;

          while (row.length !== headerLength) {
            row.push("");
          }
          return row;
        });

        setConfig(config)
        setData(data);
        setLoading(false);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        const { response } = err;

        if (response) {
          enqueueSnackbar(response.data, {
            variant: "error"
          });
        }
        else {
          console.log(err)
          alert(
            `Something Unexpected happened while getting the sheet data. Please try again.`
          );
        }

        props.history.goBack();
      });
  };

  if (loading) return (
    <SkeletonTheme color="#E2DEDE">
      <p style={{ padding: "20px" }}>
        <Skeleton height={70} />
        <br />
        <br />
        <Skeleton count={8} height={50} />
      </p>
    </SkeletonTheme>
  )

  if (config.reports && !config.reports.length)
    return <InvalidReport message="No Reports selected" config={config} />

  const type = query.get('type');
  const tm = query.get('tm');

  const reportIndex = config.reports.findIndex(r => r.type === type);
  // checking the query params 'type' and if it's available in the reports array of this disease
  if (type && reportIndex !== -1) {
    const reportProps = { data, config, type };
    switch (type) {
      case "timeline-chart": return <Report3 {...reportProps} />;
      case "timeline-table": return <Report4 {...reportProps} />;
      case "event-calendar": return <Report5 {...reportProps} />;
      case "list": return <Report6 {...reportProps} />;

      case "landscape-type-1": {
        if (config.tm && tm) return <Report2 {...reportProps} />;

        return <Report1 {...reportProps} />;
      }

      case "landscape-type-2": return <Report7 {...reportProps} />;
      case "landscape-type-3": return <Report8 {...reportProps} />;
      case "events-2": return <Report9 {...reportProps} />;

      default: return <InvalidReport message="Report Not Available" {...reportProps} />
    }
  }

  // return / redirect to the first report
  return <Redirect to={reportsLink(clientId, taId, dId, config.reports[0].type)} />
}

export default GroupReports;

