import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { diseasesLink, reportsLink, tasLink } from '../../constants/routes';
import { idToIndexes } from '../../redux/app';

// withPermissionsBreadCrumbs
const withPBC = () => {
    const { clientId, dId, taId } = useParams();

    // extracting client, ta and disease indexes for their names
    const { permitted } = useSelector((state) => state.app);
    const { clientIndex, taIndex, dIndex } = idToIndexes(permitted, clientId, taId, dId);
    // console.log(permitted, {clientIndex, taIndex, dIndex});
    
    const breadcrumbs = [
        { name: 'Clients', to: '/clients' },
        { name: permitted[clientIndex].name, to: tasLink(clientId) },
    ];

    if(taIndex !== undefined) 
        breadcrumbs.push({ name: permitted[clientIndex].permissions[taIndex].therapeuticArea.name, to: diseasesLink(clientId, taId, dId) })
    
    if( dIndex !== undefined )
        breadcrumbs.push({ name: permitted[clientIndex].permissions[taIndex].diseases[dIndex].name, to: reportsLink(clientId, taId, dId) })

    return breadcrumbs
}

export default withPBC;