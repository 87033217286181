export const SET_ALL_NEWS = "SET_ALL_NEWS";
export const SET_FILTERED_NEWS = "SET_FILTERED_NEWS";
export const SET_CANT_FILTERS = "SET_CANT_FILTERS";
export const SET_FILTERS = "SET_FILTERS";
export const RESET_FILTERS = "RESET_FILTERS";
export const SET_FILTER_DATE = "SET_FILTER_DATE";
export const SET_CAT_CONTENT = "SET_CAT_CONTENT";
export const SET_FILTER_OPTIONS = "SET_FILTER_OPTIONS";
export const SET_NEWS_LOADING = 'SET_NEWS_LOADING';
export const SET_SEARCH_TERMS = 'SET_SEARCH_TERMS';
export const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT';
export const SET_NEWS_PAGE = 'SET_NEWS_PAGE';
export const SET_NEWS_LIMIT = 'SET_NEWS_LIMIT'; 
export const ADD_NEWS = 'ADD_NEWS'; 
export const DELETE_NEWS = 'DELETE_NEWS'; 
export const UPDATE_NEWS = 'UPDATE_NEWS'; 
export const SET_EXPANDED_NEWS_ID = 'SET_EXPANDED_NEWS_ID';
export const RESET_ALL = 'RESET_ALL';

