import { Button } from '@material-ui/core';
import FilterIcon from '../../../assets/img/filter.svg';
import React, { useState } from 'react';
import SearchBox from '../../ReduceFilters/search-box';
import AdministrationNav from './AdministrationNav';
import GenericBreadCrumbs from '../../common/GenericBreadCrumbs'

import './styles.scss';
import GeneralLayout from '../../common/GeneralLayout';

const AdminBase = ({ children, ...props }) => {
  const [search, setSearch] = useState('');


  return (
    <div className="admin-base">
      <GeneralLayout
        headerLeftComponent={props.rootTitle ?
          props.rootTitle :
          (<GenericBreadCrumbs items={[{ to: '/administration', name: 'Administration' }]} />)
        }

        headerRightComponent={props.headerRightComponent}
        toolbarLeftComponent={props.headerCenterComponent ?
          props.headerCenterComponent : <AdministrationNav />
        }
        toolbarRightComponent={
          <>
            {props.showFilters && <Button>
              <img src={FilterIcon} alt="filter" style={{ marginRight: '8px' }} />
              <span style={{ textTransform: 'initial' }}>
                Filters
                    </span>
            </Button>}

            {props.showSearchBox && <SearchBox placeholder="Search"
              value={search}
              doSearch={() => props.handleSearch ? props.handleSearch(search) : null}
              onReset={() => {
                setSearch('');
                return props.handleResetSearch ? props.handleResetSearch() : null;
              }}
              onChange={(e) => setSearch(e.target.value)} />}
          </>
        }

      >
        {children}
      </GeneralLayout>
    </div>
  )
};

export default AdminBase;