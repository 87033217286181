import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import SearchBox from '../../ReduceFilters/search-box';
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Dropzone from "react-dropzone";
import { withSnackbar } from "notistack";
import { base, storage } from "../../../base";
import classNames from "classnames";

import "./companyLogos.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  header: {
    flexGrow: 1
  },
  root: {
    margin: 0,
    padding: "20px"
  },
  closeButton: {
    position: "absolute",
    right: "10px",
    top: "10px",
    color: theme.palette.grey[500],
  },
  cardMedia: {
      paddingTop: "30%", // 16:9
      backgroundSize: "contain",
  },
  layout: {
    width: "auto",
    margin: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(6))]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardGrid: {
    padding: `${theme.spacing(8)}px 0`
  },
  addCompanyButton: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.015em',
    color: '#313131',
    textTransform: 'none',
  },
  cardTitle: {
    marginTop: '5px',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px'
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "10px 20px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: "10px",
  },
}))(MuiDialogActions);

class CompanyLogos extends Component {
  state = {
    open: false,
    file: null,
    companyName: "",
    companies: [],
    search: ""
  };

  allCompaniesWithImage = [];

  openModal = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, file: null, companyName: "" });
  };

  resetFile = () => {
    this.setState({ file: null });
  };

  companyNameChange = (e) => {
    this.setState({ companyName: e.target.value });
  };

  onDrop = (files) => {
    if (files[0].size > 1024 * 1024 * 5) {
      this.props.enqueueSnackbar(`Maximum allowed file size is 5MB!`, {
        variant: "error",
      });
      return;
    }

    const file = Object.assign(files[0], {
      preview: URL.createObjectURL(files[0]),
    });

    this.setState({ file });
    // this.uploadImageAddDisease();
  };

  handleSubmit = () => {
    const { file, companyName } = this.state;
    if (!companyName || !file) {
      this.props.enqueueSnackbar("Company Name or Logo can't be blank!", {
        variant: "error",
      });
      return;
    }

    const ref = "companyLogos/" + Date.now() + file.name;
    const storageRef = storage.ref(ref);
    const task = storageRef.put(file);
    const snackKey = this.props.enqueueSnackbar("Uploading File..", {
      persist: true,
      variant: "info",
    });
    task.on(
      "state_changed",
      (snapshot) => {
        // let percentage =
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        // this.setState({ uploadParcentage: percentage });
      },
      (err) => {},
      () => {
        this.props.closeSnackbar(snackKey);

        //saving the company
        base.addToCollection("companies", { name: companyName, logo: ref, logoName: file.name })
          .then(() => {
            // Reset the fields
            this.handleClose();
            // this.props.toggleLoading();

            this.props.enqueueSnackbar("Company Added!", {
              variant: "success",
            });
          })
          .catch((err) => {
            //handle error
          });
      }
    );
  };

  deleteCompany = (index) => {
    const company = this.originals[index];
    base
      .removeDoc("companies/" + company.id)
      .then(async () => {
        this.props.enqueueSnackbar("Company Deleted!", {
          variant: "success",
        });
      })
      .catch((error) => {
        this.props.enqueueSnackbar("Error ocured while deleting disease.", {
          variant: "error",
        });
      });
    const oldImgRef = storage.ref(company.logo);
    const thumbs = [
      storage.ref(this.thumb(company.logo, 200)),
      storage.ref(this.thumb(company.logo, 80)),
    ];
    const promises = thumbs.map((ref) => ref.delete());
    Promise.all(promises)
      .then(() => {})
      .catch((err) => {
        console.error("thums deletion faild..", err);
      });

    oldImgRef
      .delete()
      .then(() => {
        this.props.enqueueSnackbar("Logo Deleted..", {
          variant: "info",
        });
      })
      .catch(() => {
        this.props.enqueueSnackbar("Logo could not be deleted..", {
          variant: "error",
        });
      });
  };

  componentDidMount() {
    this.companiesRef = base.listenToCollection("companies", {
      context: this,
      withIds: true,
      then: async data => {
        this.originals = data;
        this.setState({ companies: data }); // for inital view
        //geting the image urls and resetting the state
        const dWithImage = await this.mapImageUrl(data);
        this.allCompaniesWithImage = dWithImage;
        this.setState({ companies: dWithImage });
      },
    });
  }

  mapImageUrl = async (data) => {
    let dWithImage = await data.map(async (d) => {
      const dd = { ...d };
      try {
        const thumb = this.thumb(d.logo, 200);
        if (d.logo) dd.logo = await storage.ref(thumb).getDownloadURL();
      } catch {
        if (d.logo) dd.logo = await storage.ref(d.logo).getDownloadURL();
      } finally {
        return dd;
      }
    });
    return await Promise.all(dWithImage);
  };

  thumb = (logo, size) => {
    const ext = logo.split(".").pop();
    if (ext === "svg") return logo;

    const dir = logo.split("/")[0];
    const name = logo.split("/")[1];
    return `${dir}/thumb@${size}_${name}`;
  };

  componentWillUnmount() {
    base.removeBinding(this.companiesRef);
  }

  searching = () => {
    // this.props.dispatchSetLoading(true);
    // searchCompLogos(props.dispatchSetFilteredNews, callbackError)
  }

  doSearch = () => {
    const searchText = this.state.search.trim();
    if(!searchText) {
      this.props.dispatchSetSearchTerms([])
    }
    this.props.dispatchSetSearchTerms([searchText])
    // searching();
  }

  doSearch = (e) => {
    const query = e.target.value.toLowerCase();
    const searched = this.allCompaniesWithImage.filter((c) =>
      c.name.toLowerCase().includes(query)
    );
    this.setState({ companies: searched, search: query });
  };

  handleResetSearch = () => {
    // setSearchQuery("");
    // props.dispatchSetSearchTerms([]);
    // searching();
  }

  render() {
    const { file, companyName, open, companies, search } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.header}>
          <Grid container>
            <Grid item xs={12} md={3}>
              <h1 style={{fontFamily: 'Poppins'}}>Companies</h1>
            </Grid>
            <Hidden smDown>
              <Grid item className="news-search" xs={6} md={6}>
                <SearchBox
                  id="search"
                  placeholder="Search here"
                  doSearch={this.doSearch}
                  onReset={this.handleResetSearch}
                  value={search}
                  onChange={this.doSearch}
                  disabled={!Boolean(this.allCompaniesWithImage.length)}
                />
              </Grid> 
            </Hidden>
            {/* {this.context.isAdmin() && */}
            <Grid item className="news-search" md={3} xs={12}>
              <Button
                className={classes.addCompanyButton}
                startIcon={<AddCircleIcon color="secondary" />}
                onClick={this.openModal}
                >
                  Add Company
              </Button>
            </Grid>
            {/* } */}
          </Grid>
        </div>
        <Grid
          container
          className={classNames(classes.layout, classes.cardGrid)}
          id="companyLogoManagerContainer"
          spacing={5}
        > 
        {/* <TextField
          style={{ margin: "5px 20px" }}
          id="search"
          label="Search..."
          type="text"
          fullWidth
          value={search}
          onChange={this.search}
          disabled={!Boolean(this.allCompaniesWithImage.length)}
        /> */}
        {/* <div> */}
          {/* <Fab id="addCompanyButton" color="primary" onClick={this.openModal}>
            <AddIcon />
          </Fab> */}
        {/* </div> */}
          {companies.map((company, index) => (
            <Grid key={index} item sm={12} md={3} lg={3}>
              <Card>
                <CardContent className="logoCard">
                <CardMedia
                  className={classes.cardMedia}
                  image={company.logo}
                  title={company.logoName}
                />

                {/* <CardContent style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                  <Typography variant="h6">{company.name}</Typography>
                </CardContent> */}
                  {/* <Button
                    size="small"
                    variant="contained"
                    disabled={false}
                    style={{ textTransform: "initial" }}
                  >
                    Edit
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    disabled={false}
                    style={{ textTransform: "initial" }}
                    onClick={() => this.deleteCompany(index)}
                  >
                    Delete
                  </Button> */}
                  </CardContent>
              </Card>
              <Typography className={classes.cardTitle} variant="h6">{company.name}</Typography>
            </Grid>
          ))}
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="Add more company logo"
            open={open}
            TransitionComponent={Transition}
            fullWidth={true}
          >
            <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
              Add Company Logo
            </DialogTitle>
            <DialogContent dividers="5">
              <TextField
                autoFocus
                margin="dense"
                id="company_name"
                label="Company Name"
                type="text"
                fullWidth
                value={companyName}
                onChange={this.companyNameChange}
              />
              <Dropzone onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section
                    style={{ margin: "10px 0px" }}
                    className="dropzone-container"
                  >
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input
                        {...getInputProps({
                          accept: ".gif, .jpg, .png",
                          multiple: false,
                        })}
                      />
                      <p>
                        Drag &#38; drop logo here, or click to select logo for
                        this company.
                      </p>
                    </div>
                    {file && (
                      <aside className="image-preview">
                        <div>
                          <img src={file.preview} alt={file.name} />
                        </div>
                        <Fab
                          className="delete-button"
                          variant="round"
                          size="medium"
                          color="secondary"
                          onClick={this.resetFile}
                        >
                          <DeleteIcon />
                        </Fab>
                      </aside>
                    )}
                  </section>
                )}
              </Dropzone>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleClose}
                variant="contained"
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={this.handleSubmit}
                variant="contained"
                color="primary"
              >
                Add Logo
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withSnackbar(withStyles(styles)(CompanyLogos));
