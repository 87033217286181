import React, {useEffect, useState} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import { Button } from '@material-ui/core';
import {connect, useDispatch, useSelector} from "react-redux";
import { AddCircle, Edit, DeleteOutline } from '@material-ui/icons';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Switch from "@material-ui/core/Switch";
import {withSnackbar} from "notistack";

import './styles.scss';
import { ADMINISTRATION, CLIENT } from '../../../constants/routes';
import AdminBase from "./AdminBase";
import GenericTable from "../../Table/genericTable";
import PublishNewsScreenIcon from "../../../assets/img/News/draftScreenIcon.svg";
import ConfirmModal from "../../Modal/ConfirmModal";
import {getAllClients, deleteClientById, editClient} from "../../../service/clients";
import {setAllClients, deleteClient, setActiveClient, updateClient} from "../../../redux/clients/actions";

const Clients = ({enqueueSnackbar, ...props}) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [clientsToShow, setClientsToShow] = useState([]);
  const {loading, allClients} = useSelector((state)=> state.clients);

  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  useEffect(()=>{
    if(!loading){
      setClientsToShow(allClients);
    }

    getAllClients((data) => {
      dispatch(setAllClients(data));
      setClientsToShow(data);
    }, (response) => {
      if(response){
        enqueueSnackbar(response.data.message, {variant: 'error'});
      }
      else{
        enqueueSnackbar("Unexpected error occurred while getting companies!", {variant: 'error'});
      }
    });
  }, []);

  const handleDelete = () => {
    deleteClientById(toBeDeleted._id, (data) => {
      dispatch(deleteClient(toBeDeleted));
      setToBeDeleted(null);
      enqueueSnackbar("Client Deleted", {variant: 'success'});
    },(response) => {
      if(response)
        enqueueSnackbar(response.data.message, {variant: 'error'});
      else{
        enqueueSnackbar("Unexpected error occurred while deleting a client!", {variant: 'error'});
      }
    });
  };
  
  const handleSearch = (text) => {
    if(!text.trim()) {
      setClientsToShow(allClients);
      return;
    }
    const clientsToShow = allClients.filter(client=> client.name.toLowerCase().includes(text.toLowerCase()));
    setClientsToShow(clientsToShow);
  };

  const headerRightComponent = (
    <Link to={`${ADMINISTRATION}/${CLIENT}/add`} >
      <Button>
        <AddCircle fontSize="default" color="secondary" />
        <span style={{ textTransform: 'initial', marginLeft: '2px' }}>
          Add Client
        </span>
      </Button>
    </Link>
  );
  const titleComponent = (
    <h2>Clients List</h2>
  );
  const columns = [
    {
      key: 'name',
      name: 'Name',
      showOnMdUp: true
    },
    {
      key: 'contact',
      name: 'Contact',
      showOnMdUp: true,
      renderRow: (row) => {
        if(row.contacts && row.contacts.length) {
          return row.contacts.map((contact) => {
            return contact.name;
          }).join(',');
        }
      }
    },
    {
      key: 'logo',
      name: 'Logo',
      renderRow: (row) => {
        if(row.logo) return (
            <a className="generic_table_logos" target="blank" href={row.logo}>
              <img src={row.logo} alt={row.name} />
            </a>
        )
      }
    },
    {
      key: 'status',
      name: 'Status',
      showOnMdUp: true,
    }
  ];
  const rowActions = [
    {
      component: (
          <>
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Edit</Typography>
          </>
      ),
      onClick: (row) => {
        dispatch(setActiveClient(row));
        props.history.push(`${path}/edit/${row._id}`);
      }
    },
    {
      component: (
          <>
            <ListItemIcon>
              <DeleteOutline fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Delete</Typography>
          </>
      ),
      onClick: (row) => {
        setToBeDeleted(row);
        setShowDeleteConfirm(true);
      }
    },
    {
      component: (row) => (
          <>
            <Typography variant="inherit">Status</Typography>
            <Switch
                checked={row.status === 'active'}
                onChange={(event) => {
                  row.status = event.target.checked? 'active':'inactive';
                  const { _id, ...rwid } = row  ;
                  editClient(_id, rwid, (data) => {
                    props.dispatchUpdateClient(data.client);
                    enqueueSnackbar(`Client ${row.status}!`, {
                      variant: "success",
                    });
                  }, ({ response }) => {
                    console.log(response)
                    if (response) {
                      enqueueSnackbar(response.data.message, {
                        variant: "error",
                      });
                    } else {
                      enqueueSnackbar(`Error occurred while editing the client.`, {
                        variant: "error",
                      });
                    }
                  })
                  .finally(() => {
                  })
                }}
                name="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <DeleteOutline fontSize="small" />
          </>
      ),
    }
  ];
  return (
    <AdminBase headerRightComponent={headerRightComponent}
               titleComponent={titleComponent}
               handleSearch={handleSearch}
               handleResetSearch={() => setClientsToShow(allClients)}
               showFilters={true}
               showSearchBox={true}>
      <GenericTable
          columns={columns}
          data={clientsToShow}
          rowActions={rowActions}
          loading={loading}/>

      <ConfirmModal
          icon={PublishNewsScreenIcon}
          title="Delete Client"
          message="Are you sure you want to delete this client?"
          action="Delete"
          open={showDeleteConfirm}
          handleClose={() => setShowDeleteConfirm(false)}
          handleConfirm={handleDelete}
      />
    </AdminBase>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapActionsToProps = {
  dispatchUpdateClient: updateClient
};

export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(Clients));