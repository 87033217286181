import { config } from "./../base";
import axios from "axios";
import { getPlainFrom } from "../helpers/storage";

const Axios = axios.create();

const authToken = () => 'Bearer ' + getPlainFrom('token');

Axios.defaults.baseURL = config.apiRoot;
// Axios.defaults.headers.common['Authorization'] = authToken();
// Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

Axios.interceptors.request.use(function (conf) {
  // Do something before request is sent
  conf.headers = {
    Authorization: authToken(),
    "Content-Type": "application/json",
  }

  return conf;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});


export default Axios;
