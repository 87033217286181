import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";

import "./users.scss";

const UserRequestFilters = ({ onSelect }) => {
  const [value, setValue] = React.useState('');

  useEffect(()=>{
    onSelect && onSelect(value);
  },[value]);

  const radios = [
    {label: "ALL", value: ""},
    {label: "PENDING", value: "pending"},
    {label: "ACTIVE", value: "active"},
    {label: "INACTIVE", value: "inactive"},
    {label: "TERMINATED", value: "terminated"},
    {label: "BLOCKED", value: "blocked"}
  ];
  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <div className="root-radios-user-status">
          <RadioGroup row aria-label="position" name="position" defaultValue="top" value={value} onChange={onChange}>
            {radios.map((radio,index) =>
              <FormControlLabel key={index} value={radio.value} control={<Radio color="secondary" />} label={radio.label} />)}
          </RadioGroup>
        </div>
      </Grid>
    </Grid>
  );
};

export default UserRequestFilters;