import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {connect} from 'react-redux';
import { withSnackbar } from 'notistack';
import * as moment from "moment";
import * as momentTimezone from "moment-timezone";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { ChevronLeft } from '@material-ui/icons';

import AdminBase from './AdminBase';
import FormBase from './FormBase';
import AddEventIcon from '../../../assets/img/addEventIcon.svg';
import { toggleLoading } from '../../../redux/app/actions';
import { createNewEvent, editEvent } from '../../../service/events';
import { getFilters } from "../../../service/events";
import {
  resetRelatedFields,
  setRelatedClient,
  setRelatedCompound,
  setRelatedDisease,
  setRelatedTA
} from "../../../redux/relatedFields/actions";
import { addEvent, setEventQuery, updateEvent } from "../../../redux/events/actions";
import { EVENT_TYPES } from '../../../constants/events';

const EventAdd = ({ enqueueSnackbar,
                    dispatchToggleLoading,
                    dispatchAddEvent,
                    dispatchSetCompanyQuery,
                    activeEvent,
                    selected,
                    ...props }) => {

  const [eventTitle, setEventTitle] = useState('');
  const [startsDate, setStartsDate] = useState(null);
  const [endsDate, setEndsDate] = useState(null);
  const [status, setStatus] = useState(true);
  const [importantStatus, setImportantStatus] = useState(false);
  const [eventType, setEventType] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [timezone, setTimezone] = useState('');

  const { eventId } = useParams();
  

  useEffect(() =>  {

    return () => {
      props.dispatchResetRelatedFields();
    }
  }, []);

  useEffect(() => {
    if (eventId) {
      activeEvent ? initExisting(activeEvent) : goBack();
    }
  }, [activeEvent]);

  useEffect(() => {
    if(selected.clients.length){
      getFilters(selected.clients[0]._id).catch(e => {
        enqueueSnackbar(e.message, {variant: 'error'});
        props.history.goBack();
      });
    }
  },[selected.clients]);

  const initExisting = (event) => {
    props.dispatchSetRelatedClient(event.client? [event.client] : []);
    props.dispatchSetRelatedTA(event.therapeuticArea? [event.therapeuticArea]:[]);
    props.dispatchSetRelatedDisease(event.disease? [event.disease] : []);
    props.dispatchSetRelatedCompound(event.compound? [event.compound] : []);
    setEventTitle(event.title);
    setEventDescription(event.description);
    setEventType(event.type);
    setStartsDate(moment(`${event.startDateTime}`).utc());
    setEndsDate(moment(`${event.endDateTime}`).utc());
    setTimezone(event.timezone);
    setStatus(event.status === 'active' ? true : false);
    setImportantStatus(event.isImportant);
  }

  const handleImportantStatusChange = ({ target }) => {
    setImportantStatus(target.checked);
  };

  const renderImportantStatus = () => {
    const customStyle = {
      color: '#56C6CC',
      margin: '8px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '21px'
    };
    return importantStatus ?
      <span style={customStyle}>YES</span> :
      <span style={customStyle}>NO</span>;
  }

  const formComponents = [
    {
      placeHolder: "Event Title",
      onChange: title => setEventTitle(title),
      type: 'input',
      id: 'eventTitle',
      name: 'eventTitle',
      key: 'eventTitle',
      required: true,
      value: eventTitle,
      sm: 6
    },
    {
      id: "eventType",
      name: 'eventType',
      type: 'select',
      required: true,
      value: eventType,
      onChange: (e, value) => setEventType(value),
      placeHolder: "EVENT TYPE",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      id: 'description',
      name: 'description',
      type: 'rich-textarea',
      required: true,
      placeHolder: "Event Description",
      onChange: description => setEventDescription(description),
      value: eventDescription,
      sm: 12
    },
    {
      type: 'relatedClientTaDisease',
      toUpperCase: true,
      sm: 6,
      relatedFields: {
        client: true,
        therapeuticArea: true,
        disease: true,
        compound: true
      }
    },
    {
      id: 'startDateTime',
      name: 'startDateTime',
      type: 'datetime',
      key: 'startDateTime',
      required: true,
      placeHolder: "Starts",
      onChange: date => {
        setStartsDate(date);
        if(moment(date).isAfter(endsDate)){
          setEndsDate(date);
        }
      },
      value: startsDate,
      sm: 6
    },
    {
      id: "timezone",
      name: 'timezone',
      type: 'select',
      required: true,
      value: timezone,
      onChange: (e, value) => setTimezone(value),
      placeHolder: "TIME ZONE",
      options: momentTimezone.tz.names(),
      sm: 6
    },
    {
      id: 'endDateTime',
      name: 'endDateTime',
      key: 'endDateTime',
      type: 'datetime',
      required: true,
      placeHolder: "Ends",
      onChange: date => {
        setEndsDate(date);
        if(moment(date).isBefore(startsDate)){
          setStartsDate(date);
        }
      },
      value: endsDate,
      sm: 6,
      datetime: {
        minDate: new Date(startsDate),
        invalidMinDate: "End date must be greater than start date"
      }
    },
    {
      type: 'switch',
      value: importantStatus,
      name: "isImportant",
      label: 'Important',
      switchColor: "primary",
      onChange: e => handleImportantStatusChange(e),
      render: () => renderImportantStatus(),
      sm: 3
    },
    {
      type: 'switch',
      value: status,
      label: 'Status',
      switchColor: 'secondary',
      name: "status",
      onChange: e => handleStatusChange(e),
      render: () => renderStatus(),
      sm: 3
    },
  ];
  
  const renderStatus = () => {
    const customStyle = { margin: '7px' }
    return status ?
      <VisibilityOutlinedIcon style={customStyle} color="action" /> :
      <VisibilityOffOutlinedIcon style={customStyle} color="action" />
  }

  const handleStatusChange = ({ target }) => {
    setStatus(target.checked);
  };

  const goBack = () => {
    props.history.goBack();
  };

  const admTitleComponent = (
    <Link to="#" onClick={goBack}>
      <h2><ChevronLeft /> {eventId ? 'Update' : 'Add'} Event</h2>
    </Link>
  );

  const buttons = [
    {
      onClick: () => handleCancelClick(),
      variant: 'outlined',
      text: 'cancel'
    },
    {
      variant: 'contained',
      type: 'submit',
      text: eventId ? 'update' : 'add'
    },
  ]

  const handleCancelClick = () => {
    goBack();
  };

  const validate = () => {
    let valid = true;
    if (!timezone || timezone === '') {
      enqueueSnackbar("The Event must have a Timezone!", { variant: 'warning' });
      valid = false;
    }
    return valid;
  }

  const handleEventAdd = () => {
    if (!validate()) return;
    dispatchToggleLoading();

    const event = buildEvent();

    createNewEvent(event, (newEvent) => {
      dispatchToggleLoading();
      dispatchAddEvent(newEvent)
      enqueueSnackbar("Event Added!", {
        variant: "success",
      });
      goBack();
    }, ({ response }) => {
      dispatchToggleLoading();
      if (response) {
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(`Error occurred while creating the event.`, {
          variant: "error",
        });
      }
    })
  };

  const handleEventUpdate = () => {
    dispatchToggleLoading();
    const event = buildEvent();
    editEvent(eventId, event)
      .then(({ data }) => {
        props.dispatchUpdateEvent(data);
        goBack();
      })
      .catch(({ response }) => {
        if (response) {
          enqueueSnackbar(response.data.message, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(`Error occurred while creating the event.`, {
            variant: "error",
          });
        }
      })
      .finally(() => {
        dispatchToggleLoading();
      })
  }

  const buildEvent = () => {
    const event = {};
    event.client = selected.clients.length? selected.clients[0]._id:"";
    event.therapeuticArea = selected.therapeuticAreas.length? selected.therapeuticAreas[0]._id:"";
    event.disease = selected.diseases.length? selected.diseases[0]._id:"";
    event.compound = selected.compounds.length? selected.compounds[0]._id:"";
    event.title = eventTitle;
    event.type = eventType;
    event.description = eventDescription? eventDescription:"";
    event.startDateTime = moment(startsDate).format("YYYY-MM-DD HH:mm:ss");
    event.endDateTime = moment(endsDate).format("YYYY-MM-DD HH:mm:ss");
    event.timezone = timezone;
    event.isImportant = importantStatus;
    event.status = status ? 'active' : 'inactive';
    return event;
  };

  return (
    <AdminBase
      titleComponent={admTitleComponent}
      showFilters={false}
      showSearchBox={false}
    >
      <FormBase
        title={`${eventId ? 'Update the' : 'Add a New'}  Event`}
        onSubmit={eventId ? handleEventUpdate : handleEventAdd}
        formComponents={formComponents}
        buttons={buttons}
        iconImage={AddEventIcon}
      />

    </AdminBase>
  )
}

const mapStateToProps = (state) => {
  const { active, query } = state.events;
  const { filterOptions } = state.news;
  const { therapeuticAreas, diseases,  compounds,  clients } = state.relatedFields;
  return {
    activeEvent: active,
    eventQuery: query,
    filterOptions,
    selected: {
      clients,
      therapeuticAreas,
      diseases,
      compounds
    }
  };
};

const mapActionsToProps = {
  dispatchToggleLoading: toggleLoading,
  dispatchAddEvent: addEvent,
  dispatchUpdateEvent: updateEvent,
  dispatchSetEventQuery: setEventQuery,
  dispatchResetRelatedFields: resetRelatedFields,
  dispatchSetRelatedDisease: setRelatedDisease,
  dispatchSetRelatedClient: setRelatedClient,
  dispatchSetRelatedTA: setRelatedTA,
  dispatchSetRelatedCompound: setRelatedCompound
};

export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(EventAdd));