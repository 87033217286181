import React, { Component, Fragment } from "react";
import FormControl from "@material-ui/core/FormControl";
import { verifyColumns } from "../utils/dataService";
import InvalidRecords from "../InvalidRecords";
import { BioBadge, BioMarkers, bmMap } from "./BioMarkers";
import BoxSizeControl from '../report1/BoxSizeControl';
import InvalidReport from "../groupReports/InvalidReport";
import { Checkbox, FormControlLabel, Grid, ListItem, MenuItem, Select } from "@material-ui/core";
import SelectWithSelectAll from "./SelectWithSelectAll";
import _ from 'lodash'
import "./index.scss";
import ReportBase from "../groupReports/ReportBase";
import $ from 'jquery';

class Report extends Component {
  footnotesColumnName = "report-1-footnotes";
  state = {
    formated: null,
    boxSize: { height: 180, width: 185 },
    notAvailable: [],
    viewBy: 'Trial Phase',
    filters: [],
    LOT: [],
    combine: false
  };

  bioColors = {
    "Established Biomarker": "#234258",
    "Exploratory Biomarker": "#4985B0",
    // "Predictive": "#234258",
    // "Prognostic": "#4985B0",
    // "Retrospective": "#ADCFE4",
  }

  invalidHeaders = [
    "Established Biomarker",
    "Exploratory Biomarker",
    // "Biomarker Type",
    "Class",
    "Trial Sponsor",
    "LOT"
  ];

  leftBehind = [];
  footnotes = [];
  generalFootnotes = [];
  data = null;

  validation() {
    const notAvailable = verifyColumns(this.props.data, this.invalidHeaders);
    if (notAvailable.length) {
      this.setState({ notAvailable })
      return false;
    }
    return true;
  }

  componentDidMount() {
    this.data = this.props.data;
    if (!this.validation()) return;

    const fileHeaders = this.data[0];
    let auxBody = [];
    this.data.forEach((row, index) => {
      if (index === 0) return;

      const processedObject = row.reduce((object, cell, index) => {
        object[fileHeaders[index]] = cell;
        return object;
      }, {});

      const statusColumnAvailable = row[0].includes('Recruitment status')

      if(statusColumnAvailable) {
        const invalidStatus = ['Terminated', 'Suspended'].some(t => {
          return processedObject['Recruitment status'] ? processedObject['Recruitment status'].includes(t) : true;
        });
        if (invalidStatus) {
          this.leftBehind.push(processedObject);
          return;
        }
      }

      auxBody.push(processedObject);
    });

    this.data = auxBody;

    // spliting the Biomarker and selecting the distinct ones as filters
    const filters = [];
    this.data.forEach(row => {
      let biomarkerString = row["Established Biomarker"] || '';

      if(Boolean(row["Established Biomarker"]) && Boolean(row["Exploratory Biomarker"]))
        biomarkerString = biomarkerString.concat(';', row["Exploratory Biomarker"]);
      else
        biomarkerString = biomarkerString.concat(row["Exploratory Biomarker"]);

      row['BiomarkersString'] = biomarkerString;
      biomarkerString.split(";").map(bm => bm.trim().toLowerCase()).forEach(bm => {
        if (!filters.includes(bm)) filters.push(bm);
      })
    })

    this.bioMarkers = filters.sort();
    this.setState({ filters });

    // distinct LOT
    this.LOT = this.data.map(row => row['LOT'])
      .filter((value, index, arr) => {
        // handling empty value
        if (!value.trim()) {
          this.leftBehind.push(this.data[index]);
          return false;
        }

        return arr.indexOf(value) === index
      });



    this.setState({ LOT: this.orderLOT(this.LOT) });
    this.formatData(this.state.viewBy)

    this.boxHeightFix();
  }

  formatData = (viewBy) => {
    const distinctKeys = this.data
      .map(row => row[viewBy])
      .sort()
      .filter((value, index, arr) => arr.indexOf(value) === index);

    const formated = {};
    distinctKeys.forEach(key => {
      formated[key] = {
        visibility: true,
        value: this.data.filter(row => row[viewBy] === key)
      }
    })

    this.setState({ formated })
  }

  changeColumnVisibility = (value) => {
    const formated = { ...this.state.formated };
    Object.keys(formated).forEach(key => {
      formated[key].visibility = value.includes(key);
    });

    this.setState({ formated })
  }

  changeRowVisibility = (value) => {
    this.setState({ LOT: this.orderLOT(value) })
  }

  orderLOT(lot) {
    const item_order = ["Neoadjuvant", "Adjuvant", "1L", "1LC", "1LM", "2L", "2LC", "2LM", "3L", "3LC", "3LM"];
    lot.sort((a, b) => item_order.indexOf(a) - item_order.indexOf(b));
    return lot;
  }


  changeViewBy = (e) => {
    const viewBy = e.target.value;
    this.setState({ viewBy });
    this.formatData(viewBy);
  }

  toggleCombine = () => {
    const combine = !this.state.combine;

    const LOT = this.LOT
      .map(value => this.lotMaping(value, combine))
      .filter((value, index, arr) => arr.indexOf(value) === index);

    this.setState({ combine, LOT })
  }


  render(props) {
    const { formated, boxSize, notAvailable, viewBy, filters, LOT, combine } = this.state;
    const id = "report-6";

    // const activeFilters = this.state.filters.filter(f => f.visibility).map(f => f.name);

    if (notAvailable.length) return InvalidReport({ notAvailable, ...this.props });

    if (!formated) return null;
    const visibleColumns = Object.keys(formated).filter(key => formated[key].visibility);

    return (
      <div id={id}>
        <ReportBase
          {...this.props}
          exportImg={id}
          filters={(
            <div className="filters">
              <FormControl>
                <Select
                  labelId="view-by"
                  value={viewBy}
                  onChange={this.changeViewBy}
                >
                  <MenuItem value={'Class'}>Class</MenuItem>
                  <MenuItem value={'Trial Phase'}>Trial Phase</MenuItem>
                </Select>
              </FormControl>

              <FormControl style={{ margin: "0 10px" }}>
                <SelectWithSelectAll
                  value={visibleColumns.filter(key => formated[key].visibility)}
                  options={Object.keys(formated)}
                  onChange={this.changeColumnVisibility}
                  renderValue={() => "Columns"}
                />
              </FormControl>

              <FormControl style={{ margin: "0 10px" }}>
                <SelectWithSelectAll
                  value={filters}
                  options={this.bioMarkers}
                  onChange={this.handleFilterChange}
                  renderValue={() => "Biomarkers"}
                />
              </FormControl>

              <FormControl style={{ margin: "0 10px" }}>
                <SelectWithSelectAll
                  value={LOT}
                  options={this.LOT}
                  onChange={this.changeRowVisibility}
                  renderValue={(selected) => "LOT"}
                />
              </FormControl>

              <FormControl style={{ margin: "0 10px", verticalAlign: 'bottom' }}>
                <FormControlLabel
                  style={{ margin: '0' }}
                  control={
                    <Checkbox
                      checked={combine}
                      onChange={this.toggleCombine}
                      name="combine"
                    />
                  }
                  label="Combine LOT"
                />
              </FormControl>


            </div>
          )}
          toolbarRightComponent={(
            <BoxSizeControl
              className="boxSizeControl"
              onChange={this.boxSizeChange}
              value={boxSize}
            />
          )}
        >
          <div id="toImage">
            <Grid container spacing={0} justify="flex-start">
              {Object.keys(bmMap).map((key, index) => {
                if (index > 13) return null;
                return (
                  <Grid className="logo-legends" key={index} item xs={2} md={2}>
                    <ListItem alignItems="flex-start">
                      <BioMarkers bm={key}></BioMarkers>
                      <p
                        className="legendText"
                        style={{ textTransform: 'capitalize' }}
                      >{key}</p>
                    </ListItem>
                  </Grid>
                );
              })}
            </Grid>

            <Grid container spacing={0} justify="flex-start">
              {Object.keys(this.bioColors).map((key, index) => {
                if (index > 13) return null;
                return (
                  <Grid className="legend" key={index} item xs={2} md={2}>
                    <div
                      className="colorBox"
                      style={{
                        background: this.bioColors[key]
                      }}
                    >
                      <p className="legendText">{key}</p>
                    </div>
                  </Grid>
                );
              })}
            </Grid>


            {formated && (
              <table>
                <thead>
                  <tr>
                    <th></th>
                    {visibleColumns.map((key, index) => <th key={`head-${index}`}>{key}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {LOT.map((lot, li) => {
                    const { empty, filtered } = this.filterData(lot)
                    if (empty) return null;

                    // const withBorder = lot.slice(-1) === 'C';

                    return (<Fragment key={li}>
                      <tr className="spacer"></tr>
                      <tr className="data-row">
                        <td className="lot-row">
                          <div className="holder"><span>{lot}</span></div>
                        </td>
                        {visibleColumns.map((key) => {
                          return (
                            <td key={key}>
                              <div className="holder">
                                {filtered[key].map((row, index) => {
                                  return (
                                    <div
                                      key={key + index}
                                      style={{
                                        minHeight: boxSize.height + "px",
                                        width: boxSize.width + "px",
                                      }}
                                      className={row['LOT'] === lot ? "box" : "box withBorder"}
                                    >
                                      <div className="box-texts">
                                        {row["Focus Area"] && (
                                          // <div className="tm-container">
                                          <BioMarkers bm={row["Focus Area"]}></BioMarkers>
                                          // </div>
                                        )}
                                        <p>
                                          {Boolean(row["Regimen"]) && <strong className="for-image">{row["Regimen"]}</strong>}
                                        </p>

                                        <p>{"PCD: " + row["Primary Completion Date"]}</p>

                                        <p>{row["Trial Sponsor"]}</p>
                                        <strong className="for-image">{row["Trial Name"] || row["Trial Identifier"]}</strong>
                                        {row["Established Biomarker"] && <BioBadge bm={row["Established Biomarker"]} color={this.bioColors["Established Biomarker"]}/>}
                                        <div></div>
                                        {row["Exploratory Biomarker"] && <BioBadge bm={row["Exploratory Biomarker"]} color={this.bioColors["Exploratory Biomarker"]}/>}
                                      </div>


                                      {/* {row["Focus Area"] && (
                                        <div className="tm-container">
                                          <BioMarkers bm={row["Focus Area"]}></BioMarkers>
                                        </div>
                                      )} */}
                                    </div>
                                  );
                                })}
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                      <tr></tr>
                      <tr className="spacer">
                        <td colSpan={visibleColumns.length + 1}>
                          <div></div>
                        </td>
                      </tr>
                    </Fragment>
                    )
                  })}
                </tbody>
              </table>
            )}

          </div>
        </ReportBase>
        {formated && (
          <InvalidRecords
            headers={this.invalidHeaders}
            totalRecord={this.data.length - 1}
            leftBehind={this.leftBehind.map((row) =>
              this.invalidHeaders.map((v) => row[v])
            )}
          />
        )}
      </div>
    );
  }

  handleFilterChange = (value) => {
    this.setState({ filters: value });
  };

  doBioFilter = (row) => {
    const bioMarkers = row['BiomarkersString'].split(';').map(bm => bm.trim().toLowerCase());
    const result = _.difference(bioMarkers, this.state.filters);
    return !result.length;
  }

  filterData = (lot) => {
    const { formated, combine } = this.state;
    let empty = true;
    const filtered = {}
    Object.keys(formated).forEach(key => {
      if (!formated[key].visibility || !formated[key].value.length) return;

      const rows = formated[key].value.filter(row => this.lotMaping(row['LOT'], combine) === lot && this.doBioFilter(row));
      filtered[key] = rows;

      if (rows.length) empty = false;
    });

    return { empty, filtered };
  }


  lotMaping = (lot, combine) => {
    if (!combine) return lot;

    const containTrigger = ['C', 'M'].includes(lot.slice(-1));
    if (containTrigger) return lot.slice(0, -1);

    return lot;
  }


  boxSizeChange = (e) => {
    const { boxSize } = this.state;
    boxSize[e.target.name] = e.target.value;
    this.setState({ boxSize });
  };

  boxHeightFix = () => {
    setTimeout(() => {
      const { height, width } = this.state.boxSize;
      const boxes = $('div.box');
      let maxHeight = 0;
      boxes.each(function () {
        const h = $(this).outerHeight();
        maxHeight = maxHeight >= h ? maxHeight : h;
      })

      if (maxHeight > height) {
        this.setState({ boxSize: { width, height: parseInt(maxHeight) } });
      }
    }, 0);
  }
}

export default Report;



