import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog/Dialog";
import { connect } from "react-redux";
import { compose } from "redux";
import { withSnackbar } from "notistack";
import { CircularProgress } from "@material-ui/core";

import { RelatedFields } from "../../common";
import { addTA, updateTA } from "../../../service/therapeuticAreas";
import { addTa, updateTa } from "../../../redux/app/actions";
import { setAllClients } from "../../../redux/clients/actions";
import { setRelatedClient } from '../../../redux/relatedFields/actions';
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  addTherapAreaModalButtons: {
    textTransform: 'none',
    fontWeight: '600',
    fontSize: '10px 20px',
    margin: '15px 10px',
    fontStyle: 'Poppins'
  },
  addTherapAreaInput: {
    height: '80%',
    width: '100%'
  },
  dialogActions: {
    justifyContent: 'center',
    padding: '10px'
  },
  dialogTitle: {
    padding: '20px 44px'
  }
});

const configRelatedFields = {
  client: {
    variant: 'outlined',
    disabled: true,
  }
};

class AddCategory extends Component {
  state = { 
    loading: false,
    category: {
      name: ""
    }
  };

  componentDidMount() {
    const { category, selectedClient } = this.props;
    if (category && category._id) {
      this.setState({ category });
    } 
    
    if(selectedClient){
      // if we are creating a new tA, we need to pre-select the client
      this.props.dispatchSetRelatedClient([selectedClient]);
    }
  };

  handleAdd = () => {
    this.setState({loading: true});
    const { _id, name } = this.state.category;
    const {clientId} = this.props.match.params

    if (_id) {
      updateTA({client: clientId, ...this.state.category}, ()=>{
        this.props.enqueueSnackbar("Therapeutic Area Updated", {
          variant: "success",
        });
        this.props.dispatchUpdateTa(clientId, this.state.category);
        this.props.handleClose();
      }, this.handleError)
      return;
    }

    addTA({ name, client: clientId}, (data)=>{
      this.props.enqueueSnackbar(data.message, {
        variant: "success",
      });
      this.props.dispatchAddTa(clientId, data.therapeuticArea)
      this.props.handleClose();
    }, this.handleError)
    
  };

  handleError = ({response})=>{
    this.setState({loading: false})

    if(response) {
      const {data} = response;
      this.props.enqueueSnackbar(data.message, {
        variant: "error",
      });
    } else {
      this.props.enqueueSnackbar("Unexpected Error occured..", {
        variant: "error",
      });
    }
  };

  handleChange = (event) => {
    const {category} = this.state
    category.name = event.target.value;
    this.setState({category});
  };

  render() {
    const { category, loading } = this.state;
    const { open, handleClose, classes } = this.props;
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle
          id="simple-modal-title"
          className={classNames("addTherapAreaTitle", classes.dialogTitle)}
        >
          {category && category._id ? "Edit" : "New"} Therapeutic Area
        </DialogTitle>
        <DialogContent>
          <TextField
            size="small"
            id="outlined-basic"
            autoFocus
            className={classes.addTherapAreaInput}
            label="Area name"
            variant="outlined"
            value={category.name}
            onChange={this.handleChange}
          />
          <RelatedFields config={configRelatedFields} />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
              className={classes.addTherapAreaModalButtons}
              variant="outlined"
              color="secondary"
              onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={classes.addTherapAreaModalButtons}
            variant="contained"
            color="secondary"
            onClick={this.handleAdd}
          >
            
            { loading 
              ? (<CircularProgress color="inherit" size={20}></CircularProgress> )
              : (category && category._id) ? "Update" : "Add"
            }
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddCategory.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  category: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { clients } = state.relatedFields;
  const { client } = state.app;
  return {
    selectedClient: client,
    clients
  };
};

const mapActionsToProps = {
  dispatchAddTa: addTa,
  dispatchUpdateTa: updateTa,
  dispatchSetAllClients: setAllClients,
  dispatchSetRelatedClient: setRelatedClient
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapActionsToProps),
  withRouter,
) (withSnackbar(AddCategory));
