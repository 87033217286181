import { 
    SET_ALL_TA, 
    SET_ALL_DISEASES, 
    SET_CURRENT_PERMISSIONS
 } from "./types"



export const setAllTa = (allTa) => {
    return {
        type: SET_ALL_TA,
        payload: allTa
    }
}

export const setAllDiseases = (diseases) => {
    return {
        type: SET_ALL_DISEASES,
        payload: diseases
    }
}



export const setCurrentPermissions = (permissions) => {
    return {
        type: SET_CURRENT_PERMISSIONS,
        payload: permissions
    }
}