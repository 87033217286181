import { UPDATE_DISEASE } from "../../therapeutic/types";
import {
  SET_ALL_NEWS,
  SET_FILTERED_NEWS,
  SET_CANT_FILTERS,
  SET_FILTERS,
  RESET_FILTERS,
  SET_CAT_CONTENT,
  SET_FILTER_DATE,
  SET_FILTER_OPTIONS,
  SET_NEWS_LOADING,
  SET_SEARCH_TERMS,
  SET_TOTAL_COUNT,
  SET_NEWS_LIMIT,
  SET_NEWS_PAGE,
  DELETE_NEWS,
  ADD_NEWS,
  SET_EXPANDED_NEWS_ID,
  RESET_ALL
} from "./../types";

export const setLoading = (isLoading) => {
  return {
    type: SET_NEWS_LOADING,
    payload: isLoading,
  };
};

export const addNews = (news) => {
  return {
    type: ADD_NEWS,
    payload: news
  }
}

export const updateNews = (news) => {
  return {
    type: UPDATE_DISEASE,
    payload: news
  }
}

export const deleteNews = (news) => {
  return {
    type: DELETE_NEWS,
    payload: news
  }
}

export const setTotalCount = (total) => {
  return {
    type: SET_TOTAL_COUNT,
    payload: total
  }
}
export const setNewsLimit = (limit) => {
  return {
    type: SET_NEWS_LIMIT,
    payload: limit
  }
}

export const setNewsPage = (page) => {
  return {
    type: SET_NEWS_PAGE,
    payload: page
  }
}

export const SetAllNews = (news) => {
  return {
    type: SET_ALL_NEWS,
    payload: news,
  };
};
export const setCantContent = (catContent) => {
  return {
    type: SET_CAT_CONTENT,
    payload: catContent,
  };
};
export const setFiltersOptions = (filterOptions) => {
  return {
    type: SET_FILTER_OPTIONS,
    payload: filterOptions,
  };
};
export const setFilterDate = (dates) => {
  return {
    type: SET_FILTER_DATE,
    payload: dates,
  };
};

export const setSearchTerms = (term) => {
  return {
    type: SET_SEARCH_TERMS,
    payload: term
  }
}

export const resetFilters = (disabledDiseases) => {
  return {
    type: RESET_FILTERS,
    payload: disabledDiseases
  };
};
export const setFilters = (filters) => {
  return {
    type: SET_FILTERS,
    payload: filters,
  };
};

export const setFilteredNews = (news) => {
  return {
    type: SET_FILTERED_NEWS,
    payload: news,
  };
};
export const setCantFilters = (cant) => {
  return {
    type: SET_CANT_FILTERS,
    payload: cant,
  };
};

export const setExpandedNewsId = (id=null) => {
  return {
    type: SET_EXPANDED_NEWS_ID,
    payload: id
  }
}

export const resetAll = () => {
  return {
    type: RESET_ALL
  }
};
