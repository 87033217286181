import React, { Fragment } from "react";
import classNames from "classnames";
import CategoriesBackground from "../../../assets/img/card.png";

import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { compose } from "redux";
import { Divider } from "@material-ui/core";
import { AuthContext } from "../../../auth/AuthContext";
import "./styles.scss";
import { setCurrentPermissions } from "../../../redux/therapeutic/actions";
import { setCurrentClient } from "../../../redux/app/actions";
import reportsIcon from "../../../assets/img/reportsIcon.svg"
import { tasLink } from "../../../constants/routes";

const styles = (theme) => ({
    appBar: {
        position: "relative",
    },
    addCategoryButton: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.015em',
        color: '#313131',
        textTransform: 'none',
    },
    pageHeader: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 16px',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    layout: {
        width: "auto",
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(6))]: {
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    cardGrid: {
        padding: `${theme.spacing(5)}px 0`,
    },
    grid: {
        margin: '0px!important'
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(6)
    },
    copyRightText: {
        marginTop: '45px'
    }
});

class Clients extends React.Component {

    static contextType = AuthContext;

    componentDidMount(){
        const clients = this.props.permitted;
        if(clients.length === 1 && clients[0]._id && clients[0].name) {
            this.handleSelectClient(clients[0]);
        }
    }

    handleSelectClient = (client) => {
        const {_id, name, permissions} = client;
        this.props.dispatchSetCurrentClient({_id, name})
        this.props.dispatchSetCurrentPermissions(permissions);
        this.props.history.push(tasLink(_id));
    }

    render() {
        const { classes, permitted } = this.props;

        return (
            <React.Fragment>
                <div className={classes.pageHeader}>
                    <span className="ta-screen-title">Welcome to Atacana</span>
                </div>
                <Divider />

                {!Boolean(this.props.client) && <Fragment>
                    <h2 className="client-subheader">Please Select a Client to continue</h2>
                    <Divider />
                </Fragment>}

                <CssBaseline />
                <main>
                    <div className={classNames(classes.layout, classes.cardGrid)}>
                        {/* <div className={classes.cardGrid}> */}
                        <Grid container spacing={5} className={classNames(classes.grid)}>
                            {Boolean(permitted && permitted.length) && (
                                <React.Fragment>
                                    {permitted.map(client => (
                                        <div
                                            key={client._id}
                                            onClick={() => this.handleSelectClient(client)}
                                            className="single-client"
                                        >
                                            <img src={CategoriesBackground} className="background-single-disease" alt="background" />
                                            <div className="client-info">
                                                <h3>{client.name}</h3>
                                                <span>
                                                    <img src={reportsIcon} style={{ marginRight: '10px' }} alt="background" />
                            Other info?
                          </span>
                                            </div>

                                        </div>
                                    ))}
                                </React.Fragment>
                            )}
                        </Grid>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    const { permitted, client } = state.app;
    return {
        client,
        permitted
    }
}

const mapActionToProps = {
    dispatchSetCurrentClient: setCurrentClient,
    dispatchSetCurrentPermissions: setCurrentPermissions
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapActionToProps)
)(withSnackbar(Clients));
