import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router-dom";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItems from "./menuItems/listItems";
import Divider from "@material-ui/core/Divider";
import TherapeuticAreas from "./therapeuticAreas";
import Logo from "../../assets/img/logo1.png";
import GroupReports from "../groupReports/groupReports";
import AddDisease from "./addDisease/AddDisease";
import CompanyLogos from "./CompanyLogos/CompanyLogos";
import News from "./news/news";
import Clients from './clients'
import AddNews from "./news/AddNews";
import DraftsNews from "./news/DraftsNews";
import * as Routes from "./../../constants/routes";
import Diseases from "./Diseases/Diseases";
import { compose } from "redux";
import { connect } from "react-redux";

import { AuthContext } from "../../auth/AuthContext";
import AdminRoute from "../../auth/AdminRoute";
import Administration from "./administration";

import { toggleMenu } from "../../redux/layout/actions";
import { setPlainOn, getPlainFrom } from "../../helpers/storage";
import UserMenu from "./UserMenu";
import ChangePassword from "./ChangePassword";
import { version } from '../../../package.json'

import "./layout.scss";

const drawerWidth = 240;

const styles = (theme) => {
  return {
    root: {
      display: "flex",
      backgroundColor: '#ffffff',
    },
    toolbar: {
      padding: 24, // keep right padding when drawer closed
      // [theme.breakpoints.down("md")]: {
      //   padding: theme.spacing(0)
      // }
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      margin: "10px 0px 10px",
      ...theme.mixins.toolbar,
    },
    appBar: {
      backgroundColor: theme.palette.secondary.main,
      zIndex: theme.zIndex.drawer + 1,
      height: '90px',
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },

    avatar: {
      margin: 10,
    },

    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      backgroundSize: "cover",
      boxShadow: "1px 0px 5px #aaaaaa",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },

    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      height: "100vh",
      overflowY: "auto",
      overflowX: "hidden",
      backgroundColor: '#F4F4F4',
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1),
      },
    },
    chartContainer: {
      // marginLeft: -22
    },
    tableContainer: {
      height: 320,
    },
    h5: {
      marginBottom: theme.spacing(2),
    },
  };
};

class Dashboard extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);

    // menu status set from local storage.
    const menu = getPlainFrom("menu");
    if (menu === "close") {
      this.props.dispatchToggleMenu(false);
    } else {
      this.props.dispatchToggleMenu(true);
    }

    this.state = { drawerType: "permanent" };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const width = window.innerWidth;
    if (width <= 768) {
      this.props.dispatchToggleMenu(false);
      this.setState({ drawerType: "temporary" });
    } else {
      this.setState({ drawerType: "permanent" });
    }
  };

  /*----Drawer----*/
  handleDrawerOpen = () => {
    this.props.dispatchToggleMenu(true);
    setPlainOn("menu", "open");
  };

  handleDrawerClose = () => {
    this.props.dispatchToggleMenu(false);
    setPlainOn("menu", "close");
  };

  render() {
    const { classes, activeClient } = this.props;
    const { drawerType } = this.state;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          id="atacanaAppBar"
          className={classNames(
            classes.appBar,
            this.props.openMenu && classes.appBarShift
          )}
        >
          <Toolbar
            disableGutters={!this.props.openMenu}
            className={classes.toolbar}
          >
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                this.props.openMenu && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>

            <div style={{ marginLeft: "auto", display: 'flex' }}>
              {activeClient &&
                <p style={{ margin: "auto 10px auto auto" }}>Client: {activeClient.name}</p>
              }

              <UserMenu/>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          id="atacanaDrawer"
          variant={drawerType}
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !this.props.openMenu && classes.drawerPaperClose
            ),
          }}
          open={this.props.openMenu}
        >
          <div className={classes.toolbarIcon}>
            <img src={Logo} alt="logo" height="70vh" />
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <ListItems />
        </Drawer>
        <main id="main-to" className={classes.content}>
          <div id="appBarSpacer" />

          <Typography
            id="printMarginFix"
            component="div"
            className={classes.chartContainer}
          >
            <Switch>
              <Redirect exact from="/" to={Routes.CLIENTS} />
              <Route exact path={'/change-password'} component={ChangePassword} />
              <Route exact path={Routes.CLIENTS} component={Clients} />
              <Route exact path={Routes.tasLink(':clientId')} component={TherapeuticAreas} />
              <Route exact path={Routes.diseasesLink(':clientId', ':taId')} component={Diseases} />
              <Route
                exact
                path={Routes.reportsLink(':clientId', ':taId', ':dId')}
                component={GroupReports}
              />
              <AdminRoute exact path={`${Routes.ADD_NEWS}`} component={AddNews} />
              <AdminRoute exact path={`${Routes.DRAFTS_NEWS}/:dId?`} component={DraftsNews} />
              <AdminRoute exact path={`${Routes.EDIT_NEWS}/:newsId`} component={AddNews} />
              {/*<AdminRoute path={`${Routes.USERS}`} component={Users} />*/}
              <AdminRoute exact path={Routes.addDiseaseLink(':clientId', ':taId')} component={AddDisease} />
              <AdminRoute
                exact
                path={Routes.editDiseaseLink(':clientId', ':taId', ':dId')}
                component={AddDisease}
              />
              <AdminRoute
                exact
                path={`/manage-company-logos`}
                component={CompanyLogos}
              />
              <AdminRoute
                path={`/administration`}
                component={Administration}
              />
              <Route exact path={`/news`} component={News} />
              <Route exact path={`/about`} component={()=> (<div>Version: {version}</div>)} />
            </Switch>
          </Typography>
        </main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    openMenu,
  } = state.layout;
  return {
    login: { ...state.login },
    openMenu,
    activeClient: state.app.client
  };
};

const mapActionsToProps = {
  dispatchToggleMenu: toggleMenu
};


export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapActionsToProps)
)(Dashboard);
