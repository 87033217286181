export const SET_ALL_COMPANIES = "SET_ALL_COMPANIES";
export const SET_MORE_COMPANIES = "SET_MORE_COMPANIES";
export const ADD_COMPANY = "ADD_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const SET_ACTIVE_COMPANY = "ACTIVE_COMPANY";
export const SET_COMPANY_LOADING = 'SET_COMPANY_LOADING';
export const SET_COMPANY_QUERY = "SET_COMPANY_QUERY";

export function setAllCompanies(data) {
  return {
    type: SET_ALL_COMPANIES,
    payload: data
  };
}

export function setMoreCompanies(data) {
  return {
    type: SET_MORE_COMPANIES,
    payload: data
  };
}

export function addCompany(company) {
  return {
    type: ADD_COMPANY,
    payload: company
  };
}

export function deleteCompany(company) {
  return {
    type: DELETE_COMPANY,
    payload: company
  };
}

export function updateCompany(company) {
  return {
    type: UPDATE_COMPANY,
    payload: company
  };
}

export function setActiveCompany(company) {
  return {
    type: SET_ACTIVE_COMPANY,
    payload: company
  };
}

export function setCompanyLoading(loading) {
  return {
    type: SET_COMPANY_LOADING,
    payload: loading
  };
}

export function setCompanyQuery(query) {
  return {
    type: SET_COMPANY_QUERY,
    payload: query
  };
}