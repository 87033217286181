import React, { Fragment, useContext, useEffect, useState } from "react";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DiseasesBackground from "../../../assets/img/card.png";
import reportsIcon from "../../../assets/img/reportsIcon.svg";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useSnackbar } from "notistack";
import "./Diseases.scss";
import { useDispatch, useSelector } from "react-redux";
import { deleteDisease } from "../../../service/diseases";
import { dispatchDeleteDisease } from "../../../redux/app/actions";
import { AuthContext } from "../../../auth/AuthContext";
import ConfirmModal from "../../Modal/ConfirmModal";
import { addDiseaseLink, editDiseaseLink, reportsLink } from "../../../constants/routes";
import { idToIndexes } from "../../../redux/app";
import GenericBreadCrumbs from "../../common/GenericBreadCrumbs";
import withPBC from "../../common/withPBC";
import GeneralLayout from "../../common/GeneralLayout";

const styles = (theme) => ({
  appBar: {
    position: "relative",
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`,
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(6))]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  addDiseaseButton: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.015em',
    color: '#313131',
    textTransform: 'none',
  },
  cardGrid: {
    padding: `${theme.spacing(8)}px 0`,
  },
  card: {
    background: '#F9F6F6',
    height: '100%',
    display: "flex",
    borderRadius: 10,
    flexDirection: "column",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  },
  cardMedia: {
    paddingTop: "30%", // 16:9
    backgroundSize: "contain",
  },
  cardContent: {
    flexGrow: 1,
    background: '#AE2F2F'
  },
  CardActions: {
    backgroundColor: "#fbdddd",
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: "transparent",
    padding: theme.spacing(6),
  },
  pageHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: 34,
    justifyContent: 'space-between',
    fontFamily: 'PoppinsSemiBold'
  },
  rootHeader: {
    cursor: 'pointer',
    display: 'flex',
    paddingBottom: '10px'
  },
  arrowBackIcon: {
    width: '15px',
    marginRight: '10px',
    height: '15px',
    color: '#8A8A8A'
  },
  diseaseName: {
    color: '#ffffff',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Poppins',
    width: '250px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  diseaseInfo: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    margin: '2px 16px',
    fontWeight: '500',
    fontSize: '14px',
    color: '#CCC3C3'
  },
  optionsButton: {
    background: 'white',
    width: 0,
    position: 'absolute',
    left: '77%',
    top: '5%',
    zIndex: '100'
  },
  moreOptIcon: {
    color: '#AE2F2F'
  }
});

const Diseases = ({ classes, ...props }) => {

  const [popoverId, setPopoverId] = useState('')
  const [diseases, setDiseases] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [forEdit, setForEdit] = useState({})
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { clientId, taId } = useParams();
  const { permitted } = useSelector(state => state.app);
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const context = useContext(AuthContext)

  useEffect(() => {
    try {
      const { clientIndex, taIndex } = idToIndexes(permitted, clientId, taId)
      const diseases = permitted[clientIndex].permissions[taIndex].diseases || [];
      setDiseases(diseases)
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Invalid ID", { variant: 'error' });
      props.history.push('/');
    }
  }, [])

  const handleConfirmClose = () => {
    setConfirmDelete(false);
    setForEdit({});
    setPopoverId("");
    setAnchorEl(null);
  }

  const openConfirmDelete = (disease) => {
    setConfirmDelete(true);
    setForEdit(disease)
  }

  const handleDelete = async () => {
    try {
      await deleteDisease(forEdit._id)
      dispatch(dispatchDeleteDisease(clientId, taId, forEdit._id))
      enqueueSnackbar("Disease Deleted!", {
        variant: "success",
      });
      handlePopoverClose();
    } catch ({ response }) {
      if (response) {
        const { message } = response.data
        enqueueSnackbar(message, {
          variant: "error"
        });
      } else {
        enqueueSnackbar("Error ocured while deleting disease.", {
          variant: "error",
        });
      }
    }
  };

  const handlePopoverOpen = (event, popoverId) => {
    setPopoverId(popoverId);
    setAnchorEl(event.target)
  }

  const handlePopoverClose = () => {
    setPopoverId(null);
    setAnchorEl(null)
  }

  return (
    <GeneralLayout
      headerLeftComponent={<GenericBreadCrumbs items={withPBC()} />}
      headerRightComponent={context.isAdmin() &&
        <Link to={addDiseaseLink(clientId, taId)}>
          <Button
            className={classes.addDiseaseButton}
            startIcon={<AddCircleIcon color="secondary" />}
          >
            Add New Disease
        </Button>
        </Link>}
    >
      <main>
        <div className={classNames(classes.layout)}>
          <Grid container spacing={5}>
            {diseases.map((disease, index) => {
              return (
                <div
                  key={index}
                  className="single-disease">

                  {context.isAdmin() &&
                    <Fragment>
                      <MoreVertIcon
                        className="edit-delete-disease"
                        onClick={e => handlePopoverOpen(e, disease._id)} />
                      <Popover
                        open={disease._id && popoverId === disease._id}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}>
                        <div className="optionsPopover">
                          <div className="optionsContainer">
                            <Link to={editDiseaseLink(clientId, taId, disease._id)}>
                              <EditIcon /><span className="optionTag">Edit</span>
                            </Link>
                          </div>
                          <Divider />
                          {context.onlyAdmin() &&
                            <div className="optionsContainer" onClick={() => openConfirmDelete(disease)}>
                              <DeleteIcon /><span className="optionTag">Delete</span>
                            </div>}
                        </div>
                      </Popover>
                    </Fragment>}
                  <img src={DiseasesBackground}
                    className="background-single-disease"
                    alt="background"
                    onClick={() => props.history.push(reportsLink(clientId, taId, disease._id))} />

                  <Link to={reportsLink(clientId, taId, disease._id)} className="diseaseOptionsLink">
                    <span
                      className="disease-card-title"
                    >
                      {disease.name}
                    </span>
                    <Typography
                      className={classes.diseaseInfo}
                    >
                      <img src={reportsIcon} style={{ marginRight: '10px' }} alt="background" />
                        Contain 3 reports
                      </Typography>
                  </Link>
                </div>
              );
            })}

            {!diseases.length && (
              <h2 style={{ margin: "0 auto" }}>
                No Disease Found in this Category!
              </h2>
            )}
          </Grid>
        </div>
      </main>
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom />
        <div style={{ display: 'block' }}>
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
          >
            &copy; Atacana Group Inc. {moment().year()} - All Rights Reserved.
          </Typography>
        </div>
      </footer>
      <ConfirmModal
        open={confirmDelete}
        handleClose={handleConfirmClose}
        handleConfirm={handleDelete}
        action='Delete'
        title="Delete Disease"
        message="Are you sure you want to delete this disease?"
      />
    </GeneralLayout>
  );
}

export default withStyles(styles)(Diseases);
