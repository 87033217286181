import React, { useEffect } from "react";
import Modal from "./../Modal/AdvancedFilters";
import { Card } from "@material-ui/core";
// import AdvancedFiltersHeader from "./../AdvancedFiltersHeader";
import "./styless.scss";
import HandleFilters from "./../HandleFilters";
import MobileModal from "../Modal/MobileModal";
const AdvancedFilters = ({ open, handleClose }) => {
  useEffect(() => {
    return () => {};
  }, [open]);

  if(window.innerWidth < 600){
    return (
      <MobileModal 
        open={open} 
        label={'Filters'}
        handleClose={handleClose}
      >
        <HandleFilters handleClose={handleClose}></HandleFilters>
      </MobileModal>
    )
  }
  return (
    <Modal  open={open} handleClose={handleClose}>
      <Card className="filterModal">
        <HandleFilters handleClose={handleClose}></HandleFilters>
      </Card>
    </Modal>
  );
};
export default AdvancedFilters;
