export const SET_RELATED_FIELD_CLIENT = "SET_RELATED_FIELD_CLIENT";
export const SET_RELATED_FIELD_TA = "SET_RELATED_FIELD_TA";
export const SET_RELATED_FIELD_DISEASE = "SET_RELATED_FIELD_DISEASE";
export const SET_RELATED_FIELD_COMPOUND = "SET_RELATED_FIELD_COMPOUND";
export const SET_RELATED_FIELD_COMPANY = "SET_RELATED_FIELD_COMPANY";
export const SET_RELATED_FIELD_MOA = "SET_RELATED_FIELD_MOA";
export const CLEAR_RELATED_FIELD_CLIENT = "CLEAR_RELATED_FIELD_CLIENT";
export const RESET_RELATED_FIELDS = "RESET_RELATED_FIELDS";

export const SET_RELATED_FIELD_OPTION_VALUES_COMPOUND = "SET_RELATED_FIELD_OPTION_VALUES_COMPOUND";

export function setRelatedClient(clients) {
  return {
    type: SET_RELATED_FIELD_CLIENT,
    payload: clients
  };
}

export function setRelatedTA(ta) {
  return {
    type: SET_RELATED_FIELD_TA,
    payload: ta
  };
}

export function setRelatedDisease(disease) {
  return {
    type: SET_RELATED_FIELD_DISEASE,
    payload: disease
  };
}

export function setRelatedCompound(compound) {
  return {
    type: SET_RELATED_FIELD_COMPOUND,
    payload: compound
  };
}

export function setRelatedCompany(company) {
  return {
    type: SET_RELATED_FIELD_COMPANY,
    payload: company
  };
}

export function setRelatedMoa(moa) {
  return {
    type: SET_RELATED_FIELD_MOA,
    payload: moa
  };
}

export function setOptionValuesCompound(compounds) {
  return {
    type: SET_RELATED_FIELD_OPTION_VALUES_COMPOUND,
    payload: compounds
  };
}

export function clearRelatedClient() {
  return {
    type: CLEAR_RELATED_FIELD_CLIENT,
  }
}

export function resetRelatedFields() {
  return {
    type: RESET_RELATED_FIELDS,
  }
}