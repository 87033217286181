import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { toggleAuthentication } from "./../redux/app/actions";
import { AuthContext } from "../auth/AuthContext";

class Logout extends Component {
  state = { redirect: false };
  static contextType = AuthContext;

  componentDidMount() {
    this.context.logout();
    this.setState({ redirect: true });
  }
  
  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }

    return (
      <div
        style={{
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <h3>Loging Out....</h3>
      </div>
    );
  }
}

export default connect(null, { toggleAuthentication: toggleAuthentication })(
  Logout
);
