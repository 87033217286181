import { ADD_PAIR, REMOVE_PAIR, RESET, SET_ALL_PAIR, UPDATE_PAIR } from "./actions";

const initialState = {
  allPairs: [{region: 'US', companies: []}]
};

export default function pairReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_PAIR:
    {
      const allPairs = [...state.allPairs];
      allPairs.push({region: '', companies: []});
      return {...state, allPairs}
    }
    case REMOVE_PAIR: 
    {
      const allPairs = [...state.allPairs];
      console.log(allPairs);
      console.log(payload);
      allPairs.splice(payload, 1);
      return {...state, allPairs}
    }
    case UPDATE_PAIR: 
    {
      const allPairs = [...state.allPairs];
      allPairs[payload.index][payload.key] = payload.value;
      return {...state, allPairs}
    }
    case RESET: 
    {
      return {...state, allPairs: [{region: '', companies: []}]}
    }
    case SET_ALL_PAIR: 
    {
      return {...state, allPairs: payload}
    }
    
    default:
      return state;
  }
}
