import { Button, Card, CardContent, FormControl, FormHelperText } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import LockOutlined from '../../../assets/img/lockOutlined.svg'

import React, { useContext } from 'react';

import dashBg from '../../../assets/img/dashBg.png';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import './style.scss';
import { Password } from '../../common';
import { fullValidatorForSchema, passwordValidator } from '../../../helpers/utils';
import { useDispatch } from 'react-redux';
import { toggleLoading } from '../../../redux/app/actions';
import { changePassword } from '../../../service/users';
import { useSnackbar } from 'notistack';
import { AuthContext } from '../../../auth/AuthContext';

const initialValues = {
    "currPassword": "",
    "newPassword": "",
    "confirmPass": ''
}

const ChangePasswordSchema = Yup.object().shape({
    currPassword: Yup.string().required('Required'),
    newPassword: passwordValidator,
    confirmPass: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match').required('Required')
});

const ChangePassword = (props) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const {currentUser} = useContext(AuthContext)

    const handleSubmit = (values, { setSubmitting }) => {
        dispatch(toggleLoading());

        const { currPassword, newPassword } = values;

        changePassword(currPassword, newPassword, currentUser._id)
            .then(({ data }) => {
                enqueueSnackbar('Password changed successfully', { variant: 'success' })
                setTimeout(() => {
                    props.history.push('/logout');
                }, 1000);
            })
            .catch(({ response }) => {
                if (!response) {
                    enqueueSnackbar('Unexpected error occured!', { variant: 'error' })
                } else {
                    const { message } = response.data;
                    enqueueSnackbar(message, { variant: 'error' })
                }
            })
            .finally(() => {
                dispatch(toggleLoading());
                setSubmitting(false);
            })
    }

    return (
        <div className="changePassword" style={{ backgroundImage: 'url(' + dashBg + ')' }}>
            <div className="pageHeader">
                <h1 onClick={props.history.goBack}>
                    <ArrowBackIos />
                    <span className="diseases-screen-title">Change Password</span>
                </h1>
            </div>

            <section>
                <Card>
                    <CardContent className="cpContent">
                        <div className="cpTitle">
                            <img src={LockOutlined} alt="locked"/>
                            <h2>Change Password</h2>
                        </div>

                        <Formik
                            initialValues={initialValues}
                            validate={fullValidatorForSchema(ChangePasswordSchema)}
                            validationSchema={ChangePasswordSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form autoComplete="off" className="cpForm" >

                                    <Field name="currPassword">
                                        {({
                                            field,
                                            form: { touched, errors }
                                        }) => {
                                            const showError = Boolean(touched['currPassword'] && errors['currPassword']);
                                            return (
                                                <FormControl error={showError} className="authFormControl">
                                                    <label>Current password</label>
                                                    <Password
                                                        {...field}
                                                        fullWidth
                                                        placeholder="Password"
                                                        variant="outlined"
                                                        className="auth-inputs"
                                                        notched={false}
                                                    />

                                                    {showError && errors['currPassword'].map((error, index) => (
                                                        <FormHelperText key={index} style={{ color: 'red' }}>
                                                            {error}
                                                        </FormHelperText>
                                                    ))}

                                                </FormControl>

                                            )
                                        }}
                                    </Field>

                                    <Field name="newPassword">
                                        {({
                                            field,
                                            form: { touched, errors }
                                        }) => {
                                            const showError = Boolean(touched['newPassword'] && errors['newPassword']);
                                            return (
                                                <FormControl error={showError} className="authFormControl">
                                                    <label>New password</label>
                                                    <Password
                                                        {...field}
                                                        fullWidth
                                                        placeholder="New password"
                                                        className="auth-inputs"
                                                        variant="outlined"
                                                        notched={false}
                                                    />

                                                    {showError && errors['newPassword'].map((error, index) => (
                                                        <FormHelperText key={index} style={{ color: 'red' }}>
                                                            {error}
                                                        </FormHelperText>
                                                    ))}

                                                </FormControl>

                                            )
                                        }}
                                    </Field>

                                    <Field name="confirmPass">
                                        {({
                                            field, // { name, value, onChange, onBlur }
                                            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                            meta,
                                        }) => {
                                            const showError = Boolean(touched['confirmPass'] && errors['confirmPass']);
                                            return (<FormControl error={showError} className="authFormControl">
                                                <Password
                                                    {...field}
                                                    fullWidth
                                                    placeholder="Confirm new password"
                                                    variant="outlined"
                                                    className="auth-inputs"
                                                    notched={false}
                                                />

                                                {showError && <FormHelperText style={{ color: 'red' }}>
                                                    {meta.error}
                                                </FormHelperText>}

                                            </FormControl>)

                                        }}
                                    </Field>

                                    <div className="cpAction">
                                        <Button color="secondary" variant="outlined" style={{borderWidth: 2, fontWeight: 600}}>
                                            Cancel
                                        </Button>

                                        <Button type="submit" disabled={isSubmitting} color="secondary" className="btnFilled" variant="outlined">
                                            Change
                                        </Button>
                                    </div>
                                </Form>


                            )}
                        </Formik>
                    </CardContent>
                </Card>
            </section>
        </div>
    );
}

export default ChangePassword;