/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import './styles.scss';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function Compounds({
  options,
  style,
  id,
  label,
  value,
  handleMyChange,
  variant,
  loading
}) {
  const selectedIds = value ? value.map(v=>v._id) : [];
  // reset fix
  value = value || [];

  return (
    <Autocomplete
      // reset fix
      className={"customAutoComplete"}
      key={value.length ? id : id+'wrong'}
      id={id}
      options={!Boolean(loading)? options:[]}
      getOptionLabel={(option) => option.name.generic}
      renderOption={(option, {selected}) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selectedIds.includes(option._id)}
          />
          {option.name.generic}
        </React.Fragment>
      )}
      style={{ ...style }}
      defaultValue={value}
      multiple
      limitTags={2}
      onChange={(e, v)=>handleMyChange(id, v)}
      renderInput={(params) => <TextField {...params} variant={variant || "outlined"} label={label} />}
      loading={Boolean(loading)}
    />
  );
}
