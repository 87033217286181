import {
  SET_ALL_EVENTS,
  SET_MORE_EVENTS,
  ADD_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
  SET_ACTIVE_EVENT,
  SET_EVENT_LOADING,
  SET_EVENT_QUERY,
  SET_EVENTS_FILTER_OPTIONS
} from "./actions";

const initialState = {
  filterOptions: {
    loading: true
  },
  allEvents: [],
  nextPage: null,
  loading: true,
  active: null,
  query: null,
  total: 0
};

export default function eventsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ALL_EVENTS: {
      const duplicateState = { ...state };
      duplicateState.allEvents = payload.events;
      duplicateState.nextPage = payload.nextPage;
      duplicateState.total = payload.total;
      duplicateState.loading = false;
      return duplicateState;
    }
    
    case SET_MORE_EVENTS: {
      const duplicateState = { ...state };
      duplicateState.allEvents = [...state.allEvents, ...payload.events];
      duplicateState.nextPage = payload.nextPage;
      duplicateState.total = payload.total;
      duplicateState.loading = false;
      return duplicateState;
    }

    case DELETE_EVENT: {
      const allEvents = [...state.allEvents];
      const index = allEvents.findIndex(c=> c._id === payload._id);
      allEvents.splice(index, 1);
      return {...state, allEvents};
    }

    case ADD_EVENT: {
      const allEvents = [...state.allEvents];
      allEvents.splice(0, 0, payload);
      return {...state, allEvents};
    }

    case UPDATE_EVENT: {
      const allEvents = [...state.allEvents];
      const index = allEvents.findIndex(c=> c._id === payload._id);
      allEvents.splice(index, 1, payload);
      return {...state, allEvents};
    }

    case SET_ACTIVE_EVENT: {
      const duplicateState = { ...state };
      duplicateState.active = payload;
      return duplicateState
    }
    
    case SET_EVENT_LOADING: {
      const duplicateState = { ...state };
      duplicateState.loading = payload;
      return duplicateState;
    }

    case SET_EVENT_QUERY: {
      const duplicateState = { ...state };
      duplicateState.query = payload;
      return duplicateState
    }

    case SET_EVENTS_FILTER_OPTIONS: {
      const duplicateState = { ...state };
      duplicateState.query = payload;
      return duplicateState
    }

    default:
      return state;
  }
}
