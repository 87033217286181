import {
  SET_RELATED_FIELD_CLIENT,
  CLEAR_RELATED_FIELD_CLIENT,
  SET_RELATED_FIELD_TA,
  SET_RELATED_FIELD_DISEASE,
  SET_RELATED_FIELD_COMPOUND,
  SET_RELATED_FIELD_COMPANY,
  SET_RELATED_FIELD_MOA,
  RESET_RELATED_FIELDS,
  SET_RELATED_FIELD_OPTION_VALUES_COMPOUND
} from "./actions";

const initialState = {
  clients: [
    // {_id: '5f34f94fa4d764a4e356ef12', name: 'Takeda'}
  ],
  therapeuticAreas: [],
  diseases: [],
  compounds: [],
  companies: [],
  moas: [],
  optionValues: {
    compounds: {
      loading: true
    }
  }
};

export default function companiesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_RELATED_FIELD_CLIENT: {
      const duplicateState = { ...state };

      // no client
      if(!payload.length){
        duplicateState.therapeuticAreas = [];
        duplicateState.diseases = [];
      }
      duplicateState.clients = payload;
      return duplicateState;
    }
    
    case SET_RELATED_FIELD_TA: {
      const duplicateState = { ...state };

      // no TA
      if(!payload.length){
        duplicateState.diseases = [];
      }

      duplicateState.therapeuticAreas = payload;
      return duplicateState;
    }
    
    case SET_RELATED_FIELD_DISEASE: {
      const duplicateState = { ...state };
      duplicateState.diseases = payload;
      return duplicateState;
    }

    case CLEAR_RELATED_FIELD_CLIENT: {
      const duplicateState = { ...state };
      duplicateState.clients = [];
      return duplicateState;
    }

    case SET_RELATED_FIELD_COMPOUND: {
      const duplicateState = { ...state };
      duplicateState.compounds = payload;
      return duplicateState;
    }

    case SET_RELATED_FIELD_COMPANY: {
      const duplicateState = { ...state };
      duplicateState.companies = payload;
      return duplicateState;
    }

    case SET_RELATED_FIELD_MOA: {
      const duplicateState = { ...state };
      duplicateState.moas = payload;
      return duplicateState;
    }

    case SET_RELATED_FIELD_OPTION_VALUES_COMPOUND: {
      const duplicateState = { ...state };
      duplicateState.optionValues = {
        ...state.optionValues,
        compounds: payload
      };
      return duplicateState;
    }
      
    case RESET_RELATED_FIELDS: {
      return initialState;
    }
      

    default:
      return state;
  }
}
