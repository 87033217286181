import React, {useEffect, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import {makeStyles} from "@material-ui/core";
import {cloneDeep} from "lodash";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FormBase from "./FormBase";

const useStyles = makeStyles({
  root: {},
  FormControl: {
    margin: '0 35px'
  },
  labelsPlaceHolders: {
    marginLeft: '20px'
  },
  cancelIconWrapper: {
    position: 'relative',
    left: '94%',
    top: '19px',
    zIndex: '100'
  },
  addIconWrapper: {
    justifyContent: 'flex-end',
    display: 'flex'
  },
  lineSeparator: {
    textAlign: 'center',
    color: '#CCC4C4',
    fontWeight: '700'
  },
  formMargin: {
    padding: '0 2%'
  },
});

const DynamicField = ({ fields = [], onChangeValues = (values) => {} }) => {
  const [blankObject, setBlankObject] = useState({});
  const [values, setValues] = useState([]);
  const [configuration, setConfiguration] = useState([]);

  useEffect(()=>{
    /** Create the object of values */
    const obj = {};
    fields && fields.length > 0 && fields.map((field) => {
      obj[`${field.id}`] = ""
    });
    setBlankObject(obj);
    /** Set values with the blank object */
    setValues([obj]);
  },[]);

  useEffect(()=>{
    const c = cloneDeep(configuration);
    values.map((value,index) => {
      if(values && values[index]){
        /** Build configuration */
        const config = fields.map(c => {
          const id = `${c.id}`;
          return {
            ...c,
            id: id,
            name: `${c.name}`,
            onChange: (value) => {
              const v = cloneDeep(values);
              v[index][id] = value;
              setValues(v);
            },
            value: values[index][id]
          }
        });
        c[index] = config;
      }
    });
    setConfiguration(c);
    onChangeValues(values);
  },[values]);

  const addPair = () => {
    const allValues = cloneDeep(values);
    allValues.push(blankObject);
    setValues(allValues);
  };

  const removePair = index => {
    /** Delete values */
    const allValues = cloneDeep(values);
    allValues.splice(index, 1);
    setValues(allValues);
    /** And delete configuration */
    const c = cloneDeep(configuration);
    c.splice(index,1);
    setConfiguration(c);
  };

  const {
    cancelIconWrapper,
    addIconWrapper,
    root,
    formMargin
  } = useStyles();
  
  return (
    <React.Fragment>
      {/** Iterate over all fields */}
      {configuration.length > 0 && configuration.map((formComponents, indexFormComponents) => {
        return <React.Fragment key={indexFormComponents}>
          {/** Show delete button */}
          {indexFormComponents > 0 && <div className={cancelIconWrapper}>
            <IconButton
              onClick={()=>removePair(indexFormComponents)}
              color="secondary"
              aria-label="add"
              component="span">
              <CancelIcon color="secondary" />
            </IconButton>
          </div>}
          {/** Repeated fields */}
          <div className={root}>
            <FormBase formComponents={formComponents} formMargin={formMargin} />
          </div>zº
        </React.Fragment>;
      })}
      {/** Show + button */}
      <div className={addIconWrapper}>
        <IconButton onClick={addPair} color="secondary" aria-label="add" component="span">
          <AddCircleOutlineIcon />
        </IconButton>
      </div>
    </React.Fragment>
  )
};

export default DynamicField;