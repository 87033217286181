import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  stringToDate,
  minDate,
  validateDate,
  isThisDuplicate,
  collectFootnotes,
} from "../utils/utilities";
import InvalidRecords from "../InvalidRecords";
import Footnotes from "../footnotes/Footnotes";
import { extractSegment } from "../utils/mapSegment";
import { verifyColumns } from "../utils/dataService";
import ReportBase from "../groupReports/ReportBase";
import "./styles.scss";
import InvalidReport from "../groupReports/InvalidReport";

class Report3 extends Component {
  footnotesColumnName = "report-3-footnotes";
  state = { body: null, segments: null, notAvailable: [] };

  invalidHeaders = [
    "H/M/L; New; NA",
    "NCT link",
    "Segment",
    "Product name",
    "RoA",
    "Compound phase",
    "Company Name",
    "Est. Launch",
    "Duplicated",
  ];

  leftBehind = [];
  auxBody = [];
  footnotes = [];
  generalfootnotes = [];

  validation() {
    const notAvailable = verifyColumns(this.props.data);
    if (notAvailable.length) {
      this.setState({ notAvailable })
      return false;
    }
    return true;
  }

  componentDidMount() {
    const data = this.props.data;
    if (!this.validation()) return;

    const fileHeaders = data[0];
    let auxSegments = [];
    data.forEach((row, index) => {
      if (index === 0) return;
      const processedObject = row.reduce((object, cell, index) => {
        object[fileHeaders[index]] = cell;
        return object;
      }, {});

      //collecting general footnotes
      if (processedObject["report-3-general-footnotes"]) {
        this.generalfootnotes.push(
          processedObject["report-3-general-footnotes"]
        );
      }

      if (
        ["HH", "Medium"].includes(processedObject["H/M/L; New; NA"]) &&
        validateDate(processedObject["Est. Launch"])
      ) {
        const isDuplicate = isThisDuplicate(this.auxBody, processedObject);
        if (isDuplicate) {
          const oldOnesDate = stringToDate(
            this.auxBody[isDuplicate.index]["Est. Launch"]
          );
          const newOnesDate = stringToDate(processedObject["Est. Launch"]);

          // replacing product with min date
          if (minDate(oldOnesDate, newOnesDate) === newOnesDate) {
            this.auxBody[isDuplicate.index].Duplicated = "Yes";
            this.leftBehind.push(this.auxBody[isDuplicate.index]);
            this.auxBody.splice(isDuplicate.index, 1);
            this.auxBody.push(processedObject);
            return;
          }
          processedObject.Duplicated = "Yes";
          this.leftBehind.push(processedObject);
          return;
        }

        this.auxBody.push(processedObject);
        auxSegments.push(processedObject["Segment"]);
      } else {
        this.leftBehind.push(processedObject);
      }
    });

    //Distinct Footnotes
    this.footnotes = collectFootnotes(this.auxBody, this.footnotesColumnName);

    //distinct segments
    auxSegments = auxSegments.filter(
      (value, index, arr) => arr.indexOf(value) === index
    );
    auxSegments = auxSegments.sort();

    this.setState({
      body: this.auxBody,
      segments: auxSegments,
    });

    console.log("report4:", {
      body: this.auxBody,
      segments: auxSegments,
    });
    // });
  }

  render() {
    const { body, segments, notAvailable } = this.state;
    const id = "report-3";

    if (notAvailable.length) return InvalidReport({ notAvailable, ...this.props });

    return (
      <div id={id}>
        <ReportBase
          {...this.props}
          exportImg={id}
        >
          <div id="toImage">
            <div style={{ marginBottom: "1rem" }}>
              <div style={{ textAlign: "right" }}>
                <span className="dot" />{" "}
                <span>
                  Existing trial site, JPN = Japan. RoW = Rest of world{" "}
                </span>
              </div>
            </div>
            {body && (
              <table>
                <thead>
                  <tr>
                    <th>Sub-Indication</th>
                    <th>Product name</th>
                    <th>RoA</th>
                    <th>Compound Phase in Indication</th>
                    <th>Company Name</th>
                    <th>Trial Online Resource</th>
                    <th className="bg-red">US</th>
                    <th className="bg-red">EU</th>
                    <th className="bg-red">JPN</th>
                    <th className="bg-red">RoW</th>
                    <th className="bg-blue">Est. Launch (US)</th>
                  </tr>
                </thead>
                <tbody>
                  {segments.map((segment, si) => {
                    return body.map(
                      (row, index) =>
                        segment === row["Segment"] && (
                          <tr
                            key={"row-" + index}
                            className={si % 2 ? "bg-white" : ""}
                          >
                            <td>{extractSegment(row["Segment"])}</td>
                            <td>
                              {row["Product name"]}
                              {row[this.footnotesColumnName] && (
                                <span className="footnotesIndex">
                                  {row[this.footnotesColumnName]
                                    .split("|")
                                    .map((note) => {
                                      let index = -1;
                                      this.footnotes.some((n, i) => {
                                        if (n.text === note.trim()) {
                                          index = i;
                                          return true;
                                        }
                                        return false;
                                      });
                                      if (index === -1) return null;
                                      return (
                                        <span key={index}>({index + 1})</span>
                                      );
                                    })}
                                </span>
                              )}
                            </td>
                            <td>{row["RoA"]}</td>
                            <td>{row["Compound phase"]}</td>
                            <td>{row["Company Name"]}</td>
                            <td>
                              <a href={row["NCT Url"]}>{row["NCT link"]}</a>
                            </td>
                            <td className="center">
                              {row["US"] === "TRUE" ? (
                                <span className="dot" />
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="center">
                              {row["EU"] === "TRUE" ? (
                                <span className="dot" />
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="center">
                              {row["Japan"] === "TRUE" ? (
                                <span className="dot" />
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="center">
                              {row["Rest of World"] === "TRUE" ? (
                                <span className="dot" />
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <span>
                                {row["US"] !== "TRUE" &&
                                  row["Est.Launch Country"] && (
                                    <span>
                                      {row["Est.Launch Country"]}:&nbsp;
                                    </span>
                                  )}
                                <span>{getQ(row["Est. Launch"])}</span>
                              </span>
                            </td>
                          </tr>
                        )
                    );
                  })}
                </tbody>
              </table>
            )}
            {!body && (
              <SkeletonTheme color="#E2DEDE">
                <p>
                  <Skeleton height={70} />
                  <br />
                  <br />
                  <Skeleton count={5} height={50} />
                </p>
              </SkeletonTheme>
            )}
            {body && (
              <Footnotes general={this.generalfootnotes} notes={this.footnotes} />
            )}
          </div>
        </ReportBase>
        {body && (
          <InvalidRecords
            headers={this.invalidHeaders}
            totalRecord={this.props.data.length - 1}
            leftBehind={this.leftBehind.map((row) =>
              this.invalidHeaders.map((val) => row[val])
            )}
          />
        )}
      </div>
    );
  }
}

function getQ(date) {
  let dateObj = stringToDate(date);
  let month = dateObj.getMonth();
  let quarter;
  if (month < 3) quarter = 1;
  else if (month < 6) quarter = 2;
  else if (month < 9) quarter = 3;
  else if (month < 12) quarter = 4;

  return `Q${quarter} ${dateObj.getFullYear()}`;
}

export default Report3;
