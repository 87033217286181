import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ComboBox = ({
  options,
  style,
  id,
  label,
  disabled,
  value,
  handleMyChange,
  variant,
  loading,
  handleSearch
}) => {

  const objectType = options.length && typeof options[0] === 'object';
  const selectedIds = value ? value.map(v=>v._id) : [];
  // reset fix
  value = value || [];

  const onChange = (e, value, reason) => {
    if(reason === 'clear' && handleSearch){
      handleSearch();
    }
    handleMyChange(id, value);
  };

  return (
      <Autocomplete
          // reset fix
          className={"customAutoComplete"}
          key={value.length ? id : id+'wrong'}
          id={id}
          disabled={disabled}
          options={options}
          getOptionLabel={(option) => objectType ? option.name : option}
          loading={loading}
          renderOption={(option, {selected}) => (
              <React.Fragment>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={objectType ? selectedIds.includes(option._id) : value.includes(option)}
                />
                {objectType ? option.name : option}
              </React.Fragment>
          )}
          style={{ ...style }}
          defaultValue={value}
          multiple
          limitTags={2}

          onChange={onChange}
          renderInput={(params) =>
            <TextField
              {...params}
              variant={variant || "outlined"}
              label={label}
              onChange={ ev => {
                // dont fire API if the user delete or not entered anything
                if (ev.target.value !== "" || ev.target.value !== null) {
                  if (handleSearch) {
                    handleSearch(ev.target.value);
                  }
                }
              }}/>
          }
      />
  );
};
export { ComboBox };