import React, { useContext, useState } from "react";
import { connect, useDispatch } from "react-redux";
import AttachmentIcon from '@material-ui/icons/Attachment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  makeStyles,
  withStyles
} from "@material-ui/core/styles";
import DevChangesIcon from '../../assets/img/News/devchanges.svg';
import OtherUpdIcon from '../../assets/img/News/otherupd.svg';
import CliTrialResIcon from '../../assets/img/News/ctr.svg';
import CliTrialInitIcon from '../../assets/img/News/cti.svg';
import CommercialIcon from '../../assets/img/News/commercial.svg';
import RegulatoryIcon from '../../assets/img/News/regulatory.svg';
import LinkIcon from '@material-ui/icons/Link';
import { Divider } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Popover from "@material-ui/core/Popover";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import { Grid, Card } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';

import { withSnackbar } from "notistack";
import * as moment from "moment";
import Skeleton from "react-loading-skeleton";
import Moment from "react-moment";
import "./styless.scss";

import { deleteNewsById } from "../../service/news";
import { deleteNews, setExpandedNewsId } from '../../redux/news/actions'

import { EDIT_NEWS } from "../../constants/routes";
import { Link } from 'react-router-dom';
import { AuthContext } from "../../auth/AuthContext";
import ConfirmModal from "../Modal/ConfirmModal";
import { AtacanaChip, StatusIndicator } from "../common";

const DAYS_UNTIL_NEW = 7;

const TextOnlyTooltip = withStyles({
  tooltip: {
    fontSize: '15px'
  }
})(Tooltip);

const useStyles = makeStyles(theme => ({
  statusIndicator: {
    marginLeft: '10px',
    marginRight: '10px'
  },
  visibleContent: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      maxHeight: '210px'
    },
  },
}));

const ShowNews = ({ data, loading, enqueueSnackbar, expandedNewsId, ...props }) => {
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [activeNews, setActiveNews] = useState(null);
  const [popoverId, setPopoverId] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const { isAdmin } = useContext(AuthContext);
  const classes = useStyles();

  const handleNewOpen = id => {
    expandedNewsId === id ?
      dispatch(setExpandedNewsId(null)) :
      dispatch(setExpandedNewsId(id));
  };

  const getImageByName = taName => {
    switch (taName) {
      case "Regulatory":
        return RegulatoryIcon
      case "Commercial":
        return CommercialIcon;
      case "Development Needs":
        return DevChangesIcon;
      case "Others":
        return OtherUpdIcon;
      case "Trial Results":
        return CliTrialResIcon;
      case "Trial Initiation":
        return CliTrialInitIcon;
      default:
        return DevChangesIcon;
    }
  }

  const handlePopoverOpen = ({ target }, { _id }) => {
    setPopoverId(_id);
    setAnchorEl(target);
  };

  const handlePopoverClose = () => {
    setPopoverId(null);
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      await deleteNewsById(activeNews._id)
      dispatch(deleteNews(activeNews))
      enqueueSnackbar("News Deleted", {
        variant: "success"
      });
    } catch({ response }) {
        if (response) {
          const { message } = response.data;
          enqueueSnackbar(message, {
            variant: "error"
          });
        } else {
          enqueueSnackbar("Eror occured while deleting news.", {
            variant: "error"
          });
        }
      }
  }

  const renderSources = ({ sources }) => {
    if (sources[0] && sources[0].link) {
      return (
        <div className="sources-wrapper">
          <span className="sources-legend">
            Sources
          </span>
          {sources.map(({ name, link }, index) => (
            link && (
              <div key={index} className="sourcelink-icon-wrapper">
                <LinkIcon color="secondary" className="link-icon" />
                <TextOnlyTooltip title={link} placement="right">
                  <a target="_blank" rel="noopener noreferrer" href={link} className="source-name">{name ? name : link}</a>
                </TextOnlyTooltip>
              </div>
            )
          ))}
        </div>
      );
    }
  }


  // const getNewsObject = (cat) => {
  //   let news = NEWS_CATEGORY.filter((elm) => {
  //     if (elm.category === cat) return elm;
  //   });
  //   if (news[0]) return news[0];
  //   else
  //     return {
  //       category: "Development Changes",
  //       show: "Development Changes",
  //       img: "devChangeIcon.png",
  //       color: "#3562aa",
  //     };
  // };

  if (loading) {
    return (
      <Grid container className="newsContainer">
        <div className="news-loader">
          <Skeleton width={"100%"} count={2} height={"25vh"} />
        </div>
      </Grid>
    )
  }

  if (!data.length) {
    return (
      <Grid container className="newsContainer">
        <h4 style={{ textAlign: 'center', padding: '40px 0px' }}>No news found with current filters!</h4>
      </Grid>
    )
  }

  const isNew = (news) => {
    return moment().diff(moment.unix(news.publishedAt),'days') <= DAYS_UNTIL_NEW;
  };

  return (
    <Grid container className="newsContainer">
      {data.map((news, i) => {
        const categoryName = news.categories[0];
        return (
          <Grid container className="newsRow" key={news._id}>
            <Card className="rowcard">
              <div className="news-category title-date-wrapper">
                <div className="icon-title-wrapper">
                  <TextOnlyTooltip title={categoryName} placement="right">
                    <img
                      src={getImageByName(categoryName)}
                      className="icon-title"
                      alt="news category"
                    />
                  </TextOnlyTooltip>
                  {isNew(news)?
                      <AtacanaChip
                          text="New"
                      />:null}
                </div>
                <div className="date-more-wrapper">
                  <Grid className="news-date" item>
                    {news.publishedAt &&
                      <Moment format="DD/MM/YYYY" unix>{news.publishedAt}</Moment>}
                  </Grid>
                  <StatusIndicator className={classes.statusIndicator} priorNum={news.priority} />
                  {isAdmin() && <MoreVertIcon onClick={e => handlePopoverOpen(e, news)} fontSize="large" color="secondary" />}
                </div>
              </div>
              <h5 className="news-title">
                {news.title}
              </h5>

              <Popover
                open={popoverId === news._id}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: -70,
                }}
              >
                <div className="optionsPopover">
                  <Link to={`${EDIT_NEWS}/${news._id}`}>
                    <div className="optionsContainer">
                      <EditIcon /><span className="optionTag">Edit</span>
                    </div>
                  </Link>
                  <Divider />
                  <div className="optionsContainer" onClick={() => {
                    handlePopoverClose();
                    setActiveNews(news);
                    setDeleteConfirm(true);
                  }}>
                    <DeleteIcon /><span className="optionTag">Delete</span>
                  </div>
                </div>
              </Popover>

              <div className="compound-icon-wrapper">
                <Grid item xs={12} className="diseases">
                  {news.therapeuticArea.name} - {news.disease.name}
                </Grid>
                <div className={expandedNewsId === news._id ? 'seen-icon-disabled-wrapper' : 'seen-icon-wrapper'}>
                  5<VisibilityIcon style={{ margin: '0 5px' }} />
                </div>
              </div>
              <Grid item xs={12} className="compounds-companies-priority">
                {news.companies.map(c => c.name).join(', ')} - {news.compounds.map(c => c.name.generic).join(', ')}
              </Grid>

              <div className="card-footer-xs">
                <div className="news-date-xs">
                  {news.publishedAt &&
                  <Moment format="DD/MM/YYYY" unix>{news.publishedAt}</Moment>}
                </div>
              </div>

              <div style={{overflow: 'hidden'}} className={expandedNewsId !== news._id && news.analystCommentary? 'content-comentary-visible-wrapper' : 'content-comentary-hidden-wrapper'}>
                <Grid container className={"second shownews "+classes.visibleContent}>
                  <div dangerouslySetInnerHTML={{ __html: news.analystCommentary }} />
                </Grid>
              </div>
              <div className={expandedNewsId === news._id ? 'content-comentary-visible-wrapper' : 'content-comentary-hidden-wrapper'}>
                <span className="line"></span>
                <Grid container className="second shownews">
                  <div dangerouslySetInnerHTML={{ __html: news.content }} />
                </Grid>
                <span className="line-short"></span>
                {news.analystCommentary && <div className="comentary" dangerouslySetInnerHTML={{ __html: news.analystCommentary }} />}
                <div className="attachments-wrapper">
                  {news.attachments && <div className="attachments-legend">
                    Attachments
                  </div>}
                  {news.attachments && news.attachments.map(({ name, link }) => (
                    <div className="attachment-wrapper" key={name}>
                      <AttachmentIcon className="attachment-icon" />
                      <a className="attachment-name" href={link}>{name}</a>
                    </div>
                  ))}
                </div>
                {renderSources(news)}
              </div>
            </Card>
            <div className="expand-icon-wapper">
              <IconButton onClick={() => handleNewOpen(news._id)}>
                {/* <IconButton onClick={MakeNewVisible}> */}
                {expandedNewsId === news._id ?
                  <ExpandLessIcon className="expand-icon" /> :
                  <ExpandMoreOutlinedIcon className="expand-icon" />}
              </IconButton>
            </div>
          </Grid>
        );
      })}

      <ConfirmModal
        // icon={PublishNewsScreenIcon}
        title="Delete News"
        message="Are you sure you want to delete this news?"
        action="Delete"
        open={deleteConfirm}
        handleClose={() => setDeleteConfirm(false)}
        handleConfirm={handleDelete}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const { catContent, dateFilter, loading, expandedNewsId } = state.news;
  return {
    loading,
    catContent,
    dateFilter,
    expandedNewsId
  };
};



export default connect(mapStateToProps, {})(withSnackbar(ShowNews));
