import React, {useEffect, useState} from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import { Button } from '@material-ui/core';
import { AddCircle, Edit, DeleteOutline, VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Switch from "@material-ui/core/Switch";

import './styles.scss';
import { ADMINISTRATION, COMPANY } from '../../../constants/routes';
import AdminBase from "./AdminBase";
import GenericTable from "../../Table/genericTable";
import PublishNewsScreenIcon from "../../../assets/img/News/draftScreenIcon.svg";
import ConfirmModal from "../../Modal/ConfirmModal";
import { deleteCompanyById, editCompany, getAllCompany } from '../../../service/companies';
import { withSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCompany, setActiveCompany, setCompanyQuery, updateCompany } from '../../../redux/companies/actions';

const Company = ({enqueueSnackbar, ...props}) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const {loading, allCompanies, total} = useSelector((state)=> state.companies);

  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  useEffect(()=>{
    dispatch(setCompanyQuery(null))
    loadCompanies();
  }, [])

  const loadCompanies = () => {
    getAllCompany()
      .then((data)=>{})
      .catch((error)=>{
        console.error(error);
        const {response} = error;
        if(response){
          enqueueSnackbar(response.data.message, {variant: 'error'})
        }else
          enqueueSnackbar("Unexpected error occured while getting companies!", {variant: 'error'})
      })
  }

  const getNext100 = async () => {
    return getAllCompany(true);
  }

  const handleDelete = async () => {
    try {
      await deleteCompanyById(toBeDeleted._id);
      dispatch(deleteCompany(toBeDeleted));
      setToBeDeleted(null);
      enqueueSnackbar("Company Deleted", {variant: 'success'});
    }
    catch({response}) {
      if(response)
        enqueueSnackbar(response.data.message, {variant: 'error'})
      else
        enqueueSnackbar("Unexpected error occured while deleting a company!", {variant: 'error'})
    }
  };

  const handleSearch = (text) => {
    dispatch(setCompanyQuery(text))
    loadCompanies();
  };

  const headerRightComponent = (
    <Link to={`${ADMINISTRATION}/${COMPANY}/add`} >
      <Button>
        <AddCircle fontSize="default" color="secondary" />
        <span style={{ textTransform: 'initial', marginLeft: '2px' }}>
          Add Company
        </span>
      </Button>
    </Link>
  );
  const titleComponent = (
    <h2>Company List</h2>
  );
  const columns = [
    {
      key: 'name',
      name: 'Company',
      showOnMdUp: true,
    },
    {
      key: 'type',
      name: 'Company Type',
      showOnMdUp: true
    },
    {
      key: 'logo',
      name: 'Logo',
      renderRow: (row) => {
        if(row.logo) return (
          <a className="generic_table_logos" target="blank" href={row.logo}>
            <img src={row.logo} alt={row.name}/>
          </a>
        )
      }
    },
    {
      key: 'headquarters',
      name: 'Company Headquarters'
    },
    {
      key: 'parentCompany.name',
      name: 'Parent Company'
    },
  ];
  const rowActions = [
    {
      component: (
          <>
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Edit</Typography>
          </>
      ),
      onClick: (row) => {
        dispatch(setActiveCompany(row));
        props.history.push(`${path}/edit/${row._id}`);
      }
    },
    {
      component: (
          <>
            <ListItemIcon>
              <DeleteOutline fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Delete</Typography>
          </>
      ),
      onClick: (row) => {
        setToBeDeleted(row);
        setShowDeleteConfirm(true);
      }
    },
    {
      component: (row) => (
        <>
          <Typography variant="inherit">Status</Typography>
          <Switch
              checked={row.status === 'active'}
              onChange={(event) => {
                row.status = event.target.checked? 'active':'inactive';
                const { _id, ...rest} = row ;
                const payload = {...rest};
                if(payload.parentCompany) payload.parentCompany = payload.parentCompany._id;
                console.log(payload)
                editCompany(_id, payload)
                  .then(({data}) => {
                    dispatch(updateCompany(data))
                    enqueueSnackbar(`Company ${row.status}!`, {
                      variant: "success",
                    });
                  })
                  .catch(({ response }) => {
                    console.log(response)
                    if (response) {
                      enqueueSnackbar(response.data.message, {
                        variant: "error",
                      });
                    } else {
                      enqueueSnackbar(`Error occurred`, {
                        variant: "error",
                      });
                    }
                  })
              }}
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          {row.status === 'active' ? 
            <VisibilityOutlined fontSize="small" /> :
            <VisibilityOffOutlined fontSize="small" />
          }
        </>
    ),
    }
  ];
  return (
    <AdminBase headerRightComponent={headerRightComponent}
        titleComponent={titleComponent}
        handleSearch={handleSearch}
        handleResetSearch={()=>handleSearch('')}
        showFilters={true}
        showSearchBox={true}>
      <GenericTable
          columns={columns}
          data={allCompanies}
          loading={loading}
          rowActions={rowActions}
          pagination={{
            loadMore: getNext100,
            total
          }}
          />

      <ConfirmModal
          icon={PublishNewsScreenIcon}
          title="Delete Company"
          message="Are you sure you want to delete this company?"
          action="Delete"
          open={showDeleteConfirm}
          handleClose={() => setShowDeleteConfirm(false)}
          handleConfirm={handleDelete}
      />
    </AdminBase>
  );
}

export default withSnackbar(Company);