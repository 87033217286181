export const ADD_PAIR = "ADD_PAIR";
export const REMOVE_PAIR = "REMOVE_PAIR";
export const SET_ALL_PAIR = "SET_ALL_PAIR";
export const RESET = "RESET";
export const UPDATE_PAIR = "UPDATE_PAIR";


export function addPair() {
  return {
    type: ADD_PAIR
  };
}

export function removePair(index) {
  return {
    type: REMOVE_PAIR,
    payload: index
  };
}

export function resetPairs() {
  return {
    type: RESET,
  };
}

export function setAllPair(allPair) {
  return {
    type: SET_ALL_PAIR,
    payload: allPair
  };
}

export function updatePair(payload) {
  return {
    type: UPDATE_PAIR,
    payload
  };
}