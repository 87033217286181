import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import {withSnackbar} from 'notistack';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import {ChevronLeft} from '@material-ui/icons';

import AddClientImgIcon from '../../../assets/img/addClientIcon.svg';
import FormBase from './FormBase';
import AdminBase from './AdminBase';
import { uploadFile } from '../../../service/files';
import { toggleLoading } from '../../../redux/app/actions';
import { createClient, editClient } from "../../../service/clients";
import { addClient, updateClient } from '../../../redux/clients/actions';

const ClientAdd = ({ enqueueSnackbar, dispatchToggleLoading, activeClient, ...props }) => {

  const [clientName, setClientName] = useState('');
  const [uploading, setUploading] = useState(false);
  const [name, setName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [title,setTitle] = useState('');
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState(true);

  const [isValid, setValidity] = useState(false);

  const { clientId } = useParams();

  const goBack = () => {
    props.history.goBack();
  };

  useEffect(() => {
    if (clientId) {
      activeClient ? initExisting(activeClient) : goBack();
    }
  }, []);

  const initExisting = (client) => {
    const getContactFromKey = (key) => (client.contacts && client.contacts.length) && client.contacts[0][key] ? client.contacts[0][key]  : '';
    setClientName(client.name);
    setName(getContactFromKey('name'));
    setEmailAddress(getContactFromKey('email'));
    setTitle(getContactFromKey('title'));
    setStatus(client.status === 'active' ? true : false);
    if (client.logo) setFiles([{ name: 'logo', link: client.logo }])
  };

  const admTitleComponent = (
    <Link to= "#" onClick={goBack}>
      <h2><ChevronLeft /> Add Client</h2>
    </Link>
  );

  const formComponents = [
    {
      id: 'name',
      name: 'name',
      title: 'Name',
      required: true,
      placeHolder: "Client Name",
      type: 'input',
      onChange: clientName => setClientName(clientName),
      key: 'client_name',
      value: clientName,
      sm: 12
    },
    {
      title: "Client Logo",
      placeHolder: "Choose file to upload",
      type: 'fileUploader',
      onChange: e => handleFileChange(e),
      id: "file",
      name: "file",
      uploadStatus: uploading,
      files: files,
      onClick: index => deleteFile(index),
      sm: 12
    },
    {
      title: 'Contact',
      placeHolder: 'Name',
      type: 'input',
      onChange: name => setName(name),
      key: 'name',
      value: name,
      sm: 12
    },
    {
      placeHolder: 'Email Address',
      type: 'input',
      onChange: emailaddr => setEmailAddress(emailaddr),
      key: 'email',
      value: emailAddress,
      sm: 12
    },
    {
      placeHolder: 'Title',
      type: 'input',
      onChange: title => setTitle(title),
      key: 'title',
      value: title,
      sm: 12
    },
    {
      type: 'switch',
      label: 'Status',
      onChange: e => handleStatusChange(e),
      value: status,
      name: 'status',
      render: () => renderStatus(),
      sm: 12
    }
  ];

  const handleFileChange = ({ target }) => {
    const { files } = target;
    if (!files.length) return;

    const formData = new FormData();
    const tempState = [];
    for (let f of files) {
      tempState.push({ name: f.name, link: '' });
      formData.append('file', f)
    }
    setFiles(tempState);
    setUploading(true);

    uploadFile(formData)
      .then(({ data }) => {
        // console.log(data);
        tempState.forEach((f, i) => f.link = data[i]);
        setFiles(tempState);
        setUploading(false)
      })
      .catch(({ response }) => {
        if (!response) {
          enqueueSnackbar('Unexpected error occured!', { variant: 'error' })
        } else {
          const { message } = response.data;
          enqueueSnackbar(message, { variant: 'error' })
        }
      })
  };

  const deleteFile = (index) => {
    const tempFiles = [...files];
    tempFiles.splice(index, 1);
    setFiles(tempFiles);
  }

  const renderStatus = () => {
    const customStyle = { margin: '7px' }
    return status ?
      <VisibilityOutlinedIcon style={customStyle} color="action" /> :
      <VisibilityOffOutlinedIcon style={customStyle} color="action" />;
  }

  const handleStatusChange = ({ target }) => {
    setStatus(target.checked);
  };

  const buttons = [
    {
      onClick:  () => handleCancelClick(),
      variant: 'outlined',
      text: 'cancel'
    },
    {
      variant: 'contained',
      type: 'submit',
      text: clientId ? 'update' : 'add',
      disabled: !isValid
    },
  ];

  const handleCancelClick = () => {
    goBack();
  };

  const handleClientAdd = () => {
    dispatchToggleLoading();

    const client = buildClient();

    createClient(client, (data) => {
      dispatchToggleLoading();
      props.dispatchAddClient(data.client);
      enqueueSnackbar("Client Added!", {
        variant: "success",
      });
      goBack();
    }, ({ response }) => {
      dispatchToggleLoading();
      if (response) {
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(`Error occurred while creating the client.`, {
          variant: "error",
        });
      }
    })
  };

  const handleClientUpdate = () => {
    dispatchToggleLoading();
    const client = buildClient();
    editClient(clientId, client, (data) => {
          props.dispatchUpdateClient(data.client);
          enqueueSnackbar("Client updated!", {
            variant: "success",
          });
          goBack();
        }, ({ response }) => {
          if (response) {
            enqueueSnackbar(response.data.message, {
              variant: "error",
            });
          } else {
            enqueueSnackbar(`Error occurred while editing the client.`, {
              variant: "error",
            });
          }
        })
        .finally(() => {
          dispatchToggleLoading();
        })
  };

  const buildClient = () => {
    const client = {contacts: [{}]};
    formComponents.forEach((input, index) => {
      //TODO() improve this -> set nested values
      const value = input.value;
      if(input.key === 'client_name'){
        client['name'] = value;
      } else{
        client.contacts[0][input.key] = value;
      }
    });
    client.status = formComponents.value? 'active':'inactive';
    client.logo = formComponents[1].files.length ? formComponents[1].files[0].link : "";
    return client;
  };
  
  return (
    
    <AdminBase
      titleComponent={admTitleComponent}
      showFilters={false}
      showSearchBox={false}
    >
      <FormBase
        title={`${clientId ? 'Update the' : 'Add a New'}  Client`}
        onSubmit={clientId ? handleClientUpdate : handleClientAdd}
        iconImage={AddClientImgIcon}
        formComponents={formComponents}
        buttons={buttons}
        onChangeValidity={setValidity}
      />
    </AdminBase>
  )
};

const mapStateToProps = (state) => {
  const { active } = state.clients;
  return { activeClient: active };
};

const mapActionsToProps = {
  dispatchToggleLoading: toggleLoading,
  dispatchAddClient: addClient,
  dispatchUpdateClient: updateClient
};

export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(ClientAdd));