import React, { useState, useContext } from "react";
import { Button } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withSnackbar } from "notistack";
import { SaveAltRounded } from "@material-ui/icons";
import FileSaver from "file-saver";

import './news.scss';

import {
  setFilters,
  setCantContent,
  setFilteredNews,
  setLoading,
  setSearchTerms
} from "./../../../redux/news/actions";
import DraftsIcon from "../../../assets/img/News/draftsIcon.png";
import { ADD_NEWS, DRAFTS_NEWS } from "../../../constants/routes";
import { AuthContext } from "../../../auth/AuthContext";
import AdvancedFilters from "../../AdvancedFilters";
import { exportNews } from "../../../service/news";

const NewsFilter = (props) => {
  const { isAdmin } = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [downloading, setDownloading] = useState(false)

  // geting the disease id when we are inside the disease report
  const { dId } = useParams();

  // sending the disease id incase we are already inside the disease report.
  const toDraft = () => {
    return dId ? `${DRAFTS_NEWS}/${dId}` : DRAFTS_NEWS;
  };

  const closeAdvance = () => {
    setOpen(false);
  };

  // dd/mm/yyyy format
  const formatDate = (dateString) => {
    if (!dateString) return null;
    //params format yyyy-mm-dd
    const date = dateString.split('-');
    return `${date[2]}/${date[1]}/${date[0]}`
  };

  const initiateDownload = () => {
    const { client } = props.allFilters;
    if (!client) {
      props.enqueueSnackbar("No Client Selected!", { variant: 'warning' });
      return;
    }
    

    setDownloading(true);

    exportNews()
      .then((response) => {
        const {data, headers} = response;
        const nameString = headers['content-disposition'];
        const fileName = nameString ? nameString.split('"')[1] : 'News.pptx';
        const blob = new Blob([data], {type: headers['content-type']})
        FileSaver.saveAs( blob, fileName );
      })
      .catch(error => {
        console.log(error)
        const {response} = error;
        if (response && response.data)
          props.enqueueSnackbar(response.data.message, { variant: 'error' });
        else
          props.enqueueSnackbar("Unexpected Error occured!", { variant: 'error' })
      })
      .finally(() => {
        setDownloading(false);
      })
  }

  return (
    <React.Fragment>
      <div style={{ width: '100%' }}>
        <Button disabled={downloading} onClick={initiateDownload}>
          <SaveAltRounded fontSize="default" color="secondary" />
          <span style={{ textTransform: 'initial', marginLeft: '2px' }}>
              Download News
            </span>
        </Button>

        {isAdmin() &&
          <Link id="addNewsButton" to={toDraft()}>
            <img src={DraftsIcon} className="drafts-icon" alt="draft" />
            <span className="description-button">&nbsp;&nbsp;Drafts</span>
          </Link>
        }

        {isAdmin() &&
          <Button id="addNewsButton">
            <Link to={ADD_NEWS}>
              <AddCircleIcon fontSize="default" color="secondary" />
              <span className="description-button">&nbsp;Add News</span>
            </Link>
          </Button>
        }
      </div>

      {props.allFilters.startDate &&
        <span id="date-range-info">
          From: {formatDate(props.allFilters.startDate)} &nbsp;
          To: {formatDate(props.allFilters.endDate)}
        </span>}

      <AdvancedFilters
        open={open}
        handleClose={closeAdvance}
      ></AdvancedFilters>

    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  const {
    filtered,
    cantOfFilters,
    catContent,
    dateFilter,
    allNews,
    allFilters
  } = state.news;

  return {
    filtered,
    catContent,
    allNews,
    dateFilter,
    allFilters,
    cantOfFilters
  };
};

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    {
      dispatchSetFilteredNews: setFilteredNews,
      dispatchSetFilters: setFilters,
      dispatchSetCantContent: setCantContent,
      dispatchSetLoading: setLoading,
      dispatchSetSearchTerms: setSearchTerms
    },
    dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(NewsFilter));
