import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import {ADMINISTRATION, CLIENT, COMPANY, COMPOUND, KEYWORD, USERS, EVENTS, TRIAL} from '../../../constants/routes';
import { AuthContext } from '../../../auth/AuthContext';
import HorizontalScrollItems from "../../common/HorizontalScrollItems";

const AdministrationNav = () => {
    const { onlyAdmin } = useContext(AuthContext);

    const elements = [
        <NavLink activeClassName="menu active" to={`${ADMINISTRATION}/${COMPANY}`}>
            <button className="atButton medium">Companies</button>
        </NavLink>,
        <NavLink activeClassName="menu active" to={`${ADMINISTRATION}/${COMPOUND}`}>
            <button className="atButton medium">Compounds</button>
        </NavLink>,
        <NavLink activeClassName="menu active" to={`${ADMINISTRATION}/${KEYWORD}`}>
            <button className="atButton medium">Keyword</button>
        </NavLink>,
        <NavLink activeClassName="menu active" to={`${ADMINISTRATION}/${USERS}`}>
            <button className="atButton medium">Users</button>
        </NavLink>,
        <NavLink activeClassName="menu active" to={`${ADMINISTRATION}/${EVENTS}`}>
            <button className="atButton medium">Events</button>
        </NavLink>,
        /*<NavLink activeClassName="menu active" to={`${ADMINISTRATION}/${TRIAL}`}>
            <button className="atButton medium">Trials</button>
        </NavLink>*/
    ];
    onlyAdmin() && elements.unshift(<NavLink activeClassName="menu active" to={`${ADMINISTRATION}/${CLIENT}`}>
        <button className="atButton medium">Clients</button>
    </NavLink>);

    return (
      <div className="scrollable-nav"><HorizontalScrollItems elements={elements} /></div>
    );
}

export default AdministrationNav;