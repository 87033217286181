import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setFilteredNews,
  setCantFilters,
  setFilters,
  resetFilters,
  setLoading
} from "./../../redux/news/actions";
import { searchNews } from "./../../service/news";
import {  Grid, CircularProgress } from "@material-ui/core";
import { ComboBox, RelatedFields } from "../common";
import { withSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { setRelatedDisease, setRelatedTA } from "../../redux/relatedFields/actions";

const HandleFilters = ({
  filterOptions,
  allFilters,
  dispatchSetFilteredNews,
  dispatchSetCantFilters,
  dispatchResetFilters,
  dispatchSetFilters,
  dispatchSetLoading,
  permittedDiseases,
  handleClose,
  ...props
}) => {
  const [filters, setFilters] = useState({});

  const {dId} = useParams();
  const disableIndication = Boolean(dId);

  // geting the disease id when we are inside the disease report

  const handleSelectChange = (id, value) => {
    let aux = filters;
    aux[id] = value;
    // console.log(aux)
    setFilters({ ...aux });
  };

  useEffect(() => {
    setFilters({ ...allFilters });
  }, []);

  const handleReset = (e) => {
    e.preventDefault();
    if(disableIndication){
      const {diseases} = allFilters;
      setFilters({diseases});
      dispatchResetFilters(diseases);
    }else {
      props.dispatchSetRelatedTA([]);
      props.dispatchSetRelatedDisease([]);
      setFilters({});
      dispatchResetFilters();
    }
    dispatchSetLoading(true);
    searchNews(dispatchSetFilteredNews, callbackError);
    handleClose()
  };

  const callbackError = (err, variant) => {
    props.enqueueSnackbar(err, variant);
  };

  const handleFilter = (e) => {
    e.preventDefault();
    dispatchSetFilters({...filters,...props.relatedFields});
    dispatchSetLoading(true);
    searchNews(dispatchSetFilteredNews, callbackError)
    handleClose()
  };

  if (filterOptions.loading) {
    return (
      <CircularProgress color="secondary"></CircularProgress>
    )
  }

  return (

    <form onSubmit={handleFilter}>
      <Grid
        container
        className="filterContainer"
        spacing={3}
      >
        <Grid item xs={12}>
          <RelatedFields config={{
            client: {disabled: true},
            therapeuticArea: {multiple: true, disabled: disableIndication},
            disease: {multiple: true, disabled: disableIndication},
            compound: {multiple: true, options: filterOptions.compounds},
            company: {multiple: true, options: filterOptions.companies},
            moa: {multiple: true, options: filterOptions.mechanismOfActions},
          }}/>
        </Grid>

        <Grid item xs={12}>
          <ComboBox
              //className="News Category"
              id="newsPriority"
              options={filterOptions.compoundPriority}
              value={filters.newsPriority || []}
              fullWidth
              handleMyChange={handleSelectChange}
              style={{ width: "100%" }}
              label="News Priority"
          />
        </Grid>

        <Grid item xs={12}>
          <ComboBox
              //className="News Category"
              id="compoundPriority"
              options={[
                { _id: 1, name: 'High' },
                { _id: 2, name: 'Medium' },
                { _id: 3, name: 'Low' },
              ]}
              value={filters.compoundPriority || []}
              fullWidth
              handleMyChange={handleSelectChange}
              style={{ width: "100%" }}
              label="Compound Priority"
          />
        </Grid>
        <Grid item xs={12}>
          <ComboBox
            className="News Category"
            id="categories"
            options={filterOptions.categories}
            value={filters.categories || []}
            fullWidth
            handleMyChange={handleSelectChange}
            style={{ width: "100%" }}
            label="Categories"
          />
        </Grid>

        <Grid container>

          <Grid item xs={12} container spacing={3} className="filterActions">
            <Grid item>
              <button onClick={handleClose} className="modalButton fill">
                Cancel
                </button>
            </Grid>
            <Grid item>
              <button onClick={handleReset} className="modalButton">
                Reset
                </button>
            </Grid>
            <Grid item>
              <button type="submit" className="modalButton fill">
                Apply
                </button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>

  );
};
const mapStateToProps = (state) => {
  const {
    filtered,
    filterOptions,
    allFilters,
  } = state.news;

  const {
    therapeuticAreas,
    diseases,
    compounds,
    companies,
    moas
  } = state.relatedFields;

  const permittedDiseases = []
  state.therapeutics.permissions.forEach(p => {
    p.diseases.forEach(({_id, name})=>{
      if(_id && name) permittedDiseases.push({_id, name})
    })
  });

  // console.log(permittedDiseases)
  return {
    filtered,
    filterOptions,
    allFilters,
    permittedDiseases,
    relatedFields: {
      therapeuticAreas,
      diseases,
      compounds,
      companies,
      mechanismOfActions: moas
    }
  };
};

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    {
      dispatchSetFilteredNews: setFilteredNews,
      dispatchSetCantFilters: setCantFilters,
      dispatchSetFilters: setFilters,
      dispatchResetFilters: resetFilters,
      dispatchSetLoading: setLoading,
      dispatchSetRelatedTA: setRelatedTA,
      dispatchSetRelatedDisease: setRelatedDisease
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(HandleFilters));
