import {
  SET_ALL_CLIENTS,
  ADD_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  SET_ACTIVE_CLIENT
} from "./actions";

const initialState = {
  allClients: [],
  loading: true
};

export default function companiesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ALL_CLIENTS: {
      const duplicateState = { ...state };
      duplicateState.allClients = payload;
      duplicateState.loading = false;
      return duplicateState;
    }

    case DELETE_CLIENT: {
      const allClients = [...state.allClients];
      const index = allClients.findIndex(c=> c._id === payload._id);
      allClients.splice(index, 1);
      return {allClients};
    }

    case ADD_CLIENT: {
      const allClients = [...state.allClients];
      allClients.splice(0, 0, payload);
      return {allClients};
    }

    case UPDATE_CLIENT: {
      const allClients = [...state.allClients];
      const index = allClients.findIndex(c=> c._id === payload._id);
      allClients.splice(index, 1, payload);
      return {allClients};
    }

    case SET_ACTIVE_CLIENT: {
      const duplicateState = { ...state };
      duplicateState.active = payload;
      return duplicateState
    }

    default:
      return state;
  }
}
