export const SET_ALL_TRIALS = "SET_ALL_TRIALS";
export const SET_MORE_TRIALS = "SET_MORE_TRIALS";
export const ADD_TRIAL = "ADD_TRIAL";
export const DELETE_TRIAL = "DELETE_TRIAL";
export const UPDATE_TRIAL = "UPDATE_TRIAL";
export const SET_ACTIVE_TRIAL = "ACTIVE_TRIAL";
export const SET_TRIAL_LOADING = 'SET_TRIAL_LOADING';
export const SET_TRIAL_QUERY = "SET_TRIAL_QUERY";
export const SET_TRIALS_FILTER_OPTIONS = "SET_TRIALS_FILTER_OPTIONS";

export function setAllTrials(data) {
  return {
    type: SET_ALL_TRIALS,
    payload: data
  };
}

export function setMoreTrials(data) {
  return {
    type: SET_MORE_TRIALS,
    payload: data
  };
}

export function addTrial(event) {
  return {
    type: ADD_TRIAL,
    payload: event
  };
}

export function deleteTrial(event) {
  return {
    type: DELETE_TRIAL,
    payload: event
  };
}

export function updateTrial(event) {
  return {
    type: UPDATE_TRIAL,
    payload: event
  };
}

export function setActiveTrial(event) {
  return {
    type: SET_ACTIVE_TRIAL,
    payload: event
  };
}

export function setTrialLoading(loading) {
  return {
    type: SET_TRIAL_LOADING,
    payload: loading
  };
}

export function setTrialQuery(query) {
  return {
    type: SET_TRIAL_QUERY,
    payload: query
  };
}

export const setFiltersOptions = (filterOptions) => {
    return {
      type: SET_TRIALS_FILTER_OPTIONS,
      payload: filterOptions,
    };
}