import { combineReducers } from "redux";
import appReducer from "./app";
import news from "./news";
import users from './users';
import layout from './layout';
import companies from './companies';
import therapeutics from './therapeutic';
import clients from './clients';
import compounds from './compounds';
import keywords from './keywords';
import licensee from './licenseePair';
import relatedFields from './relatedFields';
import events from './events';
import trials from './trials';

const rootReducer = combineReducers({
  app: appReducer,
  news: news,
  users: users,
  layout: layout,
  companies,
  therapeutics: therapeutics,
  clients,
  compounds,
  licensee,
  keywords,
  relatedFields,
  events,
  trials
});

export default rootReducer;
