import React, {Fragment, useEffect} from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {withSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";

import { ComboBoxSimple } from './ComboBoxSimple';
import {
  clearRelatedClient,
  setRelatedClient,
  setRelatedDisease,
  setRelatedTA,
  setRelatedCompound,
  setRelatedCompany,
  setRelatedMoa,
  resetRelatedFields, setOptionValuesCompound
} from '../../redux/relatedFields/actions';
import { Compounds } from "../Compounds";
import {searchCompounds} from "../../service/compounds";

const RelatedFieldsExport = ({ config, permitted, ...props }) => {

  const { client, therapeuticArea, disease, compound, company, moa } = config;
  const { clients, therapeuticAreas, diseases, compounds, companies, moas, optionValues } = props.fields;
  
  const permittedClients = permitted.map(({_id, name})=>{
    return {_id, name}
  });

  useEffect(() => {
    // when compound is configured, but it haven't options associated
    if(mustGetCompoundOptionsFromServer()){
      getCompounds();
    }
  },[clients,therapeuticAreas,diseases]);

  const mustGetCompoundOptionsFromServer = () => {
    return compound && (!compound.options || !compound.options.length)
      && Boolean(clients && clients.length && clients[0])
      && Boolean(therapeuticAreas && therapeuticAreas.length && therapeuticAreas[0])
      && Boolean(diseases && diseases.length && diseases[0]);
  };

  const getCompounds = () => {
    console.log(diseases, Boolean(diseases), Boolean(diseases && diseases.length && diseases[0]))
    const filters = {
      status: "active",
      client: clients[0]._id,
      therapeuticArea: therapeuticAreas[0]._id,
      disease: diseases[0]._id,
    };
    searchCompounds(filters)
      .then(({data}) => {
        props.dispatchSetOptionValuesCompound(data.compounds);
      })
      .catch((error) => {
        console.log(error)
        // TODO handle errors
      })
  }

  const getIndexClient = (client, goBack) => {
    const index = permitted.findIndex(c=>c._id === client._id);
    if(index < 0 && goBack) { // when the client doesn't exist in the client permissions list
      if(props.history){
        props.history.goBack();
      }
      // clear storage
      props.dispatchResetRelatedFields();
      props.enqueueSnackbar(<span>
                      The client {client.name} <br/>
                      was not found in the client permissions list <br/>
                      <b>Check your permissions!</b>
                  </span>, {variant: "error"});
      return index;
    } else{
      return index;
    }
  };

  // const permittedTA = permissions.map(p=>p.therapeuticArea);
  const permittedTA = () => {
    if(!clients.length) return [];

    let allTa = [];
    // clients[0]._id
    clients.forEach(client=>{
      const index = client? getIndexClient(client):-1;
      if(index < 0) return []; // when the ta doesn't exist in the client permissions list
      const ta = permitted[index].permissions.map(p=>p.therapeuticArea);
      allTa = [...allTa, ...ta];
    });

    return allTa;
  }
  
  const permittedDisease = () => {
    let allDiseases = [];
    
    //allready one or more TA selected
    // mapping the diseases inside selected theraputic areas
    if(therapeuticAreas.length) {
      clients.forEach(client=>{
        const clientIndex = getIndexClient(client, true);
        if(clientIndex < 0) return [];
        const clientPermissions = permitted[clientIndex].permissions;
  
        therapeuticAreas.forEach(ta=>{
          if(!ta) return;
          const index = clientPermissions.findIndex(p=> p.therapeuticArea._id === ta._id);
          if(index < 0) { // when the ta doesn't exist in the client permissions list
            if(props.history){
              props.history.goBack();
            }
            // clear storage
            props.dispatchResetRelatedFields();
            return props.enqueueSnackbar(<span>
                      The therapeutic area {ta.name} <br/>
                      was not found in the client permissions list <br/>
                      <b>Check your permissions!</b>
                  </span>, {variant: "error"});

          }
          clientPermissions[index].diseases.forEach(d=> d.name ? allDiseases.push(d) : null)
        })
      });
    }
    
    return allDiseases;
  }

  const permittedEntity = (entity) => {
    if(!clients.length) return [];

    //TODO() uncomment when the filters bring the client field
    //let allEntities = [];
    let allEntities = entity.options;
    if(entity.options && entity.options.length){
      clients.forEach(client=>{
        const c = entity.options.filter(c => c.client && c.client._id === client.id);
        allEntities = [...allEntities, ...c];
      });
    }

    return allEntities;
  };

  const getPermittedCompanies = () => {
    // if there aren't compound options
    if (!compound.options || !compound.options.length) return company.options;

    return getAllCompanies(compounds);
  };

  const getAllCompanies = (selectedCompounds) => {
    if (!selectedCompounds || !selectedCompounds.length) return [];

    let allCompanies = [];
    if (compound.multiple){
      selectedCompounds.forEach(aCompound=>{
        if (!aCompound.originator) return;
        const aCompany = getCompanyById(aCompound.originator._id);
        if(!aCompany) return []; // when the company doesn't exist
        allCompanies = [...allCompanies, aCompany];
      });
    } else{
      if (!selectedCompounds.originator) return [];
      const aCompany = getCompanyById(selectedCompounds.originator._id);
      if(!aCompany) return []; // when the company doesn't exist
      allCompanies = aCompany;
    }

    return allCompanies;
  }

  const getCompanyById = (companyId) => {
    if (!company || !company.options || !company.options.length) return null;
    const c = company.options.filter(aCompany => aCompany._id === companyId);
    return c.length? c[0]:null;
  }

  const handleChanges = (multi, key, selected) => {
    const payload = multi ? selected : [selected];
    switch (key) {
      case 'clients': {
        // change the only selected client
        if(!multi){
          props.dispatchChangeTA([]);
          props.dispatchChangeCompound([]);
        }

        props.dispatchChangeClient(payload);
        break;
      }

      case 'ta':{
        // change the only selected ta
        if(!multi){
          props.dispatchChangeDisease([]);
          props.dispatchChangeCompound([]);
        }

        props.dispatchChangeTA(payload)
        break;
      }
      
      case 'disease':
        props.dispatchChangeDisease(payload);
        props.dispatchChangeCompound([]);
        break;

      case 'compound':
        // TODO() move this inside the redux logic -> instead 2 dispatch, one dispatch and one if in the reducer
        props.dispatchChangeCompound(payload);

        if(payload.length < compounds.length){
          props.dispatchChangeCompany([]);
        }
        
        // autofill feature in news form
        if(company && company.autoFill) {
          props.dispatchChangeCompany(getAllCompanies(payload));
        }

        break;

      case 'company':
        props.dispatchChangeCompany(payload);
        break;

      case 'moa':
        props.dispatchChangeMoa(payload);
        break;

      default:
        break;
    }
  };

  const withGrid = (component, breakpoints) => {
    if(breakpoints){
      return (
          <Grid item xs={12} sm={breakpoints.sm? breakpoints.sm:12} md={breakpoints.md? breakpoints.md:12} >
            {component}
          </Grid>
      )
    } else {
      return component;
    }
  };

  //TODO():improve to show errors
  const withFormControl = (component, formControl) => {
    if(formControl){
      return (
        <FormControl fullWidth={!!formControl.fullWidth} className={formControl.className}>
          {component}
        </FormControl>
      );
    } else{
      return component;
    }
  };


  return (
    <Fragment>
      {client && withGrid(withFormControl(<ComboBoxSimple
          options={permittedClients && permittedClients.length ? permittedClients : []}
          id={'relatedClient'}
          value={client.multiple ? clients : clients[0] || {name: ''}}
          style={{ paddingTop: '10px' }}
          multiple={client.multiple}
          variant={client.variant}
          disabled={client.disabled}
          fullWidth
          handleMyChange={(e, selected)=> handleChanges(client.multiple, 'clients' ,selected)}
          placeHolder={client.placeHolder || 'Client'}
        />, client.formControl), client.breakpoints)}
      
      {therapeuticArea && withGrid(withFormControl(<ComboBoxSimple
          options={permittedTA()}
          id={'relatedTA'}
          value={therapeuticArea.multiple ? therapeuticAreas : therapeuticAreas[0] || {name: ''}}
          style={{ paddingTop: '10px' }}
          multiple={therapeuticArea.multiple}
          variant={therapeuticArea.variant}
          disabled={therapeuticArea.disabled}
          fullWidth
          handleMyChange={(e, selected)=> handleChanges(therapeuticArea.multiple, 'ta' ,selected)}
          placeHolder={therapeuticArea.placeHolder || 'Therapeutic Area'}
        />, therapeuticArea.formControl), therapeuticArea.breakpoints)}
      
      {disease && withGrid(withFormControl(<ComboBoxSimple
          options={permittedDisease()}
          id={'relatedDisease'}
          value={disease.multiple ? diseases : diseases[0] || {name: ''}}
          style={{ paddingTop: '10px' }}
          multiple={disease.multiple}
          variant={disease.variant}
          disabled={disease.disabled}
          fullWidth
          handleMyChange={(e, selected)=> handleChanges(disease.multiple, 'disease' ,selected)}
          placeHolder={disease.placeHolder || 'Disease'}
        />, disease.formControl), disease.breakpoints)}

      {compound && withGrid(withFormControl(<Compounds
          options={mustGetCompoundOptionsFromServer()? optionValues.compounds:permittedEntity(compound)}
          loading={mustGetCompoundOptionsFromServer()? Boolean(optionValues.compounds.loading):[]}
          id={'relatedCompound'}
          value={compound.multiple ? compounds : compounds[0] || {name: ''}}
          style={{ paddingTop: '10px' }}
          multiple={compound.multiple}
          variant={compound.variant}
          disabled={compound.disabled}
          fullWidth
          handleMyChange={(e, selected)=> handleChanges(compound.multiple, 'compound' ,selected)}
          label={compound.placeHolder || 'Compounds'}
      />, compound.formControl), compound.breakpoints)}

      {company && withGrid(withFormControl(<ComboBoxSimple
          options={getPermittedCompanies()}
          id={'relatedCompany'}
          value={company.multiple ? companies : companies[0] || {name: ''}}
          style={{ paddingTop: '10px' }}
          multiple={company.multiple}
          variant={company.variant}
          disabled={company.disabled}
          fullWidth
          handleMyChange={(e, selected)=> handleChanges(company.multiple, 'company' ,selected)}
          placeHolder={company.placeHolder || 'Companies'}
      />, company.formControl), company.breakpoints)}

      {moa && withGrid(withFormControl(<ComboBoxSimple
          options={permittedEntity(moa)}
          id={'relatedMechanismOfActions'}
          value={moa.multiple ? moas : moas[0] || {name: ''}}
          style={{ paddingTop: '10px' }}
          multiple={moa.multiple}
          variant={moa.variant}
          disabled={moa.disabled}
          fullWidth
          handleMyChange={(e, selected)=> handleChanges(moa.multiple, 'moa' ,selected)}
          placeHolder={moa.placeHolder || 'Mechanism Of Actions'}
      />,moa.formControl),moa.breakpoints)}
    </Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    // allClients: state.clients.allClients,
    fields: state.relatedFields,
    permitted: state.app.permitted
  };
};

const mapActionsToProps = {
  // dispatchSetAllClients: setAllClients,
  dispatchChangeClient: setRelatedClient,
  dispatchChangeTA: setRelatedTA,
  dispatchChangeDisease: setRelatedDisease,
  dispatchChangeCompound: setRelatedCompound,
  dispatchChangeCompany: setRelatedCompany,
  dispatchChangeMoa: setRelatedMoa,
  dispatchClearClient: clearRelatedClient,
  dispatchResetRelatedFields: resetRelatedFields,
  dispatchSetOptionValuesCompound: setOptionValuesCompound
};

const RelatedFields = connect(mapStateToProps, mapActionsToProps)(withSnackbar(RelatedFieldsExport));

RelatedFields.propTypes = {
  config: PropTypes.object.isRequired
};

export { RelatedFields };