import React from 'react';
import { Route, useRouteMatch} from 'react-router-dom';
import './styles.scss';
import { COMPANY, CLIENT, COMPOUND, KEYWORD, USERS, EVENTS, TRIAL } from '../../../constants/routes';
import Clients from './Clients';
import Company from './Company';
import Compounds from './Compounds';
import Keyword from './Keyword';
import CompanyAdd from './CompanyAdd';
import ClientAdd from './ClientAdd';
import CompoundAdd from './CompoundAdd';
import KeywordAdd from './KeywordAdd';
import UsersList from './Users/UsersList';
import AddUser from './Users/AddUser';
import EventsList from './EventsList';
import EventAdd from './EventAdd';
import TrialAdd from "./TrialAdd";
import Trials from "./Trials";

const Administration = () => {

    const { path } = useRouteMatch();

    return (
        <section className="administration-wrapper">
            <Route exact path={`${path}`} component={Company} />
            {/*<Redirect exact from={`${path}`} to={`${path}/${CLIENT}`} />*/}

            <Route exact path={`${path}/${COMPANY}`} component={Company} />
            <Route exact path={`${path}/${COMPANY}/add`} component={CompanyAdd} />
            <Route exact path={`${path}/${COMPANY}/edit/:companyId`} component={CompanyAdd} />

            <Route exact path={`${path}/${CLIENT}`} component={Clients} />
            <Route exact path={`${path}/${CLIENT}/add`} component={ClientAdd} />
            <Route exact path={`${path}/${CLIENT}/edit/:clientId`} component={ClientAdd} />

            <Route exact path={`${path}/${COMPOUND}`} component={Compounds} />
            <Route exact path={`${path}/${COMPOUND}/add`} component={CompoundAdd} />
            <Route exact path={`${path}/${COMPOUND}/edit/:compoundId`} component={CompoundAdd} />
            
            <Route exact path={`${path}/${KEYWORD}`} component={Keyword} />
            <Route exact path={`${path}/${KEYWORD}/add`} component={KeywordAdd} />
            <Route exact path={`${path}/${KEYWORD}/edit/:keywordId`} component={KeywordAdd} />

            <Route exact path={`${path}/${USERS}`} component={UsersList} />
            <Route exact path={`${path}/${USERS}/add`} component={AddUser} />
            <Route exact path={`${path}/${USERS}/edit/:userId`} component={AddUser} />

            <Route exact path={`${path}/${EVENTS}`} component={EventsList} />
            <Route exact path={`${path}/${EVENTS}/add`} component={EventAdd} />
            <Route exact path={`${path}/${EVENTS}/edit/:eventId`} component={EventAdd} />

                {/*<Route exact path={`${path}/${TRIAL}`} component={Trials} />
            <Route exact path={`${path}/${TRIAL}/add`} component={TrialAdd} />
            <Route exact path={`${path}/${TRIAL}/edit/:trialId`} component={TrialAdd} />*/}
        </section>
    );
}

export default Administration;