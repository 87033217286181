export const SET_ALL_KEYWORDS = "SET_ALL_KEYWORDS";
export const ADD_KEYWORD = "ADD_KEYWORD";
export const DELETE_KEYWORD = "DELETE_KEYWORD";
export const UPDATE_STATUS_KEYWORD = "UPDATE_STATUS_KEYWORD";
export const UPDATE_KEYWORD = "UPDATE_KEYWORD";
export const SET_ACTIVE_KEYWORD = "SET_ACTIVE_KEYWORD";

export const SET_KEYWORD_LOADING = "SET_KEYWORD_LOADING";
export const SET_MORE_KEYWORDS = "SET_MORE_KEYWORDS";
export const CLEAR_ALL_KEYWORDS = "CLEAR_ALL_KEYWORDS";
export const SET_KEYWORD_QUERY = "SET_KEYWORD_QUERY";

export function setAllKeywords(data) {
  return {
    type: SET_ALL_KEYWORDS,
    payload: data
  };
}

export function setMoreKeywords(data) {
  return {
    type: SET_MORE_KEYWORDS,
    payload: data
  };
}

export function addKeyword(keyword) {
  return {
    type: ADD_KEYWORD,
    payload: keyword
  };
}

export function deleteKeyword(keyword) {
  return {
    type: DELETE_KEYWORD,
    payload: keyword
  };
}

export function updateKeyword(keyword) {
  return {
    type: UPDATE_KEYWORD,
    payload: keyword
  };
}

export function updateStatusKeyword(keyword) {
  return {
    type: UPDATE_STATUS_KEYWORD,
    payload: keyword
  };
}

export function setActiveKeyword(keyword) {
  return {
    type: SET_ACTIVE_KEYWORD,
    payload: keyword
  };
}

export function setKeywordLoading(loading) {
  return {
    type: SET_KEYWORD_LOADING,
    payload: loading
  };
}

export function clearAllKeywords() {
  return {
    type: CLEAR_ALL_KEYWORDS
  }
}

export function setKeywordQuery(query) {
  return {
    type: SET_KEYWORD_QUERY,
    payload: query
  };
}