import React, { useState } from "react";
// import { Redirect, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleLoading } from "../../redux/app/actions";
import LogoVector from "../../assets/img/logo.svg";
import { Button, Card, FormControl, OutlinedInput } from "@material-ui/core";
// import { loginWithCreds } from '../../service/auth';
import AuthLayout from "./AuthLayout";
import { Link } from "react-router-dom";

import { Formik, Form, Field } from 'formik';
import { signUp } from "../../service/auth";
import { useSnackbar } from "notistack";

const initialValues = {
    "email": "",
    "name": {
        "first": "",
        "last": ""
    }
}

const SignUp = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [successMessage, setSuccessMessage] = useState(null)

    const handleSubmit = (values, { setSubmitting }) => {
        dispatch(toggleLoading())
        signUp(values)
            .then(({ data }) => {
                setSuccessMessage(data.message)
                // enqueueSnackbar(data.message, { variant: 'success' });
            })
            .catch(error => {
                console.error(error);
                const { response } = error;
                if (response)
                    enqueueSnackbar(response.data.message, { variant: "error" });
                else
                    enqueueSnackbar("Unexpected Error occured!", { variant: 'error' });
            })
            .finally(() => {
                setSubmitting(false);
                dispatch(toggleLoading())
            })
    }



    return (
        <AuthLayout>
            <Card className="auth-card" id="recovery">

                <div className="welcome-message">
                    <img src={LogoVector} alt="logo-vector" />
                    <h4>Sign Up</h4>
                </div>

                {successMessage ?
                    <div className="instruction-text">
                        <p>{successMessage}</p>
                    </div>
                    :
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {({
                            isSubmitting
                        }) => (
                            <Form autoComplete="off" className="auth-form">
                                <Field name="name.first">
                                    {({ field }) => (
                                        <FormControl>
                                            <OutlinedInput
                                                placeholder="First Name"
                                                fullWidth
                                                variant="outlined"
                                                className="auth-inputs"
                                                type="text"
                                                autoFocus={true}
                                                required
                                                {...field}
                                            />
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="name.last">
                                    {({ field }) => (
                                        <FormControl>
                                            <OutlinedInput
                                                placeholder="Last Name"
                                                fullWidth
                                                variant="outlined"
                                                className="auth-inputs"
                                                type="text"
                                                required
                                                {...field}
                                            />
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="email">
                                    {({ field }) => (
                                        <FormControl>
                                            <OutlinedInput
                                                placeholder="Email"
                                                fullWidth
                                                variant="outlined"
                                                className="auth-inputs"
                                                type="email"
                                                required
                                                {...field}
                                            />
                                        </FormControl>
                                    )}
                                </Field>

                                <Button type="submit" disabled={isSubmitting} color="secondary" variant="outlined" className="accessBtn">
                                    Create Account
                                </Button>

                            </Form>
                        )}
                    </Formik>
                }

                <Link className="reset-link" to="/login" >Back to Login</Link>

            </Card>
        </AuthLayout>
    )
}

export default SignUp;


