/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import $ from "jquery";
import "./ExportButton.scss";

class ExportButton extends Component {
  render(props) {
    return (
      <div className="export">

        <Button
          onClick={() => window.print()}
          variant="contained"
          color="primary"
          style={{ marginRight: "10px", backgroundColor: "#30475C" }}
        >
          <Icon style={{color: 'white'}}>print</Icon>
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={() => this.fixedResolution(props)}
        >
          Export
        </Button>
        
      </div>
    );
  }

  exportAsImage = () => {
    // const that = this;

    html2canvas(document.getElementById("toImage")).then((canvas) => {
      let button = document.getElementById("download");
      let dataURL = canvas.toDataURL("image/png");
      //resize process
      let img = new Image();
      img.src = dataURL;

      button.href = dataURL;
      button.click();
    });
  };

  resizeImage(img, w, h) {
    let result = new Image();
    let canvas = document.createElement("canvas");
    canvas.width = w;
    canvas.height = h;
    canvas.getContext("2d").drawImage(img, 0, 0, w, h);
    result.src = canvas.toDataURL();
    return result;
  }

  

  fixedResolution(props) {
    const reportId = this.props.exportImg;

    let div = document.querySelector(`#${reportId} #toImage`);
    let w = div.offsetWidth;
    let h = div.offsetHeight;
    console.log(w, h)

    // let w = 1000
    // let h = 1000

    //Changing Footnotes Checkbox visibility
    const footnotesCheck = document.querySelector(
      `#${reportId} #toImage #footnotes-check`
    );
    if (footnotesCheck) {
      footnotesCheck.style.visibility = "hidden";
      const footnotesViewType = document.querySelector(
        `#${reportId} #toImage #footnotes-viewtype-toggle`
      );
      if (footnotesViewType) {
        footnotesViewType.style.visibility = "hidden";
      }
    }

    const quality = 2.5; //max 5

    let canvas = document.createElement("canvas");
    canvas.width = w * quality;
    canvas.height = h * quality;
    canvas.style.width = quality*w + "px";
    canvas.style.height = quality*h + "px";
    let context = canvas.getContext("2d");
    context.scale(quality, quality);

    html2canvas(div, {
      canvas: canvas,
      letterRendering: 1,
      allowTaint: false,
      useCORS: true,
      scale: 1,
      onclone: this.onclone
    })
      .then(function (canvas) {
        let dataURL = canvas.toDataURL("image/png");
        saveAs(dataURL, `${reportId}.png`);

        //Restoring Footnotes checkbox visibility

        if (footnotesCheck) {
          const footnotesViewType = document.querySelector(
            `#${reportId} #toImage #footnotes-viewtype-toggle`
          );
          footnotesCheck.style.visibility = "visible";
          if (footnotesViewType) {
            footnotesViewType.style.visibility = "visible";
          }
        }
      })
      .catch((err) => {
        console.log(err)
      });

    // var src = document.getElementById("toImage");
    // let img = new Image();
    // this.takeHighResScreenshot(src, img, 10);
  }

  onclone = (dom)=>{
    const reportId = this.props.exportImg;
    const forImage = $(dom).find(`#${reportId} .for-image`);
    console.log('sanitizing text..')
    forImage.each(function(){
      // const sanitized = sanitize($(this).html());
      console.log($(this).html())
      $(this).html((index, content)=>sanitize(content));
      // $(this).css('word-break', 'break-all');
    })
    // console.log(toImage)

  }



  takeHighResScreenshot(srcEl, destIMG, scaleFactor) {
    // Save original size of element
    var originalWidth = srcEl.offsetWidth;
    var originalHeight = srcEl.offsetHeight;
    // Force px size (no %, EMs, etc)
    srcEl.style.width = originalWidth + "px";
    srcEl.style.height = originalHeight + "px";

    // Position the element at the top left of the document because of bugs in html2canvas. The bug exists when supplying a custom canvas, and offsets the rendering on the custom canvas based on the offset of the source element on the page; thus the source element MUST be at 0, 0.
    // See html2canvas issues #790, #820, #893, #922
    srcEl.style.position = "absolute";
    srcEl.style.top = "0";
    srcEl.style.left = "0";

    // Create scaled canvas
    var scaledCanvas = document.createElement("canvas");
    scaledCanvas.width = originalWidth * scaleFactor;
    scaledCanvas.height = originalHeight * scaleFactor;
    scaledCanvas.style.width = originalWidth + "px";
    scaledCanvas.style.height = originalHeight + "px";
    var scaledContext = scaledCanvas.getContext("2d");
    scaledContext.scale(scaleFactor, scaleFactor);

    html2canvas(srcEl, { canvas: scaledCanvas }).then(function (canvas) {
      canvas.toDataURL("image/png");
      let button = document.getElementById("download");
      button.href = canvas.toDataURL("image/png");
      button.click();
    });
  }
}

export default ExportButton;

export function sanitize(string){
  //replacing space + special charecter
  const str = string.replace(/[^ ]([&\/\\#,+()$~%.\-'":*?<>{}])/g, (match, p1)=>{
    return match[0]+' '+p1;
  });

  //replacing specialcharecter + space
  return str.replace(/([&\/\\#,+()$~%.\-'":*?<>{}])(?!\s)/g, '$1 ');

  // return string.replace(/[^a-zA-Z0-9 ]/g,'_');
}
