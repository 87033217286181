import React from 'react';
import { Checkbox, Input, ListItemText, MenuItem, Select } from '@material-ui/core';

const SelectWithSelectAll = ({ value, options, onChange, ...props }) => {

    const isAllClicked = (event) => {
        let value = event.target.value;
        if (value.includes('all')) {
            value.splice(value.indexOf('all'), 1);
            value = options.length === value.length ? [] : options;
        }

        onChange(value)
    }

    return (
        <Select
            multiple
            value={value}
            onChange={isAllClicked}
            input={<Input />}
            displayEmpty={true}
            {...props}
        >
            {(options && options.length) &&
                <MenuItem key={'selectAll'} value="all">
                    <Checkbox checked={value.length === options.length} />
                    <ListItemText primary={"Select All"} />
                </MenuItem>
            }
            {options && options.map((option) => {
                if (typeof option === 'string') return (
                    <MenuItem key={option} value={option}>
                        <Checkbox checked={value.indexOf(option) > -1} />
                        <ListItemText primary={option.toUpperCase()} />
                    </MenuItem>
                )

                return (
                    <MenuItem key={option._id} value={option}>
                        <Checkbox checked={value.includes(option)} />
                        <ListItemText primary={option.name} />
                    </MenuItem>
                )
            })}
        </Select>
    );
}

export default SelectWithSelectAll;