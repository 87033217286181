import { randomBytes, createCipheriv } from 'crypto';

const cipherKey = "0a9202f45798a7ca3e3a013d09c3f93bfebf1402f0e91a220fdbbeb4495df2da";

export const encryptPassword = async (
    text,
    iv,
    key = cipherKey,
    algorithm = 'aes-256-cbc'
) => {
    iv = iv || randomBytes(16);
    let cipher = createCipheriv(algorithm, Buffer.from(key, 'hex'), iv);
    let encryptedText = cipher.update(Buffer.from(text));
    encryptedText = Buffer.concat([encryptedText, cipher.final()]);
    return {
        iv: iv.toString('hex'),
        encryptedText: encryptedText.toString('hex')
    };
};