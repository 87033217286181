import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { Grid, Button, Card } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));



export default function MobileModal({ children, open, handleClose, label }) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="Mobile-modal-title"
        aria-describedby="Mobile-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
          <Card className="mobile-modal-container" 
            style={{
                padding: '25px', 
                height: '100vh', 
                width: '100vw',
                fontFamily: "Poppins",
                overflow: 'auto'
            }}
          >
            {/* <Grid  spacing={2}> */}
                <Grid 
                    container
                    justify="space-between"
                    style={{marginBottom: '20px',}}
                >
                    <b style={{fontSize: '26px'}}>{label}</b>
                    <Button onClick={handleClose} variant="text">Cancel</Button>
                </Grid>
                <Grid container justify="center">
                    {children}
                </Grid>
            {/* </Grid> */}
          </Card>
      </Modal>
    </div>
  );
}
