import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import {List, Grid} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import {makeStyles} from "@material-ui/core/styles";
import { connect } from 'react-redux';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { groupBy } from 'lodash';

const useStyles = makeStyles({
    clientTitle: {
        fontWeight: 'bold'
    }
});

const DiseaseList = ({ permitted, permissions, onChange, forwardRef, ...props }) => {
    const [categories, setCategories] = useState([]);
    const [openId, setOpenId] = useState(false);

    const [selectedDisease, setSelectedDisease] = useState(new Set());

    const [loadingClients, setLoadingClients ] = useState(false);
    const [clients, setClients] = useState([]);
    const [firstLevelOpenId, setFirstLevelOpenId] = useState(false);

    const classes = useStyles();

    forwardRef(() => { // select Everything
        const filteredPermission = categories.filter(c=> !!c.diseases.length);
        const allPermission = filteredPermission.map(p=>{
            const therapeuticArea = {
                name: p.name,
                id: p.id
            };

            const diseases = p.diseases.map(d=>{
                return {name: d.name, id: d._id}
            });

            return {therapeuticArea, diseases}

        })
        onChange(allPermission)
    });

    // to load the permissions on the combos
    useEffect(() => {
        setLoadingClients(true);
        const grouped = groupBy(permitted, (p) => {
            return p._id;
        });

        const intermediate = Object.keys(grouped).map(clientId => {
            const client = permitted.find((client) => client._id === clientId);
            return {
                clientId,
                name: client.name? client.name:"no name",
                tA: grouped[clientId].flatMap(group => {
                    return group.permissions.map(permission=>{
                        //let diseases = allDiseases.filter(d=>d.therapeuticArea === ta._id);
                        const diseases = permission.diseases;
                        return {
                            name: permission.therapeuticArea.name,
                            _id: permission.therapeuticArea._id,
                            id: permission.therapeuticArea._id,
                            diseases
                        }
                    })
                })
            }
        });

        const categories = intermediate.flatMap(client=>{
            return client.tA.map(ta => ({
                name: ta.name,
                _id: ta.id,
                id: ta.id,
                diseases: ta.diseases
            }));

        });

        setClients(intermediate);
        setCategories(categories);

        setLoadingClients(false);

    }, [permitted]);

    // to load the permissions from out the component
    useEffect(()=>{
        // matching Data
        // console.log('matching data...')
        const sD = new Set();
        if(permissions.length){
            permissions.forEach(p=>{
                p.diseases && p.diseases.forEach(d=>sD.add(d.id))
            })
            // console.log(sD);
        } 

        setSelectedDisease(sD);
    }, [permissions]);


    const findPermissionByTa = (id) => {
        const index = permissions.findIndex(p=>p.therapeuticArea.id === id);
        if (index === -1){
            return null
        }

        const {diseases} = permissions[index];

        return {index, diseases}
    }


    const handleClick = (index) => {
        const toBe = index === openId ? false : index;
        setOpenId(toBe);
    };

    const handleFirstLevelClick = (index) => {
        const toBe = index === firstLevelOpenId ? false : index;
        setFirstLevelOpenId(toBe);
    };

    const handleChecked = (catI, j, dI) => {
        const ta = clients[j].tA[catI];
        const disease = ta.diseases[dI];
        const dId = disease._id;

        const pCopy = [...permissions];
        const permission = findPermissionByTa(ta.id);

        // const sTAcopy = new Set(selectedTA);
        const sDcopy = new Set(selectedDisease);

        if (sDcopy.has(dId)) { // remove selection
            sDcopy.delete(dId);
            const diseases = pCopy[permission.index].diseases.filter(d=> d.id !== dId);
            if(diseases.length){
                pCopy[permission.index].diseases = diseases;
            }else{
                pCopy.splice(permission.index, 1)
            }
        } else { // select the item
            sDcopy.add(dId);
            if(permission){
                pCopy[permission.index].diseases.push({
                    name: disease.name,
                    id: dId
                })
            }else{
                pCopy.push({
                    therapeuticArea: {id: ta.id, name: ta.name},
                    diseases: [{
                        name: disease.name,
                        id: dId
                    }]
                })
            }
        }

        setSelectedDisease(sDcopy);
        onChange(pCopy);
    };

    const handleSelectAll = (i, j) => {
        const catId = clients[j].tA[i].id
        const catName = clients[j].tA[i].name;

        const pCopy = [...permissions];
        const permission = findPermissionByTa(catId);

        // const sTAcopy = new Set(selectedTA);
        const sDcopy = new Set(selectedDisease);
        
        if (checkIfAllSelected(i, j)) { // remove all selection
            // sTAcopy.delete(catId);
            clients[j].tA[i].diseases.forEach(d => sDcopy.delete(d._id));

            if(permission){
                // console.log(permission)
                pCopy.splice(permission.index, 1);
            }
        } else { // select all
            // sTAcopy.add(catId)
            const diseases = clients[j].tA[i].diseases.map(d => {
                sDcopy.add(d._id);
                return {id: d._id, name: d.name}
            });

            if(permission) { // add all disease
                pCopy[permission.index].diseases = diseases;
            } else { // create new permission
                pCopy.push({
                    therapeuticArea: {id: catId, name: catName},
                    diseases
                })
            }
        }

        setSelectedDisease(sDcopy);
        onChange(pCopy)
    };

    const checkIfAllSelected = (i, j) => {
        const notSelected = clients[j].tA[i].diseases.filter(d => !selectedDisease.has(d._id));
        return !notSelected.length;
    };

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className="permissionsList"
        >
            {!loadingClients && clients.map((client, j) => (
                <React.Fragment key={`${j}-${client._id}`}>
                    <ListItem button onClick={() => handleFirstLevelClick(j)}>
                        <ListItemText primary={client.name} classes={{primary: classes.clientTitle}} />
                        {j === firstLevelOpenId ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={j === firstLevelOpenId} >
                        <List component="div" disablePadding>
                            {client.tA.map((cat, i) => (
                                <React.Fragment key={`${i}-${cat._id}`}>
                                    <ListItem button onClick={() => handleClick(i)}>
                                        <ListItemText primary={cat.name} />
                                        {i === openId ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>

                                    {!!cat.diseases.length &&
                                    <Collapse in={i === openId} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItem button onClick={() => handleSelectAll(i, j)} className="nested">
                                                <ListItemText primary={`Select All`} />
                                                <Checkbox
                                                    edge="start"
                                                    checked={checkIfAllSelected(i, j)}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': 'Select All' }}
                                                />
                                            </ListItem>
                                            {cat.diseases.map((d, di) => (
                                                <ListItem key={d._id} button onClick={() => handleChecked(i, j, di)} className="nested">
                                                    <ListItemText primary={d.name} />
                                                    <Checkbox
                                                        edge="start"
                                                        checked={selectedDisease.has(d._id)}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': d.name }}
                                                    />
                                                </ListItem>
                                            ))}

                                        </List>
                                    </Collapse>}
                                </React.Fragment>
                            ))}
                        </List>
                    </Collapse>
                </React.Fragment>
            ) )}
            {(loadingClients) &&
                <Grid item sm={12} md={12} lg={12}>
                    <SkeletonTheme color="#E2DEDE">
                    <p className="skeleton-p">
                        <Skeleton width={"100%"} count={4} height={"5vh"} />
                    </p>
                    </SkeletonTheme>
                </Grid>
            }

        </List>
    );
}

DiseaseList.prototype = {
    onChange: PropTypes.func,
    permissions: PropTypes.array
}

const mapStateToProps = (state) => {
    const { permitted } = state.app;
    const { allClients } = state.clients;
    return {
        allClients,
        permitted
    }
};

export default connect(mapStateToProps, {})(DiseaseList);

