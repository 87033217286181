import React, { Component, Fragment } from "react";
// import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { toggleLoading } from "../../redux/app/actions";
import LogoVector from "../../assets/img/logo.svg";
import { Button, Card, FormControl, OutlinedInput } from "@material-ui/core";
import { AuthContext } from "../../auth/AuthContext";
// import { loginWithCreds } from '../../service/auth';
import AuthLayout from "./AuthLayout";
import { recoverPassword } from "../../service/auth";
import { withSnackbar } from "notistack";
import { Link } from "react-router-dom";


class Recovery extends Component {
    static contextType = AuthContext;
    state = {
        email: '',
        sent: false,
        loading: false,
    }

    componentDidMount() {
        // if (this.context.isLoggedIn()) {
        //   this.setState({ redirect: true });
        // }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log('submiting!');
        this.props.toggleLoading();
        recoverPassword(this.state.email)
            .then(({data})=>{
                this.setState({sent: true})
            })
            .catch(({response})=>{
                if(!response){
                    this.props.enqueueSnackbar('Unexpected error occured!', {variant: 'error'})
                }else{
                    const {message} = response.data;
                    this.props.enqueueSnackbar(message, {variant: 'error'})
                }
            })
            .finally(()=>{
                this.props.toggleLoading();
            })
    }

    render() {
        const { email, sent } = this.state;
        return (
            <AuthLayout>
                <Card className="auth-card" id="recovery">

                    <div className="welcome-message">
                        <img src={LogoVector} alt="logo-vector" />
                        <h4>Account Recovery</h4>

                    </div>

                    <div className="instruction-text">
                        {sent
                            ? <p>
                                We send you an email to {email} to help you recover your password
                            </p>
                            : <Fragment>
                                <p className="punch-line">Did you forget your password?</p>
                                <p>
                                    That’s ok, we will help you recover it. Just fill your Email and we`ll send you a link to reset your password
                                </p>
                            </Fragment>
                        }
                    </div>

                    <form autoComplete="off" className="auth-form" onSubmit={this.handleSubmit}>
                        {!sent && <FormControl>
                            <OutlinedInput
                                placeholder="Email"
                                fullWidth
                                variant="outlined"
                                className="auth-inputs"
                                onChange={(e) =>
                                    this.setState({ email: e.currentTarget.value })
                                }
                                value={email}
                                type="email"
                                autoFocus={true}
                                required
                            />
                        </FormControl>}

                        {sent && <p style={{fontSize: '16px'}}>Didn’t you get it?</p>}

                        <Button type="submit" color="secondary" variant="outlined" className="accessBtn">
                            {sent ? 'Send Again' : 'Send'}
                        </Button>
                        <Link className="reset-link" to="/login" >Back to Login</Link>
                    </form>
                </Card>
            </AuthLayout>
        )
    }
}

const actionsToProps = {
    toggleLoading: toggleLoading
};



export default connect(null, actionsToProps)(withSnackbar(Recovery));


