import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
// import Divider from "@material-ui/core/Divider";

import { withSnackbar } from "notistack";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose } from "redux";
import NewsTable from "./NewsTable";
import { searchNews} from "./../../../service/news";
import {
  setFilteredNews,
  SetAllNews,
  setFiltersOptions,
  resetAll
} from "../../../redux/news/actions";

const styles = (theme) => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    width: "auto",
    //marginTop: "-30px",
    fontFamily: 'Poppins'
    // padding: "20px 30px",
  },
});

class News extends Component {
  constructor(props) {
    super();
    this.state = {
      filterNews: props.filterNews,
      ready: false,
    };
  }
  succesInitial = (data) => {
    this.props.dispatchSetFilteredNews(data);
    this.setState({ ready: true });
  };

  componentDidMount() {

    if(!this.props.client){
      this.props.enqueueSnackbar("Please, select a client first!", {variant: 'error'});
      return this.props.history.push('/');
    }

    searchNews(this.succesInitial, this.callbackError);
  }
  
  callbackError = (err, variant) => {
    this.props.enqueueSnackbar(err, variant);
  };

  callbackSucces = (data) => {
    this.props.dispatchSetFilteredNews(data);
    // this.props.dispatchSetAllNews(data);
  };
  // succesFilter = (filters) => {
  //   this.props.dispatchSetFilterOptions(filters);
  // };

  componentWillUnmount() {
    this.props.dispatchResetAll();
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <NewsTable data={this.props.filtered}></NewsTable>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { allNews, filtered, allFilters } = state.news;
  const { client } = state.app;

  return {
    allNews,
    filtered,
    allFilters,
    client
  };
};
const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    {
      dispatchSetAllNews: SetAllNews,
      dispatchSetFilteredNews: setFilteredNews,
      dispatchSetFilterOptions: setFiltersOptions,
      dispatchResetAll: resetAll
    },
    dispatch
  );

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps)
)(withSnackbar(News));

// export default withSnackbar(News);
