import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflowX: 'hidden',
    flexWrap: 'nowrap',
    padding: '10px 0px 10px 0px'
  },
  element: {
    margin: 'auto 5px'
  },
  buttonRoot: {
    // paddingTop: '12px'
  },
  chevronButton: {
    padding: '0px 0px 0px 0px'
  }
}));

/**
 * Render horizontal elements horizontally
 * @param elements - array of the elements to be rendered
 * @param querySelector - to start scrolled to the element position
 * @returns {JSX.Element}
 * @constructor
 */
const HorizontalScrollItems = ({ elements,
                                 querySelector = '.active',
                               }) => {
  const navRef = React.createRef();
  const classes = useStyles();

  const [atBeginning, setAtBeginning] = useState(false);
  const [atEnd, setAtEnd] = useState(false);
  const [currentScroll, setCurrentScroll] = useState(0);
  const [refs, setRefs] = useState([]);
  const [indexElement, setIndexElement] = useState(0);
  const [startFromIndex, setStartFromIndex] = useState(0);
  const [initialized, setInitialized] = useState(false);
  const [hasScrollbar, setHasScrollbar] = useState(false);

  useEffect(()=>{
    if(!initialized && startFromIndex >= 0 && startFromIndex <= refs.length-1){
      if (indexElement !== startFromIndex){
        handleNav('right');
      }
      if(indexElement === startFromIndex) setInitialized(true);
    }
  },[refs, startFromIndex, indexElement]);

  useEffect(()=>{
    querySelector !== "" && refs.length>0 && refs.map((ref,index) => {
      if([...ref.current.querySelectorAll(querySelector)].length){
        setInitialized(false);
        setStartFromIndex(index);
      }
    });
  },[refs]);

  useEffect(()=>{
    if(navRef.current) {
      // current scroll
      setCurrentScroll(navRef.current.scrollLeft);
      // check if is scrollable
      checkIsHasScrollbar();
    }
    // set references
    let references =  [];
    elements.map((e) => {
      references.push(React.createRef());
    })
    setRefs(references);
  },[navRef.current]);

  useEffect(()=>{
    const maximum = navRef.current.scrollWidth - navRef.current.clientWidth;
    setAtBeginning(!currentScroll);
    setAtEnd(currentScroll === maximum);
  },[currentScroll]);

  const checkIsHasScrollbar = () => {
    setHasScrollbar(navRef.current.scrollWidth > navRef.current.clientWidth);
  }

  const handleNav = (direction) => {
    if(direction === 'left'){
      const index = indexElement>0? indexElement - 1:indexElement;
      setIndexElement(index);
      const scroll = navRef? navRef.current.scrollLeft -= refs[index].current.scrollWidth : null;
      setCurrentScroll(navRef.current.scrollLeft);
      return scroll;
    } else{
      const index = indexElement<elements.length-1? indexElement + 1:indexElement;
      setIndexElement(index);
      const scroll =  navRef? navRef.current.scrollLeft += refs[index].current.scrollWidth : null;
      setCurrentScroll(navRef.current.scrollLeft);
      return scroll;
    }
  }

  return (
    <>
      <span className={classes.buttonRoot}>
        {hasScrollbar && <IconButton disabled={atBeginning} className={classes.chevronButton} aria-label="delete" onClick={() => handleNav('left')}>
          <ChevronLeft />
        </IconButton>}
      </span>
      <div className={classes.root} ref={navRef}>
        {elements.map((element, index) =>
          <span ref={refs[index]} key={index} className={classes.element}>{element}</span>)
        }
      </div>
      <span className={classes.buttonRoot}>
        {hasScrollbar && <IconButton disabled={atEnd} className={classes.chevronButton} aria-label="delete" onClick={() => handleNav('right')}>
          <ChevronRight />
        </IconButton>}
      </span>
    </>
  );
}

export default HorizontalScrollItems;