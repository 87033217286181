import React, { Component } from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";

class InvalidRecords extends Component {
  constructor(props) {
    super(props);
    const button = props.button && props.button === "hidden" ? false : true;

    this.state = { button, visible: !button };
  }

  toggleInvalidRecords = () => {
    this.setState({ visible: !this.state.visible });
  };

  render() {
    const { visible, button } = this.state;
    const { totalRecord, leftBehind, headers } = this.props;

    return (
      <div className="left-behind">
        {button && (
          <Button
            onClick={this.toggleInvalidRecords}
            variant="contained"
            color="primary"
            style={{ textTransform: "unset" }}
          >
            {visible ? "Hide" : "Show"} Invalid Records
          </Button>
        )}

        {visible && (
          <React.Fragment>
            <h3>
              Invalid Records - {leftBehind.length} / {totalRecord}
            </h3>
            <table>
              <thead>
                <tr>
                  {headers.map((header, i) => (
                    <th key={i}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {leftBehind.map((row, i) => {
                  return (
                    <tr key={i}>
                      {row.map((item, itemI) => (
                        <td align="center" key={`item-${itemI}`}>
                          {item}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </React.Fragment>
        )}
      </div>
    );
  }
}

InvalidRecords.propTypes = {
  totalRecord: PropTypes.number,
  leftBehind: PropTypes.array,
  headers: PropTypes.array
};

export default InvalidRecords;
