import Axios from './';
const path = '/diseases/';


export const getDisease = ({ taId, dId }, callBackSuccess, callBackError) => {
    Axios
        .get(`/diseases/${taId}/${dId}`)
        .then(response => {
            callBackSuccess(response.data)
        })
        .catch(err => {
            callBackError(err)
        })
}


export const deleteDisease = (id) => {
    return Axios.delete(path + id)
}


export const addDisease = (disease, callBackSuccess, callBackError) => {
    Axios
        .post(path, disease)
        .then(response => {
            callBackSuccess(response.data.disease)
        })
        .catch(err => {
            callBackError(err)
        })
};

export const updateDisease = (id, data, callBackSuccess, callBackError) => {
    Axios
        .put(path + id, data)
        .then(response => {
            callBackSuccess(response.data)
        })
        .catch(err => {
            console.log(err)
            callBackError(err)
        })
}




export async function getDiseaseData(id) {
    return Axios.get("/root/disease-data/" + id);
}

export async function getGroupReportData({ dId, taId }) {
    return Promise.all([
        getDiseaseData(dId),
        // Promise.resolve({data: [[]]}), // for report diesase association test
        Axios.get(`/diseases/${taId}/${dId}`)
    ])
}

export function getAllDiseases(callbackSuccess, callbackError) {
    return Axios.get(`${path}`).then(res => {
        callbackSuccess(res.data);
    }).catch(err => {
        console.log(err.response);
        callbackError(err);
    });
}