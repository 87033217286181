export const requiredCol = [
  "H/M/L; New; NA",
  "Compound phase",
  "Product name",
  "MOA (Revised)",
  "Company Name",
  "Segment",
  "Condition treated",
  "Est. Launch",
  "Launch Assumptions",
  "RoA",
  "NCT link",
  "US",
  "EU",
  "Japan",
  "Rest of World",
];

export function verifyColumns(data, required=requiredCol) {
  const colsOnData = required.filter((col) => data[0].includes(col));
  const notAvailabe = required.filter((col) => !colsOnData.includes(col));
  return notAvailabe;
}

export function separateSegments(data) {
  const withAllSeg = [];
  const segIn = data[0].indexOf("Segment");

  data.forEach((row, index) => {
    // for headers and rows which doesn't have segments
    if (index === 0 || !row[segIn]) {
      withAllSeg.push(row);
      return;
    }
    row[segIn].split(",").forEach((seg) => {
      const dupRow = [...row];
      dupRow[segIn] = seg.trim();
      withAllSeg.push(dupRow);
    });
  });
  return withAllSeg;
}

