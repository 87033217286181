import {
  SET_ALL_COMPANIES,
  SET_MORE_COMPANIES,
  ADD_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,
  SET_ACTIVE_COMPANY,
  SET_COMPANY_LOADING,
  SET_COMPANY_QUERY} from "./actions";

const initialState = {
  allCompanies: [],
  nextPage: null,
  loading: true,
  active: null,
  query: null,
  total: 0
};

export default function companiesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ALL_COMPANIES: {
      const duplicateState = { ...state };
      duplicateState.allCompanies = payload.companies;
      duplicateState.nextPage = payload.nextPage;
      duplicateState.total = payload.total;
      duplicateState.loading = false;
      return duplicateState;
    }
    
    case SET_MORE_COMPANIES: {
      const duplicateState = { ...state };
      duplicateState.allCompanies = [...state.allCompanies, ...payload.companies];
      duplicateState.nextPage = payload.nextPage;
      duplicateState.total = payload.total;
      duplicateState.loading = false;
      return duplicateState;
    }

    case DELETE_COMPANY: {
      const allCompanies = [...state.allCompanies];
      const index = allCompanies.findIndex(c=> c._id === payload._id);
      allCompanies.splice(index, 1);
      return {...state, allCompanies};
    }

    case ADD_COMPANY: {
      const allCompanies = [...state.allCompanies];
      allCompanies.splice(0, 0, payload);
      return {...state, allCompanies};
    }

    case UPDATE_COMPANY: {
      const allCompanies = [...state.allCompanies];
      const index = allCompanies.findIndex(c=> c._id === payload._id);
      allCompanies.splice(index, 1, payload);
      return {...state, allCompanies};
    }

    case SET_ACTIVE_COMPANY: {
      const duplicateState = { ...state };
      duplicateState.active = payload;
      return duplicateState
    }
    
    case SET_COMPANY_LOADING: {
      const duplicateState = { ...state };
      duplicateState.loading = payload;
      return duplicateState;
    }

    case SET_COMPANY_QUERY: {
      const duplicateState = { ...state };
      duplicateState.query = payload;
      return duplicateState
    }

    default:
      return state;
  }
}
