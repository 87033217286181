import _ from 'lodash';

export const TOGGLE_LOADING = "app:toggleLoading";
export const TOGGLE_AUTHENTICATION = "app:toggleAuthentication";
export const SET_CURRENT_CLIENT = "app:setClient";
export const SET_APP_PERMISSIONS = "app:setAppPermissions";
export const CLEAR_CURRENT_CLIENT = "app:clearCurrentClient";

export const DELETE_TA = 'DELETE_TA';
export const UPDATE_TA = 'UPDATE_TA';
export const ADD_TA = 'ADD_TA';
export const DELETE_DISEASE = 'DELETE_DISEASE';
export const UPDATE_DISEASE = 'UPDATE_DISEASE';
export const ADD_DISEASE = 'ADD_DISEASE';

export function toggleLoading() {
  return {
    type: TOGGLE_LOADING,
  };
}

export function toggleAuthentication(toBe) {
  return {
    type: TOGGLE_AUTHENTICATION,
    payload: toBe,
  };
}

export function setCurrentClient(toBe) {

  return {
    type: SET_CURRENT_CLIENT,
    payload: toBe,
  };
}

export function clearCurrentClient() {
  return {
    type: CLEAR_CURRENT_CLIENT
  }
}

export function setAppPermissions(permissions) {
  return {
    type: SET_APP_PERMISSIONS,
    payload: permissions,
  };
}


export const deleteTa = (clientId, taId) => {
  return {
      type: DELETE_TA,
      payload: {clientId, taId}
  }
}

export const updateTa = (clientId, ta) => {
  return {
      type: UPDATE_TA,
      payload: {clientId, ta}
  }
}

export const addTa = (clientId, ta) => {
  const payload = {
    clientId,
    ta: {_id: ta._id, name: ta.name}
  }

  return {
      type: ADD_TA,
      payload
  }
}

export const dispatchDeleteDisease = (clientId, taId, dId) => {
  return {
      type: DELETE_DISEASE,
      payload: {clientId, taId, dId}
  }
}

export const dispatchUpdateDisease = (clientId, taId, updatedDisease) => {
  const disease = _.pick(updatedDisease, ["_id", "name"]);
  return {
      type: UPDATE_DISEASE,
      payload: {clientId, taId, disease}
  }
}

export const dispatchAddDisease = (clientId, taId, newDisease) => {
  const disease = _.pick(newDisease, ["_id", "name"]);
  return {
      type: ADD_DISEASE,
      payload: {clientId, taId, disease}
  }
}


