import React from "react";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    borderWidth: '2px',
    fontWeight: 600
  }
}));

const AtacanaChip = ({ text, color = 'secondary', variant = 'outlined', isBig = false, className = '' }) => {
  const classes = useStyles();
  return (
      <Chip
          label={text}
          color={color}
          variant={variant}
          size={!isBig? "small":"medium"}
          className={classes.root+" "+className}
      />
  )
};

export { AtacanaChip };