import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const ITEM_HEIGHT = 48;

/*
 * options: to be rendered
 *    - component: to show into MenuItems
 *    - onClick(): action
 * data: the one that is affected by the action
 */
export default function ActionMenu({ options, data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option) => {
    setAnchorEl(null);
    if(option.onClick){
      option.onClick(data);
    }
  };

  const renderOpts = options.filter(option => {
    let opt;
    if(typeof option.component === 'function'){
      opt = option.component(data);
    } else{
      opt = option.component;
    }
    return !opt? false:true;
  }).filter(opt => opt);
  return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        {renderOpts.length > 0?
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '22ch',
              },
            }}
          >
            {renderOpts.map((option, i) => {
              return (
              <MenuItem key={`menu_item_${i}`} onClick={() => {handleClose(option);}}>
                {typeof option.component === 'function'? option.component(data):option.component}
              </MenuItem>
            )})}
          </Menu>
          :
          null
        }
      </div>
  );
}