import React from 'react';
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import './styles.scss';

const SearchBox = ({doSearch, onReset, value, id, ...rest}) => {

  const doReset = () => {
    if(onReset)
      onReset();
    else
      console.log('no reset function...');
  }


  return ( 
    <div id={id} className="search-container">
      <SearchIcon onClick={doSearch} className="search-button"></SearchIcon>
      <input
        className="search-text"
        onKeyDown={(e) => e.keyCode === 13 ? doSearch(): null}
        value={value}
        {...rest}
      ></input>
      {value && <ClearIcon onClick={doReset} className="clear-icon"></ClearIcon>}
    </div> 
  );
}

export default SearchBox;