import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {connect} from 'react-redux';
import { withSnackbar } from 'notistack';
import * as moment from "moment";
import * as momentTimezone from "moment-timezone";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { ChevronLeft } from '@material-ui/icons';

import AdminBase from './AdminBase';
import FormBase from './FormBase';
import AddTrialIcon from '../../../assets/img/addTrialIcon.svg';
import { toggleLoading } from '../../../redux/app/actions';
import { createNewTrial, editTrial } from '../../../service/trials';
import { getFilters } from "../../../service/events";
import {
  resetRelatedFields,
  setRelatedClient,
  setRelatedCompound,
  setRelatedDisease,
  setRelatedTA
} from "../../../redux/relatedFields/actions";
import { addEvent, setEventQuery, updateEvent } from "../../../redux/events/actions";
import { EVENT_TYPES } from '../../../constants/events';

import FormStepper, {LinearStep} from "../../common/FormStepper";

const TrialAdd = ({ enqueueSnackbar,
                    dispatchToggleLoading,
                    dispatchAddEvent,
                    dispatchSetCompanyQuery,
                    activeEvent,
                    selected,
                    ...props }) => {

  const [formIsValid, setFormIsValid] = useState(false);

  const [eventTitle, setEventTitle] = useState('');
  const [startsDate, setStartsDate] = useState(null);
  const [endsDate, setEndsDate] = useState(null);
  const [status, setStatus] = useState(true);
  const [importantStatus, setImportantStatus] = useState(false);
  const [eventType, setEventType] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [timezone, setTimezone] = useState('');
  const [dynamic, setDynamic] = useState({});
  const [otherDrugs, setOtherDrugs] = useState([]);

  const { trialId } = useParams();

  useEffect(() =>  {
    return () => {
      props.dispatchResetRelatedFields();
    }
  }, []);

  useEffect(() => {
    if (trialId) {
      activeEvent ? initExisting(activeEvent) : goBack();
    }
  }, [activeEvent]);

  useEffect(() => {
    if(selected.clients.length){
      getFilters(selected.clients[0]._id).catch(e => {
        enqueueSnackbar(e.message, {variant: 'error'});
        props.history.goBack();
      });
    }
  },[selected.clients]);

  const initExisting = (trial) => {
    props.dispatchSetRelatedClient(trial.client? [trial.client] : []);
    props.dispatchSetRelatedTA(trial.therapeuticArea? [trial.therapeuticArea]:[]);
    props.dispatchSetRelatedDisease(trial.disease? [trial.disease] : []);
    props.dispatchSetRelatedCompound(trial.compound? [trial.compound] : []);
    /*setEventTitle(trial.title);
    setEventDescription(trial.description);
    setEventType(trial.type);
    setStartsDate(moment(`${trial.startDateTime}`).utc());
    setEndsDate(moment(`${trial.endDateTime}`).utc());
    setTimezone(trial.timezone);
    setStatus(trial.status === 'active' ? true : false);
    setImportantStatus(trial.isImportant);*/
  }

  const handleImportantStatusChange = ({ target }) => {
    setImportantStatus(target.checked);
  };

  const renderImportantStatus = (status) => {
    const customStyle = {
      color: '#56C6CC',
      margin: '8px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '21px'
    };
    return status ?
      <span style={customStyle}>YES</span> :
      <span style={customStyle}>NO</span>;
  }

  const clientTaDisease = [
    {
      type: 'relatedClientTaDisease',
      toUpperCase: true,
      sm: 12,
      relatedFields: {
        client: true,
        therapeuticArea: true,
        disease: true
      }
    },
  ];

  const trialInformation1 = [
    {
      placeHolder: "Title",
      onChange: title => setEventTitle(title),
      type: 'input',
      id: 'eventTitle',
      name: 'eventTitle',
      key: 'eventTitle',
      required: true,
      value: eventTitle,
      sm: 6
    },
    {
      id: "trialStatus",
      name: 'trialStatus',
      type: 'select',
      required: true,
      value: eventType,
      onChange: (e, value) => setEventType(value),
      placeHolder: "TRIAL STATUS",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      placeHolder: "Trial Identifier",
      onChange: title => setEventTitle(title),
      type: 'input',
      id: 'trialIdentifier',
      name: 'trialIdentifier',
      key: 'trialIdentifier',
      required: true,
      value: eventTitle,
      sm: 12
    },
    {
      id: 'startDateTime',
      name: 'startDateTime',
      type: 'date',
      key: 'startDateTime',
      required: true,
      label: 'Start Date',
      onChange: date => {
        setStartsDate(date);
      },
      value: startsDate,
      sm: 6
    },
    {
      placeHolder: "Acronyms",
      onChange: title => setEventTitle(title),
      type: 'input',
      id: 'acronyms',
      name: 'acronyms',
      key: 'acronyms',
      value: eventTitle,
      sm: 6
    },
    {
      id: "startDateType",
      name: 'startDateType',
      type: 'select',
      required: true,
      value: eventType,
      onChange: (e, value) => setEventType(value),
      placeHolder: "START DATE TYPE",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      id: "lineOfTherapy",
      name: 'lineOfTherapy',
      type: 'select',
      value: eventType,
      onChange: (e, value) => setEventType(value),
      placeHolder: "Line of Therapy",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      placeHolder: "Patient Segment by Disease",
      onChange: title => setEventTitle(title),
      type: 'input',
      id: 'patientSegmentByDisease',
      name: 'patientSegmentByDisease',
      key: 'patientSegmentByDisease',
      value: eventTitle,
      sm: 6
    },
    {
      id: "patientSegmentByAge&Sex",
      name: 'patientSegmentByAge&Sex',
      type: 'select',
      required: true,
      value: eventType,
      onChange: (e, value) => setEventType(value),
      placeHolder: "Patient Segment by Age & Sex",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      key: 'dynamic',
      sm: 12,
      required: true,
      id: "enrollment",
      name: 'enrollment',
      type: 'dynamicField',
      value: dynamic,
      onChange: data => setDynamic(data),
      placeHolder: "ENROLLMENT",
      options: [
        {
          placeHolder: "Enrollment Number",
          onChange: title => setEventTitle(title),
          type: 'input',
          id: 'enrollmentNumber',
          name: 'enrollmentNumber',
          key: 'enrollmentNumber',
          required: true,
          value: eventTitle,
          sm: 6
        },
        {
          id: "enrollmentType",
          name: 'enrollmentType&Sex',
          type: 'select',
          required: true,
          value: eventType,
          onChange: (e, value) => setEventType(value),
          placeHolder: "Enrollment TYPE",
          options: EVENT_TYPES,
          sm: 6
        },
      ]
    },
  ];

  const trialInformation2 = [
    {
      id: "pcdType",
      name: 'pcdType',
      type: 'select',
      value: eventType,
      onChange: (e, value) => setEventType(value),
      placeHolder: "PCD TYPE",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      id: "phase",
      name: 'phase',
      type: 'select',
      required: true,
      value: eventType,
      onChange: (e, value) => setEventType(value),
      placeHolder: "PHASE",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      id: 'dosageBeingEvaluated',
      name: 'dosageBeingEvaluated',
      type: 'rich-textarea',
      placeHolder: "Dosage being evaluated",
      onChange: description => setEventDescription(description),
      value: eventDescription,
      sm: 12
    },
    {
      type: 'chipInput',
      id: 'otherDrugs',
      name: 'otherDrugs',
      required: true,
      value: otherDrugs,
      handleAddChip: (newOtherName) => addChipName(newOtherName, otherDrugs, setOtherDrugs),
      handleDeleteChip: (nameToQuit) => quitChipName(nameToQuit, otherDrugs, setOtherDrugs),
      placeHolder: "Other Drugs",
      key: 'name.other',
      sm: 12
    },
    {
      id: "comparator",
      name: 'comparator',
      type: 'select',
      value: eventType,
      onChange: (e, value) => setEventType(value),
      placeHolder: "COMPARATOR",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      id: "primaryDrugs",
      name: 'primaryDrugs',
      type: 'select',
      required: true,
      value: eventType,
      onChange: (e, value) => setEventType(value),
      placeHolder: "PRIMARY DRUGS",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      id: "class",
      name: 'class',
      type: 'select',
      value: eventType,
      onChange: (e, value) => setEventType(value),
      placeHolder: "CLASS",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      id: 'routeOfAdministration',
      name: 'routeOfAdministration',
      key: 'routeOfAdministration',
      value: eventType,
      type: 'select',
      required: true,
      onChange: roa => setEventType(roa),
      placeHolder: "Route of Administration",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      id: 'biomarkers',
      name: 'biomarkers',
      key: 'biomarkers',
      value: eventType,
      type: 'select',
      options: EVENT_TYPES,
      required: true,
      onChange: roa => setEventType(roa),
      placeHolder: "BIOMARKERS",
      sm: 6
    },
    {
      id: 'diagnosticTest',
      name: 'diagnosticTest',
      key: 'diagnosticTest',
      value: eventType,
      type: 'select',
      options: EVENT_TYPES,
      required: true,
      onChange: roa => setEventType(roa),
      placeHolder: "Diagnostic Test",
      sm: 6
    },
    {
      id: 'therapyType',
      name: 'therapyType',
      key: 'therapyType',
      value: eventType,
      type: 'select',
      options: EVENT_TYPES,
      required: true,
      onChange: roa => setEventType(roa),
      placeHolder: "Therapy Type",
      sm: 6
    },
    {
      id: 'primaryCompletionDate',
      name: 'primaryCompletionDate',
      type: 'date',
      key: 'primaryCompletionDate',
      label: 'Primary Completion Date',
      onChange: date => {
        setStartsDate(date);
      },
      value: startsDate,
      sm: 6
    },
  ];

  const trialInformation3 = [
    {
      id: "sponsors",
      name: 'sponsors',
      type: 'select',
      required: true,
      value: eventType,
      onChange: (e, value) => setEventType(value),
      placeHolder: "SPONSORS",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      id: "collaborators",
      name: 'collaborators',
      type: 'select',
      required: true,
      value: eventType,
      onChange: (e, value) => setEventType(value),
      placeHolder: "COLLABORATORS",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      id: 'objective',
      name: 'objective',
      type: 'rich-textarea',
      placeHolder: "Objective",
      onChange: description => setEventDescription(description),
      value: eventDescription,
      sm: 12
    },
    {
      id: "countries",
      name: 'countries',
      type: 'select',
      required: true,
      value: eventType,
      onChange: (e, value) => setEventType(value),
      placeHolder: "COUNTRIES",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      id: "regions",
      name: 'regions',
      type: 'select',
      required: true,
      value: eventType,
      onChange: (e, value) => setEventType(value),
      placeHolder: "REGIONS",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      id: "priority",
      name: 'priority',
      type: 'select',
      required: true,
      value: eventType,
      onChange: (e, value) => setEventType(value),
      placeHolder: "PRIORITY",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      id: "status",
      type: 'switch',
      value: importantStatus,
      name: "status",
      label: 'Status',
      switchColor: "primary",
      onChange: e => handleImportantStatusChange(e),
      render: () => renderImportantStatus(),
      sm: 3
    },
    {
      id: 'notes',
      name: 'notes',
      placeHolder: "NOTES",
      onChange: data => setDynamic(data),
      type: 'dynamicField',
      key: 'notes',
      value: dynamic,
      sm: 12,
      required: true,
      options: [
        {
          id: 'details',
          name: 'details',
          placeHolder: "Details",
          type: 'input',
          key: 'name',
          required: true,
          sm: 12
        },
      ]
    },
  ];

  const trialDetail1 = [
    {
      key: 'trialArms',
      sm: 12,
      required: true,
      id: "trialArms",
      name: 'trialArms',
      type: 'dynamicField',
      value: dynamic,
      onChange: data => setDynamic(data),
      placeHolder: "TRIALS ARMS",
      options: [
        {
          placeHolder: "Arms Details",
          onChange: title => setEventTitle(title),
          type: 'input',
          id: 'armsDetails',
          name: 'armsDetails',
          key: 'armsDetails',
          required: true,
          value: eventTitle,
          sm: 12
        },
      ]
    },
    {
      key: 'primaryEndpoints',
      sm: 12,
      required: true,
      id: "primaryEndpoints",
      name: 'primaryEndpoints',
      type: 'dynamicField',
      value: dynamic,
      onChange: data => setDynamic(data),
      placeHolder: "PRIMARY ENDPOINT",
      options: [
        {
          placeHolder: "Details",
          onChange: title => setEventTitle(title),
          type: 'input',
          id: 'details',
          name: 'details',
          key: 'details',
          required: true,
          value: eventTitle,
          sm: 12
        },
        {
          key: 'endpoints',
          sm: 12,
          required: true,
          id: "endpoints",
          name: 'endpoints',
          type: 'dynamicField',
          value: dynamic,
          onChange: data => setDynamic(data),
          placeHolder: "ENDPOINTS",
          options: [
            {
              id: "group",
              name: 'group',
              type: 'select',
              required: true,
              value: eventType,
              onChange: (e, value) => setEventType(value),
              placeHolder: "GROUP",
              options: EVENT_TYPES,
              sm: 6
            },
            {
              id: "subgroup",
              name: 'subgroup',
              type: 'select',
              required: true,
              value: eventType,
              onChange: (e, value) => setEventType(value),
              placeHolder: "SUBGROUP",
              options: EVENT_TYPES,
              sm: 6
            },
            {
              placeHolder: "Primary Endpoint",
              onChange: title => setEventTitle(title),
              type: 'input',
              id: 'primaryEndpoint',
              name: 'primaryEndpoint',
              key: 'primaryEndpoint',
              required: true,
              value: eventTitle,
              sm: 12
            },
          ]
        },
      ]
    },
    {
      key: 'secondayEndpoints',
      sm: 12,
      required: true,
      id: "secondayEndpoints",
      name: 'secondayEndpoints',
      type: 'dynamicField',
      value: dynamic,
      onChange: data => setDynamic(data),
      placeHolder: "SECONDARY ENDPOINT",
      options: [
        {
          placeHolder: "Details",
          onChange: title => setEventTitle(title),
          type: 'input',
          id: 'details',
          name: 'details',
          key: 'details',
          required: true,
          value: eventTitle,
          sm: 12
        },
        {
          key: 'endpoints',
          sm: 12,
          required: true,
          id: "endpoints",
          name: 'endpoints',
          type: 'dynamicField',
          value: dynamic,
          onChange: data => setDynamic(data),
          placeHolder: "ENDPOINTS",
          options: [
            {
              id: "group",
              name: 'group',
              type: 'select',
              required: true,
              value: eventType,
              onChange: (e, value) => setEventType(value),
              placeHolder: "GROUP",
              options: EVENT_TYPES,
              sm: 6
            },
            {
              id: "subgroup",
              name: 'subgroup',
              type: 'select',
              required: true,
              value: eventType,
              onChange: (e, value) => setEventType(value),
              placeHolder: "SUBGROUP",
              options: EVENT_TYPES,
              sm: 6
            },
            {
              placeHolder: "Secondary Endpoint",
              onChange: title => setEventTitle(title),
              type: 'input',
              id: 'secondaryEndpoint',
              name: 'secondaryEndpoint',
              key: 'secondaryEndpoint',
              required: true,
              value: eventTitle,
              sm: 12
            },
          ]
        },
      ]
    },
  ]

  const trialDetail2 = [
    {
      placeHolder: "Min Age",
      onChange: title => setEventTitle(title),
      type: 'input',
      id: 'minAge',
      name: 'minAge',
      key: 'minAge',
      value: eventTitle,
      sm: 6
    },
    {
      id: "minAgeUnits",
      name: 'minAgeUnits',
      type: 'select',
      value: eventType,
      onChange: (e, value) => setEventType(value),
      placeHolder: "MIN AGE UNITS",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      placeHolder: "Max Age",
      onChange: title => setEventTitle(title),
      type: 'input',
      id: 'maxAge',
      name: 'maxAge',
      key: 'maxAge',
      value: eventTitle,
      sm: 6
    },
    {
      id: "maxAgeUnits",
      name: 'maxAgeUnits',
      type: 'select',
      value: eventType,
      onChange: (e, value) => setEventType(value),
      placeHolder: "MAX AGE UNITS",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      id: "gender",
      name: 'gender',
      type: 'select',
      value: eventType,
      onChange: (e, value) => setEventType(value),
      placeHolder: "GENDER",
      options: EVENT_TYPES,
      sm: 6
    },
    {
      placeHolder: "Inclusion Criteria",
      onChange: title => setEventTitle(title),
      type: 'input',
      id: 'inclusionCriteria',
      name: 'inclusionCriteria',
      key: 'inclusionCriteria',
      value: eventTitle,
      sm: 6
    },
    {
      placeHolder: "Exclusion Criteria",
      onChange: title => setEventTitle(title),
      type: 'input',
      id: 'exclusionCriteria',
      name: 'exclusionCriteria',
      key: 'exclusionCriteria',
      value: eventTitle,
      sm: 6
    },
    {
      placeHolder: "Study Design",
      onChange: title => setEventTitle(title),
      type: 'input',
      id: 'studyDesign',
      name: 'studyDesign',
      key: 'studyDesign',
      value: eventTitle,
      sm: 6
    },
    {
      type: 'chipInput',
      id: 'studyKeywords',
      name: 'studyKeywords',
      required: true,
      value: otherDrugs,
      handleAddChip: (newOtherName) => addChipName(newOtherName, otherDrugs, setOtherDrugs),
      handleDeleteChip: (nameToQuit) => quitChipName(nameToQuit, otherDrugs, setOtherDrugs),
      placeHolder: "Study Keywords",
      key: 'name.other',
      sm: 12
    },
    {
      placeHolder: "Patient Population",
      onChange: title => setEventTitle(title),
      type: 'input',
      id: 'patientPopulation',
      name: 'patientPopulation',
      key: 'patientPopulation',
      value: eventTitle,
      sm: 6
    },
    {
      placeHolder: "Treatment Plan",
      onChange: title => setEventTitle(title),
      type: 'input',
      id: 'treatmentPlan',
      name: 'treatmentPlan',
      key: 'treatmentPlan',
      value: eventTitle,
      sm: 6
    },
  ];

  const trialResults = [
    {
      id: 'details',
      name: 'details',
      placeHolder: "Details",
      type: 'input',
      key: 'name',
      required: true,
      sm: 12
    },
    {
      id: "resultsMeetExpectation",
      type: 'switch',
      value: status,
      label: 'Results Met?',
      switchColor: 'secondary',
      name: "resultsMeetExpectation",
      onChange: e => handleStatusChange(e),
      render: () => renderImportantStatus(status),
      sm: 12
    }
  ]

  const addChipName = (newOtherName, getter, setter) => {
    const values = [...getter];
    values.push(newOtherName);
    setter(values);
  }

  const quitChipName = (index, getter, setter) => {
    const values = [...getter];
    const filteredItems = values.filter(item => item !== index);
    setter(filteredItems);
  }

  const renderStatus = () => {
    const customStyle = { margin: '7px' }
    return status ?
      <VisibilityOutlinedIcon style={customStyle} color="action" /> :
      <VisibilityOffOutlinedIcon style={customStyle} color="action" />
  }

  const handleStatusChange = ({ target }) => {
    setStatus(target.checked);
  };

  const goBack = () => {
    props.history.goBack();
  };

  const admTitleComponent = (
    <Link to="#" onClick={goBack}>
      <h2><ChevronLeft /> {trialId ? 'Update' : 'Add'} Trial</h2>
    </Link>
  );

  /*const buttons = [
    {
      onClick: () => handleCancelClick(),
      variant: 'outlined',
      text: 'cancel'
    },
    {
      variant: 'contained',
      type: 'submit',
      text: eventId ? 'update' : 'add'
    },
  ]

  const handleCancelClick = () => {
    goBack();
  };

  const validate = () => {
    let valid = true;
    if (!timezone || timezone === '') {
      enqueueSnackbar("The Event must have a Timezone!", { variant: 'warning' });
      valid = false;
    }
    return valid;
  }*/

  const handleTrialAdd = () => {
    dispatchToggleLoading();

    const trial = buildTrial();

    createNewTrial(trial, (newEvent) => {
      dispatchToggleLoading();
      dispatchAddEvent(newEvent)
      enqueueSnackbar("Event Added!", {
        variant: "success",
      });
      goBack();
    }, ({ response }) => {
      dispatchToggleLoading();
      if (response) {
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(`Error occurred while creating the trial.`, {
          variant: "error",
        });
      }
    })
  };

  const handleTrialUpdate = () => {
    dispatchToggleLoading();
    const trial = buildTrial();
    editTrial(trialId, trial)
      .then(({ data }) => {
        props.dispatchUpdateEvent(data);
        goBack();
      })
      .catch(({ response }) => {
        if (response) {
          enqueueSnackbar(response.data.message, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(`Error occurred while creating the trial.`, {
            variant: "error",
          });
        }
      })
      .finally(() => {
        dispatchToggleLoading();
      })
  }

  const buildTrial = () => {
    const trial = {};
    /*trial.client = selected.clients.length? selected.clients[0]._id:"";
    trial.therapeuticArea = selected.therapeuticAreas.length? selected.therapeuticAreas[0]._id:"";
    trial.disease = selected.diseases.length? selected.diseases[0]._id:"";
    trial.compound = selected.compounds.length? selected.compounds[0]._id:"";
    trial.title = eventTitle;
    trial.type = eventType;
    trial.description = eventDescription? eventDescription:"";
    trial.startDateTime = moment(startsDate).format("YYYY-MM-DD HH:mm:ss");
    trial.endDateTime = moment(endsDate).format("YYYY-MM-DD HH:mm:ss");
    trial.timezone = timezone;
    trial.isImportant = importantStatus;
    trial.status = status ? 'active' : 'inactive';*/
    return trial;
  };

  const formBaseFields = {
    iconImage: AddTrialIcon,
    title: "Add a New Trial",
    validateOnMount: true,
    onChangeValidity: (value) => setFormIsValid(true)
  };

  return (
    <AdminBase
      titleComponent={admTitleComponent}
      showFilters={false}
      showSearchBox={false}
    >
      <FormStepper enableNext={formIsValid}
                   finishText={`${trialId ? 'Update' : 'Create'}`}
                   onFinalStep={trialId ? handleTrialUpdate : handleTrialAdd}>
        <LinearStep label={"Client & TA & Disease"}>
          <FormBase
            formComponents={clientTaDisease}
            {...formBaseFields}
            onChangeValidity={value => {
              setFormIsValid(true);
            }}
          />
        </LinearStep>
        <LinearStep label={"Trial Information Part 1"}>
          <FormBase
            formComponents={trialInformation1}
            onChangeValidity={setFormIsValid}
            {...formBaseFields}
          />
        </LinearStep>
        <LinearStep label={"Trial Information Part 2"}>
          <FormBase
            formComponents={trialInformation2}
            onChangeValidity={(value) => {
              console.log(value);
              setFormIsValid(value)
            }}
            {...formBaseFields}
          />
        </LinearStep>
        <LinearStep label={"Trial Information Part 3"}>
          <FormBase
            formComponents={trialInformation3}
            onChangeValidity={setFormIsValid}
            {...formBaseFields}
          />
        </LinearStep>
        <LinearStep label={"Trial Detail Part 1"}>
          <FormBase
            formComponents={trialDetail1}
            onChangeValidity={setFormIsValid}
            {...formBaseFields}
          />
        </LinearStep>
        <LinearStep label={"Trial Detail Part 2"}>
          <FormBase
            formComponents={trialDetail2}
            onChangeValidity={setFormIsValid}
            {...formBaseFields}
          />
        </LinearStep>
        <LinearStep label={"Trial Results"}>
          <FormBase
            formComponents={trialResults}
            {...formBaseFields}
          />
        </LinearStep>
      </FormStepper>

    </AdminBase>
  )
}

const mapStateToProps = (state) => {
  const { active, query } = state.events;
  const { filterOptions } = state.news;
  const { therapeuticAreas, diseases,  compounds,  clients } = state.relatedFields;
  return {
    activeEvent: active,
    eventQuery: query,
    filterOptions,
    selected: {
      clients,
      therapeuticAreas,
      diseases,
      compounds
    }
  };
};

const mapActionsToProps = {
  dispatchToggleLoading: toggleLoading,
  dispatchAddEvent: addEvent,
  dispatchUpdateEvent: updateEvent,
  dispatchSetEventQuery: setEventQuery,
  dispatchResetRelatedFields: resetRelatedFields,
  dispatchSetRelatedDisease: setRelatedDisease,
  dispatchSetRelatedClient: setRelatedClient,
  dispatchSetRelatedTA: setRelatedTA,
  dispatchSetRelatedCompound: setRelatedCompound
};

export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(TrialAdd));