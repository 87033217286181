import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import TablePagination from '@material-ui/core/TablePagination';
import { Paper, Hidden } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { get } from 'lodash';

import { setActiveUser } from "../../redux/users/actions";

import ActionMenu from "../ActionMenu/ActionMenu";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: 24,
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  body: {
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#F9F6F6",
      },
    },
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#DDDADA",
      },
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    // padding: 20,
  },

  pagination: {
    fontFamily: 'Poppins',
    color: '#8A8A8A'
  }
});

/*
 * columns: table columns
 *     - key: to which the data is accessed
 *     - name: column name,
 *     - showOnMdUp: if true, the column will be showed when width reduces
 * className: of table root
 * data: to be rendered
 * rowActions: actions to showed up at the end of row
 * loading: true when the data is loading
 * actionsPermission: function to decide if current user have permission to
 */
function GenericTable({
  columns,
  className,
  data,
  rowActions,
  actionsPermission,
  loading,
  pagination
}) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const renderHeader = () => (
    <TableHead>
      <TableRow>
        {columns.map((col, i) => {
          return <StyledTableCell key={i} align={col.align ? col.align : "center"}>{col.name}</StyledTableCell>;
        })}
        <StyledTableCell key={"actions"} align="center"></StyledTableCell>
      </TableRow>
    </TableHead>
  );

  const renderBody = () => (
    <TableBody>
      {(perPage > 0
        ? data.slice(page * perPage, page * perPage + perPage)
        : data
      ).map((row, index) => (
        <React.Fragment key={index}>
          {/* More width */}
          <Hidden smDown>
            <StyledTableRow>
              {columns.map((col, i) => {
                return <StyledTableCell key={i} className={col.rowClassName ? col.rowClassName : ''} align={col.align ? col.align : "center"}>
                  {col.renderRow ?
                    col.renderRow(row)
                    :
                    <div dangerouslySetInnerHTML={{
                      __html: get(row, col.key)
                    }}></div>
                  }
                </StyledTableCell>;
              })}
              <StyledTableCell align="center">
                <ActionMenu options={rowActions} data={row} />
              </StyledTableCell>
            </StyledTableRow>
          </Hidden>
          {/* Less width */}
          <Hidden mdUp>
            <StyledTableRow>
              {// eslint-disable-next-line
              columns.map((col, i) => {
                if (col.showOnMdUp) {
                  return <StyledTableCell key={i} className={col.rowClassName ? col.rowClassName : ''} align="left">
                    {col.renderRow ?
                      col.renderRow(row)
                      :
                      <div dangerouslySetInnerHTML={{
                        __html: get(row, col.key)
                      }}></div>
                    }
                  </StyledTableCell>;
                }
              })}
              <StyledTableCell align="right">
                {actionsPermission && actionsPermission(row) ?
                  "test" :
                  null
                }
                {!actionsPermission && <ActionMenu options={rowActions} data={row} />}
              </StyledTableCell>
            </StyledTableRow>
          </Hidden>

        </React.Fragment>
      ))}
      {renderEmptyRow()}
    </TableBody>
  );

  const renderEmptyRow = () => {
    if (!data || !data.length) {
      return <StyledTableRow>
        <StyledTableCell align="center" colSpan={columns.length + 1}>
          No data yet
        </StyledTableCell>
      </StyledTableRow>
    } else {
      return null;
    }
  };

  const onChangePage = async (e, p) => {
    const end = p * perPage >= data.length;
    if (end && pagination) await pagination.loadMore();
    setPage(p);
  }

  // Fix: when the pagination changes, it must reset the page number
  useEffect( () => {
    if(pagination && pagination.total > 0){
      setPage(0);
    }
  }, pagination ? [pagination.total] : []);

  const onChangeRowsPerPage = async (e) => {
    const newPerPage = e.target.value;
    const end = (page+1) * newPerPage >= data.length;
    if (end) {
      setPage(0);
      if(pagination) await pagination.loadMore();
    }
    setPerPage(newPerPage)
  }

  const renderTable = () => (
    <>
      <TableContainer className={className} component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          {renderHeader()}
          {renderBody()}
        </Table>
      </TableContainer>

      <Grid container justify="flex-end" className="usersPagination">
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          count={pagination ? pagination.total : data.length}
          className={classes.pagination}
          page={page}
          onChangePage={onChangePage}
          rowsPerPage={perPage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </Grid>
    </>
  );


  const renderLoading = () => (
    <div>
      <Skeleton height={70} />
      <Skeleton animation={false} height={70} />
      <Skeleton animation="wave" height={70} />
      <Skeleton animation="wave" height={70} />
      <Skeleton animation="wave" height={70} />
    </div>
  );

  return (
    <React.Fragment>
      { !loading ?
        renderTable() : renderLoading()
      }
    </React.Fragment>

  );
}

const mapActionToProps = {
  dispatchSetActiveUser: setActiveUser
};

export default connect(null, mapActionToProps)(GenericTable);
