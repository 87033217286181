import React, { Component } from "react";
// import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { toggleLoading } from "../../redux/app/actions";
import LogoVector from "../../assets/img/logo.svg";
import { Button, Card, FormControl, FormHelperText } from "@material-ui/core";
// import { loginWithCreds } from '../../service/auth';
import AuthLayout from "./AuthLayout";
import { Password } from "../common";
import { updatePassword } from "../../service/auth";
import { withSnackbar } from "notistack";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import { fullValidatorForSchema, passwordValidator } from "../../helpers/utils";


const initialValues = {
    password: '',
    confirmPass: ''
}

const ResetPasswordSchema = Yup.object().shape({
    password: passwordValidator,
    confirmPass: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required')
});

class ResetPassword extends Component {

    componentDidMount() {
        const { token } = this.props.match.params;

        if (!token) {
            this.props.enqueueSnackbar('No Token!', { variant: 'error', persist: true })
        }
    }

    handleSubmit = (values, { setSubmitting }) => {
        this.props.toggleLoading();

        const { token } = this.props.match.params;
        const { password } = values;

        updatePassword({ token, password })
            .then(({ data }) => {
                this.props.enqueueSnackbar('Password set successfully', { variant: 'success' })
                setTimeout(() => {
                    this.props.history.push('/');
                }, 2000);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.props.enqueueSnackbar('Unexpected error occured!', { variant: 'error' })
                } else {
                    const { message } = response.data;
                    this.props.enqueueSnackbar(message, { variant: 'error' })
                }

                if(response.status === 404) {
                    this.props.history.push('/forgot-password')
                }
            })
            .finally(() => {
                this.props.toggleLoading();
                setSubmitting(false);
            })
    }

    render() {
        return (
            <AuthLayout>
                <Card className="auth-card" id="reset-password">

                    <div className="welcome-message">
                        <img src={LogoVector} alt="logo-vector" />
                        <h4>Change Password</h4>

                    </div>
                    <Formik
                        initialValues={initialValues}
                        validate={fullValidatorForSchema(ResetPasswordSchema)}
                        validationSchema={ResetPasswordSchema}
                        onSubmit={this.handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form autoComplete="off" className="auth-form" >

                                <Field name="password">
                                    {({
                                        field,
                                        form: { touched, errors }
                                    }) => {
                                        const showError = Boolean(touched['password'] && errors['password']);
                                        return (
                                            <FormControl error={showError} className="authFormControl">
                                                <Password
                                                    {...field}
                                                    fullWidth
                                                    placeholder="New Password"
                                                    variant="outlined"
                                                    className="auth-inputs"
                                                    notched={false}
                                                />

                                                {showError && errors['password'].map((error, index) => (
                                                    <FormHelperText key={index} style={{ color: 'red' }}>
                                                        {error}
                                                    </FormHelperText>
                                                ))}

                                            </FormControl>

                                        )
                                    }}
                                </Field>

                                <Field name="confirmPass">
                                    {({
                                        field, // { name, value, onChange, onBlur }
                                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                        meta,
                                    }) => {
                                        const showError = Boolean(touched['confirmPass'] && errors['confirmPass']);
                                        return (<FormControl error={showError} className="authFormControl">
                                            <Password
                                                {...field}
                                                fullWidth
                                                placeholder="Confirm Password"
                                                variant="outlined"
                                                className="auth-inputs"
                                                notched={false}

                                            />

                                            {showError && <FormHelperText style={{ color: 'red' }}>
                                                {meta.error}
                                            </FormHelperText>}

                                        </FormControl>)

                                    }}
                                </Field>

                                <Button type="submit" disabled={isSubmitting} color="secondary" variant="outlined" className="accessBtn">
                                    Change
                                </Button>


                            </Form>


                        )}
                    </Formik>
                    <Link className="reset-link" to="/login" >Back to Login</Link>

                </Card>
            </AuthLayout>
        )
    }
}

const actionsToProps = {
    toggleLoading: toggleLoading
};


export default connect(null, actionsToProps)(withSnackbar(ResetPassword));


